import { compose, withHooks } from "enhancers";
import { Box, Typography } from "components";
import { map, isEmpty } from "lodash";
import htmlReactParser from "html-react-parser";
import {
  formatPhoneNumber,
  formatFullName,
  formatDate,
  toDate,
} from "utils/helper";
import { differenceInDays, startOfToday } from "date-fns";

const BuyerInfoCard = (props) => (
  <Box
    style={{ background: "#E7F4FD", borderRadius: 8, width: 512 }}
    p={2}
    mt={6}
  >
    {map(props.details, (detail, index) => (
      <Box key={index} display="flex" mt={index === 0 ? 0 : 2}>
        <Typography width={140}>{detail.label}</Typography>
        <Typography width={24} mx={1}>
          :
        </Typography>
        <Typography flex={1}>{detail.value}</Typography>
      </Box>
    ))}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info = {} } = props;
    const { useMemo } = hooks;

    const buyerDetails = useMemo(() => {
      return [
        {
          label: "ชื่อผู้ซื้อ",
          value: formatFullName(info.firstName, info.lastName),
        },
        {
          label: "วันที่ต้องส่งถึง",
          value: info.deliveryDate
            ? `${formatDate(
                info.deliveryDate,
                "dd/MM/yyyy"
              )} (อีก ${differenceInDays(
                toDate(info.deliveryDate),
                startOfToday()
              )} วัน)`
            : "-",
        },
        {
          label: "เวลาที่ต้องส่งของ",
          value: info.deliveryTime ? `${info.deliveryTime} น.` : "-",
        },
      ];
    }, [info]);

    const addressDetails = useMemo(() => {
      const deliveryAddress = info.deliveryAddress || {};

      const isBangkok = deliveryAddress.province === "กรุงเทพมหานคร";
      const subDistrictPrefix = isBangkok ? "แขวง" : "ตำบล";
      const districtPrefix = isBangkok ? "เขต" : "อำเภอ";
      const addressDetails = [
        formatFullName(deliveryAddress.firstName, deliveryAddress.lastName),
        deliveryAddress.address,
        `${
          deliveryAddress.subDistrict
            ? `${subDistrictPrefix} ${deliveryAddress.subDistrict}`
            : ""
        } ${
          deliveryAddress.district
            ? `${districtPrefix} ${deliveryAddress.district}`
            : ""
        }`,
        `${deliveryAddress.province ?? ""} ${deliveryAddress.zipCode ?? ""}`,
      ].filter((detail) => !/^ *$/.test(detail || ""));

      return [
        {
          label: "ที่อยู่ส่งของ",
          value: isEmpty(addressDetails)
            ? "-"
            : map(addressDetails, (detail, index) => (
                <Box mt={index === 0 ? 0 : 2}>{detail}</Box>
              )),
        },
        {
          label: "โทรศัพท์",
          value: deliveryAddress.phoneNumber
            ? formatPhoneNumber(deliveryAddress.phoneNumber)
            : "-",
        },
        { label: "หมายเหตุ", value: deliveryAddress.remark ?? "-" },
      ];
    }, [info]);

    const brandDetails = useMemo(() => {
      return [
        { label: "แบรนด์", value: info.brand ?? "-" },
        {
          label: "บันทึกเพิ่มเติม",
          value: (
            <div className="renderHtmlFromString">
              {info.note ? htmlReactParser(info.note) : "-"}
            </div>
          ),
        },
      ];
    }, [info]);

    const details = useMemo(() => {
      return [...buyerDetails, ...addressDetails, ...brandDetails];
    }, [buyerDetails, addressDetails, brandDetails]);

    return { details };
  })
);

export default enhancer(BuyerInfoCard);
