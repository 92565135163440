import React from "react";
import { compose, withHooks, withFormik } from "enhancers";
import {
  Form,
  Field,
  TextField,
  Button,
  Alert,
  Avatar,
  Paper,
  Typography,
  Helmet,
  Link,
  Box,
} from "components";
import { gql, Yup, paths } from "utils/helper";
import { setToken } from "api";

type SignInPageProps = {
  errorMessage?: string;
  shouldDisplayRememberPasswordCheckbox?: boolean;
};

const SignInPage = (props: SignInPageProps) => (
  <Paper width="100%" p={10}>
    <Helmet title="Setup Password" />
    <Avatar
      alt="Moreloop"
      src="/static/img/logo.jpg"
      variant="square"
      mx="auto"
    />
    <Typography component="h1" variant="h4" align="center" mt={10}>
      เข้าสู่ระบบ
    </Typography>
    <Typography component="h2" variant="caption" align="center" mt={2}>
      กรอกอีเมลและรหัสผ่านเพื่อเข้าสู่ระบบ
    </Typography>
    <Form>
      {props.errorMessage && (
        <Alert severity="error" mt={6}>
          {props.errorMessage}
        </Alert>
      )}
      <Field
        component={TextField.Email}
        name="email"
        label="อีเมล"
        fullWidth
        mt={6}
      />
      <Field
        component={TextField}
        name="password"
        type="password"
        label="รหัสผ่าน"
        fullWidth
        mt={6}
      />
      {/*<Field
          component={Checkbox}
          name="isRememberPassword"
          type="checkbox"
          label="จำรหัสผ่าน"
          mt={6}
        />
      )*/}
      <Button type="submit" color="primary" fullWidth mt={10}>
        เข้าสู่ระบบ
      </Button>
      <Box
        height={32}
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={2}
      >
        <Link to={paths.resetPasswordPath()}>ลืมรหัสผ่าน</Link>
      </Box>
    </Form>
  </Paper>
);

const API = {
  LOGIN: gql`
    mutation LOGIN($email: String!, $password: String!) {
      signIn(input: { email: $email, password: $password }) {
        currentUser {
          email
          authenticationToken
          ownerType
          ownerData {
            seller {
              isProfileDataInitialize
            }
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      email: "",
      password: "",
      isRememberPassword: false,
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string().email("รูปแบบอีเมลไม่ถูกต้อง"),
      password: Yup.string()
        .min(8, "รหัสผ่านต้องไม่น้อยกว่า 8 ตัวอักษร")
        .max(10, "รหัสผ่านต้องน้อยกว่าหรือเท่ากับ 10 ตัวอักษร")
        .matches(
          /^\w*[A-z]\w*[0-9]\w*$|^\w*[0-9]\w*[A-z]\w*$/,
          "รูปแบบรหัสผ่านไม่ถูกต้อง"
        )
        .required("รูปแบบรหัสผ่านไม่ถูกต้อง"),
    }),
  }),
  withHooks((props: any, hooks: any) => {
    const { useHandleSubmit, useMutation, useMemo } = hooks;
    const [login, { loading, error }] = useMutation(API.LOGIN);

    useHandleSubmit(
      async (values: any) => {
        const { email, password } = values;
        const { data } = await login({
          variables: { email, password },
        });
        const { signIn } = data || {};
        const { currentUser } = signIn || {};

        const authenticationToken = currentUser.authenticationToken;
        setToken(authenticationToken);

        paths.homePath().reload();
      },
      [login]
    );

    const errorMessage = useMemo(() => {
      if (!loading && error) {
        return "อีเมลหรือรหัสผ่านไม่ถูกต้อง";
      }
    }, [loading, error]);

    return { errorMessage };
  })
);

export default enhancer(SignInPage);
