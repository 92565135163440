import { compose, withHooks, withFormik, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Tabs,
  Tab,
  Paper,
  Button,
  Hidden,
  Modal,
  TextField,
  Typography,
  Field,
} from "components";
import {
  gql,
  paths,
  cleanTypename,
  notifyError,
  notifySuccess,
  toCurrency,
  getErrorMessage,
} from "utils/helper";

import { cloneDeep } from "lodash";

import OrderEditPage from "./edit";
import CancelRemarkCard from "./CancelRemarkCard";

import PurchaseOrderHistoryPage from "./history";
import appStore from "stores/appStore";

const PurchaseOrderShowPage = (props) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={[]}
    paper={false}
  >
    {props.initialized ? (
      <Box mt={4}>
        {/* <Hidden when={!props.canceled}>
          <CancelRemarkCard message={props.canceledRemark} />
        </Hidden> */}
        {/* <Box display="flex" justifyContent="space-between" alignItems="center">
          <Tabs value={props.tabState} onChange={props.changeTabState}>
            <Tab label="สถานะใบสั่งสินค้า" />
            <Tab label="ประวัติการแก้ไข" />
          </Tabs>
          <Hidden when={!props.canCancel}>
            <Button
              onClick={props.showCancelPurchaseOrderModal}
              style={{ color: "#F34336", borderColor: "#F34336" }}
            >
              ยกเลิกใบสั่งสินค้า
            </Button>
          </Hidden>
        </Box> */}
        <Paper px={4} pt={4} pb={6}>
          <Box display={props.tabState === 0 ? "block" : "none"}>
            <OrderEditPage
              configs={props.configs}
              purchaseOrder={props.purchaseOrder}
              reloadPurchaseOrder={props.reloadPurchaseOrder}
            />
          </Box>
          {/*<Box display={props.tabState === 1 ? "block" : "none"}>
            <PurchaseOrderHistoryPage
              histories={props.purchaseOrderHistories}
            />
          </Box>*/}
        </Paper>
      </Box>
    ) : null}
  </PageContent>
);

export const API = {
  FETCH_PURCHASE_ORDER: gql`
    query FETCH_PURCHASE_ORDER($id: ID!) {
      purchaseOrder(id: $id) {
        id
        oldSystemPurchaseOrderCode
        code
        orderedAt
        orderedRemark
        paymentedAt
        receivedAt
        finalPrice
        paymentDocuments
        receiveDocuments
        rawPrice
        shippingCharge
        receivedRemark
        note
        hasVat
        checklistStatus
        canceled
        canceledRemark
        deliveryAddressId
        totalItemsPrice
        totalPrice
        vat
        canCancel
        orderDeliveryAt
        seller {
          enName
          sellingCondition
          deliveryCondition
          paymentCondition
          note
          averageDeliveryDate
          billingAddress {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            id
            isMain
            lastName
            ownerType
            phoneNumber
            province
            remark
            subDistrict
            taxId
            taxPayerType
            zipCode
          }
          bankAccount {
            bankName
            bankAccountName
            bankAccountNumber
          }
        }
        deliveryAddress {
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          id
          isMain
          lastName
          ownerType
          phoneNumber
          province
          remark
          subDistrict
          taxId
          taxPayerType
          zipCode
        }
        orderItems {
          productCode
          productName
          amount
          sellingUnit
          costPricePerUnit
          cuttingPrice
          totalPrice
          totalCost
          orderCode
          productId
          orderSlug
          sellerProductCode
        }
        actionHistories {
          id
          description
          modifiedName
          updatedAt
        }
      }
    }
  `,
  CANCEL_PURCHASE_ORDER: gql`
    mutation CANCEL_PURCHASE_ORDER($id: ID!, $canceledRemark: String!) {
      cancelPurchaseOrder(input: { id: $id, canceledRemark: $canceledRemark }) {
        purchaseOrder {
          id
        }
      }
    }
  `,
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      products {
        products {
          id
          code
          productName
          sellingUnit
          costPerCapital
          pricePerCapital
          totalQuantity
          quantityPerFold
          lastActiveAt
          seller {
            code
            thName
            enName
            sellingCondition
            deliveryCondition
            paymentCondition
            note
          }
        }
      }
      setting {
        deliveryAddresses {
          address
          addressType
          branchName
          companyName
          createdAt
          district
          firstName
          gmapUrl
          id
          isMain
          lastName
          ownerType
          phoneNumber
          province
          remark
          subDistrict
          taxId
          taxPayerType
          updatedAt
          zipCode
        }
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({}),
  withFormik({
    mapPropsToValues: () => ({
      published: false,
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useQuery,
      useMutation,
      useEffect,
      useParams,
      useLocation,
      useCallback,
      useState,
    } = hooks;

    const { id } = useParams();

    const configs = useQuery(API.FETCH_CONFIG);
    const { loading, data, error, refetch } = useQuery(
      API.FETCH_PURCHASE_ORDER,
      {
        variables: { id },
      }
    );
    const [cancelPurchaseOrder] = useMutation(API.CANCEL_PURCHASE_ORDER);

    const initialized = !configs.loading && !loading;

    useEffect(() => {
      refetch();
    }, [refetch]);

    const purchaseOrder = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.purchaseOrdersPath().push();
        return null;
      }
      return cleanTypename(cloneDeep(data.purchaseOrder));
    }, [loading, data, error]);

    const [tabState, setTabState] = useState(0);
    const changeTabState = useCallback(
      (e, value) => {
        if (value === 0) {
          paths.purchaseOrderEditPath(id).push("");
        } else if (value === 1) {
          paths.purchaseOrderEditPath(id).push("#action_histories");
        }
      },
      [id]
    );

    const location = useLocation();
    const hashTag = location.hash;
    useEffect(() => {
      if (hashTag === "#action_histories") {
        setTabState(1);
      } else {
        setTabState(0);
      }
    }, [hashTag]);

    const title = purchaseOrder?.code;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.purchaseOrdersPath(), label: "ใบสั่งสินค้า" },
        { path: null, label: title },
      ];
    }, [title]);

    const showCancelPurchaseOrderModal = useCallback(() => {
      const { id, code, finalPrice } = purchaseOrder;

      Modal.open({
        title: "ยกเลิกคำสั่งซื้อ",
        className: "reject-modal",
        children: (
          <Box>
            <Box display="flex">
              <Typography color="Text/Dark Grey">
                ต้องการยกเลิกคำสั่งซื้อ&nbsp;
              </Typography>
              <Typography>
                {code} มูลค่า {toCurrency(finalPrice)} บาท
              </Typography>
            </Box>
            <Field
              component={TextField}
              name="canceledRemark"
              type="text"
              label="สาเหตุที่ยกเลิก"
              fullWidth
              multiline
              className="height-136"
              mt={4}
            />
          </Box>
        ),
        cancelButtonLabel: "ปิด",
        okButtonLabel: "ยกเลิกคำสั่งซื้อ",
        onOk: async ({ close, values }) => {
          try {
            const { canceledRemark } = values || {};

            if (!canceledRemark) {
              notifyError("โปรดใส่เหตุผล");
              return;
            }

            await cancelPurchaseOrder({ variables: { id, canceledRemark } });
            await refetch();
            appStore.state.configSidebarRefetch();
            close();
            paths.purchaseOrdersPath().push();
            notifySuccess("ยกเลิกรายการสั่งซื้อสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [refetch, cancelPurchaseOrder, purchaseOrder]);

    const canceled = purchaseOrder?.canceled;
    const canCancel = purchaseOrder?.canCancel;
    const canceledRemark = purchaseOrder?.canceledRemark;

    const purchaseOrderHistories = useMemo(() => {
      return purchaseOrder?.actionHistories || [];
    }, [purchaseOrder]);

    return {
      title,
      breadcrumbs,
      initialized,
      tabState,
      changeTabState,
      showCancelPurchaseOrderModal,
      purchaseOrder,
      reloadPurchaseOrder: refetch,
      configs: configs.data,
      canCancel,
      canceledRemark,
      purchaseOrderHistories,
      canceled,
    };
  })
);

export default enhancer(PurchaseOrderShowPage);
