import { compose, withHooks } from "enhancers";
import { IFrame } from "components";
import ENV from "env";

const SidekiqPage = (props) => (
  <IFrame
    title="Sidekiq"
    url={props.sidekiqUrl}
    style={{ marginTop: -24 }}
  ></IFrame>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo } = hooks;

    const sidekiqUrl = useMemo(() => {
      return ENV.API_ENDPOINT.toString().replaceAll("/graphql", "/sidekiq");
    }, [ENV.API_ENDPOINT]);
    return { sidekiqUrl };
  })
);

export default enhancer(SidekiqPage);
