import { compose, withHooks } from "enhancers";
import { Box, Typography, Divider } from "components";

import PurchaseOrderCheckListOrder from "./PurchaseOrderCheckListOrder";
import PurchaseOrderCheckListDeliveryOrder from "./PurchaseOrderCheckListDeliveryOrder";
import PurchaseOrderCheckListPaymentOrder from "./PurchaseOrderCheckListPaymentOrder";

const OrderCheckList = (props) => (
  <Box>
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h4">เช็คลิสต์</Typography>
      <Typography variant="h4">{props.checklistStatus}</Typography>
    </Box>
    <Box display="flex" alignItems="center" mt={2} mb={4}>
      <Typography color="Text/Dark Grey">
        สิ่งที่ต้องทำเพื่อให้การสั่งสินค้าจากผู้ขายเสร็จสมบูรณ์
      </Typography>
      <Typography variant="caption" color="Text/Dark Grey">
        (เมื่อปรับสถานะใบสั่งสินค้าจะอัพเดทคำสั่งซื้อที่เกี่ยวข้องให้อัตโนมัติ)
      </Typography>
    </Box>

    <PurchaseOrderCheckListOrder
      info={props.purchaseOrderCheckListOrder}
      reloadPurchaseOrder={props.reloadPurchaseOrder}
    />
    <Divider />
    <PurchaseOrderCheckListPaymentOrder
      info={props.PurchaseOrderCheckListPaymentOrder}
      reloadPurchaseOrder={props.reloadPurchaseOrder}
    />
    <Divider />
    <PurchaseOrderCheckListDeliveryOrder
      info={props.PurchaseOrderCheckListDeliveryOrder}
      reloadPurchaseOrder={props.reloadPurchaseOrder}
    />
    <Divider />
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      reloadPurchaseOrder,
      checklistStatus,
      finalPrice,
      purchaseOrderCheckListOrder,
      PurchaseOrderCheckListPaymentOrder,
      PurchaseOrderCheckListDeliveryOrder,
    } = props;

    return {
      purchaseOrderCheckListOrder,
      PurchaseOrderCheckListPaymentOrder,
      PurchaseOrderCheckListDeliveryOrder,
      finalPrice,
      checklistStatus,
      reloadPurchaseOrder,
    };
  })
);

export default enhancer(OrderCheckList);
