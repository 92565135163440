import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Button,
  Modal,
  Hidden,
  DownloadFileLink,
} from "components";
import {
  notifySuccess,
  notifyError,
  formatDate,
  forceDownload,
} from "utils/helper";
import ConfirmPaymentPurchaseOrder, {
  API,
} from "./ConfirmPaymentPurchaseOrder";

import { ReactComponent as CheckCircleOutlineIcon } from "assets/icon/check_circle_outline.svg";
import BankInfo from "./BankInfo";

const PurchaseOrderCheckListPaymentOrder = (props) => (
  <Box display="flex" justifyContent="space-between" py={2}>
    <Box>
      <Typography color="Text/Black">จ่ายเงินให้ผู้ขาย</Typography>
      <Hidden when={!props.info?.paymentedAt}>
        <Box display="flex" mt={2}>
          <Typography
            variant="caption"
            color="Text/Dark Grey"
            style={{ width: "68px" }}
          >
            หลักฐาน
          </Typography>
          <Box display="flex">
            {props.info?.paymentDocuments !== 0 ? (
              props.info?.paymentDocuments.map((document) => (
                <DownloadFileLink
                  filename={document.filename}
                  url={document.url}
                />
              ))
            ) : (
              <Typography variant="caption">-</Typography>
            )}
          </Box>
        </Box>
      </Hidden>
      <Box display="flex" mt={2}>
        <BankInfo info={props.info?.bankAccount} />
      </Box>
    </Box>

    {props.info?.paymentedAt ? (
      <Box
        display="flex"
        height="fit-content"
        minWidth={200}
        justifyContent="flex-end"
        alignItems="center"
      >
        <CheckCircleOutlineIcon />
        <Typography variant="caption" color="Text/Dark Grey" ml={2} width={154}>
          {formatDate(props.info?.paymentedAt, "สำเร็จเมื่อ dd/MM/yyyy, HH:mm")}
        </Typography>
      </Box>
    ) : (
      <Box>
        <Button
          onClick={props.openConfirmQuotationModal}
          variant="outlined"
          color="primary"
          disabled={!props.info?.orderedAt || props.info?.canceled}
        >
          ดำเนินการ
        </Button>
      </Box>
    )}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info, reloadPurchaseOrder } = props;
    const { useParams, useCallback, useMutation } = hooks;

    const { id } = useParams();
    const [paidPurchaseOrder] = useMutation(API.PAID_PURCHASE_ORDER);

    const openConfirmQuotationModal = useCallback(() => {
      Modal.open({
        className: "ConfirmOrderQuotationInfoModal",
        title: "จ่ายเงินให้ผู้ขาย",
        children: (
          <ConfirmPaymentPurchaseOrder
            orderDeliveryDate={info.orderDeliveryDate ?? ""}
            orderDeliveryTime={info.orderDeliveryTime ?? ""}
            orderItems={info.orderItems ?? []}
            totalItemsPrice={info.totalItemsPrice ?? 0}
            discount={info.discount ?? 0}
            info={info.totalItemPriceWithDiscount ?? 0}
            vat={info.vat ?? 0}
            totalPrice={info.totalPrice ?? 0}
            hasVat={info.hasVat}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onOk: async ({ close, values }) => {
          try {
            await paidPurchaseOrder({
              variables: {
                id,
                ...values,
              },
            });
            await reloadPurchaseOrder();
            close();
            notifySuccess("ดำเนินการสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [info, paidPurchaseOrder, reloadPurchaseOrder, id]);

    const download = useCallback(
      (document) => () => {
        const { filename, url } = document;
        if (filename && url) {
          const loadFile = async () => {
            try {
              const blob = await fetch(url).then((r) => r.blob());
              const file = new File([blob], filename, {
                type: blob.type,
              });

              if (file) {
                const url = URL.createObjectURL(file);
                forceDownload(url);
              }
            } catch (e) {
              notifyError(e);
            }
          };

          loadFile();
        }
      },
      []
    );

    return {
      openConfirmQuotationModal,
      info,
      download,
    };
  })
);

export default enhancer(PurchaseOrderCheckListPaymentOrder);
