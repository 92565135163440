import { compose, withHooks } from "enhancers";
import { Box, Typography } from "components";
import { toCurrency } from "utils/helper";

const GraphLabel = (props) => (
  <Box mt={4} ml={props.ml}>
    <Box display="flex" mb={2} alignItems="center" height={16}>
      <Box
        style={{ backgroundColor: props.color }}
        width={16}
        height={16}
        mr={2}
      />
      <Typography variant="caption" color="Text/Dark Grey">
        {props.label}
      </Typography>
    </Box>
    <Typography variant="h3">
      {toCurrency(props.total ?? 0, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })}{" "}
      kg
    </Typography>
    <Box>
      <Typography variant="caption" color="Text/Dark Grey">
        {toCurrency(props.totalKg ?? 0, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}{" "}
        kg /{" "}
        {toCurrency(props.totalYard ?? 0, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}{" "}
        yd /{" "}
        {toCurrency(props.totalMetre ?? 0, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}{" "}
        m
      </Typography>
    </Box>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    return { ...props };
  })
);

export default enhancer(GraphLabel);
