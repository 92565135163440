import { compose, withHooks, withStores } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Table,
  Typography,
  Modal,
  ExcelGenerator,
  Backdrop,
} from "components";
import {
  formatDate,
  gql,
  notifyError,
  notifySuccess,
  paths,
} from "utils/helper";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import { ReactComponent as CopyIcon } from "assets/icon/copy-icon.svg";
import { ReactComponent as QrCodeIcon } from "assets/icon/qrcode_icon.svg";
import { HighlightOff } from "@material-ui/icons";
import server from "common/api";

const ProductIndexPage = (props) => (
  <PageContent
    title="ผลิตภัณฑ์"
    breadcrumbs={[
      { path: paths.homePath(), label: "หน้าแรก" },
      { path: null, label: "ผลิตภัณฑ์" },
    ]}
    pageActions={props.pageActions}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายชื่อผลิตภัณฑ์
      </Typography>
      {props.showCheckboxSelected ? (
        <Table
          columns={[
            {
              width: 10,
              field: "published",
              headerName: " ",
              type: "boolean",
            },
            { width: 125, field: "code", headerName: "รหัส" },
            { width: 200, field: "productName", headerName: "ชื่อ" },
            { width: 200, field: "sellerName", headerName: "ผู้ขาย" },
          ]}
          rows={props.createQRTableData}
          loading={props.productsLoading}
          density="compact"
          autoHeight
          includeToolbar
          checkboxSelection={props.showCheckboxSelected}
          onSelectionModelChange={props.onSelectionChange}
          isRowSelectable={(params) => params.row.published === true}
          rowCount={props.rowCount}
          initialFilter={{
            items: [],
          }}
        />
      ) : (
        <Table
          columns={[
            {
              width: 10,
              field: "published",
              headerName: " ",
              type: "boolean",
            },
            { width: 125, field: "code", headerName: "รหัส" },
            { width: 200, field: "productName", headerName: "ชื่อ" },
            { width: 200, field: "sellerName", headerName: "ผู้ขาย" },
            {
              width: 130,
              field: "totalQuantity",
              headerName: "คงเหลือ",
              type: "currency",
            },
            { width: 135, field: "sellingUnitForExcel", headerName: "หน่วย" },
            {
              width: 195,
              field: "lastEditedBy",
              headerName: "แก้ไขล่าสุดโดย",
            },
            {
              width: 175,
              field: "lastActiveAt",
              headerName: "แก้ไขล่าสุด",
              type: "dateTime",
            },
            {
              width: 100,
              field: "actions",
              headerName: " ",
              filterable: false,
              sortable: false,
              type: "actions",
            },
          ]}
          rows={props.tableData}
          loading={props.loading}
          onRowClickTo={paths.productEditPath}
          density="compact"
          autoHeight
          disableSelectionOnClick
          includeToolbar
          checkboxSelection={props.showCheckboxSelected}
          onSelectionModelChange={props.onSelectionChange}
          isRowSelectable={(params) => params.row.published === true}
          paginationMode="server"
          filterMode="server"
          sortingMode="server"
          refetch={props.refetch}
          rowCount={props.rowCount}
          initialFilter={{
            items: [],
          }}
        />
      )}
    </Box>
  </PageContent>
);

export const API = {
  FETCH_PRODUCTS: gql`
    query FETCH_PRODUCTS(
      $filters: JSON
      $sorts: JSON
      $page: Float
      $pageSize: Float
    ) {
      products(
        filters: $filters
        sorts: $sorts
        page: $page
        pageSize: $pageSize
      ) {
        products {
          legacyCode
          id
          code
          productName
          sellingUnit
          sellingUnitForExcel
          totalQuantity
          published
          canDelete
          sellerName
          lastActiveAt
          lastEditedBy
        }
        paginate {
          page
          pageSize
          rowCount
        }
      }
    }
  `,
  COPY_PRODUCT: gql`
    mutation COPY_PRODUCT($id: String!) {
      copyProduct(input: { id: $id }) {
        product {
          id
        }
      }
    }
  `,
  DELETE_PRODUCTS: gql`
    mutation DELETE_PRODUCTS($ids: [String!]!) {
      deleteProducts(input: { ids: $ids }) {
        products {
          id
        }
      }
    }
  `,
  IMPORT_PRODUCTS: gql`
    mutation IMPORT_PRODUCTS($excelFile: Upload!) {
      importProducts(input: { excelFile: $excelFile }) {
        products {
          id
        }
      }
    }
  `,
  FETCH_PRODUCTS_FOR_DOWNLOAD: gql`
    query FETCH_PRODUCTS_FOR_DOWNLOAD(
      $filters: JSON
      $sorts: JSON
      $page: Float
      $pageSize: Float
    ) {
      products(
        filters: $filters
        sorts: $sorts
        page: $page
        pageSize: $pageSize
      ) {
        products {
          legacyCode
          carbonFootprintPerKg
          # carbonFootprintType
          carbonFootprintTypeForExcel
          category
          categoryForExcel
          # colors
          colorForExcel
          composition
          # constructions
          constructionForExcel
          costPerCapital
          cwWidth
          defect
          # fibers
          fiberForExcel
          fwWidth
          gsm
          id
          code
          productCodeForExcel
          images
          note
          physicalCode
          # physicalSeperations
          physicalSeperationForExcel
          pricePerCapital
          productName
          quantityPerFold
          sellerColor
          sellerId
          sellerProductCode
          sellingCondition
          sellingUnit
          sellingUnitForExcel
          # specialFunctions
          specialFunctionForExcel
          # suitableForThings
          suitableForThingForExcel
          # tags
          tWidth
          # thickness
          thicknessForExcel
          # tone
          toneForExcel
          totalQuantity
          waterFootprintPerKg
          yarnCount
          published
          publishedForExcel
          canDelete
          sellerName
          sellerNameForExcel
          lastActiveAt
          lastEditedBy
          shopifyUrl
        }
      }
    }
  `,
  FETCH_PRODUCTS_FOR_QR: gql`
    query FETCH_PRODUCTS_FOR_QR(
      $filters: JSON
      $sorts: JSON
      $page: Float
      $pageSize: Float
    ) {
      products(
        filters: $filters
        sorts: $sorts
        page: $page
        pageSize: $pageSize
      ) {
        products {
          id
          code
          productName
          sellerName
          published
        }
      }
    }
  `,
  DOWNLOAD_PRODUCTS: gql`
    mutation DOWNLOAD_PRODUCTS($email: String!) {
      downloadProducts(input: { email: $email }) {
        success
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores) => ({
    currentUserEmail: stores.appStore.currentUser?.email,
    line: stores.appStore.currentUser?.line,
  })),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useCallback,
      useMutation,
      useEffect,
      useState,
      useQuery,
      useLazyQuery,
    } = hooks;
    const { currentUserEmail, line } = props;
    const { loading, data, error, refetch } = useQuery(API.FETCH_PRODUCTS, {
      variables: {
        page: 0,
        pageSize: 100,
        sorts: [{ field: "code", sort: "asc" }],
      },
    });
    const [
      fetchProductsForQR,
      { loading: productsLoading, data: productsData },
    ] = useLazyQuery(API.FETCH_PRODUCTS_FOR_QR, {
      variables: {
        sorts: [{ field: "code", sort: "asc" }],
      },
    });

    const [copyProduct] = useMutation(API.COPY_PRODUCT);
    const [deleteProducts] = useMutation(API.DELETE_PRODUCTS);
    const [importProducts] = useMutation(API.IMPORT_PRODUCTS);
    const [downloadProducts] = useMutation(API.DOWNLOAD_PRODUCTS);
    const [
      fetchProductsForDownload,
      {
        loading: loadingProductsForDownload,
        refetch: refetchProductsForDownload,
      },
    ] = useLazyQuery(API.FETCH_PRODUCTS_FOR_DOWNLOAD, {
      variables: {
        sorts: [{ field: "code", sort: "asc" }],
      },
      onCompleted: (data) => {
        setProductsForDownload(data?.products?.products);
      },
    });

    const [showCheckboxSelected, setShowCheckboxSelected] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const [productsForDownload, setProductsForDownload] = useState(null);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const handleCopyProduct = useCallback(
      (params) => {
        const { id, code, productName } = params.row;
        Modal.open({
          title: "คัดลอกผลิตภัณฑ์",
          children: `ระบบจะสร้างผลิตภัณฑ์ใหม่ที่มีลักษณะเหมือนกับ ${code} - ${productName}`,
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "คัดลอก",
          onOk: async ({ close }) => {
            try {
              const { data } = await copyProduct({ variables: { id } });
              const newProductId = data.copyProduct.product.id;

              await refetch();
              close();
              notifySuccess("ดำเนินการคัดลอกผลิตภัณฑ์ 1 ชิ้น");

              paths.productEditPath(newProductId).push();
            } catch (e) {
              notifyError(e);
            }
          },
        });
      },
      [copyProduct, refetch]
    );

    const handleDeleteProduct = useCallback(
      async (params) => {
        const { id } = params;

        Modal.open({
          title: "ลบผลิตภัณฑ์",
          children: `การดำเนินการนี้จะไม่สามารถย้อนกลับได้ แน่ใจใช่หรือไม่?`,
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ลบ",
          onOk: async ({ close }) => {
            Backdrop.open();
            try {
              await deleteProducts({ variables: { ids: [id] } });
              close();
              await refetch();
              notifySuccess("ดำเนินการลบผลิตภัณฑ์ 1 ชิ้น");
            } catch (e) {
              notifyError(e);
            }
            Backdrop.close();
          },
        });
      },
      [deleteProducts, refetch]
    );

    const createQRTableData = useMemo(() => {
      return productsData?.products?.products || [];
    }, [productsData]);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.products.products.map((product) => {
        const {
          firstName,
          lastName,
          secondaryChannel,
          canDelete,
          sellingUnit,
          legacyCode,
          code,
          ...rest
        } = product;

        return {
          ...rest,
          sellingUnit,
          code,
          actions: [
            { Icon: CopyIcon, onClick: handleCopyProduct },
            ...(canDelete
              ? [{ Icon: TrashIcon, onClick: handleDeleteProduct }]
              : []),
          ],
        };
      });
    }, [loading, data, error, handleCopyProduct, handleDeleteProduct]);

    const handleDownloadProducts = useCallback(async () => {
      try {
        await downloadProducts({ variables: { email: currentUserEmail } });
        notifySuccess(`
          ระบบกำลังทำการสร้างไฟล์ เมื่อเสร็จแล้วจะส่งไปที่ ${currentUserEmail}
        `);
      } catch (e) {
        notifyError("ไม่สามารถโหลดไฟล์ได้");
      }
    }, [downloadProducts, line]);

    useEffect(() => {
      if (loadingProductsForDownload) {
        Backdrop.open();
      } else {
        Backdrop.close();
      }
    }, [loadingProductsForDownload]);

    useEffect(() => {
      if (productsForDownload) {
        ExcelGenerator.generate({
          fileName: `product-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
          columns: [
            {
              title: "Code",
              field: "productCodeForExcel",
            },
            {
              title: "รหัสผลิตภัณฑ์จากระบบเก่า",
              field: "legacyCode",
            },
            {
              title: "ชื่อผลิตภัณท์",
              field: "productName",
            },
            {
              title: "ชนิดผ้า",
              field: "categoryForExcel",
            },
            {
              title: "ส่วนประกอบผ้า",
              field: "composition",
            },
            {
              title: "ลักษณะวัสดุ",
              field: "constructionForExcel",
            },
            {
              title: "เส้นใยที่ใช้",
              field: "fiberForExcel",
            },
            {
              title: "เบอร์เส้นด้าย",
              field: "yarnCount",
            },
            {
              title: "น้ำหนักต่อตารางเมตร",
              field: "gsm",
            },
            {
              title: "ความหนา",
              field: "thicknessForExcel",
            },
            {
              title: "ความกว้างหน้าผ้า FW",
              field: "fwWidth",
            },
            {
              title: "ความกว้างหน้าผ้า CW",
              field: "cwWidth",
            },
            {
              title: "ความกว้างหน้าผ้า T",
              field: "tWidth",
            },
            {
              title: "Physical Seperation",
              field: "physicalSeperationForExcel",
            },
            {
              title: "โทนสี",
              field: "toneForExcel",
            },
            {
              title: "สี",
              field: "colorForExcel",
            },
            {
              title: "สีที่ผู้ขายใช้เรียก",
              field: "sellerColor",
            },
            {
              title: "คุณสมบัติพิเศษ",
              field: "specialFunctionForExcel",
            },
            {
              title: "คำแนะนำในการนำไปใช้",
              field: "suitableForThingForExcel",
            },
            {
              title: "ประเภทผ้าเพื่อคำนวณ Carbon footprints",
              field: "carbonFootprintTypeForExcel",
            },
            {
              title: "Carbon footprint ต่อผ้า 1 กิโลกรัม",
              field: "carbonFootprintPerKg",
            },
            {
              title: "Water footprint ต่อผ้า 1 กิโลกรัม",
              field: "waterFootprintPerKg",
            },
            {
              title: "ผู้ขาย",
              field: "sellerNameForExcel",
            },
            {
              title: "Physical code",
              field: "physicalCode",
            },
            {
              title: "รหัสผ้าฝั่งผู้ขาย",
              field: "sellerProductCode",
            },
            {
              title: "หน่วยในการจัดเก็บ/ขาย",
              field: "sellingUnitForExcel",
            },
            {
              title: "ราคาทุนต่อหน่วย",
              field: "costPerCapital",
            },
            {
              title: "ราคาขายต่อหน่วย",
              field: "pricePerCapital",
            },
            {
              title: "เงื่อนไขในการซื้อ",
              field: "sellingCondition",
            },
            {
              title: "ตำหนิผ้า",
              field: "defect",
            },
            {
              title: "ปริมาณที่มีทั้งหมด",
              field: "totalQuantity",
            },
            {
              title: "ปริมาณต่อพับ",
              field: "quantityPerFold",
            },
            {
              title: "แสดงใน Shopify",
              field: "publishedForExcel",
            },
          ],
          data: productsForDownload,
        });
        setProductsForDownload(null);
      }
    }, [productsForDownload]);

    const uploadProducts = useCallback(
      async (file) => {
        try {
          await importProducts({ variables: { excelFile: file } });
          await refetch();
          notifySuccess("upload สำเร็จ");
        } catch (e) {
          notifyError(e);
        }
      },
      [importProducts, refetch]
    );

    const onSelectionChange = useCallback((newSelection) => {
      setSelectedIds(newSelection);
    }, []);

    const handleQRCodeGenerate = useCallback(async () => {
      Backdrop.open();
      try {
        await server.download(
          "/download_qrcode_pdf",
          {
            ids: selectedIds,
          },
          { method: "post" }
        );
        notifySuccess("สำเร็จ");
      } catch (e) {
        console.log("e", e);
        notifyError(e);
      }

      Backdrop.close();
    }, [selectedIds]);

    const createQRClick = useCallback(async () => {
      setShowCheckboxSelected(true);
      await fetchProductsForQR();
    }, [setShowCheckboxSelected, fetchProductsForQR]);

    const pageActions = useMemo(() => {
      if (showCheckboxSelected) {
        return [
          {
            children: "ยกเลิก",
            startIcon: <HighlightOff />,
            onClick: () => setShowCheckboxSelected(false),
            variant: "outlined",
            color: "primary",
          },
          {
            children: "Download ฉลาก",
            startIcon: <QrCodeIcon />,
            onClick: () => handleQRCodeGenerate(),
            color: "primary",
          },
        ];
      } else {
        return [
          {
            children: "Download",
            startIcon: "download",
            onClick: handleDownloadProducts,
            variant: "outlined",
            color: "primary",
          },
          {
            children: "Upload",
            startIcon: "upload",
            onBrowse: uploadProducts,
            variant: "outlined",
            color: "primary",
            type: "file",
          },
          {
            children: "สร้างฉลากผลิตภัณฑ์",
            startIcon: <QrCodeIcon />,
            onClick: createQRClick,
            color: "primary",
          },
          {
            children: "เพิ่มผลิตภัณฑ์",
            startIcon: "add",
            onClick: () => paths.productNewPath().push(),
            color: "primary",
          },
        ];
      }
    }, [
      showCheckboxSelected,
      handleDownloadProducts,
      uploadProducts,
      handleQRCodeGenerate,
      createQRClick,
    ]);

    return {
      tableData,
      loading,
      handleDownloadProducts,
      uploadProducts,
      // onPageChange,
      // page,
      onSelectionChange,
      pageActions,
      showCheckboxSelected,
      // selectedData,
      // setSelectedData,
      rowCount: data?.products?.paginate?.rowCount ?? 0,
      refetch,
      createQRTableData,
      productsLoading,
    };
  })
);

export default enhancer(ProductIndexPage);
