import { compose, withHooks } from "enhancers";
import { Box, Typography } from "components";
import { map, isEmpty } from "lodash";
import htmlReactParser from "html-react-parser";
import { formatPhoneNumber, formatFullName, formatTaxId } from "utils/helper";

const SellerInfoCard = (props) => (
  <Box
    style={{ background: "#E7F4FD", borderRadius: 8, width: 512 }}
    p={2}
    mt={6}
  >
    {map(props.details, (detail, index) => (
      <Box key={index} display="flex" mt={index === 0 ? 0 : 2}>
        <Typography width={140}>{detail.label}</Typography>
        <Typography width={24} mx={1}>
          :
        </Typography>
        <Typography flex={1}>{detail.value}</Typography>
      </Box>
    ))}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info } = props;
    const { useMemo } = hooks;

    const billingAddress = useMemo(() => info.billingAddress || {}, [info]);

    const isBangkok = billingAddress.province === "กรุงเทพมหานคร";
    const subDistrictPrefix = isBangkok ? "แขวง" : "ตำบล";
    const districtPrefix = isBangkok ? "เขต" : "อำเภอ";
    const addressDetails = [
      formatFullName(billingAddress.firstName, billingAddress.lastName),
      billingAddress.address,
      `${
        billingAddress.subDistrict
          ? `${subDistrictPrefix} ${billingAddress.subDistrict}`
          : ""
      } ${
        billingAddress.district
          ? `${districtPrefix} ${billingAddress.district}`
          : ""
      }`,
      `${billingAddress.province ?? ""} ${billingAddress.zipCode ?? ""}`,
    ].filter((detail) => !/^ *$/.test(detail || ""));

    const details = useMemo(() => {
      return [
        {
          label: "ชื่อผู้ขาย",
          value: info.enName ?? "-",
        },
        {
          label: "ที่อยู่ส่งของ",
          value: isEmpty(addressDetails)
            ? "-"
            : map(addressDetails, (detail, index) => (
                <Box mt={index === 0 ? 0 : 2}>{detail}</Box>
              )),
        },
        {
          label: "โทรศัพท์",
          value: billingAddress.phoneNumber
            ? formatPhoneNumber(billingAddress.phoneNumber)
            : "-",
        },
        { label: "หมายเหตุ", value: billingAddress.remark ?? "-" },
        {
          label: "เลขประจำตัวผู้เสียภาษี",
          value: formatTaxId(billingAddress.taxId) ?? "-",
        },
        { label: "การส่ง", value: info.deliveryCondition ?? "-" },
        {
          label: "ระยะเวลาจัดส่ง",
          value: `ประมาณ ${info?.averageDeliveryDate ?? 0} วัน`,
        },
        { label: "การจ่ายเงิน", value: info.paymentCondition ?? "-" },
        { label: "เงื่อนไขการขาย", value: info.sellingCondition ?? "-" },
        {
          label: "บันทึกเพิ่มเติม",
          value: (
            <div className="renderHtmlFromString">
              {info.note ? htmlReactParser(info.note) : "-"}
            </div>
          ),
        },
      ];
    }, [info, addressDetails, billingAddress]);

    return { details };
  })
);

export default enhancer(SellerInfoCard);
