import { compose, withHooks } from "enhancers";
import {
  Box,
  Grid,
  Field,
  TextField,
  DatePicker,
  Select,
  Address,
  Typography,
  Button,
  Divider,
  Hidden,
} from "components";
import { paths } from "utils/helper";
import { differenceInDays, isValid, parseISO, startOfToday } from "date-fns";
import { isString } from "lodash";

import BrandInfo from "../../../new/BrandInfo";
import BuyerInfoCard from "./BuyerInfoCard";

const OrderBuyerInfo = (props) => (
  <Box mt={props.mt}>
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="h4" color="Text/Black">
          ผู้ซื้อ
        </Typography>
        <Typography color="Text/Dark Grey" mt={2}>
          ข้อมูลผู้ซื้อและรายละเอียดการจัดส่งสินค้า
        </Typography>
      </Box>
      <Button onClick={props.gotoBuyerDetailPage} variant="outlined" ml={6}>
        ดูรายละเอียดผู้ซื้อ
      </Button>
    </Box>

    {props.readOnly ? (
      <BuyerInfoCard info={props.buyerInfo} />
    ) : (
      <>
        <Grid container spacing={6} mt={6}>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="buyerName"
              label="ชื่อผู้ซื้อ"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={DatePicker}
              name="deliveryDate"
              label="วันที่ต้องส่งถึง"
              fullWidth
              helperText={props.deliveryDateHelperText}
              disabled={props.disabledForm}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={Select}
              name="deliveryTime"
              label="เวลาที่ต้องส่งของ"
              options={props.deliveryTimeOptions}
              fullWidth
              disabled={props.disabledForm}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={Select}
              name="deliveryAddressId"
              label="ที่อยู่ที่ใช้ในการส่งของ"
              options={props.deliveryAddressIdOptions}
              fullWidth
              disabled={props.disabledForm}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={12}>
            <Field
              component={Address.Delivery}
              name="deliveryAddress"
              disabled={props.disabledForm}
            />
          </Grid>
          <Grid item xs={6}>
            <BrandInfo info={props.buyerInfo} />
          </Grid>
          <Grid item xs={6} />
        </Grid>

        <Hidden when={props.disabledForm}>
          <Button
            onClick={props.handleSubmit}
            disabled={props.disabledSubmitButton}
            color="primary"
            mt={6}
          >
            บันทึก
          </Button>
        </Hidden>
      </>
    )}

    <Divider mt={6} />
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useCallback, useMemo } = hooks;
    const {
      mt,
      buyerInfo,
      deliveryAddressIdOptions,
      deliveryDate,
      handleSubmit,
      disabledSubmitButton,
      canEditBuyerInfo,
      canceled,
    } = props;

    const deliveryDateHelperText = useMemo(() => {
      const date = isString(deliveryDate)
        ? parseISO(deliveryDate)
        : deliveryDate;

      if (isValid(date)) {
        return (
          <Typography
            variant="body1"
            color="Other/Info"
            ml={-3.5}
          >{`*อีก ${differenceInDays(date, startOfToday())} วัน`}</Typography>
        );
      }
    }, [deliveryDate]);

    const gotoBuyerDetailPage = useCallback(() => {
      const buyerId = buyerInfo?.id;
      if (buyerId) {
        paths.buyerEditPath(buyerId).push();
      }
    }, [buyerInfo]);

    const deliveryTimeOptions = useMemo(() => {
      return [
        { label: "8:00 น.", value: "8:00" },
        { label: "8:30 น.", value: "8:30" },
        { label: "9:00 น.", value: "9:00" },
        { label: "9:30 น.", value: "9:30" },
        { label: "10:00 น.", value: "10:00" },
        { label: "10:30 น.", value: "10:30" },
        { label: "11:00 น.", value: "11:00" },
        { label: "11:30 น.", value: "11:30" },
        { label: "12:00 น.", value: "12:00" },
        { label: "12:30 น.", value: "12:30" },
        { label: "13:00 น.", value: "13:00" },
        { label: "13:30 น.", value: "13:30" },
        { label: "14:00 น.", value: "14:00" },
        { label: "14:30 น.", value: "14:30" },
        { label: "15:00 น.", value: "15:00" },
        { label: "15:30 น.", value: "15:30" },
        { label: "16:00 น.", value: "16:00" },
        { label: "16:30 น.", value: "16:30" },
        { label: "17:00 น.", value: "17:00" },
      ];
    }, []);

    const readOnly = !canEditBuyerInfo;

    const disabledForm = canceled;

    return {
      mt,
      deliveryDateHelperText,
      deliveryTimeOptions,
      deliveryAddressIdOptions,
      buyerInfo,
      gotoBuyerDetailPage,
      handleSubmit,
      disabledSubmitButton,
      disabledForm,
      readOnly,
    };
  })
);

export default enhancer(OrderBuyerInfo);
