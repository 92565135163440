import React from "react";
import { compose, defaultProps, withFormik, withHooks } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  FieldArray,
  TextField,
  RadioGroup,
  Editor,
  Address,
  AddressList,
  FileUploadList,
  Divider,
  Button,
  Select,
  BankAccount,
} from "components";
import { gql, notifyError, notifySuccess, paths } from "utils/helper";
import { flatten, map, compact, uniq } from "lodash";

export const SellerForm = (props) => (
  <Form>
    <Typography variant="h4" mb={4}>
      ข้อมูลทั่วไป
    </Typography>
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="enName"
          type="text"
          label="ชื่อภาษาอังกฤษ"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="thName"
          type="text"
          label="ชื่อภาษาไทย"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="tags"
          type="text"
          label="แท็ก"
          fullWidth
          freeSolo
          multiple
          options={props.tagOptions}
          forceFix
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="industryTypes"
          type="text"
          label="ประเภทบริษัท"
          fullWidth
          options={props.industryTypeOptions}
          multiple
          forceFix
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={Select}
          name="productTypes"
          type="text"
          label="ประเภทผลิตภัณฑ์"
          fullWidth
          options={props.productTypeOptions}
          multiple
          forceFix
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="sellingCondition"
          type="text"
          label="เงื่อนไขในการขายผ้า"
          fullWidth
          multiline
          className="height-136"
        />
      </Grid>
      <Grid
        container
        spacing={6}
        style={{ paddingRight: 0 }}
        item
        xs={6}
        pr={0}
      >
        <Grid item xs={12} style={{ paddingRight: 0 }}>
          <Field
            component={TextField}
            name="deliveryCondition"
            type="text"
            label="เงื่อนไขในการจัดส่ง"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{ paddingRight: 0 }}>
          <Field
            component={TextField}
            name="paymentCondition"
            type="text"
            label="เงื่อนไขการชำระเงิน"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="analysisInfo"
          type="text"
          label="ผลการวิเคราะห์บริษัท"
          fullWidth
          multiline
          className="height-136"
        />
      </Grid>
      <Grid
        container
        spacing={6}
        style={{ paddingRight: 0 }}
        item
        xs={6}
        pr={0}
      >
        <Grid item xs={12} style={{ paddingRight: 0 }}>
          <Field
            component={TextField}
            name="financialInfo"
            type="text"
            label="ข้อมูลทางการเงิน"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{ paddingRight: 0 }}>
          <Field
            component={Select}
            name="sellerTier"
            label="Seller Tier"
            fullWidth
            options={props.sellerTierOptions}
            forceFix
          />
        </Grid>
      </Grid>
    </Grid>

    <Typography variant="h4" mt={10} mb={6}>
      บันทึกเพิ่มเติม
    </Typography>
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Field component={Editor} name="note" label="แบรนด์" fullWidth />
      </Grid>
    </Grid>

    <Typography variant="h4" mt={10} mb={6}>
      การติดต่อ
    </Typography>
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="adminId"
          type="text"
          label="ผู้รับผิดชอบ"
          fullWidth
          required
          options={props.adminIdOptions}
          forceFix
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="contactName"
          type="text"
          label="ชื่อคนที่ต้องติดต่อ"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField.PhoneNumber}
          name="phoneNumber1"
          label="เบอร์โทรศัพท์"
          fullWidth
          required
        />
      </Grid>
    </Grid>
    <Box mt={6}>
      <Grid mt={6} container spacing={6}>
        <Grid item xs={6}>
          <Box fullWidth display="flex" alignItems="center">
            <Field
              component={TextField.PhoneNumber}
              name="phoneNumber2"
              label="เบอร์โทรศัพท์ 2"
              flex={1}
            />
            <Field
              component={RadioGroup}
              name="secondaryChannel"
              options={[{ label: "ช่องทางสำรอง", value: "phone_number2" }]}
              width={160}
              ml={4}
              mr={0}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box fullWidth display="flex" alignItems="center">
            <Field
              component={TextField}
              name="line"
              type="text"
              label="ไลน์"
              flex={1}
            />
            <Field
              component={RadioGroup}
              name="secondaryChannel"
              options={[{ label: "ช่องทางสำรอง", value: "line" }]}
              width={160}
              ml={4}
              mr={0}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box fullWidth display="flex" alignItems="center">
            <Field
              component={TextField}
              name="facebook"
              type="text"
              label="เฟสบุ๊ค"
              flex={1}
            />
            <Field
              component={RadioGroup}
              name="secondaryChannel"
              options={[{ label: "ช่องทางสำรอง", value: "facebook" }]}
              width={160}
              ml={4}
              mr={0}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box fullWidth display="flex" alignItems="center">
            <Field
              component={TextField}
              name="ig"
              type="text"
              label="อินสตาแกรม"
              flex={1}
            />
            <Field
              component={RadioGroup}
              name="secondaryChannel"
              options={[{ label: "ช่องทางสำรอง", value: "ig" }]}
              width={160}
              ml={4}
              mr={0}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box fullWidth display="flex" alignItems="center">
            <Field
              component={TextField.Email}
              name="email"
              label="อีเมล"
              flex={1}
              required
            />
            <Field
              component={RadioGroup}
              name="secondaryChannel"
              options={[{ label: "ช่องทางสำรอง", value: "email" }]}
              width={160}
              ml={4}
              mr={0}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" color="Text/Dark Grey" mt={6}>
            *ช่องทางสำรองจะแสดงในหน้าตาราง
          </Typography>
        </Grid>
      </Grid>
    </Box>

    <Field
      component={BankAccount}
      name="bankAccount"
      title="รายละเอียดการจ่ายเงิน"
      mt={10}
      noBranch
    />
    <Field
      component={Address.Billing}
      name="billingAddress"
      title="ที่อยู่ที่ใช้ในการออกบิล"
      mt={10.5}
    />
    <FieldArray
      component={AddressList.Delivery}
      name="deliveryAddresses"
      title="ที่อยู่ที่ใช้ในการรับของ"
      addButtonLabel="เพิ่มที่อยู่รับของ"
      hasGmap
      mt={10}
    />
    <FieldArray
      component={FileUploadList}
      name="relatedDocuments"
      title="เอกสาร"
      addButtonLabel="เพิ่มเอกสาร"
      mt={10}
    />

    <Divider mt={10} />
    <Button type="submit" color="primary" mt={6}>
      {props.submitButtonLabel}
    </Button>
  </Form>
);

const SellerNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <SellerForm {...props} />
  </PageContent>
);

const API = {
  CREATE_SELLER: gql`
    mutation CREATE_SELLER(
      $email: String
      $facebook: String
      $ig: String
      $note: String
      $phoneNumber1: String
      $phoneNumber2: String
      $secondaryChannel: String
      $line: String
      $billingAddress: AddressArguments
      $deliveryAddresses: [AddressArguments!]
      $analysisInfo: String
      $contactName: String
      $deliveryCondition: String
      $financialInfo: String
      $thName: String
      $enName: String
      $tags: [String!]
      $industryTypes: [String!]
      $productTypes: [String!]
      $paymentCondition: String
      $sellerTier: String
      $sellingCondition: String
      $bankAccount: BankAccountArguments
      $relatedDocuments: [Upload!]
      $adminId: String
    ) {
      createSeller(
        input: {
          email: $email
          facebook: $facebook
          ig: $ig
          note: $note
          phoneNumber1: $phoneNumber1
          phoneNumber2: $phoneNumber2
          secondaryChannel: $secondaryChannel
          line: $line
          billingAddress: $billingAddress
          deliveryAddresses: $deliveryAddresses
          analysisInfo: $analysisInfo
          contactName: $contactName
          deliveryCondition: $deliveryCondition
          financialInfo: $financialInfo
          thName: $thName
          enName: $enName
          tags: $tags
          industryTypes: $industryTypes
          productTypes: $productTypes
          paymentCondition: $paymentCondition
          sellerTier: $sellerTier
          sellingCondition: $sellingCondition
          bankAccount: $bankAccount
          relatedDocuments: $relatedDocuments
          adminId: $adminId
        }
      ) {
        seller {
          id
          email
          facebook
          ig
          note
          phoneNumber1
          phoneNumber2
          secondaryChannel
          line
          analysisInfo
          contactName
          deliveryCondition
          financialInfo
          thName
          enName
          tags
          industryTypes
          productTypes
          paymentCondition
          sellerTier
          sellingCondition
          adminId
          bankAccount {
            bankAccountName
            bankAccountNumber
            bankName
            branchName
          }
          billingAddress {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
          }
          deliveryAddresses {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
            isMain
          }
          relatedDocuments
        }
      }
    }
  `,
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      admins {
        id
        firstName
        lastName
      }
      sellers {
        tags
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({
    industryTypeOptions: [
      { label: "Textile", value: "textile" },
      { label: "Garment", value: "garment" },
      { label: "Trader", value: "trader" },
      { label: "Brand Owner", value: "brand_owner" },
    ],
    productTypeOptions: [
      { label: "Knit", value: "knit" },
      { label: "Woven", value: "woven" },
      { label: "Natural", value: "natural" },
      { label: "Synthetic", value: "synthetic" },
      { label: "Mixed", value: "mixed" },
      { label: "Cotton", value: "cotton" },
      { label: "Linen", value: "linen" },
      { label: "Ramie", value: "ramie" },
      { label: "Bamboo", value: "bamboo" },
      { label: "Wool", value: "wool" },
      { label: "Silk", value: "silk" },
      { label: "Mohair", value: "mohair" },
      { label: "Polyester", value: "polyester" },
      { label: "Nylon", value: "nylon" },
      { label: "Rayon", value: "rayon" },
      { label: "Spandex", value: "spandex" },
      { label: "Jersey", value: "jersey" },
      { label: "Pique", value: "pique" },
      { label: "Interlock", value: "interlock" },
      { label: "Double Knit", value: "bouble_knit" },
      { label: "French Terry", value: "french_terry" },
      { label: "Mesh", value: "mesh" },
      { label: "Twill", value: "twill" },
      { label: "Canvas", value: "canvas" },
      { label: "Denim", value: "denim" },
      { label: "Corduroy", value: "corduroy" },
      { label: "Velvet", value: "velvet" },
      { label: "Satin", value: "satin" },
      { label: "Oxford", value: "oxford" },
      { label: "Plain ", value: "plain" },
      { label: "Pattern", value: "pattern" },
      { label: "Print", value: "print" },
      { label: "Solid", value: "solid" },
      { label: "Yarn Dyed", value: "yarn_dyed" },
      { label: "Home Deco", value: "home_deco" },
      { label: "Chiffone", value: "chiffone" },
      { label: "Sateen", value: "sateen" },
    ],
    sellerTierOptions: [
      { label: "Tier 1 - Green", value: "tier1" },
      { label: "Tier 2 - Amber", value: "tier2" },
      { label: "Tier 3 - Red", value: "tier3" },
    ],
  }),
  withFormik({
    mapPropsToValues: () => ({
      deliveryAddresses: [{}],
    }),
  }),
  withHooks((props, hooks) => {
    const { useMemo, useHandleSubmit, useQuery, useMutation } = hooks;
    const {
      industryTypeOptions,
      productTypeOptions,
      sellerTierOptions,
    } = props;
    const configs = useQuery(API.FETCH_CONFIG);
    const [createSeller] = useMutation(API.CREATE_SELLER);

    const title = `เพิ่มผู้ขาย`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.sellersPath(), label: "ผู้ขาย" },
        { path: null, label: title },
      ];
    }, [title]);

    const admins = configs.data?.admins;
    const adminIdOptions = useMemo(() => {
      const options = admins
        ? admins.map((a) => ({
            label: `${a.firstName} ${a.lastName}`,
            value: a.id,
          }))
        : [];
      return options;
    }, [admins]);
    const sellers = configs.data?.sellers;
    const tagOptions = useMemo(() => {
      const options = sellers
        ? compact(uniq(flatten(map(sellers, "tags")))).map((tag) => ({
            label: tag,
            value: tag,
          }))
        : [];
      return options;
    }, [sellers]);

    useHandleSubmit(
      async (values) => {
        try {
          await createSeller({ variables: values });
          paths.sellersPath().push();
          notifySuccess("เพิ่มข้อมูลสำเร็จ");
        } catch (e) {
          notifyError(e);
        }
      },
      [createSeller]
    );

    return {
      title,
      breadcrumbs,
      industryTypeOptions,
      productTypeOptions,
      sellerTierOptions,
      adminIdOptions,
      tagOptions,
      submitButtonLabel: "เพิ่มผู้ขาย",
    };
  })
);

export default enhancer(SellerNewPage);
