import { compose, withHooks } from "enhancers";
import { Box, Typography } from "components";
import { map, isEmpty } from "lodash";
import { ReactComponent as ShopifyLogo } from "../../assets/image/shopify_logo_black.svg";

const BuyerShopifyAddressCard = (props) => (
  <>
    <Box display="flex" flexDirection="row" alignItems="center">
      <ShopifyLogo style={{ marginRight: "8px", color: "black" }} />
      <Typography variant="h6" bold flex={1}>
        ข้อมูลที่อยู่จาก Shopify
      </Typography>
    </Box>
    <Box style={{ background: "#E7F4FD", borderRadius: 8 }} p={2} mt={6}>
      {props.info.addresses && (
        <Box display="flex">
          <Typography width={140}>ที่อยู่ส่งของ</Typography>
          <Typography width={24} mx={1}>
            :
          </Typography>
          <Box flex={1}>
            {props.info.addresses.map((address, index) => (
              <>
                {!isEmpty(address) && (
                  <Typography mt={index === 0 ? 0 : 2}>{address}</Typography>
                )}
              </>
            ))}
          </Box>
        </Box>
      )}
      <Box display="flex" mt={2}>
        <Typography width={140}>โทรศัพท์</Typography>
        <Typography width={24} mx={1}>
          :
        </Typography>
        <Typography flex={1}>{props.info.phone || "-"}</Typography>
      </Box>
      <Box display="flex" mt={2}>
        <Typography width={140}>อัปเดตเมื่อ</Typography>
        <Typography width={24} mx={1}>
          :
        </Typography>
        <Typography flex={1}>{props.info.updatedAt || "-"}</Typography>
      </Box>
    </Box>
  </>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info = {} } = props;

    return { info };
  })
);

export default enhancer(BuyerShopifyAddressCard);
