import { compose, withHooks, withFormik, defaultProps } from "enhancers";
import { Box } from "components";
import {
  gql,
  notifySuccess,
  notifyError,
  getFullAddress,
  cleanTypename,
} from "utils/helper";
import { isEqual, map, find, omit } from "lodash";
import { format, parseISO } from "date-fns";

import OrderEditCheckList from "./OrderCheckList";
import OrderBuyerInfo from "./OrderBuyerInfo";
import OrderSellerInfo from "./OrderSellerInfo";
import OrderItemsInfo from "./OrderItemsInfo";
import OrderNoteInfo from "./OrderNoteInfo";

const OPTIONS = {};

const OrderEditPage = (props) => (
  <Box>
    <OrderEditCheckList {...props.orderEditCheckList} />
    <OrderBuyerInfo {...props.orderBuyerInfo} mt={10} />
    <OrderSellerInfo {...props.orderSellerInfo} mt={10} />
    <OrderItemsInfo {...props.orderItemsInfo} mt={10} />
    <OrderNoteInfo {...props.orderNoteInfoProps} mt={10} />
  </Box>
);

export const API = {
  UPDATE_ORDER_NOTE: gql`
    mutation UPDATE_ORDER_NOTE($id: ID!, $note: String) {
      updateOrderNote(input: { id: $id, note: $note }) {
        order {
          id
        }
      }
    }
  `,
  UPDATE_ORDER_BUYER_INFO: gql`
    mutation UPDATE_ORDER_BUYER_INFO(
      $id: ID
      $deliveryDate: ISO8601DateTime
      $deliveryTime: String
      $deliveryAddress: AddressArguments
    ) {
      updateOrderBuyerInfo(
        input: {
          id: $id
          deliveryDate: $deliveryDate
          deliveryTime: $deliveryTime
          deliveryAddress: $deliveryAddress
        }
      ) {
        order {
          id
        }
      }
    }
  `,
  UPDATE_ORDER_ITEMS: gql`
    mutation UPDATE_ORDER_ITEMS(
      $id: ID!
      $oldSystemInvoiceCode: String
      $orderItems: [OrderItemArguments!]
      $discount: Float
      $remark: String
    ) {
      updateOrderItems(
        input: {
          id: $id
          oldSystemInvoiceCode: $oldSystemInvoiceCode
          orderItems: $orderItems
          discount: $discount
          remark: $remark
        }
      ) {
        order {
          id
        }
      }
    }
  `,
};

const ADD_NEW_ADDRESS = "ADD_NEW_ADDRESS";

const enhancer = compose(
  defaultProps({
    ...OPTIONS,
  }),
  withFormik({}),
  withHooks((props, hooks) => {
    const { useMemo, useEffect, useCallback, useMutation } = hooks;
    const {
      order,
      setValues,
      values,
      reloadOrder,
      configs,
      setFieldValue,
    } = props;
    const {
      id,
      note,
      deliveryAddressId,
      deliveryDate,
      deliveryTime,
      deliveryAddress,
      orderItems,
      discount,
      oldSystemInvoiceCode,
      remark,
    } = values || {};

    useEffect(() => {
      if (order) {
        const buyerName = `${order.buyer.code} - ${order.buyer.firstName} ${order.buyer.lastName} (${order.buyer.nickName})`;
        const deliveryAddressId = order.deliveryAddress?.id;
        setValues({ ...order, buyerName, deliveryAddressId });
      }
    }, [setValues, order]);

    const orderEditCheckList = useMemo(() => {
      return {
        ...order?.orderCheckList,
        deliveryDate,
        deliveryTime,
        reloadOrder,
        discount,
        canceled: order?.canceled,
        buyerDeliveryAddress: deliveryAddress,
        orderItemsOfOrder: orderItems,
        code: order?.code,
      };
    }, [
      order,
      reloadOrder,
      orderItems,
      deliveryAddress,
      deliveryDate,
      deliveryTime,
      discount,
    ]);

    // NOTE: Order Buyer info props.
    const buyerDeliveryAddresses =
      configs ??
      find(configs?.buyers, {
        id: order?.buyer?.id,
      })?.deliveryAddresses;

    useEffect(() => {
      const deliveryAddress = find(order?.buyer?.deliveryAddresses, {
        id: deliveryAddressId,
      });
      if (deliveryAddress) {
        setFieldValue("deliveryAddress", cleanTypename(deliveryAddress));
      }
    }, [setFieldValue, deliveryAddressId, order]);
    const [updateOrderBuyerInfo] = useMutation(API.UPDATE_ORDER_BUYER_INFO);
    const handleClickUpdateOrderBuyerInfo = useCallback(async () => {
      try {
        await updateOrderBuyerInfo({
          variables: {
            id,
            deliveryDate,
            deliveryTime,
            deliveryAddress,
          },
        });
        await reloadOrder();
        notifySuccess("บันทึกข้อมูลเรียบร้อย");
      } catch (e) {
        notifyError(e);
      }
    }, [
      updateOrderBuyerInfo,
      reloadOrder,
      id,
      deliveryDate,
      deliveryTime,
      deliveryAddress,
    ]);
    const orderBuyerInfoValuesDirty = !isEqual(
      { id, deliveryDate, deliveryTime, deliveryAddress },
      {
        id: order?.id,
        deliveryDate: order?.deliveryDate,
        deliveryTime: order?.deliveryTime,
        deliveryAddress: order?.deliveryAddress,
      }
    );
    const orderBuyerInfo = useMemo(() => {
      const deliveryAddressIdOptions = [
        ...map(order?.buyer?.deliveryAddresses, (address) => {
          return {
            label: getFullAddress(address),
            value: address.id,
          };
        }),
      ].filter((option) => option.label);

      const isCustomDeliveryAddress = deliveryAddressId === ADD_NEW_ADDRESS;

      return {
        buyerInfo: {
          ...order?.buyer,
          deliveryAddress: order?.deliveryAddress,
          deliveryDate: order?.deliveryDate,
          deliveryTime: order?.deliveryTime,
        },
        deliveryAddressIdOptions,
        isCustomDeliveryAddress,
        deliveryDate,
        handleSubmit: handleClickUpdateOrderBuyerInfo,
        disabledSubmitButton: !orderBuyerInfoValuesDirty,
        canEditBuyerInfo: order?.canEditBuyerInfo,
        canceled: order?.canceled,
      };
    }, [
      order,
      deliveryAddressId,
      deliveryDate,
      buyerDeliveryAddresses,
      handleClickUpdateOrderBuyerInfo,
      orderBuyerInfoValuesDirty,
    ]);

    const orderSellerInfo = useMemo(() => {
      return {
        ...order?.orderSellerInfo,
        canViewSellerInfo: order?.canViewSellerInfo,
      };
    }, [order]);

    // NOTE: Order Items info props.
    const [updateOrderItems] = useMutation(API.UPDATE_ORDER_ITEMS);
    const handleClickUpdateOrderItems = useCallback(async () => {
      try {
        const deleteUnusedKeyOrderItems = map(orderItems, (orderItem) =>
          omit(orderItem, ["poSlug", "poCode", "product"])
        );
        await updateOrderItems({
          variables: {
            id,
            oldSystemInvoiceCode,
            orderItems: deleteUnusedKeyOrderItems,
            discount,
            remark,
          },
        });
        await reloadOrder();
        notifySuccess("บันทึกข้อมูลเรียบร้อย");
      } catch (e) {
        notifyError(e);
      }
    }, [
      updateOrderItems,
      reloadOrder,
      id,
      oldSystemInvoiceCode,
      orderItems,
      discount,
      remark,
    ]);
    const orderItemsValuesDirty = !isEqual(
      { id, oldSystemInvoiceCode, orderItems, discount, remark },
      {
        id: order?.id,
        oldSystemInvoiceCode: order?.oldSystemInvoiceCode,
        orderItems: order?.orderItems,
        discount: order?.discount,
        remark: order?.remark,
      }
    );
    const orderItemsInfo = useMemo(() => {
      return {
        orderItems,
        discount,
        // products: configs?.products,
        handleSubmit: handleClickUpdateOrderItems,
        disabledSubmitButton: !orderItemsValuesDirty,
        canEditOrderItemsInfo: order?.canEditOrderItemsInfo,
        canDownloadInvoicePDF:
          order?.orderCheckList?.deliveryOrder?.status === orderItems?.length &&
          !order?.canceled &&
          orderItems?.length !== 0,
        canDownloadPaySlipPDF:
          order?.orderCheckList?.paymentOrder?.completedAt && !order?.canceled,
        canceled: order?.canceled,
        code: order?.code,
        deliveryTransactions:
          order?.orderCheckList?.deliveryOrder?.deliveryTransactions ?? [],
      };
    }, [
      order,
      // configs,
      orderItems,
      discount,
      handleClickUpdateOrderItems,
      orderItemsValuesDirty,
    ]);

    // NOTE: Order Note info props.
    const [updateOrderNote] = useMutation(API.UPDATE_ORDER_NOTE);
    const handleClickUpdateOrderNote = useCallback(async () => {
      try {
        await updateOrderNote({
          variables: { id, note },
        });
        await reloadOrder();
        notifySuccess("บันทึกข้อมูลเรียบร้อย");
      } catch (e) {
        notifyError(e);
      }
    }, [updateOrderNote, reloadOrder, id, note]);
    const orderNoteValuesDirty = !isEqual(
      { id, note },
      { id: order?.id, note: order?.note }
    );
    const orderNoteInfoProps = useMemo(() => {
      return {
        handleSubmit: handleClickUpdateOrderNote,
        disabled: !orderNoteValuesDirty,
        canceled: order?.canceled,
      };
    }, [handleClickUpdateOrderNote, orderNoteValuesDirty, order]);

    return {
      orderEditCheckList,
      orderBuyerInfo,
      orderSellerInfo,
      orderItemsInfo,
      orderNoteInfoProps,
    };
  })
);

export default enhancer(OrderEditPage);
