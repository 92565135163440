import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import {} from "components";
import {
  gql,
  notifyError,
  notifySuccess,
  paths,
  cleanTypename,
  getErrorMessage,
} from "utils/helper";
import { BuyerForm } from "./new";
import { ReactComponent as TrashIcon } from "assets/icon/cart-icon.svg";
import { isEmpty } from "lodash";
import { format, parseISO } from "date-fns";

const BuyerEditPage = (props) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
    sync={props.sync}
  >
    <BuyerForm {...props} />
  </PageContent>
);

const API = {
  FETCH_BUYER: gql`
    query FETCH_BUYER($id: ID!) {
      buyer(id: $id) {
        id
        code
        firstName
        lastName
        nickName
        birthdate
        email
        phoneNumber1
        phoneNumber2
        secondaryChannel
        line
        facebook
        ig
        brand
        note
        billingAddress {
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          isMain
          lastName
          ownerType
          phoneNumber
          province
          subDistrict
          taxPayerType
          zipCode
          taxId
          remark
        }
        deliveryAddresses {
          id
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          isMain
          lastName
          ownerType
          phoneNumber
          province
          subDistrict
          taxPayerType
          zipCode
          taxId
          remark
          isMain
        }
        documents
        shopifyBuyerId
        defaultShopifyAddress {
          id
          address1
          city
          firstName
          lastName
          province
          zip
          phone
          updatedAt
        }
        lastSyncUpAt
        lastSyncDownAt
      }
    }
  `,
  UPDATE_BUYER: gql`
    mutation UPDATE_BUYER(
      $id: String!
      $firstName: String
      $lastName: String
      $nickName: String
      $birthdate: ISO8601DateTime
      $email: String
      $phoneNumber1: String
      $phoneNumber2: String
      $secondaryChannel: String
      $line: String
      $facebook: String
      $ig: String
      $brand: String
      $note: String
      $billingAddress: AddressArguments
      $deliveryAddresses: [AddressArguments!]
      $documents: [Upload!]
      $shopifyBuyerId: String
    ) {
      updateBuyerInfo(
        input: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          nickName: $nickName
          birthdate: $birthdate
          email: $email
          phoneNumber1: $phoneNumber1
          phoneNumber2: $phoneNumber2
          secondaryChannel: $secondaryChannel
          line: $line
          facebook: $facebook
          ig: $ig
          brand: $brand
          note: $note
          billingAddress: $billingAddress
          deliveryAddresses: $deliveryAddresses
          documents: $documents
          shopifyBuyerId: $shopifyBuyerId
        }
      ) {
        buyer {
          id
          birthdate
          brand
          code
          email
          facebook
          firstName
          ig
          lastName
          nickName
          note
          phoneNumber1
          phoneNumber2
          secondaryChannel
          slug
          createdAt
          updatedAt
          line
          configs
          lastActiveAt
          canDelete
          billingAddress {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
          }
          deliveryAddresses {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
            isMain
          }
          documents
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      deliveryAddresses: [{}],
    }),
  }),
  withHooks((props, hooks) => {
    const { setValues } = props;
    const {
      useMemo,
      useHandleSubmit,
      useQuery,
      useMutation,
      useEffect,
      useParams,
      useState,
    } = hooks;

    const { id } = useParams();

    const { loading, data, error, refetch } = useQuery(API.FETCH_BUYER, {
      variables: { id },
    });
    const [updateBuyer] = useMutation(API.UPDATE_BUYER);

    const [isDisabledEmail, setIsDisabledEmail] = useState(false);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const buyer = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.buyersPath().push();
        return null;
      }
      return data.buyer;
    }, [loading, data, error]);

    const title = buyer?.code;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.buyersPath(), label: "ผู้ซื้อ" },
        { path: null, label: title },
      ];
    }, [title]);

    useEffect(() => {
      if (buyer) {
        const values = cleanTypename(buyer);
        setValues(values);
      }
    }, [setValues, buyer]);

    useEffect(() => {
      setIsDisabledEmail(!isEmpty(buyer?.shopifyBuyerId));
    }, [buyer]);

    useHandleSubmit(
      async (values) => {
        try {
          await updateBuyer({ variables: values });
          paths.buyersPath().push();
          notifySuccess("เพิ่มข้อมูลสำเร็จ");
        } catch (e) {
          notifyError(e);
        }
      },
      [updateBuyer]
    );

    const pageActions = useMemo(() => {
      return [
        {
          children: "ประวัติการซื้อ",
          startIcon: "history",
          onClick: () =>
            paths
              .ordersPath({ buyerName: `${buyer.firstName} ${buyer.lastName}` })
              .push(),
          color: "black",
          variant: "outlined",
        },
        {
          children: "สร้างคำสั่งซื้อ",
          startIcon: <TrashIcon />,
          onClick: () => paths.orderNewPath({ buyerId: buyer.id }).push(),
          color: "primary",
          variant: "outlined",
        },
      ];
    }, [buyer]);

    const sync = useMemo(() => {
      if (isEmpty(buyer)) {
        return null;
      }
      if (buyer.lastSyncUpAt && buyer.lastSyncDownAt) {
        const lastSyncUpAt = new Date(buyer.lastSyncUpAt);
        const lastSyncDownAt = new Date(buyer.lastSyncDownAt);
        if (lastSyncUpAt > lastSyncDownAt) {
          return `ส่งข้อมูลล่าสุด: ${format(
            parseISO(buyer.lastSyncUpAt),
            "dd/MM/yyyy, HH:mm"
          )}`;
        } else {
          return `รับข้อมูลล่าสุด: ${format(
            parseISO(buyer.lastSyncDownAt),
            "dd/MM/yyyy, HH:mm"
          )}`;
        }
      }
      if (buyer.lastSyncUpAt) {
        return `ส่งข้อมูลล่าสุด: ${format(
          parseISO(buyer.lastSyncUpAt),
          "dd/MM/yyyy, HH:mm"
        )}`;
      }
      if (buyer.lastSyncDownAt) {
        return `รับข้อมูลล่าสุด: ${format(
          parseISO(buyer.lastSyncDownAt),
          "dd/MM/yyyy, HH:mm"
        )}`;
      }
      return null;
    }, [buyer]);

    return {
      title,
      breadcrumbs,
      submitButtonLabel: "บันทึก",
      pageActions,
      isDisabledEmail,
      sync,
    };
  })
);

export default enhancer(BuyerEditPage);
