import React from "react";
import { compose, withHooks, withStores } from "enhancers";
import { get } from "lodash";

const IFrameComponent = (props) => (
  <iframe
    title={props.title}
    src={props.url}
    width="100%"
    height="100%"
    style={{ border: "none", ...props.style }}
  ></iframe>
);

const enhancer = compose(
  withStores((stores, props) =>
    props.storeName ? get(stores, props.storeName) : {}
  ),
  withHooks((props, hooks) => {
    const { storeName, dispatch, ...rest } = props;
    const { useCallback } = hooks;
    const handleClose = useCallback(() => {}, []);
    return { ...rest, handleClose };
  })
);

export default enhancer(IFrameComponent);
