import {
  compose,
  withHooks,
  withFormik,
  defaultProps,
  withStores,
} from "enhancers";
import { PageContent } from "layouts";
import {} from "components";
import {
  gql,
  paths,
  cleanTypename,
  notifySuccess,
  notifyError,
  toCurrency,
  calculateKgToYard,
  getCarbonFootprintPerKg,
  getWaterFootprintPerKg,
  getErrorMessage,
  formatDate,
} from "utils/helper";
import { ProductForm, OPTIONS } from "./new";
import {
  find,
  uniq,
  flatten,
  map,
  isNumber,
  isEqual,
  omit,
  compact,
} from "lodash";

const ProductEditPage = (props) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={[]}
  >
    {props.initialized ? <ProductForm {...props} /> : null}
  </PageContent>
);

export const API = {
  FETCH_PRODUCT: gql`
    query FETCH_PRODUCT($id: ID!) {
      product(id: $id) {
        legacyCode
        carbonFootprintPerKg
        carbonFootprintType
        category
        colors
        composition
        constructions
        costPerCapital
        cwWidth
        defect
        fibers
        fwWidth
        gsm
        id
        code
        images
        highlightImagesIndex
        note
        physicalCode
        physicalSeperations
        pricePerCapital
        productName
        quantityPerFold
        sellerColor
        hexColorCode
        sellerId
        sellerProductCode
        sellingCondition
        sellingUnit
        specialFunctions
        suitableForThings
        tags
        video
        tWidth
        thickness
        tone
        totalQuantity
        waterFootprintPerKg
        yarnCount
        published
        lastActiveAt
        lastEditedBy
      }
    }
  `,
  UPDATE_PRODUCT: gql`
    mutation UPDATE_PRODUCT(
      $id: ID!
      $images: [Upload!]
      $highlightImagesIndex: String
      $legacyCode: String
      $productName: String
      $category: String
      $composition: String
      $constructions: [String!]
      $fibers: [String!]
      $yarnCount: String
      $gsm: Float
      $thickness: String
      $fwWidth: Float
      $cwWidth: Float
      $tWidth: Float
      $physicalSeperations: [String!]
      $tone: String
      $colors: [String!]
      $sellerColor: String
      $hexColorCode: String
      $specialFunctions: [String!]
      $suitableForThings: [String!]
      $tags: [String!]
      $video: String
      $carbonFootprintType: String
      $carbonFootprintPerKg: Float
      $waterFootprintPerKg: Float
      $sellerId: ID
      $physicalCode: String
      $sellerProductCode: String
      $sellingUnit: String
      $costPerCapital: Float
      $pricePerCapital: Float
      $sellingCondition: String
      $defect: String
      $totalQuantity: Float
      $quantityPerFold: String
      $note: String
      $published: Boolean
      $lastEditedBy: String
    ) {
      updateProduct(
        input: {
          id: $id
          images: $images
          highlightImagesIndex: $highlightImagesIndex
          legacyCode: $legacyCode
          productName: $productName
          category: $category
          composition: $composition
          constructions: $constructions
          fibers: $fibers
          yarnCount: $yarnCount
          gsm: $gsm
          thickness: $thickness
          fwWidth: $fwWidth
          cwWidth: $cwWidth
          tWidth: $tWidth
          physicalSeperations: $physicalSeperations
          tone: $tone
          colors: $colors
          sellerColor: $sellerColor
          hexColorCode: $hexColorCode
          specialFunctions: $specialFunctions
          suitableForThings: $suitableForThings
          tags: $tags
          video: $video
          carbonFootprintType: $carbonFootprintType
          carbonFootprintPerKg: $carbonFootprintPerKg
          waterFootprintPerKg: $waterFootprintPerKg
          sellerId: $sellerId
          physicalCode: $physicalCode
          sellerProductCode: $sellerProductCode
          sellingUnit: $sellingUnit
          costPerCapital: $costPerCapital
          pricePerCapital: $pricePerCapital
          sellingCondition: $sellingCondition
          defect: $defect
          totalQuantity: $totalQuantity
          quantityPerFold: $quantityPerFold
          note: $note
          published: $published
          lastEditedBy: $lastEditedBy
        }
      ) {
        product {
          legacyCode
          carbonFootprintPerKg
          carbonFootprintType
          category
          colors
          composition
          constructions
          costPerCapital
          cwWidth
          defect
          fibers
          fwWidth
          gsm
          id
          images
          highlightImagesIndex
          note
          physicalCode
          physicalSeperations
          pricePerCapital
          productName
          quantityPerFold
          sellerColor
          hexColorCode
          sellerId
          sellerProductCode
          sellingCondition
          sellingUnit
          specialFunctions
          suitableForThings
          tags
          video
          tWidth
          thickness
          tone
          totalQuantity
          waterFootprintPerKg
          yarnCount
          published
          lastEditedBy
        }
      }
    }
  `,
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      configs {
        constructionOptions
        fiberOptions
        physicalSeperationOptions
        colorOptions
        specialFunctionOptions
        suitableForThingOptions
        tagOptions
        sellerIdOptions
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({
    ...OPTIONS,
  }),
  withStores((stores) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withFormik({
    mapPropsToValues: () => ({
      published: false,
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useHandleSubmit,
      useMutation,
      useQuery,
      useEffect,
      useParams,
      useCallback,
      useState,
      usePrevious,
      useUrlParam,
      useFormikContext,
    } = hooks;
    const {
      categoryOptions,
      constructionOptions,
      fiberOptions,
      thicknessOptions,
      physicalSeperationOptions,
      toneOptions,
      colorOptions,
      specialFunctionOptions,
      tagOptions,
      carbonFootprintTypeOptions,
      sellerIdOptions,
      sellingUnitOptions,
      setFieldValue,
      setValues,
      currentUser,
    } = props;

    const code = currentUser.code;
    const form = useFormikContext();

    const { id } = useParams();

    const configs = useQuery(API.FETCH_CONFIG);
    const { loading, data, error, refetch } = useQuery(API.FETCH_PRODUCT, {
      variables: { id },
    });
    const [updateProduct] = useMutation(API.UPDATE_PRODUCT);

    const initialized = !configs.loading && !loading;

    useEffect(() => {
      refetch();
    }, [refetch]);

    const product = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.productsPath().push();
        return null;
      }
      return data.product;
    }, [loading, data, error]);

    useEffect(() => {
      if (product) {
        const values = cleanTypename(product);
        setValues(values);
      }
    }, [setValues, product]);

    const moreOptions = useMemo(() => {
      return configs.data?.configs;
    }, [configs.data]);

    const title = product?.code;
    const urlParams = useUrlParam();
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        {
          path: paths.productsPath({ page: urlParams.fromTablePage }),
          label: "ผลิตภัณฑ์",
        },
        { path: null, label: title },
      ];
    }, [title, urlParams]);

    useHandleSubmit(
      async (values) => {
        let splitOption =
          values.physicalCode && values.physicalCode.split(/[()-]/);
        splitOption = compact(splitOption);

        if (splitOption.length !== 4 && splitOption.length > 0) {
          notifyError("Physical Code ไม่ถูกต้อง ตรวจสอบอีกครั้ง");
        } else {
          try {
            await updateProduct({
              variables: { ...values, lastEditedBy: code },
            });
            paths.productsPath().push();
            notifySuccess("เพิ่มข้อมูลสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        }
      },
      [updateProduct, code, form.errors]
    );

    const totalQuantityUnit = find(sellingUnitOptions, {
      value: props.values?.sellingUnit,
    })?.label;

    const [sellingUnitHelperText, setSellingUnitHelperText] = useState();
    const sellingUnit = props.values?.sellingUnit;
    const gsm = props.values?.gsm;
    const fwWidth = props.values?.fwWidth;
    const recalculateUnitHelperText = useCallback(() => {
      const yardPerKg = calculateKgToYard(gsm, fwWidth);
      const helperText =
        sellingUnit === "kg" && isNumber(yardPerKg)
          ? `${toCurrency(yardPerKg)} หลาต่อกิโลกรัม`
          : undefined;
      setSellingUnitHelperText(helperText);
    }, [setSellingUnitHelperText, sellingUnit, gsm, fwWidth]);

    useEffect(() => {
      recalculateUnitHelperText();
    }, [recalculateUnitHelperText]);

    const prevCostPerCapital = usePrevious(props.values?.costPerCapital);
    const costPerCapital = props.values?.costPerCapital;
    useEffect(() => {
      if (
        prevCostPerCapital !== undefined &&
        costPerCapital !== prevCostPerCapital
      ) {
        const pricePerCapital = costPerCapital ? costPerCapital * 1.25 : 0;
        setFieldValue("pricePerCapital", pricePerCapital);
      }
    }, [setFieldValue, costPerCapital, prevCostPerCapital]);

    const category = props.values?.category;
    const tone = props.values?.tone;
    const carbonFootprintType = props.values?.carbonFootprintType;
    useEffect(() => {
      const carbonFootprintPerKg = getCarbonFootprintPerKg(
        category,
        tone,
        carbonFootprintType
      );
      if (isNumber(carbonFootprintPerKg)) {
        setFieldValue("carbonFootprintPerKg", carbonFootprintPerKg);
      } else {
        setFieldValue("carbonFootprintPerKg", null);
      }
    }, [setFieldValue, category, tone, carbonFootprintType]);

    useEffect(() => {
      const waterFootprintPerKg = getWaterFootprintPerKg(
        category,
        tone,
        carbonFootprintType
      );
      if (isNumber(waterFootprintPerKg)) {
        setFieldValue("waterFootprintPerKg", waterFootprintPerKg);
      }
    }, [setFieldValue, category, tone, carbonFootprintType]);

    const disabledSubmitButton = useMemo(
      () => isEqual(props.values, omit(data?.product, "__typename")),
      [props.values, data]
    );

    const lastActiveAt = formatDate(product?.lastActiveAt, "dd/MM/yyyy, HH:mm");
    const isEditPage = true;

    return {
      title,
      breadcrumbs,
      submitButtonLabel: "บันทึก",
      totalQuantityUnit,
      sellingUnitHelperText,
      categoryOptions,
      constructionOptions,
      fiberOptions,
      thicknessOptions,
      physicalSeperationOptions,
      toneOptions,
      colorOptions,
      specialFunctionOptions,
      tagOptions,
      carbonFootprintTypeOptions,
      sellerIdOptions,
      sellingUnitOptions,
      recalculateUnitHelperText,
      ...moreOptions,
      initialized,
      disabledSubmitButton,
      lastEditedBy: product?.lastEditedBy,
      lastActiveAt,
      isEditPage,
    };
  })
);

export default enhancer(ProductEditPage);
