import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, Modal } from "components";
import { gql, notifyError, notifySuccess, paths } from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import humps from "humps";

const BuyerIndexPage = (props) => (
  <PageContent
    title="ผู้ซื้อ"
    breadcrumbs={[
      { path: paths.homePath(), label: "หน้าแรก" },
      { path: null, label: "ผู้ซื้อ" },
    ]}
    pageActions={[
      {
        children: "เพิ่มผู้ซื้อ",
        startIcon: <AddIcon />,
        onClick: () => paths.buyerNewPath().push(),
        color: "primary",
      },
    ]}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายชื่อผู้ซื้อ
      </Typography>
      <Table
        columns={[
          { width: 125, field: "code", headerName: "รหัส" },
          { width: 200, field: "fullName", headerName: "ชื่อ นามสกุล" },
          {
            width: 170,
            field: "phoneNumber1",
            headerName: "เบอร์โทรศัพท์",
            type: "phoneNumber",
          },
          { width: 200, field: "email", headerName: "อีเมล" },
          {
            width: 150,
            field: "secondaryContact",
            headerName: "ช่องทางสำรอง",
            type: "social",
          },
          {
            width: 160,
            field: "lastActiveAt",
            headerName: "แก้ไขล่าสุด",
            type: "dateTime",
          },
          {
            width: 55,
            field: "actions",
            headerName: " ",
            filterable: false,
            sortable: false,
            type: "actions",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.buyerEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
        includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_BUYERS: gql`
    query FETCH_BUYERS {
      buyers {
        id
        birthdate
        brand
        code
        email
        facebook
        firstName
        ig
        lastName
        nickName
        note
        phoneNumber1
        phoneNumber2
        secondaryChannel
        slug
        createdAt
        updatedAt
        line
        configs
        lastActiveAt
        canDelete
        billingAddress {
          id
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          isMain
          lastName
          ownerType
          phoneNumber
          province
          subDistrict
          taxPayerType
          zipCode
          taxId
          remark
        }
        deliveryAddresses {
          id
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          isMain
          lastName
          ownerType
          phoneNumber
          province
          subDistrict
          taxPayerType
          zipCode
          taxId
          remark
          isMain
        }
        documents
        shopifyBuyerId
      }
    }
  `,
  DELETE_BUYERS: gql`
    mutation DELETE_BUYERS($ids: [String!]!) {
      deleteBuyers(input: { ids: $ids }) {
        buyers {
          id
          firstName
          lastName
          nickName
          birthdate
          email
          phoneNumber1
          phoneNumber2
          secondaryChannel
          line
          facebook
          ig
          brand
          note
          billingAddress {
            id
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
          }
          deliveryAddresses {
            id
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
            isMain
          }
          documents
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useQuery, useMutation, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_BUYERS);
    const [deleteBuyers] = useMutation(API.DELETE_BUYERS);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const deleteBuyer = useCallback(
      async (params) => {
        const { id, code } = params.row;

        Modal.open({
          title: "ลบผู้ซื้อ",
          children: `การดำเนินการนี้จะทำให้ผู้ซื้อ ${code} ไม่สามารถใช้งานในระบบได้อีก`,
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ลบ",
          onOk: async ({ close }) => {
            try {
              await deleteBuyers({ variables: { ids: [id] } });
              await refetch();
              close();
              notifySuccess("ดำเนินการลบผู้ซื้อ 1 คน");
            } catch (e) {
              notifyError(e);
            }
          },
        });
      },
      [deleteBuyers, refetch]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.buyers.map((buyer) => {
        const { firstName, lastName, secondaryChannel, ...rest } = buyer;

        return {
          ...rest,
          fullName: `${firstName || ""} ${lastName || ""}`,
          secondaryContact: {
            channel: secondaryChannel,
            info: secondaryChannel
              ? buyer[humps.camelize(secondaryChannel)]
              : null,
          },
          actions: [{ Icon: TrashIcon, onClick: deleteBuyer }],
        };
      });
    }, [loading, data, error, deleteBuyer]);

    return {
      tableData,
      loading,
    };
  })
);

export default enhancer(BuyerIndexPage);
