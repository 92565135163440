/* eslint-disable */
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { rgba } from "polished";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { darken } from "polished";
import { RouteType, RouteChildType } from "../types/routes";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";

import { spacing } from "@material-ui/system";

import {
  Box as MuiBox,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { ReactComponent as Logo } from "assets/image/logo.svg";

import { MAIN_MENU, SELLER_MAIN_MENU } from "routes/menu";

import { env } from "env";

import PopMenu from "components/common/PopMenu";
import { useQuery, useMemo } from "hooks";
import { API } from "pages/main/purchase_orders";
import { sumBy } from "lodash";
import appStore from "stores/appStore";
import { compose, withHooks, withStores } from "enhancers";
import { gql } from "@apollo/client";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)<{
  button?: boolean;
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.header.background)};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: flex-start;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.header.background)};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
`;

const BrandChip = styled(Chip)`
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)}px;
    padding-right: ${(props) => props.theme.spacing(1.375)}px;
  }
`;

type CategoryType = {
  activeClassName?: string;
  button?: boolean;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
};

const Category = styled(ListItem)<CategoryType>`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 1;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)<{
  activeClassName: string;
  component: typeof NavLink;
  exact: boolean;
  to: string;
}>`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 1;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.header.background)} !important;
  padding: 12px 16px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SidebarFooterSubText = styled(Typography)`
  color: #a1a1a1;
  font-size: 12px;
  line-height: 16px;
  display: block;
  padding: 1px;
`;

// const SidebarFooterBadge = styled(Badge)`
//   margin-right: ${(props) => props.theme.spacing(1)}px;
//   span {
//     background-color: ${(props) =>
//       props.theme.sidebar.footer.online.background};
//     border: 1.5px solid ${(props) => props.theme.palette.common.white};
//     height: 12px;
//     width: 12px;
//     border-radius: 50%;
//   }
// `;

type SidebarCategoryPropsType = {
  name: string;
  icon: JSX.Element;
  classes?: string;
  isOpen?: boolean;
  isCollapsable: boolean;
  badge?: string | number;
  activeClassName?: string;
  button: true;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
};

const SidebarCategory: React.FC<SidebarCategoryPropsType> = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) => {
  return (
    <Category {...rest} onClick={() => { appStore.setPaginate("", 0, 100) }}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
};

type SidebarLinkPropsType = {
  name: string;
  to: string;
  badge?: string | number;
  icon?: JSX.Element;
};

const SidebarLink: React.FC<SidebarLinkPropsType> = ({
  name,
  to,
  badge,
  icon,
}) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

type SidebarPropsType = {
  classes?: string;
  staticContext: string;
  location: {
    pathname: string;
  };
  routes: Array<RouteType>;
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  isAdmin?: boolean;
  isProfileDataInitialize?: boolean;
  onClose?: () => void;
};

const ENV_TAGS: any = {
  production: null,
  staging: (
    <BrandChip
      label="STG"
      style={{ backgroundColor: "#1bc5bd", color: "#1e293a" }}
    />
  ),
  local: (
    <BrandChip
      label="DEV"
      style={{ backgroundColor: "#ffeb3b", color: "#1e293a" }}
    />
  ),
};
const ENV_TAG: any = ENV_TAGS[env];

const Sidebar: React.FC<RouteComponentProps & SidebarPropsType> = ({
  classes,
  staticContext,
  location,
  isAdmin,
  isProfileDataInitialize,
  ...rest
}) => {
  type tplotOptions = {
    [key: number]: boolean;
  };

  const configs = useQuery(API.FETCH_CONFIG);
  const orderItemWithoutPoCount = useMemo(() => {
    return configs.data?.orderItemsWithoutPo.length ?? 0;
  }, [configs.data]);

  const customRoutes = useMemo(() => {
    const ROUTES = isAdmin ? MAIN_MENU : SELLER_MAIN_MENU;

    ROUTES.map((route) => {
      if (route.id === "ใบสั่งสินค้า") {
        route.badge = orderItemWithoutPoCount
          ? `รอสั่ง ${orderItemWithoutPoCount}`
          : "";
      }

      if (route.id === "การสั่งสินค้า") {
        // route.badge = orderItemWithoutPoCount
        //   ? `${orderItemWithoutPoCount}`
        //   : "";
      }

      return route;
    });

    return ROUTES;
  }, [orderItemWithoutPoCount, isAdmin]);

  const initOpenRoutes = (): tplotOptions => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    const ROUTES = isAdmin ? MAIN_MENU : SELLER_MAIN_MENU;

    ROUTES.forEach((route: RouteType, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  useEffect(() => {
    appStore.setState({ configSidebarRefetch: () => configs.refetch() });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs.refetch]);

  const toggle = (index: number) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/" button>
        <BrandIcon />{" "}
        <Box color="white" ml={1}>
          Moreloop{ENV_TAG}
        </Box>
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {customRoutes.map((category: RouteType, index) => (
              <React.Fragment key={index}>
                {category.header ? (
                  <SidebarSection>{category.header}</SidebarSection>
                ) : null}

                {category.children && category.icon ? (
                  <React.Fragment key={index}>
                    <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      name={category.id}
                      icon={category.icon}
                      button={true}
                      onClick={() => toggle(index)}
                    />

                    <Collapse
                      in={openRoutes[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {category.children.map(
                        (route: RouteChildType, index: number) => (
                          <SidebarLink
                            key={index}
                            name={route.name}
                            to={route.path}
                            icon={route.icon}
                            badge={route.badge}
                          />
                        )
                      )}
                    </Collapse>
                  </React.Fragment>
                ) : category.icon ? (
                  <SidebarCategory
                    isCollapsable={false}
                    name={category.id}
                    to={category.path}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    exact
                    button
                    badge={category.badge}
                  />
                ) : null}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
      <SidebarFooter>
        <PopMenu
          SidebarFooterText={SidebarFooterText}
          SidebarFooterSubText={SidebarFooterSubText}
        />
      </SidebarFooter>
    </Drawer>
  );
};

const enhancer = compose(
  withStores((stores: any) => ({
    isProfileDataInitialize: !!stores.appStore.currentUser?.ownerData?.seller
      ?.isProfileDataInitialize,
    ownerType: stores.appStore.currentUser?.ownerData?.type,
    isSuperAdmin: stores.appStore.currentUser?.ownerData?.isSuperAdmin,
  })),
  withHooks((props: any, hooks: any) => {
    const {
      isProfileDataInitialize,
      ownerType,
      isSuperAdmin: isCurrentUserSuperAdmin,
    } = props;

    const isAdmin = ownerType === "Admin";
    const isSuperAdmin = isAdmin ? isCurrentUserSuperAdmin : null;

    const propsParams = isSuperAdmin
      ? { ...props, isAdmin, isSuperAdmin, isProfileDataInitialize }
      : { ...props, isAdmin, isProfileDataInitialize };

    return propsParams;
  })
);

export default enhancer(withRouter(Sidebar));
