import { Box, Typography } from "components";
import { compose, withHooks } from "enhancers";

const Header = (props) => (
  <Box display="flex" justifyContent="space-between">
    <Box>
      <Typography variant="h4">{props.title}</Typography>
      <Typography variant="caption" color="Text/Dark Grey">
        {props.date}
      </Typography>
    </Box>
    <Box>
      <Typography variant="caption">{props.subTitle}</Typography>
    </Box>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    return { ...props };
  })
);

export default enhancer(Header);
