import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Table,
  Typography,
  Modal,
  ExcelGenerator,
  Backdrop,
} from "components";
import {
  formatDate,
  gql,
  notifyError,
  notifySuccess,
  paths,
} from "utils/helper";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import { ReactComponent as CopyIcon } from "assets/icon/copy-icon.svg";
import { HighlightOff } from "@material-ui/icons";
import PDFContent from "./PDFContent/index";
import { ReactComponent as QrCodeIcon } from "assets/icon/qrcode_icon.svg";
import server from "common/api";

const ProductIndexPage = (props) => (
  <PageContent
    title="ผลิตภัณฑ์"
    breadcrumbs={[
      { path: paths.homePath(), label: "หน้าแรก" },
      { path: null, label: "ผลิตภัณฑ์" },
    ]}
    pageActions={props.pageActions}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายชื่อผลิตภัณฑ์
      </Typography>
      <Table
        columns={[
          {
            width: 10,
            field: "published",
            headerName: " ",
            type: "boolean",
          },
          {
            width: 125,
            field: "sellerProductCode",
            headerName: "รหัส",
          },
          { width: 200, field: "productName", headerName: "ชื่อ" },
          // { width: 200, field: "sellerName", headerName: "ผู้ขาย" },
          {
            width: 130,
            field: "totalQuantity",
            headerName: "คงเหลือ",
            type: "currency",
          },
          { width: 135, field: "sellingUnit", headerName: "หน่วย" },
          { width: 195, field: "lastEditedBy", headerName: "แก้ไขล่าสุดโดย" },
          {
            width: 175,
            field: "lastActiveAt",
            headerName: "แก้ไขล่าสุด",
            type: "dateTime",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.productEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
        includeToolbar
        checkboxSelection={props.showCheckboxSelected}
        onSelectionModelChange={props.onSelectionChange}
        isRowSelectable={(params) => params.row.published === true}
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        refetch={props.refetch}
        rowCount={props.rowCount}
        initialFilter={{
          items: [],
        }}
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_PRODUCTS: gql`
    query FETCH_PRODUCTS(
      $filters: JSON
      $sorts: JSON
      $page: Float
      $pageSize: Float
    ) {
      products(
        filters: $filters
        sorts: $sorts
        page: $page
        pageSize: $pageSize
      ) {
        products {
          id
          code
          productName
          sellingUnit
          sellingUnitForExcel
          totalQuantity
          published
          canDelete
          lastActiveAt
          lastEditedBy
          sellerProductCode
        }
        paginate {
          page
          pageSize
          rowCount
        }
      }
    }
  `,
  COPY_PRODUCT: gql`
    mutation COPY_PRODUCT($id: String!) {
      copyProduct(input: { id: $id }) {
        product {
          id
        }
      }
    }
  `,
  DELETE_PRODUCTS: gql`
    mutation DELETE_PRODUCTS($ids: [String!]!) {
      deleteProducts(input: { ids: $ids }) {
        products {
          id
        }
      }
    }
  `,
  IMPORT_PRODUCTS: gql`
    mutation IMPORT_PRODUCTS($excelFile: Upload!) {
      importProducts(input: { excelFile: $excelFile }) {
        products {
          id
        }
      }
    }
  `,
  FETCH_PRODUCTS_FOR_DOWNLOAD: gql`
    query FETCH_PRODUCTS_FOR_DOWNLOAD(
      $filters: JSON
      $sorts: JSON
      $page: Float
      $pageSize: Float
    ) {
      products(
        filters: $filters
        sorts: $sorts
        page: $page
        pageSize: $pageSize
      ) {
        products {
          legacyCode
          carbonFootprintPerKg
          # carbonFootprintType
          carbonFootprintTypeForExcel
          category
          categoryForExcel
          # colors
          colorForExcel
          composition
          # constructions
          constructionForExcel
          costPerCapital
          cwWidth
          defect
          # fibers
          fiberForExcel
          fwWidth
          gsm
          id
          code
          productCodeForExcel
          images
          note
          physicalCode
          # physicalSeperations
          physicalSeperationForExcel
          pricePerCapital
          productName
          quantityPerFold
          sellerColor
          sellerId
          sellerProductCode
          sellingCondition
          sellingUnit
          sellingUnitForExcel
          # specialFunctions
          specialFunctionForExcel
          # suitableForThings
          suitableForThingForExcel
          # tags
          tWidth
          # thickness
          thicknessForExcel
          # tone
          toneForExcel
          totalQuantity
          waterFootprintPerKg
          yarnCount
          published
          publishedForExcel
          canDelete
          sellerName
          sellerNameForExcel
          lastActiveAt
          lastEditedBy
          shopifyUrl
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      useMemo,
      useCallback,
      useQuery,
      useMutation,
      useEffect,
      useState,
      useLazyQuery,
    } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_PRODUCTS, {
      variables: { sorts: [{ field: "sellerProductCode", sort: "asc" }] },
    });
    const [copyProduct] = useMutation(API.COPY_PRODUCT);
    const [deleteProducts] = useMutation(API.DELETE_PRODUCTS);
    const [importProducts] = useMutation(API.IMPORT_PRODUCTS);
    const [
      fetchProductsForDownload,
      {
        loading: loadingProductsForDownload,
        refetch: refetchProductsForDownload,
      },
    ] = useLazyQuery(API.FETCH_PRODUCTS_FOR_DOWNLOAD, {
      notifyOnNetworkStatusChange: true,
    });

    const [showCheckboxSelected, setShowCheckboxSelected] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [refetchNeeded, setRefetchNeeded] = useState(false);
    const [productsForDownload, setProductsForDownload] = useState(null);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const handleCopyProduct = useCallback(
      (params) => {
        const { id, code, productName } = params.row;
        Modal.open({
          title: "คัดลอกผลิตภัณฑ์",
          children: `ระบบจะสร้างผลิตภัณฑ์ใหม่ที่มีลักษณะเหมือนกับ ${code} - ${productName}`,
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "คัดลอก",
          onOk: async ({ close }) => {
            try {
              const { data } = await copyProduct({ variables: { id } });
              const newProductId = data.copyProduct.product.id;

              await refetch();
              close();
              notifySuccess("ดำเนินการคัดลอกผลิตภัณฑ์ 1 ชิ้น");

              paths.productEditPath(newProductId).push();
            } catch (e) {
              notifyError(e);
            }
          },
        });
      },
      [copyProduct, refetch]
    );

    const handleDeleteProduct = useCallback(
      async (params) => {
        const { id } = params;

        Modal.open({
          title: "ลบผลิตภัณฑ์",
          children: `การดำเนินการนี้จะไม่สามารถย้อนกลับได้ แน่ใจใช่หรือไม่?`,
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ลบ",
          onOk: async ({ close }) => {
            Backdrop.open();
            try {
              await deleteProducts({ variables: { ids: [id] } });
              close();
              await refetch();
              notifySuccess("ดำเนินการลบผลิตภัณฑ์ 1 ชิ้น");
            } catch (e) {
              notifyError(e);
            }
            Backdrop.close();
          },
        });
      },
      [deleteProducts, refetch]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.products.products.map((product) => {
        const {
          firstName,
          lastName,
          secondaryChannel,
          canDelete,
          sellingUnit,
          code,
          sellerProductCode,
          ...rest
        } = product;

        return {
          ...rest,
          sellerProductCode: sellerProductCode ? sellerProductCode : code,
          sellingUnit,
          actions: [
            { Icon: CopyIcon, onClick: handleCopyProduct },
            ...(canDelete
              ? [{ Icon: TrashIcon, onClick: handleDeleteProduct }]
              : []),
          ],
        };
      });
    }, [loading, data, error, handleCopyProduct, handleDeleteProduct]);

    const downloadProducts = useCallback(async () => {
      // TODO: Over fetch product for download problem
      await fetchProductsForDownload();
      setRefetchNeeded(true);
    }, [fetchProductsForDownload, setRefetchNeeded]);

    useEffect(() => {
      if (loadingProductsForDownload) {
        Backdrop.open();
      } else {
        Backdrop.close();
      }
    }, [productsForDownload, loadingProductsForDownload]);

    useEffect(() => {
      async function refetchProducts() {
        if (refetchNeeded) {
          const response = await refetchProductsForDownload({
            sorts: [{ field: "sellerProductCode", sort: "asc" }],
          });
          setProductsForDownload(response.data.products.products);
          setRefetchNeeded(false);
        }
      }

      refetchProducts();
    }, [refetchNeeded, refetchProductsForDownload]);

    useEffect(() => {
      if (productsForDownload) {
        ExcelGenerator.generate({
          fileName: `product-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
          columns: [
            // {
            //   title: "Code",
            //   field: "productCodeForExcel",
            // },
            // {
            //   title: "รหัสผลิตภัณฑ์จากระบบเก่า",
            //   field: "legacyCode",
            // },
            {
              title: "ชื่อผลิตภัณท์",
              field: "productName",
            },
            {
              title: "ชนิดผ้า",
              field: "categoryForExcel",
            },
            {
              title: "ส่วนประกอบผ้า",
              field: "composition",
            },
            {
              title: "ลักษณะวัสดุ",
              field: "constructionForExcel",
            },
            {
              title: "เส้นใยที่ใช้",
              field: "fiberForExcel",
            },
            {
              title: "เบอร์เส้นด้าย",
              field: "yarnCount",
            },
            {
              title: "น้ำหนักต่อตารางเมตร",
              field: "gsm",
            },
            {
              title: "ความหนา",
              field: "thicknessForExcel",
            },
            {
              title: "ความกว้างหน้าผ้า FW",
              field: "fwWidth",
            },
            {
              title: "ความกว้างหน้าผ้า CW",
              field: "cwWidth",
            },
            {
              title: "ความกว้างหน้าผ้า T",
              field: "tWidth",
            },
            {
              title: "Physical Seperation",
              field: "physicalSeperationForExcel",
            },
            // {
            //   title: "โทนสี",
            //   field: "toneForExcel",
            // },
            // {
            //   title: "สี",
            //   field: "colorForExcel",
            // },
            {
              title: "สีที่ผู้ขายใช้เรียก",
              field: "sellerColor",
            },
            {
              title: "คุณสมบัติพิเศษ",
              field: "specialFunctionForExcel",
            },
            {
              title: "คำแนะนำในการนำไปใช้",
              field: "suitableForThingForExcel",
            },
            // {
            //   title: "ประเภทผ้าเพื่อคำนวณ Carbon footprints",
            //   field: "carbonFootprintTypeForExcel",
            // },
            // {
            //   title: "Carbon footprint ต่อผ้า 1 กิโลกรัม",
            //   field: "carbonFootprintPerKg",
            // },
            // {
            //   title: "Water footprint ต่อผ้า 1 กิโลกรัม",
            //   field: "waterFootprintPerKg",
            // },
            {
              title: "ผู้ขาย",
              field: "sellerNameForExcel",
            },
            {
              title: "Physical code",
              field: "physicalCode",
            },
            // {
            //   title: "รหัสผ้าฝั่งผู้ขาย",
            //   field: "sellerProductCode",
            // },
            {
              title: "หน่วยในการจัดเก็บ/ขาย",
              field: "sellingUnitForExcel",
            },
            {
              title: "ราคาทุนต่อหน่วย",
              field: "costPerCapital",
            },
            // {
            //   title: "ราคาขายต่อหน่วย",
            //   field: "pricePerCapital",
            // },
            {
              title: "เงื่อนไขในการซื้อ",
              field: "sellingCondition",
            },
            // {
            //   title: "ตำหนิผ้า",
            //   field: "defect",
            // },
            // {
            //   title: "ปริมาณที่มีทั้งหมด",
            //   field: "totalQuantity",
            // },
            // {
            //   title: "ปริมาณต่อพับ",
            //   field: "quantityPerFold",
            // },
            // {
            //   title: "แสดงใน Shopify",
            //   field: "publishedForExcel",
            // },
          ],
          data: productsForDownload,
        });
        setProductsForDownload(null);
      }
    }, [productsForDownload]);

    const uploadProducts = useCallback(
      async (file) => {
        try {
          await importProducts({ variables: { excelFile: file } });
          await refetch();
          notifySuccess("upload สำเร็จ");
        } catch (e) {
          notifyError(e);
        }
      },
      [importProducts, refetch]
    );

    const onSelectionChange = useCallback((newSelection) => {
      setSelectedIds(newSelection);
    }, []);

    const handleQRCodeGenerate = useCallback(async () => {
      Backdrop.open();
      try {
        await server.download(
          "/download_qrcode_pdf",
          {
            ids: selectedIds,
          },
          { method: "post" }
        );
        notifySuccess("สำเร็จ");
      } catch (e) {
        console.log("e", e);
        notifyError(e);
      }

      Backdrop.close();
    }, [selectedIds]);

    const pageActions = useMemo(() => {
      if (showCheckboxSelected) {
        return [
          {
            children: "ยกเลิก",
            startIcon: <HighlightOff />,
            onClick: () => setShowCheckboxSelected(false),
            variant: "outlined",
            color: "primary",
          },
          {
            children: "Download ฉลาก",
            startIcon: <QrCodeIcon />,
            onClick: () => handleQRCodeGenerate(),
            color: "primary",
          },
        ];
      } else {
        return [
          {
            children: "สร้างฉลากผลิตภัณฑ์",
            startIcon: <QrCodeIcon />,
            onClick: () => setShowCheckboxSelected(true),
            color: "primary",
          },
          {
            children: "เพิ่มผลิตภัณฑ์",
            startIcon: "add",
            onClick: () => paths.productNewPath().push(),
            color: "primary",
          },
        ];
      }
    }, [
      showCheckboxSelected,
      downloadProducts,
      uploadProducts,
      handleQRCodeGenerate,
    ]);

    return {
      tableData,
      loading,
      downloadProducts,
      uploadProducts,
      onSelectionChange,
      pageActions,
      showCheckboxSelected,
      rowCount: data?.products?.paginate?.rowCount ?? 0,
      refetch,
    };
  })
);

export default enhancer(ProductIndexPage);
