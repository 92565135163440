import { compose, withHooks, withFormik, defaultProps } from "enhancers";
import { Box, Modal, Typography } from "components";
import {
  gql,
  notifySuccess,
  getErrorMessage,
  notifyError,
  getFullAddress,
  cleanTypename,
  paths,
} from "utils/helper";
import { isEqual, map, find, cloneDeep, omit } from "lodash";
import { PageContent } from "layouts";

import { format, parseISO } from "date-fns";
import OrderBuyerInfo from "./OrderBuyerInfo";
import OrderItemsInfo from "./OrderItemsInfo";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { ReactComponent as CartIcon } from "assets/icon/cart-icon.svg";

const OPTIONS = {};

const DraftOrderDetailPage = (props) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={props.actions}
    paper={false}
    sync={props.sync}
  >
    <Box>
      {props.creatOrderInfo && (
        <Box
          display="flex"
          alignItems="center"
          style={{
            background: "#E7F4FD",
            borderRadius: 8,
          }}
          p={4}
          mt={10}
        >
          <InfoOutlinedIcon
            style={{
              color: "#376FD0",
              width: "24px",
              height: "24px",
              marginRight: "8px",
            }}
          />
          <Typography variant="body1" color="#0D3C61">
            {props.creatOrderInfo}
          </Typography>
        </Box>
      )}
      <OrderBuyerInfo {...props.orderBuyerInfo} mt={6} />
      <OrderItemsInfo {...props.orderItemsInfo} mt={10} />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_ORDER: gql`
    query FETCH_ORDER($id: ID!) {
      draftOrder(id: $id) {
        id
        shopifyOrderId
        cost
        updatedAt
        lastSyncDownAt
        lastSyncUpAt
        order {
          id
          code
          createdAt
        }
        buyer {
          id
          code
          firstName
          lastName
          nickName
          brand
          note
          shopifyBuyerId
          deliveryAddresses {
            id
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
            isMain
          }
          defaultShopifyAddress {
            id
            address1
            city
            firstName
            lastName
            province
            zip
            phone
            updatedAt
          }
        }
        deliveryTime
        address {
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          id
          isMain
          lastName
          ownerType
          phoneNumber
          province
          remark
          subDistrict
          taxId
          taxPayerType
          zipCode
        }
        draftOrderItems {
          id
          productId
          sellingUnit
          costPricePerUnit
          sellingPricePerUnit
          amount
          product {
            id
            code
            productName
            sellingUnit
            costPerCapital
            pricePerCapital
            totalQuantity
            quantityPerFold
            lastActiveAt
            seller {
              code
              thName
              enName
              sellingCondition
              deliveryCondition
              paymentCondition
              note
            }
          }
        }
      }
    }
  `,
  FETCH_CONFIGS: gql`
    query FETCH_CONFIGS {
      products {
        products {
          id
          code
          productName
          sellingUnit
          costPerCapital
          pricePerCapital
          totalQuantity
          quantityPerFold
          lastActiveAt
          seller {
            code
            thName
            enName
            sellingCondition
            deliveryCondition
            paymentCondition
            note
          }
        }
      }
    }
  `,
  CREATE_ORDER: gql`
    mutation CREATE_ORDER(
      $buyerId: ID!
      $deliveryAddress: AddressArguments
      $deliveryAddressId: ID
      $deliveryDate: ISO8601Date
      $deliveryTime: String
      $discount: Float
      $note: String
      $oldSystemInvoiceCode: String
      $orderItems: [OrderItemArguments!]
      $addAddressToBuyer: Boolean
      $isFromDraftOrder: Boolean
      $draftOrderId: ID
    ) {
      createOrder(
        input: {
          buyerId: $buyerId
          deliveryAddress: $deliveryAddress
          deliveryAddressId: $deliveryAddressId
          deliveryDate: $deliveryDate
          deliveryTime: $deliveryTime
          discount: $discount
          note: $note
          oldSystemInvoiceCode: $oldSystemInvoiceCode
          orderItems: $orderItems
          addAddressToBuyer: $addAddressToBuyer
          isFromDraftOrder: $isFromDraftOrder
          draftOrderId: $draftOrderId
        }
      ) {
        order {
          id
        }
      }
    }
  `,
};

const ADD_NEW_ADDRESS = "ADD_NEW_ADDRESS";

const enhancer = compose(
  defaultProps({
    ...OPTIONS,
  }),
  withFormik({}),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useEffect,
      useQuery,
      useParams,
      useMutation,
      useCallback,
    } = hooks;
    const { setValues, values, reloadOrder, setFieldValue } = props;
    const {
      note,
      deliveryAddressId,
      deliveryDate,
      deliveryTime,
      deliveryAddress,
      orderItems,
      discount,
      oldSystemInvoiceCode,
      remark,
    } = values || {};
    const { id } = useParams();

    // const { data: configs, loading: loadingConfig } = useQuery(
    //   API.FETCH_CONFIGS
    // );

    const { loading, data, error, refetch } = useQuery(API.FETCH_ORDER, {
      variables: { id },
    });

    const order = useMemo(() => {
      if (loading) {
        return null;
      }

      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.draftOrdersPath().push();
        return null;
      }
      return cleanTypename(cloneDeep(data?.draftOrder));
    }, [loading, data, error]);

    useEffect(() => {
      if (order) {
        const buyerName = `${order?.buyer?.code} - ${order?.buyer?.firstName} ${order?.buyer?.lastName}`;
        const defaultDeliveryAddr = order?.buyer.deliveryAddresses.find(
          (addr) => addr.isMain === "true"
        );
        const deliveryAddressId = defaultDeliveryAddr?.id;
        setValues({
          ...order,
          buyerName,
          buyerId: order.buyer.id,
          deliveryAddressId,
          draftOrderId: order.id,
          orderItems: order.draftOrderItems,
          isFromDraftOrder: true,
        });
      }
    }, [setValues, order]);

    const title = data?.draftOrder.shopifyOrderId;

    const sync = useMemo(() => {
      if (order?.lastSyncUpAt && order?.lastSyncDownAt) {
        const lastSyncUpAt = new Date(order?.lastSyncUpAt);
        const lastSyncDownAt = new Date(order?.lastSyncDownAt);
        if (lastSyncUpAt > lastSyncDownAt) {
          return `ส่งข้อมูลล่าสุด: ${format(
            parseISO(order?.lastSyncUpAt),
            "dd/MM/yyyy, HH:mm"
          )}`;
        } else {
          return `รับข้อมูลล่าสุด: ${format(
            parseISO(order?.lastSyncDownAt),
            "dd/MM/yyyy, HH:mm"
          )}`;
        }
      }
      if (order?.lastSyncUpAt) {
        return `ส่งข้อมูลล่าสุด: ${format(
          parseISO(order?.lastSyncUpAt),
          "dd/MM/yyyy, HH:mm"
        )}`;
      }
      if (order?.lastSyncDownAt) {
        return `รับข้อมูลล่าสุด: ${format(
          parseISO(order?.lastSyncDownAt),
          "dd/MM/yyyy, HH:mm"
        )}`;
      }
      return null;
    }, [order]);

    const actions = useMemo(() => {
      if (order?.order) {
        return [
          {
            children: "ดูรายละเอียดคำสั่งซื้อ",
            startIcon: <CartIcon />,
            onClick: () => paths.orderEditPath(order?.order.id).push(),
            variant: "outlined",
            color: "primary",
          },
        ];
      }
      return [];
    }, [data?.draftOrder.order]);

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.draftOrdersPath(), label: "ร่างคำสั่งซื้อ" },
        { path: null, label: title },
      ];
    }, [title]);

    const orderEditCheckList = useMemo(() => {
      return {
        ...order?.orderCheckList,
        deliveryDate,
        deliveryTime,
        reloadOrder,
        discount,
        canceled: order?.canceled,
        buyerDeliveryAddress: deliveryAddress,
        orderItemsOfOrder: order?.draftOrderItems,
        code: order?.code,
      };
    }, [
      order,
      reloadOrder,
      deliveryAddress,
      deliveryDate,
      deliveryTime,
      discount,
    ]);

    const creatOrderInfo = useMemo(() => {
      if (order?.order) {
        return `สร้างคำสั่งซื้อ ${order?.order?.code} เมื่อ ${format(
          parseISO(order?.order?.createdAt),
          "dd/MM/yyyy, HH:mm"
        )}`;
      }
      return null;
    }, [order]);

    // NOTE: Order Buyer info props.

    const orderBuyerInfoValuesDirty = !isEqual(
      { id, deliveryDate, deliveryTime, deliveryAddress },
      {
        id: order?.id,
        deliveryDate: order?.deliveryDate,
        deliveryTime: order?.deliveryTime,
        deliveryAddress: order?.address,
      }
    );

    const [createOrder] = useMutation(API.CREATE_ORDER);

    const handleCreateOrder = useCallback(
      async (e) => {
        if (values.deliveryAddressId) {
          Modal.open({
            className: "create-order-modal",
            title: "สร้างคำสั่งซื้อ",
            children: `ต้องการสร้างคำสั่งซื้อใหม่จาก ${values.shopifyOrderId}
          หรือไม่ `,
            cancelButtonLabel: "ยกเลิก",
            okButtonLabel: "สร้างคำสั่งซื้อ",
            onOk: async ({ close }) => {
              try {
                const { orderItems, ...rest } = values;
                const filteredOrderItems = orderItems.filter(
                  (order) => order.productId
                );

                const deleteUnusedKeyOrderItems = map(orderItems, (orderItem) =>
                  omit(orderItem, ["product"])
                );

                await createOrder({
                  variables: {
                    ...rest,
                    orderItems: deleteUnusedKeyOrderItems,
                  },
                });
                close();
                paths.draftOrdersPath().push();
                notifySuccess("เพิ่มข้อมูลสำเร็จ");
              } catch (e) {
                close();
                Modal.alert({
                  className: "error-order-modal",
                  title: "เกิดข้อผิดพลาด",
                  children: `ร่างคำสั่งซื้อหมายเลข ${values.shopifyOrderId} ถูกสร้างเป็นคำสั่งซื้อไปแล้ว`,
                  okButtonLabel: "กลับหน้าหลัก",
                  onOk: async ({ close }) => {
                    close();
                    paths.draftOrdersPath().push();
                  },
                });
              }
            },
          });
        } else {
          Modal.alert({
            className: "error-order-modal",
            title: "เกิดข้อผิดพลาด",
            children: `ไม่สามารถสร้างคำสั่งซื้อได้ โปรดเลือกที่อยู่จัดส่ง`,
            okButtonLabel: "ปิด",
          });
        }
      },
      [values]
    );

    const orderBuyerInfo = useMemo(() => {
      const deliveryAddressIdOptions = [
        ...map(order?.buyer?.deliveryAddresses, (address) => {
          return {
            label: getFullAddress(address),
            value: address.id,
          };
        }),
        { label: "เพิ่มที่อยู่ใหม่", value: ADD_NEW_ADDRESS },
      ].filter((option) => option.label);

      const isCustomDeliveryAddress = deliveryAddressId === ADD_NEW_ADDRESS;
      const defaultDeliveryAddr = order?.buyer.deliveryAddresses.find(
        (addr) => addr.isMain === "true"
      );

      return {
        buyerInfo: {
          shopifyOrderId: order?.shopifyOrderId,
          ...order?.buyer,
          deliveryAddress: defaultDeliveryAddr || {},
          deliveryDate: order?.deliveryDate,
          deliveryTime: order?.deliveryTime,
        },
        refetchOrder: refetch,
        deliveryAddressIdOptions,
        isCustomDeliveryAddress,
        deliveryDate,
        disabledSubmitButton: !orderBuyerInfoValuesDirty,
        canEditBuyerInfo: order?.canEditBuyerInfo,
        canceled: order?.canceled,
        disableCreate: loading,
      };
    }, [
      order,
      deliveryAddressId,
      deliveryDate,
      orderBuyerInfoValuesDirty,
      // configs,
    ]);

    const orderSellerInfo = useMemo(() => {
      return {
        ...order?.orderSellerInfo,
        canViewSellerInfo: order?.canViewSellerInfo,
      };
    }, [order]);

    const orderItemsInfo = useMemo(() => {
      return {
        orderItems: order?.draftOrderItems,
        discount,
        // products: configs?.products.products,
        canceled: order?.canceled,
        code: order?.code,
        created: order?.order ? true : false,
        deliveryTransactions:
          order?.orderCheckList?.deliveryOrder?.deliveryTransactions ?? [],
        handleCreateOrder,
        disableCreate: loading,
      };
    }, [order, orderItems, discount]);

    // NOTE: Order Note info props.
    const orderNoteValuesDirty = !isEqual(
      { id, note },
      { id: order?.id, note: order?.note }
    );
    const orderNoteInfoProps = useMemo(() => {
      return {
        disabled: !orderNoteValuesDirty,
        canceled: order?.canceled,
      };
    }, [orderNoteValuesDirty, order]);

    return {
      orderEditCheckList,
      orderBuyerInfo,
      orderSellerInfo,
      orderItemsInfo,
      orderNoteInfoProps,
      breadcrumbs,
      title,
      sync,
      creatOrderInfo,
      actions,
    };
  })
);

export default enhancer(DraftOrderDetailPage);
