import { compose, withHooks, withFormik, defaultProps } from "enhancers";
import { Form } from "components";
import { gql, notifySuccess, notifyError, getFullAddress } from "utils/helper";
import { map, find, forEach, cloneDeep, isEqual } from "lodash";

import OrderEditCheckList from "./OrderCheckList";

import OrderSellerInfo from "./OrderSellerInfo";
import OrderItemsInfo from "./OrderItemsInfo";
import OrderNoteInfo from "./OrderNoteInfo";
import DeliveryAddress from "./DeliveryAddress";

const OPTIONS = {};

const OrderEditPage = (props) => (
  <Form>
    <OrderItemsInfo {...props.orderItemsInfo} mt={1} />
    <OrderEditCheckList {...props.purchaseOrderEditCheckList} mt={10} />
    <DeliveryAddress {...props.purchaseOrderDeliveryAddressInfo} mt={10} />
  </Form>
);

export const API = {
  UPDATE_PURCHASE_ORDER: gql`
    mutation UPDATE_PURCHASE_ORDER(
      $id: ID!
      $hasVat: Boolean
      $deliveryAddressId: ID!
      $oldSystemPurchaseOrderCode: String
      $note: String
    ) {
      updatePurchaseOrder(
        input: {
          id: $id
          hasVat: $hasVat
          deliveryAddressId: $deliveryAddressId
          oldSystemPurchaseOrderCode: $oldSystemPurchaseOrderCode
          note: $note
        }
      ) {
        purchaseOrder {
          id
        }
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({
    ...OPTIONS,
  }),
  withFormik({
    mapPropsToValues: () => ({
      orderItems: [],
    }),
  }),
  withHooks((props, hooks) => {
    const { useMemo, useEffect, useMutation, useCallback } = hooks;
    const {
      purchaseOrder,
      setValues,
      values,
      reloadPurchaseOrder,
      configs,
    } = props;
    const {
      id,
      hasVat,
      deliveryAddressId,
      oldSystemPurchaseOrderCode,
      orderItems,
      note,
    } = values || {};

    const [updatePurchaseOrder] = useMutation(API.UPDATE_PURCHASE_ORDER);

    useEffect(() => {
      if (purchaseOrder) {
        const customPurchaseOrder = cloneDeep(purchaseOrder);
        customPurchaseOrder.deliveryAddressId =
          purchaseOrder?.deliveryAddress.id;
        customPurchaseOrder.confirmedAll = true;

        forEach(
          customPurchaseOrder.orderItems,
          (item) => (item.confirmed = true)
        );

        setValues(customPurchaseOrder);
      }
    }, [setValues, purchaseOrder]);

    const handleSubmit = useCallback(async () => {
      try {
        await updatePurchaseOrder({ variables: values });
        await reloadPurchaseOrder();
        notifySuccess("แก้ไขข้อมูลสำเร็จ");
      } catch (e) {
        notifyError(e);
      }
    }, [updatePurchaseOrder, reloadPurchaseOrder, values]);

    const purchaseOrderEditCheckList = useMemo(() => {
      const purchaseOrderCheckListOrder = {
        orderedAt: purchaseOrder?.orderedAt,
        finalPrice: purchaseOrder?.finalPrice,
        orderedRemark: purchaseOrder?.orderedRemark,
        code: purchaseOrder?.code,
        orderItems: purchaseOrder?.orderItems,
        hasVat,
        canceled: purchaseOrder?.canceled,
        totalItemsPrice: purchaseOrder?.totalItemsPrice,
        totalPrice: purchaseOrder?.totalPrice,
        vat: purchaseOrder?.vat,
        orderDeliveryDate: purchaseOrder?.orderDeliveryAt?.orderDeliveryDate,
        orderDeliveryTime: purchaseOrder?.orderDeliveryAt?.orderDeliveryTime,
      };
      const PurchaseOrderCheckListPaymentOrder = {
        paymentedAt: purchaseOrder?.paymentedAt,
        orderedAt: purchaseOrder?.orderedAt,
        bankAccount: {
          ...purchaseOrder?.seller?.bankAccount,
          finalPrice: purchaseOrder?.finalPrice,
        },
        finalPrice: purchaseOrder?.finalPrice,
        code: purchaseOrder?.code,
        orderItems: purchaseOrder?.orderItems,
        paymentDocuments: purchaseOrder?.paymentDocuments,
        canceled: purchaseOrder?.canceled,
        hasVat: purchaseOrder?.hasVat,
        orderDeliveryDate: purchaseOrder?.orderDeliveryAt?.orderDeliveryDate,
        orderDeliveryTime: purchaseOrder?.orderDeliveryAt?.orderDeliveryTime,
      };
      const PurchaseOrderCheckListDeliveryOrder = {
        paymentedAt: purchaseOrder?.paymentedAt,
        receivedAt: purchaseOrder?.receivedAt,
        orderedAt: purchaseOrder?.orderedAt,
        receivedRemark: purchaseOrder?.receivedRemark,
        deliverPrice: purchaseOrder?.deliverPrice,
        orderItems: purchaseOrder?.orderItems,
        receiveDocuments: purchaseOrder?.receiveDocuments,
        rawPrice: purchaseOrder?.rawPrice,
        shippingCharge: purchaseOrder?.shippingCharge,
        canceled: purchaseOrder?.canceled,
        hasVat: purchaseOrder?.hasVat,
        orderDeliveryDate: purchaseOrder?.orderDeliveryAt?.orderDeliveryDate,
        orderDeliveryTime: purchaseOrder?.orderDeliveryAt?.orderDeliveryTime,
      };
      return {
        purchaseOrderCheckListOrder,
        PurchaseOrderCheckListPaymentOrder,
        PurchaseOrderCheckListDeliveryOrder,
        reloadPurchaseOrder,
        checklistStatus: purchaseOrder?.checklistStatus,
      };
    }, [purchaseOrder, reloadPurchaseOrder, hasVat]);

    const orderItemsInfo = useMemo(() => {
      return {
        orderItems,
        products: configs?.products.products,
        hasVat: values.hasVat,
        canceled: purchaseOrder?.canceled,
        code: purchaseOrder?.code,
      };
    }, [configs, orderItems, values, purchaseOrder]);

    const deliveryAddressIdOptions = useMemo(() => {
      return [
        ...map(configs.setting?.deliveryAddresses, (address) => {
          return {
            label: address.isMain ? "ใช้ที่อยู่หลัก" : getFullAddress(address),
            value: address.id,
          };
        }),
      ].filter((option) => option.label);
    }, [configs]);

    const deliveryAddressInfo = useMemo(
      () =>
        find(configs.setting?.deliveryAddresses, {
          id: values.deliveryAddressId,
        }),
      [values.deliveryAddressId, configs]
    );

    const purchaseOrderDeliveryAddressInfo = useMemo(() => {
      return {
        deliveryAddressIdOptions,
        deliveryAddressInfo,
        canceled: purchaseOrder?.canceled,
        showDeliverySelector:
          purchaseOrder?.orderedAt && !purchaseOrder?.canceled,
      };
    }, [deliveryAddressIdOptions, deliveryAddressInfo, purchaseOrder]);

    return {
      purchaseOrderEditCheckList,
      purchaseOrderDeliveryAddressInfo,
      orderItemsInfo,
    };
  })
);

export default enhancer(OrderEditPage);
