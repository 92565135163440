import { compose, withFormik, withHooks } from "enhancers";
import { Box, Typography, Divider } from "components";
import { toCurrency } from "utils/helper";
import { gql } from "utils/helper";

const ConfirmOrderQuotationInfo = (props) => (
  <Box mt={-1.5}>
    <Typography variant="caption" color="Other/Danger">
      หลังจากยืนยันจะแก้ไขรายการสินค้าและวันที่สินค้าต้องส่งถึงไม่ได้แล้ว
    </Typography>
    <Box
      display="flex"
      alignItems="center"
      height={32}
      mt={6}
      style={{ borderBottom: "1px solid #E0E0E0" }}
    >
      <Typography variant="h6" width={95} px={3}>
        รหัส
      </Typography>
      <Typography variant="h6" width={267} px={3}>
        รายการสินค้า
      </Typography>
      <Typography variant="h6" width={100} px={3} textAlign="right">
        จำนวน
      </Typography>
      <Typography variant="h6" width={80} px={3}>
        หน่วย
      </Typography>
      <Typography variant="h6" width={170} px={3} textAlign="right">
        ราคาต่อหน่วย (บาท)
      </Typography>
      <Typography variant="h6" width={170} px={3} textAlign="right">
        ค่าตัด (บาท)
      </Typography>
      <Typography variant="h6" width={150} px={3} textAlign="right">
        ราคาขายรวม (บาท)
      </Typography>
    </Box>

    {props.orderItems.map((orderItem, index) => (
      <Box key={index} display="flex" alignItems="center" height={32}>
        <Typography width={95} px={3}>
          {orderItem.productCode}
        </Typography>
        <Typography width={267} px={3}>
          {orderItem.productName}
        </Typography>
        <Typography width={100} px={3} textAlign="right">
          {orderItem.amount}
        </Typography>
        <Typography width={80} px={3}>
          {
            { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[
              orderItem.sellingUnit
            ]
          }
        </Typography>
        <Typography width={170} px={3} textAlign="right">
          {orderItem.sellingPricePerUnit}
        </Typography>
        <Typography width={170} px={3} textAlign="right">
          {orderItem.cuttingPrice}
        </Typography>
        <Typography width={150} px={3} textAlign="right">
          {toCurrency(orderItem.totalPrice)}
        </Typography>
      </Box>
    ))}
    <Divider mt={15} />

    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="caption" color="Text/Dark Grey" height={32} px={3}>
          *ต้องส่งถึงลูกค้าภายใน 01/07/2564 เวลา 10:30 น.
        </Typography>
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography textAlign="right" px={3}>
            ราคาขายรวม
          </Typography>
          <Typography textAlign="right" width={150} px={3}>
            {toCurrency(props.totalItemsPrice)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Typography textAlign="right" px={3}>
            ส่วนลด
          </Typography>
          <Typography textAlign="right" width={150} px={3}>
            {toCurrency(props.discount)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography textAlign="right" px={3}>
            ราคาขายหลังหักส่วนลด
          </Typography>
          <Typography textAlign="right" width={150} px={3}>
            {toCurrency(props.totalItemPriceWithDiscount)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography textAlign="right" px={3}>
            ภาษีมูลค่าเพิ่ม 7%
          </Typography>
          <Typography textAlign="right" width={150} px={3}>
            {toCurrency(props.vat)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography variant="h6" textAlign="right" px={3}>
            <b>ยอดรวมทั้งหมด</b>
          </Typography>
          <Typography variant="h6" textAlign="right" width={150} px={3}>
            {toCurrency(props.totalPrice)}
          </Typography>
        </Box>
      </Box>
    </Box>
    <Divider mt={6} mb={3} />
  </Box>
);

export const API = {
  CONFIRM_ORDER: gql`
    mutation CONFIRM_ORDER($id: ID!) {
      confirmOrder(input: { id: $id }) {
        order {
          id
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      published: false,
    }),
  }),
  withHooks((props, hooks) => {
    const {
      mt,
      orderItems,
      totalItemsPrice,
      discount,
      totalItemPriceWithDiscount,
      vat,
      totalPrice,
    } = props;

    return {
      mt,
      orderItems,
      totalItemsPrice,
      discount,
      totalItemPriceWithDiscount,
      vat,
      totalPrice,
    };
  })
);

export default enhancer(ConfirmOrderQuotationInfo);
