import { compose, withFormik, withHooks, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  FieldArray,
  TextField,
  Editor,
  Divider,
  Button,
  Select,
  Modal,
  Hidden,
  Address,
  DatePicker,
} from "components";
import {
  gql,
  notifyError,
  paths,
  toCurrency,
  getFullAddress,
  formatFullName,
  notifySuccess,
} from "utils/helper";
import { map, find, filter, isNumber, sumBy } from "lodash";
import { differenceInDays, isValid, startOfToday } from "date-fns";

import OrderItemList from "./OrderItemList";
import AddressInfo from "./AddressInfo";
import BrandInfo from "./BrandInfo";
import QueryString from "qs";

export const OrderForm = (props) => (
  <Form>
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h4">ผู้ซื้อ</Typography>
          <Typography color="Text/Dark Grey" mt={2}>
            ข้อมูลผู้ซื้อและรายละเอียดการจัดส่งสินค้า
          </Typography>
        </Box>
        <Box display="flex">
          <Hidden when={props.hasBuyer}>
            <Button startIcon="shoppingBag" onClick={props.createNewBuyer}>
              สร้างผู้ซื้อใหม่
            </Button>
            <Button startIcon="add" onClick={props.selectBuyer} ml={6}>
              เลือกผู้ซื้อ
            </Button>
          </Hidden>
          <Hidden when={!props.hasBuyer}>
            <Button startIcon="history" onClick={props.changeBuyer}>
              เปลี่ยนผู้ซื้อ
            </Button>
          </Hidden>
        </Box>
      </Box>

      <Hidden when={!props.hasBuyer}>
        <Grid container spacing={6} mt={3}>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="buyerName"
              label="ชื่อผู้ซื้อ"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={DatePicker}
              name="deliveryDate"
              label="วันที่ต้องส่งถึง"
              fullWidth
              helperText={props.deliveryDateHelperText}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={Select}
              name="deliveryTime"
              label="เวลาที่ต้องส่งของ"
              options={props.deliveryTimeOptions}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={Select}
              name="deliveryAddressId"
              label="ที่อยู่ที่ใช้ในการส่งของ"
              options={props.deliveryAddressIdOptions}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} />
          <Hidden when={!props.isCustomDeliveryAddress}>
            <Grid item xs={12}>
              <Field component={Address.Delivery} name="deliveryAddress" />
            </Grid>
          </Hidden>
          <Hidden
            when={props.isCustomDeliveryAddress || !props.deliveryAddressInfo}
          >
            <Grid item xs={6}>
              <AddressInfo info={props.deliveryAddressInfo} />
            </Grid>
            <Grid item xs={6} />
          </Hidden>
          <Grid item xs={6}>
            <BrandInfo info={props.buyerInfo} />
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </Hidden>

      <Divider my={6} />
      <Typography variant="h4">ผลิตภัณฑ์</Typography>
      <Typography color="Text/Dark Grey" mt={2}>
        รายการสินค้าที่ซื้อและค่าใช้จ่ายเพิ่มเติม
      </Typography>

      <Field
        component={TextField}
        name="oldSystemInvoiceCode"
        label="รหัสใบแจ้งหนี้จากระบบบัญชี"
        width={512}
        mt={6}
      />

      <FieldArray
        component={OrderItemList}
        name="orderItems"
        products={props.products || [1, 2, 3]}
        mt={6}
      />

      <Box mt={6} mr={11}>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography textAlign="right">ราคาขายรวม</Typography>
          <Typography textAlign="right" width={120} ml={3} pr={3}>
            {toCurrency(props.totalPrice)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Typography textAlign="right">ส่วนลด</Typography>
          <Field
            component={TextField}
            name="discount"
            type="number"
            textAlign="right"
            placeholder="0.00"
            width={120}
            ml={3}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography textAlign="right">ราคาขายหลังหักส่วนลด</Typography>
          <Typography textAlign="right" width={120} ml={3} pr={3}>
            {toCurrency(props.totalPriceWithDiscount)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography textAlign="right">ภาษีมูลค่าเพิ่ม 7%</Typography>
          <Typography textAlign="right" width={120} ml={3} pr={3}>
            {toCurrency(props.vat)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography textAlign="right">
            <b>ยอดรวมทั้งหมด</b>
          </Typography>
          <Typography textAlign="right" width={120} ml={3} pr={3}>
            {toCurrency(props.finalPrice)}
          </Typography>
        </Box>
      </Box>

      <Divider my={6} />

      <Typography variant="h4">บันทึกเพิ่มเติม</Typography>
      <Field component={Editor} name="note" fullWidth mt={6} />
      <Button type="submit" color="primary" width={120} mt={6}>
        {props.submitButtonLabel}
      </Button>
    </Box>
  </Form>
);

const OrderNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <OrderForm {...props} />
  </PageContent>
);

export const API = {
  CREATE_ORDER: gql`
    mutation CREATE_ORDER(
      $buyerId: ID!
      $deliveryAddress: AddressArguments
      $deliveryAddressId: ID
      $deliveryDate: ISO8601Date
      $deliveryTime: String
      $discount: Float
      $note: String
      $oldSystemInvoiceCode: String
      $orderItems: [OrderItemArguments!]
      $addAddressToBuyer: Boolean
    ) {
      createOrder(
        input: {
          buyerId: $buyerId
          deliveryAddress: $deliveryAddress
          deliveryAddressId: $deliveryAddressId
          deliveryDate: $deliveryDate
          deliveryTime: $deliveryTime
          discount: $discount
          note: $note
          oldSystemInvoiceCode: $oldSystemInvoiceCode
          orderItems: $orderItems
          addAddressToBuyer: $addAddressToBuyer
        }
      ) {
        order {
          id
        }
      }
    }
  `,
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      buyers {
        id
        code
        firstName
        lastName
        nickName
        brand
        note
        deliveryAddresses {
          address
          addressType
          branchName
          companyName
          createdAt
          district
          firstName
          gmapUrl
          id
          isMain
          lastName
          ownerType
          phoneNumber
          province
          remark
          subDistrict
          taxId
          taxPayerType
          updatedAt
          zipCode
        }
      }
      products {
        products {
          id
          code
          productName
          sellingUnit
          costPerCapital
          pricePerCapital
          totalQuantity
          quantityPerFold
          lastActiveAt
        }
      }
    }
  `,
};

const ADD_NEW_ADDRESS = "ADD_NEW_ADDRESS";

const enhancer = compose(
  defaultProps({}),
  withFormik({
    mapPropsToValues: () => ({
      orderItems: [{}, {}],
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useHandleSubmit,
      useMutation,
      useQuery,
      useCallback,
      useEffect,
    } = hooks;
    const { setFieldValue, values } = props;

    const configs = useQuery(API.FETCH_CONFIG);
    const [createOrder] = useMutation(API.CREATE_ORDER);

    const moreOptions = useMemo(() => {
      const temp = {};
      return temp;
    }, []);

    const title = `คำสั่งซื้อ`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.ordersPath(), label: "คำสั่งซื้อ" },
        { path: null, label: "เพิ่มคำสั่งซื้อ" },
      ];
    }, []);

    useHandleSubmit(
      async (values) => {
        const validate = (values) => {
          if (!values.buyerId) {
            return "โปรดเลือกผู้ซื้อ";
          }
          if (!values.deliveryAddressId) {
            return "โปรดเลือกที่อยู่จัดส่ง";
          }
        };

        const errorMessage = validate(values);
        if (errorMessage) {
          Modal.alert({
            children: errorMessage,
          });
          return;
        }

        const handleCreateOrder = async (values) => {
          try {
            const { orderItems, deliveryAddressId, ...rest } = values;
            const filteredOrderItems = orderItems.filter(
              (order) => order.productId
            );
            const customDeliveryAddressId =
              deliveryAddressId === ADD_NEW_ADDRESS ? null : deliveryAddressId;

            await createOrder({
              variables: {
                ...rest,
                orderItems: filteredOrderItems,
                deliveryAddressId: customDeliveryAddressId,
              },
            });
            paths.ordersPath().push();
            notifySuccess("เพิ่มข้อมูลสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        };

        if (values.deliveryAddressId === ADD_NEW_ADDRESS) {
          Modal.confirm({
            title: "เพิ่มที่อยู่ใหม่",
            children: (
              <Box>
                <Typography variant="caption" color="Text/Dark Grey">
                  ต้องการบันทึกที่อยู่ใหม่ลงในข้อมูลผู้ซื้อหรือไม่
                </Typography>

                <Box style={{ background: "#F6F9FC" }} mt={6} px={4} py={2}>
                  <Box display="flex">
                    <Typography variant="h6" width={100}>
                      ผู้ซื้อ
                    </Typography>
                    <Typography variant="body1" flex={1}>
                      {values?.buyerName}
                    </Typography>
                  </Box>
                  <Box display="flex" mt={2}>
                    <Typography variant="h6" width={100}>
                      ที่อยู่ส่งของ
                    </Typography>
                    <Typography variant="body1" flex={1}>
                      {formatFullName(
                        values.deliveryAddress?.firstName,
                        values.deliveryAddress?.lastName
                      ) || "-"}
                      <br />
                      ที่อยู่ {getFullAddress(values.deliveryAddress) || "-"}
                      <br />
                      โทร. {values.deliveryAddress?.phoneNumber || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ),
            cancelButtonLabel: "ไม่เพิ่ม",
            okButtonLabel: "เพิ่ม",
            onCancel: async ({ close }) => {
              await handleCreateOrder({ ...values, addAddressToBuyer: false });
              close();
            },
            onOk: async ({ close }) => {
              await handleCreateOrder({ ...values, addAddressToBuyer: true });
              close();
            },
          });
        } else {
          await handleCreateOrder(values);
        }
      },
      [createOrder]
    );

    const createNewBuyer = useCallback(() => {
      paths.buyerNewPath().newTab();
    }, []);

    useEffect(() => {
      const search = QueryString.parse(props.location.search.substring(1));

      const setValue = async () => {
        if (search.buyerId) {
          const response = await configs.refetch();
          const buyers = response.data.buyers;
          const buyerIdOptions = map(buyers, (buyer) => ({
            label: `${buyer.code} - ${buyer.firstName} ${buyer.lastName} ${
              buyer.nickName ? `(${buyer.nickName})` : ""
            }`,
            value: buyer.id,
          }));
          const buyerName = find(buyerIdOptions, { value: search.buyerId })
            .label;

          const buyer = find(buyers, { id: search.buyerId });
          const deliveryAddressId = find(buyer.deliveryAddresses, {
            isMain: "true",
          })?.id;

          setFieldValue("buyerId", search.buyerId);
          setFieldValue("buyerName", buyerName);
          setFieldValue("deliveryAddressId", deliveryAddressId);
        }
      };

      setValue();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFieldValue, props.location?.search]);

    const selectBuyer = useCallback(async () => {
      const response = await configs.refetch();
      const buyers = response.data.buyers;
      const buyerIdOptions = map(buyers, (buyer) => ({
        label: `${buyer.code} - ${buyer.firstName} ${buyer.lastName} ${
          buyer.nickName ? `(${buyer.nickName})` : ""
        }`,
        value: buyer.id,
      }));
      Modal.open({
        title: "เลือกผู้ซื้อ",
        children: (
          <Field
            component={Select}
            name="buyerId"
            title="รหัส/ชื่อ/ชื่อเล่น"
            options={buyerIdOptions}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onOk: async ({ close, values }) => {
          try {
            const buyerName = find(buyerIdOptions, { value: values.buyerId })
              .label;

            const buyer = find(buyers, { id: values.buyerId });
            const deliveryAddressId = find(buyer.deliveryAddresses, {
              isMain: "true",
            })?.id;

            setFieldValue("buyerId", values.buyerId);
            setFieldValue("buyerName", buyerName);
            setFieldValue("deliveryAddressId", deliveryAddressId);
            close();
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [configs, setFieldValue]);

    const changeBuyer = useCallback(async () => {
      const response = await configs.refetch();
      const buyers = response.data.buyers;
      const buyerIdOptions = map(buyers, (buyer) => ({
        label: `${buyer.code} - ${buyer.firstName} ${buyer.lastName} ${
          buyer.nickName ? `(${buyer.nickName})` : ""
        }`,
        value: buyer.id,
      }));
      Modal.open({
        title: "เปลี่ยนผู้ซื้อ",
        children: (
          <>
            <Typography>ข้อมูลที่กรอกไว้ในส่วนของผู้ซื้อจะถูกล้าง</Typography>
            <Field
              component={Select}
              name="buyerId"
              title="รหัส/ชื่อ/ชื่อเล่น"
              options={buyerIdOptions}
              mt={6}
            />
          </>
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onOk: async ({ close, values }) => {
          try {
            const buyerName = find(buyerIdOptions, { value: values.buyerId })
              .label;

            const buyer = find(buyers, { id: values.buyerId });
            const deliveryAddressId = find(buyer.deliveryAddresses, {
              isMain: "true",
            })?.id;

            setFieldValue("buyerId", values.buyerId);
            setFieldValue("buyerName", buyerName);
            setFieldValue("deliveryAddressId", deliveryAddressId);
            close();
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [configs, setFieldValue]);

    const hasBuyer = values.buyerId;

    const buyerInfo = find(configs.data?.buyers, { id: values.buyerId });

    const deliveryAddressIdOptions = useMemo(() => {
      return [
        ...map(buyerInfo?.deliveryAddresses, (address) => {
          return {
            label: getFullAddress(address),
            value: address.id,
          };
        }),
        { label: "เพิ่มที่อยู่ใหม่", value: ADD_NEW_ADDRESS },
      ].filter((option) => option.label);
    }, [buyerInfo]);

    const deliveryAddressId = props.values?.deliveryAddressId;
    const deliveryAddressInfo = useMemo(() => {
      return find(buyerInfo?.deliveryAddresses, { id: deliveryAddressId });
    }, [buyerInfo, deliveryAddressId]);

    const isCustomDeliveryAddress = deliveryAddressId === ADD_NEW_ADDRESS;

    const deliveryDate = props.values?.deliveryDate;
    const deliveryDateHelperText = useMemo(() => {
      if (isValid(deliveryDate)) {
        return (
          <Typography
            variant="body1"
            color="Other/Info"
            ml={-3.5}
          >{`*อีก ${differenceInDays(
            deliveryDate,
            startOfToday()
          )} วัน`}</Typography>
        );
      }
    }, [deliveryDate]);

    const deliveryTimeOptions = useMemo(() => {
      return [
        { label: "8:00 น.", value: "8:00" },
        { label: "8:30 น.", value: "8:30" },
        { label: "9:00 น.", value: "9:00" },
        { label: "9:30 น.", value: "9:30" },
        { label: "10:00 น.", value: "10:00" },
        { label: "10:30 น.", value: "10:30" },
        { label: "11:00 น.", value: "11:00" },
        { label: "11:30 น.", value: "11:30" },
        { label: "12:00 น.", value: "12:00" },
        { label: "12:30 น.", value: "12:30" },
        { label: "13:00 น.", value: "13:00" },
        { label: "13:30 น.", value: "13:30" },
        { label: "14:00 น.", value: "14:00" },
        { label: "14:30 น.", value: "14:30" },
        { label: "15:00 น.", value: "15:00" },
        { label: "15:30 น.", value: "15:30" },
        { label: "16:00 น.", value: "16:00" },
        { label: "16:30 น.", value: "16:30" },
        { label: "17:00 น.", value: "17:00" },
      ];
    }, []);

    const orderItems = props.values?.orderItems;
    const discount = props.values?.discount;
    const products = configs.data?.products.products;
    const {
      totalPrice,
      totalPriceWithDiscount,
      vat,
      finalPrice,
    } = useMemo(() => {
      const items = filter(orderItems, (item) => item.productId).map((item) => {
        const product = find(products, { id: item.productId });

        const sellingUnit = product
          ? { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[product.sellingUnit]
          : null;
        const pricePerUnit = product ? product.pricePerCapital : 0;
        const price =
          isNumber(pricePerUnit) && isNumber(item.amount)
            ? item.amount * pricePerUnit
            : 0;

        return {
          ...item,
          sellingUnit,
          pricePerUnit,
          price,
        };
      });
      const totalPrice = sumBy(items, "price");
      const totalPriceWithDiscount = totalPrice - (discount ?? 0);
      const vat = totalPriceWithDiscount * 0.07;
      const finalPrice = totalPriceWithDiscount + vat;
      return {
        totalPrice,
        totalPriceWithDiscount,
        vat,
        finalPrice,
      };
    }, [orderItems, products, discount]);

    return {
      title,
      breadcrumbs,
      submitButtonLabel: "เพิ่มคำสั่งซื้อ",
      totalPrice,
      totalPriceWithDiscount,
      vat,
      finalPrice,
      createNewBuyer,
      selectBuyer,
      changeBuyer,
      hasBuyer,
      deliveryAddressIdOptions,
      deliveryAddressInfo,
      buyerInfo,
      isCustomDeliveryAddress,
      deliveryTimeOptions,
      deliveryDateHelperText,
      ...moreOptions,
    };
  })
);

export default enhancer(OrderNewPage);
