import { Box, DatePicker, Field, Modal } from "components";
import { compose, withFormik, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { formatDate, gql, notifyError } from "utils/helper";
import BusinessProfits from "./BusinessProfit";
import Buyer from "./Buyer";
import Order from "./Order";
import Product from "./Product";
import PurchaseOrder from "./PurchaseOrder";
import SalesAndCost from "./SalesAndCost";
import SalesProduct from "./SalesProduct";
import Seller from "./Seller";
import { ReactComponent as EditIcon } from "assets/icon/edit.svg";

const DashboardPage = (props) => (
  <PageContent title="ภาพรวม" pageActions={props.pageActions} paper={false}>
    <Box display="flex" mt={4}>
      <BusinessProfits overviewInfo={props.overviewInfo} />
      <Box ml={6}>
        <SalesAndCost info={props.salesInfo} />
        <SalesAndCost mt={6} info={props.costInfo} />
      </Box>
    </Box>

    <Box display="flex" mt={6}>
      <Order info={props.pendingOrderInfo} />
      <PurchaseOrder info={props.pendingPurchaseOrderInfo} />
    </Box>

    <Box display="flex" mt={6}>
      <Product info={props.productInfo} />
      <SalesProduct info={props.salesProductInfo} />
    </Box>

    <Box display="flex" mt={6}>
      <Seller info={props.sellerInfo} />
      <Buyer info={props.buyerInfo} />
    </Box>
  </PageContent>
);

export const API = {
  GET_DASHBOARD_INFO: gql`
    query GET_DASHBOARD_INFO(
      $startDate: ISO8601DateTime!
      $endDate: ISO8601DateTime!
    ) {
      dashboard(startDate: $startDate, endDate: $endDate) {
        overviewInfo
        salesInfo
        costInfo
        pendingOrderInfo
        pendingPurchaseOrderInfo
        productInfo
        salesProductInfo
        sellerInfo
        buyerInfo
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      startDate: new Date("Jan 01, " + new Date().getFullYear() + " 07:00:00"),
      endDate: new Date(),
      productFilter: "category",
      salesProductFilter: "category",
      sellerCategoryFilter: "all",
      sellerSort: "sales",
      buyerCategoryFilter: "all",
      buyerSort: "sales",
    }),
  }),
  withHooks((props, hooks) => {
    const { values } = props;
    const { useMemo, useQuery } = hooks;

    const { data, refetch } = useQuery(API.GET_DASHBOARD_INFO, {
      variables: { startDate: values.startDate, endDate: values.endDate },
    });

    const title = `ภาพรวม`;

    const pageActions = useMemo(() => {
      return [
        {
          children: (
            <Box display="flex">
              ข้อมูลระหว่าง {formatDate(values.startDate, "dd/MM/yyyy")} -{" "}
              {formatDate(values.endDate, "dd/MM/yyyy")}{" "}
              <EditIcon style={{ alignSelf: "center", marginLeft: "10px" }} />
            </Box>
          ),
          onClick: () => {
            Modal.open({
              title: "เลือกช่วงวันที่จะดูข้อมูล",
              children: (
                <Box display="flex" justifyContent="space-around">
                  <Field
                    component={DatePicker}
                    name="startDate"
                    style={{ width: "264px" }}
                    label="วันเริ่ม"
                    placeholder=""
                  />
                  <Field
                    component={DatePicker}
                    name="endDate"
                    style={{ width: "264px" }}
                    label="วันสิ้นสุด"
                    placeholder=""
                  />
                </Box>
              ),
              cancelButtonLabel: "ยกเลิก",
              okButtonLabel: "ยืนยัน",
              onOk: async ({ close, values }) => {
                try {
                  const { startDate, endDate } = values || {};

                  if (!startDate) {
                    notifyError("โปรดเลือกวันเริ่ม");
                    return;
                  }

                  if (!endDate) {
                    notifyError("โปรดเลือกวันสิ้นสุด");
                    return;
                  }
                  props.setFieldValue("startDate", values.startDate);
                  props.setFieldValue("endDate", values.endDate);
                  await refetch();
                  close();
                } catch (e) {
                  notifyError(e);
                }
              },
            });
          },
          color: "primary",
          width: "344px",
        },
      ];
    }, [values, props, refetch]);

    const overviewInfo = useMemo(() => {
      return (
        {
          ...data?.dashboard?.overviewInfo,
          date: `ข้อมูล ${formatDate(
            values.startDate,
            "dd/MM/yyyy"
          )} - ${formatDate(values.endDate, "dd/MM/yyyy")}`,
          subTitle: `สำเร็จ ${
            data?.dashboard?.salesInfo?.successCount ?? 0
          } รายการ`,
          startYear: values.startDate.getFullYear() + 542,
        } ?? {}
      );
    }, [data, values]);

    const salesInfo = useMemo(() => {
      return (
        {
          ...data?.dashboard?.salesInfo,
          title: "ยอดขาย",
          date: `ข้อมูล ${formatDate(
            values.startDate,
            "dd/MM/yyyy"
          )} - ${formatDate(values.endDate, "dd/MM/yyyy")}`,
          subTitle: `สำเร็จ ${
            data?.dashboard?.salesInfo?.successCount ?? 0
          } รายการ`,
        } ?? {}
      );
    }, [data, values]);

    const costInfo = useMemo(() => {
      return (
        {
          ...data?.dashboard?.costInfo,
          title: "ต้นทุน",
          date: `ข้อมูล ${formatDate(
            values.startDate,
            "dd/MM/yyyy"
          )} - ${formatDate(values.endDate, "dd/MM/yyyy")}`,
          subTitle: `สำเร็จ ${
            data?.dashboard?.costInfo?.successCount ?? 0
          } รายการ`,
        } ?? {}
      );
    }, [data, values]);

    const pendingOrderInfo = useMemo(() => {
      return (
        {
          ...data?.dashboard?.pendingOrderInfo,
          title: "คำสั่งซื้อ",
          date: `ข้อมูลทั้งหมด ณ ${formatDate(new Date(), "dd/MM/yyyy")}`,
          subTitle: `รอดำเนินการ ${
            data?.dashboard?.pendingOrderInfo?.pendingOrderCount ?? 0
          } รายการ`,
        } ?? {}
      );
    }, [data]);

    const pendingPurchaseOrderInfo = useMemo(() => {
      return (
        {
          ...data?.dashboard?.pendingPurchaseOrderInfo,
          title: "ใบสั่งสินค้า",
          date: `ข้อมูลทั้งหมด ณ ${formatDate(new Date(), "dd/MM/yyyy")}`,
          subTitle: `รอดำเนินการ ${
            data?.dashboard?.pendingPurchaseOrderInfo
              ?.pendingPurchaseOrderCount ?? 0
          } รายการ`,
        } ?? {}
      );
    }, [data]);

    const productInfo = useMemo(() => {
      return {
        ...data?.dashboard?.productInfo,
        title: "ผลิตภัณฑ์",
        date: `ข้อมูลทั้งหมด ณ ${formatDate(new Date(), "dd/MM/yyyy")}`,
        values,
      };
    }, [data, values]);

    const salesProductInfo = useMemo(() => {
      return {
        ...data?.dashboard?.salesProductInfo,
        title: "ยอดขายผลิตภัณฑ์",
        date: `ข้อมูล ${formatDate(
          values.startDate,
          "dd/MM/yyyy"
        )} - ${formatDate(values.endDate, "dd/MM/yyyy")}`,
        values,
      };
    }, [data, values]);

    const sellerInfo = useMemo(() => {
      return (
        {
          ...data?.dashboard?.sellerInfo,
          date: `ข้อมูล ${formatDate(
            values.startDate,
            "dd/MM/yyyy"
          )} - ${formatDate(values.endDate, "dd/MM/yyyy")}`,
          subTitle: `จำนวนทั้งหมด ${
            data?.dashboard?.sellerInfo?.sellerCount ?? 0
          }`,
          startYear: values.startDate.getFullYear() + 542,
          values,
        } ?? {}
      );
    }, [data, values]);

    const buyerInfo = useMemo(() => {
      return (
        {
          ...data?.dashboard?.buyerInfo,
          date: `ข้อมูล ${formatDate(
            values.startDate,
            "dd/MM/yyyy"
          )} - ${formatDate(values.endDate, "dd/MM/yyyy")}`,
          subTitle: `จำนวนทั้งหมด ${
            data?.dashboard?.buyerInfo?.buyerCount ?? 0
          }`,
          startYear: values.startDate.getFullYear() + 542,
          values,
        } ?? {}
      );
    }, [data, values]);

    return {
      title,
      pageActions,
      overviewInfo,
      salesInfo,
      costInfo,
      pendingOrderInfo,
      pendingPurchaseOrderInfo,
      productInfo,
      salesProductInfo,
      sellerInfo,
      buyerInfo,
    };
  })
);

export default enhancer(DashboardPage);
