import { compose, withHooks } from "enhancers";
import { Box, Typography } from "components";
import styled from "styled-components";
import paths from "routes/paths";

const CustomBox = styled(Box)`
  background-color: #f6f9fc;
  cursor: pointer;
  width: 107px;
  height: 81px;
  margin-right: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  :hover {
    background-color: #2296f3;
    span {
      color: white;
    }
    h3 {
      color: white;
    }
  }
`;

const BoxDetail = (props) => (
  <CustomBox onClick={props.goToOrder}>
    <Typography variant="caption" textAlign="left">
      {props.label}
    </Typography>
    <Typography variant="h3" textAlign="right">
      {props.value}
    </Typography>
  </CustomBox>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useCallback } = hooks;

    const goToOrder = useCallback(() => {
      paths.purchaseOrdersPath({ status: props.label }).push();
    }, [props]);

    return { ...props, goToOrder };
  })
);

export default enhancer(BoxDetail);
