import { compose, withHooks } from "enhancers";
import { Box, Typography } from "components";
import { map } from "lodash";
import htmlReactParser from "html-react-parser";

const BrandInfo = (props) => (
  <Box style={{ background: "#E7F4FD", borderRadius: 8, padding: 8 }}>
    {map(props.details, (detail, index) => (
      <Box key={index} display="flex" mt={index === 0 ? 0 : 2}>
        <Typography width={140}>{detail.label}</Typography>
        <Typography width={24} mx={1}>
          :
        </Typography>
        <Typography flex={1}>{detail.value}</Typography>
      </Box>
    ))}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info } = props;
    const { useMemo } = hooks;

    const details = useMemo(() => {
      return [
        { label: "แบรนด์", value: info.brand ?? "-" },
        {
          label: "บันทึกเพิ่มเติม",
          value: (
            <div className="renderHtmlFromString">
              {info.note ? htmlReactParser(info.note) : "-"}
            </div>
          ),
        },
      ];
    }, [info]);
    return { details };
  })
);

export default enhancer(BrandInfo);
