import { Box, Typography } from "components";
import { compose, withHooks } from "enhancers";
import { formatNumber } from "utils/helper";

const Header = (props) => (
  <Box display="flex" justifyContent="space-between">
    <Box>
      <Typography variant="h4">{props.title}</Typography>
      <Typography variant="caption" color="Text/Dark Grey">
        {props.date}
      </Typography>
    </Box>

    <Box width={100} height={75}>
      <Box display="flex" mb={2} alignItems="center">
        <Box
          style={{ backgroundColor: "#5483D4" }}
          width={16}
          height={16}
          mr={2}
        />
        <Typography variant="caption" color="Text/Dark Grey">
          ยอดขาย
        </Typography>
      </Box>
      <Typography variant="h3">
        {formatNumber(props.totalSales ?? 0)}
      </Typography>
      {props.totalSalesPercent >= 0 ? (
        <Typography variant="caption" color="Other/Success">
          + {formatNumber(props.totalSalesPercent ?? 0)}%
        </Typography>
      ) : (
        <Typography variant="caption" color="Other/Danger">
          - {formatNumber(props.totalSalesPercent ?? 0)}%
        </Typography>
      )}
    </Box>

    <Box width={100} height={75}>
      <Box display="flex" mb={2} alignItems="center">
        <Box
          style={{ backgroundColor: "#E0E0E0" }}
          width={16}
          height={16}
          mr={2}
        />
        <Typography variant="caption" color="Text/Dark Grey">
          ต้นทุน
        </Typography>
      </Box>
      <Typography variant="h3">{formatNumber(props.totalCost ?? 0)}</Typography>
      {props.totalCostPercent >= 0 ? (
        <Typography variant="caption" color="Other/Success">
          + {formatNumber(props.totalCostPercent ?? 0)}%
        </Typography>
      ) : (
        <Typography variant="caption" color="Other/Danger">
          - {formatNumber(props.totalCostPercent ?? 0)}%
        </Typography>
      )}
    </Box>

    <Box width={100} height={75}>
      <Box display="flex" mb={2} alignItems="center" height={16}>
        <Box
          style={{ backgroundColor: "#4CAF4F" }}
          width={16}
          height={2}
          mr={2}
        />
        <Typography variant="caption" color="Text/Dark Grey">
          กำไรสุทธิ
        </Typography>
      </Box>
      <Typography variant="h3">
        {formatNumber(props.totalProfit ?? 0)}
      </Typography>
      {props.totalProfitPrecent >= 0 ? (
        <Typography variant="caption" color="Other/Success">
          + {formatNumber(props.totalProfitPrecent ?? 0)}%
        </Typography>
      ) : (
        <Typography variant="caption" color="Other/Danger">
          - {formatNumber(props.totalProfitPrecent ?? 0)}%
        </Typography>
      )}
    </Box>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    return { ...props };
  })
);

export default enhancer(Header);
