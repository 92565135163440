import { compose, withHooks } from "enhancers";
import { Box, Typography } from "components";
import { isNil, map } from "lodash";
import { formatBankNumber, toCurrency } from "utils/helper";
import { getBankNameLabel } from "components/advance/BankAccount/helper";

const BankInfo = (props) => (
  <Box style={{ background: "#E7F4FD", borderRadius: 8, width: 512 }} p={2}>
    {map(props.details, (detail, index) => (
      <Box key={index} display="flex" mt={index === 0 ? 0 : 2}>
        <Typography width={60} variant="caption">
          {detail.label}
        </Typography>
        <Typography width={24} variant="caption" mx={1}>
          :
        </Typography>
        <Typography flex={1} variant="caption">
          {detail.value}
        </Typography>
      </Box>
    ))}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info } = props;
    const { useMemo } = hooks;

    const details = useMemo(() => {
      const bankNameThai = getBankNameLabel(info?.bankName);

      return [
        {
          label: "ธนาคาร",
          value: bankNameThai ?? "-",
        },
        {
          label: "เลขที่บัญชี",
          value: formatBankNumber(info?.bankAccountNumber) ?? "-",
        },
        {
          label: "ชื่อบัญชี",
          value: info?.bankAccountName ?? "-",
        },
        {
          label: "ยอดชำระ",
          value: isNil(info?.finalPrice)
            ? "-"
            : `${toCurrency(info?.finalPrice)} บาท`,
        },
      ];
    }, [info]);
    return { details };
  })
);

export default enhancer(BankInfo);
