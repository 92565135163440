import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import {
  Form,
  Field,
  FieldArray,
  Address,
  AddressList,
  Divider,
  Button,
  BankAccount,
} from "components";
import {
  gql,
  paths,
  cleanTypename,
  notifySuccess,
  notifyError,
} from "utils/helper";

const SettingPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Form>
      <Field
        component={Address.Billing}
        name="billingAddress"
        title="ที่อยู่ที่ใช้ในการออกบิล"
        companyNameLabel="ชื่อบริษัท"
        withoutTaxType
        noBranch
      />
      <FieldArray
        component={AddressList.Delivery}
        name="deliveryAddresses"
        title="ที่อยู่ที่ใช้ในการส่งของ"
        addButtonLabel="เพิ่มที่อยู่ส่งของ"
        mt={10}
      />
      <Field
        component={BankAccount}
        name="bankAccount"
        title="รายละเอียดการจ่ายเงิน"
        mt={10}
      />
      <Divider mt={10} />
      <Button type="submit" color="primary" mt={6}>
        บันทึกข้อมูล
      </Button>
    </Form>
  </PageContent>
);

export const API = {
  FETCH_SETTING: gql`
    query FETCH_SETTING {
      setting {
        billingAddress {
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          isMain
          lastName
          ownerType
          phoneNumber
          province
          subDistrict
          taxPayerType
          zipCode
          taxId
          remark
        }
        deliveryAddresses {
          id
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          isMain
          lastName
          ownerType
          phoneNumber
          province
          subDistrict
          taxPayerType
          zipCode
          taxId
          remark
          isMain
        }
        bankAccount {
          bankAccountName
          bankAccountNumber
          bankName
          branchName
        }
      }
    }
  `,
  UPDATE_SETTING: gql`
    mutation UPDATE_SETTING(
      $billingAddress: AddressArguments
      $deliveryAddresses: [AddressArguments!]
      $bankAccount: BankAccountArguments
    ) {
      updateSetting(
        input: {
          billingAddress: $billingAddress
          deliveryAddresses: $deliveryAddresses
          bankAccount: $bankAccount
        }
      ) {
        setting {
          billingAddress {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
          }
          deliveryAddresses {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
            isMain
          }
          bankAccount {
            bankAccountName
            bankAccountNumber
            bankName
            branchName
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      billingAddress: {
        taxPayerType: "corporation",
      },
      deliveryAddresses: [{}],
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useEffect,
      useQuery,
      useMutation,
      useMemo,
      useHandleSubmit,
    } = hooks;
    const { setValues } = props;
    const { loading, data, error, refetch } = useQuery(API.FETCH_SETTING);
    const [updateSetting] = useMutation(API.UPDATE_SETTING);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const setting = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return data.setting;
    }, [loading, data, error]);

    useEffect(() => {
      if (setting) {
        const values = cleanTypename(setting);
        setValues(values);
      }
    }, [setValues, setting]);

    useHandleSubmit(
      async (values) => {
        try {
          await updateSetting({ variables: values });
          notifySuccess("แก้ไขข้อมูลสำเร็จ");
        } catch (e) {
          notifyError(e);
        }
      },
      [updateSetting]
    );

    const title = `ตั้งค่า`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: title },
      ];
    }, [title]);

    return { title, breadcrumbs };
  })
);

export default enhancer(SettingPage);
