import React from "react";
import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "components";
import { formatDate, toCurrency } from "utils/helper";
import { map } from "lodash";
import paths from "routes/paths";

import { ReactComponent as ArrowDownIcon } from "assets/icon/keyboard_arrow_down.svg";
import { ReactComponent as CheckCircleOutlineIcon } from "assets/icon/check_circle_outline.svg";

const OrderCheckListPurchaseOrder = (props) => (
  <Accordion disableGutters>
    <AccordionSummary
      expandIcon={<ArrowDownIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography color="Text/Black">{props.title}</Typography>
        {props.status !== props.sellerItems?.length ||
        props.sellerItems?.length === 0 ? (
          <Typography variant="caption" color="Text/Dark Grey">
            {`ทำที่เมนูใบสั่งสินค้า | สำเร็จ ${props.status}/${props.sellerItems.length}`}
          </Typography>
        ) : (
          <Box display="flex" alignItems="center">
            <CheckCircleOutlineIcon />
            <Typography
              variant="caption"
              color="Text/Dark Grey"
              ml={2}
              width={154}
            >
              {formatDate(props.completedAt, "สำเร็จเมื่อ dd/MM/yyyy, HH:mm")}
            </Typography>
          </Box>
        )}
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      <Box ml={6} width="100%">
        {map(props.sellerItems, (poQuotationInfo, index) => (
          <React.Fragment key={index}>
            <Divider ml={index === 0 ? -6 : 0} />
            <Box display="flex" justifyContent="space-between" mt={2} mr={9}>
              <Typography color="Text/Black">
                {poQuotationInfo.code} - {poQuotationInfo.enName}
              </Typography>
              {poQuotationInfo.completedAt && (
                <Box display="flex" alignItems="center">
                  <CheckCircleOutlineIcon />
                  <Typography
                    variant="caption"
                    color="Text/Dark Grey"
                    ml={2}
                    width={154}
                  >
                    {formatDate(
                      poQuotationInfo.completedAt,
                      "สำเร็จเมื่อ dd/MM/yyyy, HH:mm"
                    )}
                  </Typography>
                </Box>
              )}
            </Box>
            {poQuotationInfo.completedAt && (
              <Box display="flex" alignContent="center" mt={2}>
                <Typography
                  variant="caption"
                  color="Text/Dark Grey"
                  mr={2}
                  width={68}
                >
                  ใบสั่งสินค้า
                </Typography>
                {poQuotationInfo.poCodes.map((po, index) => (
                  <Link
                    to={paths.purchaseOrderEditPath(po.id)}
                    variant="caption"
                    newTab
                    style={{
                      textDecoration: "underline",
                      marginRight: "8px",
                    }}
                  >
                    {po.code}
                  </Link>
                ))}
              </Box>
            )}
            <Box py={2} width={396}>
              {poQuotationInfo.orderItems.map((orderItem, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  align="center"
                  mt={2}
                >
                  <Box display="flex" align="center">
                    <Typography
                      variant="caption"
                      color="Text/Dark Grey"
                      mx={2}
                      mt={-0.25}
                    >
                      •
                    </Typography>
                    <Typography variant="caption" color="Text/Dark Grey">
                      {orderItem.productName}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mx={2}>
                    <Typography variant="caption" color="Text/Dark Grey">
                      {toCurrency(orderItem.amount)}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="Text/Dark Grey"
                      width={44}
                      textAlign="left"
                      ml={2}
                    >
                      {
                        { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[
                          orderItem.sellingUnit
                        ]
                      }
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </AccordionDetails>
  </Accordion>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { title, poQuotationInfoList } = props;

    return {
      title,
      ...poQuotationInfoList,
    };
  })
);

export default enhancer(OrderCheckListPurchaseOrder);
