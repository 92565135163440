import { compose, withHooks } from "enhancers";
import { Box, Typography } from "components";
import { map } from "lodash";
import { toCurrency } from "utils/helper";

const SummaryCard = (props) => (
  <Box
    style={{ background: "#E7F4FD", borderRadius: 8, width: 512 }}
    p={2}
    mb={2}
  >
    {map(props.details, (detail, index) => (
      <Box key={index} display="flex" mt={index === 0 ? 0 : 2}>
        <Typography width={60} variant="caption">
          {detail.label}
        </Typography>
        <Typography width={24} variant="caption" mx={1}>
          :
        </Typography>
        <Typography flex={1} variant="caption">
          {detail.value}
        </Typography>
      </Box>
    ))}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info } = props;
    const { useMemo } = hooks;

    const details = useMemo(() => {
      return [
        { label: "ค่าส่ง", value: `${toCurrency(info.totalShippingCost)} บาท` },
      ];
    }, [info]);
    return { details };
  })
);

export default enhancer(SummaryCard);
