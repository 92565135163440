import { compose, withHooks } from "enhancers";
import { Box, Typography, Field, Button, Modal } from "components";
import { gql, toCurrency } from "utils/helper";
import { range, get, find, isNumber } from "lodash";

import OrderItem from "./OrderItem";

const OrderItemList = (props) =>
  props.initialized ? (
    <Box mt={props.mt}>
      <Box
        display="flex"
        alignItems="center"
        height={32}
        mb={4}
        style={{ borderBottom: "1px solid #E0E0E0" }}
      >
        <Typography variant="h6" width={580} mr={2}>
          รายการ
        </Typography>
        <Typography variant="h6" width={90} mr={2}>
          จำนวน
        </Typography>
        <Typography variant="h6" width={80} mr={2}>
          หน่วย
        </Typography>
        <Typography variant="h6" width={90} mr={2}>
          บาท/หน่วย
        </Typography>
        <Typography variant="h6" width={175}>
          ราคาขายรวม (บาท)
        </Typography>
      </Box>

      {range(props.amount).map((index) => (
        <Box key={index} display="flex" alignItems="center" mt={2}>
          <Field
            component={OrderItem}
            name={`${props.name}[${index}]`}
            products={props.products}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={24}
            height={32}
            style={{ cursor: "pointer" }}
            onClick={() => props.removeOrderItem(index)}
          >
            <Button.TrashIcon />
          </Box>
        </Box>
      ))}

      <Button startIcon="add" onClick={props.addOrderItem} mt={6}>
        เพิ่มรายการ
      </Button>
    </Box>
  ) : null;

export const API = {
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      buyers {
        id
        code
        firstName
        lastName
        nickName
      }
      products(published: true) {
        products {
          id
          code
          productName
          sellingUnit
          pricePerCapital
          costPerCapital
          totalQuantity
          quantityPerFold
          lastActiveAt
          sellerProductCode
          seller {
            code
            thName
            enName
            sellingCondition
            deliveryCondition
            paymentCondition
            note
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { form, name, insert, remove, mt } = props;
    const { useCallback, useQuery } = hooks;

    const configs = useQuery(API.FETCH_CONFIG);

    const values = get(form.values, name);
    const amount = values?.length ?? 0;

    const products = configs.data?.products.products;

    const addOrderItem = useCallback(() => {
      insert(amount, {});
    }, [insert, amount]);

    const removeOrderItem = useCallback(
      (index) => {
        const value = values[index];
        const product = find(products, { id: value?.productId });

        if (product) {
          const productName = product.productName;
          const amount = value?.amount;
          const unit = product.sellingUnit;

          Modal.confirm({
            className: "reject-modal",
            title: "ลบรายการ",
            children: `ต้องการลบรายการ ${productName} ${
              isNumber(amount)
                ? `${toCurrency(amount, { minimumFractionDigits: 0 })} ${unit}`
                : ""
            } หรือไม่ `,
            cancelButtonLabel: "ยกเลิก",
            okButtonLabel: "ลบ",
            onOk: ({ close }) => {
              remove(index);
              close();
            },
          });
        } else {
          remove(index);
        }
      },
      [remove, values, products]
    );

    const initialized = !configs.loading;

    return {
      mt,
      name,
      amount,
      products,
      addOrderItem,
      removeOrderItem,
      initialized,
    };
  })
);

export default enhancer(OrderItemList);
