import { compose, withHooks } from "enhancers";
import { Box, Paper } from "components";
import Header from "../Header";
import { map } from "lodash";
import BoxDetail from "./BoxDetail";

const PurchaseOrder = (props) => (
  <Paper width={344} height={175} p={4} ml={6}>
    <Header title={props.title} date={props.date} subTitle={props.subTitle} />
    <Box display="flex" mt={4}>
      {map(props.details, (item) => (
        <BoxDetail {...item} />
      ))}
    </Box>
  </Paper>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info } = props;
    return { ...props, ...info };
  })
);

export default enhancer(PurchaseOrder);
