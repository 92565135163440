import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Button,
  Modal,
  Hidden,
  DownloadFileLink,
} from "components";
import {
  notifySuccess,
  notifyError,
  formatDate,
  forceDownload,
} from "utils/helper";
import ConfirmOrderQuotationInfo from "./ConfirmOrderQuotationInfo";
import { API } from "./ConfirmOrderQuotationInfo";

import { ReactComponent as CheckCircleOutlineIcon } from "assets/icon/check_circle_outline.svg";
import DeliverSummaryCard from "./DeliverSummaryCard";

const PurchaseOrderCheckListDeliveryOrder = (props) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems={props.info?.receivedAt ? undefined : "center"}
    py={2}
  >
    <Box>
      <Typography color="Text/Black">รับสินค้าจากผู้ขาย</Typography>
      <Hidden when={!props.info?.receivedAt}>
        <Box display="flex" mt={2}>
          <Typography
            variant="caption"
            color="Text/Dark Grey"
            style={{ width: "68px" }}
          >
            หลักฐาน
          </Typography>
          <Box display="flex">
            {props.info?.receiveDocuments.length !== 0 ? (
              props.info?.receiveDocuments.map((document) => (
                <DownloadFileLink
                  filename={document.filename}
                  url={document.url}
                />
              ))
            ) : (
              <Typography variant="caption">-</Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" mt={2}>
          <DeliverSummaryCard info={props.info} />
        </Box>
      </Hidden>
    </Box>

    {props.info?.receivedAt ? (
      <Box
        display="flex"
        height="fit-content"
        minWidth={200}
        justifyContent="flex-end"
        alignItems="center"
      >
        <CheckCircleOutlineIcon />
        <Typography variant="caption" color="Text/Dark Grey" ml={2} width={154}>
          {formatDate(props.info?.receivedAt, "สำเร็จเมื่อ dd/MM/yyyy, HH:mm")}
        </Typography>
      </Box>
    ) : (
      <Box>
        <Button
          onClick={props.openConfirmQuotationModal}
          variant="outlined"
          color="primary"
          disabled={!props.info?.orderedAt || props.info?.canceled}
        >
          ดำเนินการ
        </Button>
      </Box>
    )}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info, reloadPurchaseOrder } = props;
    const { useParams, useCallback, useMutation } = hooks;

    const { id } = useParams();
    const [receivePurchaseOrder] = useMutation(API.RECEIVE_PURCHASE_ORDER);

    const openConfirmQuotationModal = useCallback(() => {
      Modal.open({
        className: "ConfirmOrderQuotationInfoModal",
        title: "ส่งใบสั่งสินค้าให้ผู้ขาย",
        children: (
          <ConfirmOrderQuotationInfo
            orderDeliveryDate={info.orderDeliveryDate ?? ""}
            orderDeliveryTime={info.orderDeliveryTime ?? ""}
            hasVat={info.hasVat}
            orderItems={info.orderItems ?? []}
            totalItemsPrice={info.totalItemsPrice ?? 0}
            discount={info.discount ?? 0}
            totalItemPriceWithDiscount={info.totalItemPriceWithDiscount ?? 0}
            vat={info.vat ?? 0}
            totalPrice={info.totalPrice ?? 0}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onOk: async ({ close, values }) => {
          try {
            await receivePurchaseOrder({
              variables: {
                id,
                ...values,
              },
            });
            await reloadPurchaseOrder();
            close();
            notifySuccess("ดำเนินการสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [info, receivePurchaseOrder, reloadPurchaseOrder, id]);

    const download = useCallback(
      (document) => () => {
        const { filename, url } = document;
        if (filename && url) {
          const loadFile = async () => {
            try {
              const blob = await fetch(url).then((r) => r.blob());
              const file = new File([blob], filename, {
                type: blob.type,
              });

              if (file) {
                const url = URL.createObjectURL(file);
                forceDownload(url);
              }
            } catch (e) {
              notifyError(e);
            }
          };

          loadFile();
        }
      },
      []
    );

    return {
      openConfirmQuotationModal,
      info,
      download,
    };
  })
);

export default enhancer(PurchaseOrderCheckListDeliveryOrder);
