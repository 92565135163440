import { compose, withFormik, withHooks } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  FieldArray,
  TextField,
  RadioGroup,
  DateSelector,
  Editor,
  Address,
  AddressList,
  FileUploadList,
  Divider,
  Button,
} from "components";
import { gql, notifyError, notifySuccess, paths } from "utils/helper";

export const BuyerForm = (props) => (
  <Form>
    <Typography variant="h4" mb={6}>
      การติดต่อ
    </Typography>
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="firstName"
          type="text"
          label="ชื่อ"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="lastName"
          type="text"
          label="นามสกุล"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="nickName"
          type="text"
          label="ชื่อเล่น"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field component={DateSelector} name="birthdate" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField.Email}
          name="email"
          label="อีเมล"
          fullWidth
          required
          disabled={props.isDisabledEmail}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField.PhoneNumber}
          name="phoneNumber1"
          label="เบอร์โทรศัพท์"
          fullWidth
          required
        />
      </Grid>
    </Grid>
    <Box mt={6}>
      <Grid mt={6} container spacing={6}>
        <Grid item xs={6}>
          <Box fullWidth display="flex" alignItems="center">
            <Field
              component={TextField.PhoneNumber}
              name="phoneNumber2"
              label="เบอร์โทรศัพท์ 2"
              flex={1}
            />
            <Field
              component={RadioGroup}
              name="secondaryChannel"
              options={[{ label: "ช่องทางสำรอง", value: "phone_number2" }]}
              width={160}
              ml={4}
              mr={0}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box fullWidth display="flex" alignItems="center">
            <Field
              component={TextField}
              name="line"
              type="text"
              label="ไลน์"
              flex={1}
            />
            <Field
              component={RadioGroup}
              name="secondaryChannel"
              options={[{ label: "ช่องทางสำรอง", value: "line" }]}
              width={160}
              ml={4}
              mr={0}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box fullWidth display="flex" alignItems="center">
            <Field
              component={TextField}
              name="facebook"
              type="text"
              label="เฟสบุ๊ค"
              flex={1}
            />
            <Field
              component={RadioGroup}
              name="secondaryChannel"
              options={[{ label: "ช่องทางสำรอง", value: "facebook" }]}
              width={160}
              ml={4}
              mr={0}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box fullWidth display="flex" alignItems="center">
            <Field
              component={TextField}
              name="ig"
              type="text"
              label="อินสตาแกรม"
              flex={1}
            />
            <Field
              component={RadioGroup}
              name="secondaryChannel"
              options={[{ label: "ช่องทางสำรอง", value: "ig" }]}
              width={160}
              ml={4}
              mr={0}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box fullWidth display="flex" alignItems="center">
            <Field
              component={TextField}
              name="shopifyBuyerId"
              type="text"
              label="Shopify ID"
              flex={1}
              disabled
            />
            <Field
              component={RadioGroup}
              name="secondaryChannel"
              options={[{ label: "ช่องทางสำรอง", value: "shopify_buyer_id" }]}
              width={160}
              ml={4}
              mr={0}
            />
          </Box>
        </Grid>
      </Grid>
      <Typography variant="body1" color="Text/Dark Grey" mt={6}>
        *ช่องทางสำรองจะแสดงในหน้าตาราง
      </Typography>
    </Box>
    <Typography variant="h4" mt={10} mb={6}>
      บันทึกเพิ่มเติม
    </Typography>
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="brand"
          type="text"
          label="แบรนด์"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field component={Editor} name="note" label="แบรนด์" fullWidth />
      </Grid>
    </Grid>

    <Field
      component={Address.Billing}
      name="billingAddress"
      title="ที่อยู่ที่ใช้ในการออกบิล"
      mt={10.5}
    />

    <FieldArray
      component={AddressList.Delivery}
      name="deliveryAddresses"
      title="ที่อยู่ที่ใช้ในการส่งของ"
      addButtonLabel="เพิ่มที่อยู่ส่งของ"
      mt={10}
    />
    <FieldArray
      component={FileUploadList}
      name="documents"
      title="เอกสาร"
      addButtonLabel="เพิ่มเอกสาร"
      mt={10}
    />

    <Divider mt={10} />
    <Button type="submit" color="primary" mt={6}>
      {props.submitButtonLabel}
    </Button>
  </Form>
);

const BuyerNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <BuyerForm {...props} />
  </PageContent>
);

const API = {
  CREATE_BUYER: gql`
    mutation CREATE_BUYER(
      $firstName: String
      $lastName: String
      $nickName: String
      $birthdate: ISO8601DateTime
      $email: String
      $phoneNumber1: String
      $phoneNumber2: String
      $secondaryChannel: String
      $line: String
      $facebook: String
      $ig: String
      $brand: String
      $note: String
      $billingAddress: AddressArguments
      $deliveryAddresses: [AddressArguments!]
      $documents: [Upload!]
    ) {
      createBuyer(
        input: {
          firstName: $firstName
          lastName: $lastName
          nickName: $nickName
          birthdate: $birthdate
          email: $email
          phoneNumber1: $phoneNumber1
          phoneNumber2: $phoneNumber2
          secondaryChannel: $secondaryChannel
          line: $line
          facebook: $facebook
          ig: $ig
          brand: $brand
          note: $note
          billingAddress: $billingAddress
          deliveryAddresses: $deliveryAddresses
          documents: $documents
        }
      ) {
        buyer {
          id
          birthdate
          brand
          code
          email
          facebook
          firstName
          ig
          lastName
          nickName
          note
          phoneNumber1
          phoneNumber2
          secondaryChannel
          slug
          createdAt
          updatedAt
          line
          configs
          lastActiveAt
          canDelete
          billingAddress {
            id
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
          }
          deliveryAddresses {
            id
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
            isMain
          }
          documents
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      deliveryAddresses: [{}],
    }),
  }),
  withHooks((props, hooks) => {
    const { useMemo, useHandleSubmit, useMutation } = hooks;
    const [createBuyer] = useMutation(API.CREATE_BUYER);

    const title = `เพิ่มผู้ซื้อ`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.buyersPath(), label: "ผู้ซื้อ" },
        { path: null, label: title },
      ];
    }, [title]);

    useHandleSubmit(
      async (values) => {
        try {
          await createBuyer({ variables: values });
          paths.buyersPath().push();
          notifySuccess("เพิ่มข้อมูลสำเร็จ");
        } catch (e) {
          notifyError(e);
        }
      },
      [createBuyer]
    );

    return {
      title,
      breadcrumbs,
      submitButtonLabel: "เพิ่มผู้ซื้อ",
    };
  })
);

export default enhancer(BuyerNewPage);
