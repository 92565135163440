import { compose, withHooks } from "enhancers";
import { Box, Typography, Button, Link, Modal } from "components";
import { notifySuccess, notifyError, formatDate } from "utils/helper";
import ConfirmOrderQuotationInfo from "./ConfirmOrderQuotationInfo";
import QuotationSummaryCard from "./QuotationSummaryCard";
import { API } from "./ConfirmOrderQuotationInfo";

import { ReactComponent as CheckCircleOutlineIcon } from "assets/icon/check_circle_outline.svg";
import appStore from "stores/appStore";

const OrderEditCheckListConfirmOrder = (props) => (
  <Box display="flex" justifyContent="space-between" py={2}>
    <Box>
      <Typography color="Text/Black">ยืนยันรายการสินค้า</Typography>
      {props.quotationInfo.completedAt ? (
        <QuotationSummaryCard info={props.quotationInfo} />
      ) : (
        <>
          <Box display="flex" mt={2}>
            <Typography variant="caption" color="Text/Dark Grey">
              เช็คสินค้ากับผู้ขายและยืนยันกับผู้ซื้อ
              สามารถปรับเปลี่ยนรายการสินค้า/จำนวน/ราคาได้ที่
            </Typography>
            <Link
              scrollTo="#product_section"
              variant="caption"
              ml={1}
              style={{ color: "#376FD0", textDecoration: "underline" }}
            >
              ผลิตภัณฑ์
            </Link>
          </Box>

          <Typography variant="caption" color="Text/Dark Grey" mt={1}>
            เมื่อยืนยันรายการเรียบร้อยแล้วกดปุ่มดำเนินการเพื่อไปขั้นตอนต่อไป
          </Typography>
        </>
      )}
    </Box>

    {props.quotationInfo.completedAt ? (
      <Box display="flex" alignItems="center" pr={9}>
        <CheckCircleOutlineIcon />
        <Typography variant="caption" color="Text/Dark Grey" ml={2} width={154}>
          {formatDate(
            props.quotationInfo.completedAt,
            "สำเร็จเมื่อ dd/MM/yyyy, HH:mm"
          )}
        </Typography>
      </Box>
    ) : (
      <Box>
        <Typography variant="caption" color="Text/Dark Grey">
          สำเร็จ {props.quotationInfo.status}
        </Typography>
        <Button
          onClick={props.openConfirmQuotationModal}
          variant="outlined"
          color="primary"
          disabled={!props.quotationInfo.canConfirm}
          ml={4}
          mr={10}
        >
          ดำเนินการ
        </Button>
      </Box>
    )}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { quotationInfo, reloadOrder } = props;
    const { useParams, useCallback, useMutation } = hooks;

    const { id } = useParams();
    const [confirmOrder] = useMutation(API.CONFIRM_ORDER);

    const openConfirmQuotationModal = useCallback(() => {
      Modal.open({
        className: "ConfirmOrderQuotationInfoModal",
        title: "ยืนยันรายการสินค้า",
        children: (
          <ConfirmOrderQuotationInfo
            orderItems={quotationInfo.orderItems ?? []}
            totalItemsPrice={quotationInfo.totalItemsPrice ?? 0}
            discount={quotationInfo.discount ?? 0}
            totalItemPriceWithDiscount={
              quotationInfo.totalItemPriceWithDiscount ?? 0
            }
            vat={quotationInfo.vat ?? 0}
            totalPrice={quotationInfo.totalPrice ?? 0}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onOk: async ({ close }) => {
          try {
            appStore.state.configSidebarRefetch();
            await confirmOrder({
              variables: {
                id,
              },
            });
            await reloadOrder();
            appStore.state.configSidebarRefetch();
            close();
            notifySuccess("ดำเนินการสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [quotationInfo, confirmOrder, reloadOrder, id]);
    return {
      openConfirmQuotationModal,
      quotationInfo,
    };
  })
);

export default enhancer(OrderEditCheckListConfirmOrder);
