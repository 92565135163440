import { compose, withHooks } from "enhancers";
import { Box, Field, Select, Typography, Divider, Hidden } from "components";
import DeliverAddressInfo from "../../../../main/orders/new/AddressInfo";

const deliveryAddress = (props) => (
  <Box mt={props.mt}>
    <Box>
      <Typography variant="h4" color="Text/Black">
        สถานที่จัดส่ง
      </Typography>
    </Box>
    <Hidden when={props.showDeliverySelector}>
      <Field
        name="deliveryAddressId"
        component={Select}
        options={props.deliveryAddressIdOptions}
        width={512}
        mt={6}
        disabled={props.disabledForm}
      />
    </Hidden>
    <Hidden when={!props.deliveryAddressInfo}>
      <DeliverAddressInfo info={props.deliveryAddressInfo} />
    </Hidden>

    <Divider mt={6} />
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      mt,
      deliveryAddressIdOptions,
      deliveryAddressInfo,
      canceled,
      showDeliverySelector,
    } = props;

    const disabledForm = canceled;

    return {
      mt,
      deliveryAddressIdOptions,
      deliveryAddressInfo,
      disabledForm,
      showDeliverySelector,
    };
  })
);

export default enhancer(deliveryAddress);
