import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Field,
  Button,
  Modal,
  Checkbox,
  Hidden,
  Link,
} from "components";
import { gql, toCurrency } from "utils/helper";
import { filter, range, get, find, isNumber, map } from "lodash";
import paths from "routes/paths";

import OrderItem from "./OrderItem";
import DeliveryItem from "./DeliveryItem";

const OrderItemList = (props) =>
  props.initialized ? (
    <Box mt={props.mt}>
      <Box
        display="flex"
        alignItems="center"
        height={32}
        mb={4}
        style={{ borderBottom: "1px solid #E0E0E0" }}
      >
        <Hidden when={props.readOnly}>
          <Typography variant="h6" width={40} mr={2}>
            ยืนยัน
          </Typography>
        </Hidden>

        <Typography variant="h6" width={604} mr={2}>
          รายการ
        </Typography>
        <Typography variant="h6" width={90} mr={2}>
          จำนวน
        </Typography>
        <Typography variant="h6" width={80} mr={2}>
          หน่วย
        </Typography>
        <Typography variant="h6" width={90} mr={2}>
          บาท/หน่วย
        </Typography>
        <Typography variant="h6" mr={2}>
          ราคาขายรวม (บาท)
        </Typography>
      </Box>

      {range(props.amount).map((index) => (
        <>
          <Box key={index} display="flex" alignItems="center" mt={2}>
            <Hidden when={props.readOnly}>
              <Field
                component={Checkbox}
                name={`${props.name}[${index}].confirmed`}
                type="checkbox"
                className="OrderItemChecked"
                ml={0}
                mr={2}
                width={40}
                disabled={props.disabled}
              />
            </Hidden>
            <Field
              component={OrderItem}
              name={`${props.name}[${index}]`}
              products={props.products}
              disabled={
                props.readOnly || props.disabled || props.checkedList[index]
              }
            />
            <Hidden when={props.readOnly}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={24}
                height={32}
                style={{
                  cursor: "pointer",
                  visibility: props.checkedList[index] ? "hidden" : "visible",
                }}
                onClick={() => props.removeOrderItem(index)}
              >
                <Button.TrashIcon />
              </Box>
            </Hidden>
          </Box>
          {props.values[index].poSlug && (
            <Box display="flex" mt={2} mb={2}>
              <Typography variant="caption">อ้างอิงใบสั่งสินค้า</Typography>
              <Link
                to={paths.purchaseOrderEditPath(props.values[index].poSlug)}
                newTab
                variant="caption"
                ml={1}
                style={{ color: "#376FD0", textDecoration: "underline" }}
              >
                {props.values[index].poCode}
              </Link>
            </Box>
          )}
        </>
      ))}

      {map(props.deliveryTransactions, (deliveryTransaction, index) => (
        <Box key={index} display="flex" alignItems="center" mt={2}>
          <DeliveryItem deliveryTransaction={deliveryTransaction} />
        </Box>
      ))}

      <Hidden when={props.readOnly || props.disabled}>
        <Button startIcon="add" onClick={props.addOrderItem} mt={6}>
          เพิ่มรายการ
        </Button>
      </Hidden>
    </Box>
  ) : null;

export const API = {
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      products {
        products {
          id
          code
          productName
          sellingUnit
          costPerCapital
          pricePerCapital
          totalQuantity
          quantityPerFold
          lastActiveAt
          published
          sellerProductCode
          seller {
            code
            thName
            enName
            sellingCondition
            deliveryCondition
            paymentCondition
            note
          }
        }
      }
    }
  `,
  FETCH_ORDER: gql`
    query FETCH_ORDER($id: ID!) {
      draftOrder(id: $id) {
        id
        shopifyOrderId
        cost
        updatedAt
        lastSyncDownAt
        lastSyncUpAt
        order {
          id
          code
          createdAt
        }
        buyer {
          id
          code
          firstName
          lastName
          nickName
          brand
          note
          shopifyBuyerId
          deliveryAddresses {
            id
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
            isMain
          }
          defaultShopifyAddress {
            id
            address1
            city
            firstName
            lastName
            province
            zip
            phone
            updatedAt
          }
        }
        deliveryTime
        address {
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          id
          isMain
          lastName
          ownerType
          phoneNumber
          province
          remark
          subDistrict
          taxId
          taxPayerType
          zipCode
        }
        draftOrderItems {
          id
          productId
          sellingUnit
          costPricePerUnit
          sellingPricePerUnit
          amount
          product {
            id
            code
            productName
            sellingUnit
            costPerCapital
            pricePerCapital
            totalQuantity
            quantityPerFold
            lastActiveAt
            seller {
              code
              thName
              enName
              sellingCondition
              deliveryCondition
              paymentCondition
              note
            }
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      form,
      name,
      insert,
      remove,
      mt,
      readOnly,
      disabled,
      deliveryTransactions,
    } = props;
    const { useCallback, useQuery, useMemo, useParams } = hooks;
    const { id } = useParams();

    // const configs = useQuery(API.FETCH_CONFIG);
    const { loading, data, error } = useQuery(API.FETCH_ORDER, {
      variables: { id },
    });

    const values = get(form.values, name);
    const amount = values?.length ?? 0;
    const products = useMemo(() => {
      return data?.draftOrder?.draftOrderItems?.map((item) => item.product);
    }, [loading, data, error]);

    const addOrderItem = useCallback(() => {
      insert(amount, {});
    }, [insert, amount]);

    const removeOrderItem = useCallback(
      (index) => {
        const value = values[index];
        const items = filter(
          data?.draftOrderItems,
          (item) => item.productId
        ).map((item) => {
          const product = item.product;

          const sellingUnit = product
            ? { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[
                product.sellingUnit
              ]
            : null;

          const { amount, cuttingPrice, sellingPricePerUnit } = item;
          const price =
            (amount ?? 0) * (sellingPricePerUnit ?? 0) + (cuttingPrice ?? 0);

          return {
            ...item,
            sellingUnit,
            price,
          };
        });
        const product = find(items, { id: value?.productId });

        if (product) {
          const productName = product.productName;
          const amount = value?.amount;
          const unit = { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[
            product.sellingUnit
          ];

          Modal.confirm({
            className: "reject-modal",
            title: "ลบรายการ",
            children: `ต้องการลบรายการ ${productName} ${
              isNumber(amount)
                ? `${toCurrency(amount, { minimumFractionDigits: 0 })} ${unit}`
                : ""
            } หรือไม่ `,
            cancelButtonLabel: "ยกเลิก",
            okButtonLabel: "ลบ",
            onOk: ({ close }) => {
              remove(index);
              close();
            },
          });
        } else {
          remove(index);
        }
      },
      [remove, values, products, loading, data, error]
    );

    const checkedListTemp = map(values, "confirmed");
    const checkedList = useMemo(() => {
      return checkedListTemp;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(checkedListTemp)]);

    const initialized = !loading;

    return {
      mt,
      name,
      amount,
      products,
      addOrderItem,
      removeOrderItem,
      initialized,
      readOnly,
      checkedList,
      disabled: true,
      values,
      deliveryTransactions,
    };
  })
);

export default enhancer(OrderItemList);
