/* eslint-disable */
import { compose, withHooks, withStores } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography } from "components";
import { gql, paths, notifyError } from "utils/helper";
import { sumBy, find, flatten } from "lodash";
import appStore from "stores/appStore";

const SellerPurchaseOrderIndexPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการสั่งสินค้า
      </Typography>
      <Table
        columns={[
          { width: 150, field: "code", headerName: "รหัส" },
          {
            width: 443,
            field: "oldSystemPurchaseOrderCode",
            headerName: "รหัสใบสั่งสินค้า",
            type: "caption",
          },
          {
            width: 155,
            field: "finalPrice",
            headerName: "มูลค่า (บาท)",
            type: "currency",
            headerAlign: "right",
          },
          { width: 140, field: "status", headerName: "สถานะ" },
          {
            width: 160,
            field: "updatedAt",
            headerName: "อัพเดทล่าสุด",
            type: "dateTime",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.purchaseOrderEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
        autoDetectInitialFilterOnUrlQueryParams
        // includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  CREATE_PURCHASE_ORDER: gql`
    mutation CREATE_PURCHASE_ORDER(
      $sellerId: ID!
      $deliveryAddressId: ID
      $oldSystemPurchaseOrderCode: String
      $orderItemIds: [ID!]
    ) {
      createPurchaseOrder(
        input: {
          sellerId: $sellerId
          deliveryAddressId: $deliveryAddressId
          oldSystemPurchaseOrderCode: $oldSystemPurchaseOrderCode
          orderItemIds: $orderItemIds
        }
      ) {
        purchaseOrder {
          id
        }
      }
    }
  `,
  FETCH_PURCHASE_ORDERS: gql`
    query FETCH_PURCHASE_ORDERS($sellerId: ID!, $status: [String!]) {
      purchaseOrders(sellerId: $sellerId, status: $status) {
        id
        code
        oldSystemPurchaseOrderCode
        finalPrice
        status
        updatedAt
      }
    }
  `,
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      orderItemsWithoutPo {
        id
      }
      setting {
        deliveryAddresses {
          id
          isMain
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores) => ({
    sellerId: stores.appStore.currentUser?.ownerData?.seller?.id,
  })),
  withHooks((props, hooks) => {
    const { sellerId } = props;
    const { useQuery, useMemo, useEffect, useCallback, useMutation } = hooks;

    const { loading, data, error, refetch } = useQuery(
      API.FETCH_PURCHASE_ORDERS,
      {
        variables: { sellerId, status: ["new", "completed"] },
      }
    );
    const configs = useQuery(API.FETCH_CONFIG);
    const [createSellerPurchaseOrder] = useMutation(API.CREATE_PURCHASE_ORDER);

    useEffect(() => {
      refetch();
      configs.refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch, configs.refetch]);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.purchaseOrders.map((order) => {
        const { ...rest } = order;
        return {
          ...rest,
        };
      });
    }, [loading, data, error]);

    const title = `การสั่งสินค้า`;

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: title },
      ];
    }, [title]);

    const orderItemWithoutPoCount = useMemo(() => {
      return configs.data?.orderItemsWithoutPo.length ?? 0;
    }, [configs.data]);

    const createPurchaseOrder = useCallback(async () => {
      try {
        const deliveryAddressId = find(
          configs.data?.setting?.deliveryAddresses,
          {
            isMain: "true",
          }
        )?.id;

        const filteredOrderItems = configs.data?.sellers
          .filter((seller) => seller.orderItemsWithoutPo.length > 0)
          .map((item) => item.orderItemsWithoutPo)
          .map((item) => item.map((item) => item.id));

        const values = {
          confirmedAll: true,
          deliveryAddressId,
          orderItemIds: flatten(filteredOrderItems),
          sellerId: sellerId,
          oldSystemPurchaseOrderCode: null,
        };

        const response = await createSellerPurchaseOrder({
          variables: values,
        });
        const { purchaseOrder } = response.data.createPurchaseOrder;
        const { id } = purchaseOrder || {};
        appStore.state.configSidebarRefetch();
        paths.purchaseOrderEditPath(id).push();
        notifySuccess("เพิ่มข้อมูลสำเร็จ");
      } catch (e) {
        notifyError(e);
      }
    }, [configs.data, sellerId, createSellerPurchaseOrder]);

    return {
      title,
      breadcrumbs,
      tableData,
      orderItemWithoutPoCount,
      loading: loading || configs.loading,
    };
  })
);

export default enhancer(SellerPurchaseOrderIndexPage);
