import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Button,
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "components";
import { formatDate, notifyError, notifySuccess, gql } from "utils/helper";

import { ReactComponent as ArrowDownIcon } from "assets/icon/keyboard_arrow_down.svg";
import { ReactComponent as CheckCircleOutlineIcon } from "assets/icon/check_circle_outline.svg";
import CreateDeliveryTransactionModal from "./new";
import { filter, map } from "lodash";
import React from "react";
import DeliveryItem from "./DeliveryItem";
import SummaryCard from "./SummaryCard";

const OrderEditCheckListDeliveryOrder = (props) => (
  <Accordion disableGutters>
    <AccordionSummary
      expandIcon={<ArrowDownIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        my={-1}
      >
        <Typography color="Text/Black">ส่งสินค้าให้ผู้ซื้อ</Typography>
        {props.isDeliverySuccess ? (
          <Box>
            <Typography variant="caption" color="Text/Dark Grey">
              {`สำเร็จ ${props.deliveryOrder.status}/${props?.orderItemsOfOrder.length}`}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              ml={4}
              onClick={props.openCreateDeliveryTransactionModal}
              disabled={props.deliveryOrder.disabled}
            >
              ดำเนินการ
            </Button>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <CheckCircleOutlineIcon />
            <Typography
              variant="caption"
              color="Text/Dark Grey"
              ml={2}
              width={154}
            >
              {formatDate(
                props.deliveryOrder.completedAt,
                "สำเร็จเมื่อ dd/MM/yyyy, HH:mm"
              )}
            </Typography>
          </Box>
        )}
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      {!props.deliveryOrder?.invoicingOrderCompletedAt ? (
        <Box ml={6} width="100%">
          {map(props.deliveryTransactions, (deliveryTransaction, index) => (
            <React.Fragment key={index}>
              <Divider ml={index === 0 ? -6 : 0} />
              <Box display="flex" justifyContent="space-between" mt={2} mr={9}>
                <Typography color="Text/Black">
                  {deliveryTransaction.trackingNo} -{" "}
                  {deliveryTransaction.channel}
                </Typography>
                {deliveryTransaction.completedAt && (
                  <Box display="flex" alignItems="center">
                    <CheckCircleOutlineIcon />
                    <Typography
                      variant="caption"
                      color="Text/Dark Grey"
                      ml={2}
                      width={154}
                    >
                      {formatDate(
                        deliveryTransaction.completedAt,
                        "สำเร็จเมื่อ dd/MM/yyyy, HH:mm"
                      )}
                    </Typography>
                  </Box>
                )}
              </Box>
              <DeliveryItem
                deliveryTransaction={deliveryTransaction}
                orderItemsOfOrder={props.orderItemsOfOrder}
                deliveryTransactions={props.deliveryTransactions}
                reloadOrder={props.reloadOrder}
                invoicingOrderCompletedAt={
                  props.deliveryOrder?.invoicingOrderCompletedAt
                }
              />
            </React.Fragment>
          ))}
        </Box>
      ) : (
        <SummaryCard info={props.deliveryOrder} />
      )}
    </AccordionDetails>
  </Accordion>
);

const API = {
  CREATE_DELIVERY_TRANSACTION: gql`
    mutation CREATE_DELIVERY_TRANSACTION(
      $id: ID!
      $deliveryAddress: AddressArguments!
      $cost: Float
      $channel: String
      $trackingNo: String
      $orderItemIds: [ID!]!
    ) {
      createDeliveryTransaction(
        input: {
          id: $id
          deliveryAddress: $deliveryAddress
          cost: $cost
          channel: $channel
          trackingNo: $trackingNo
          orderItemIds: $orderItemIds
        }
      ) {
        order {
          id
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { deliveryOrder, reloadOrder } = props;
    const { useCallback, useMemo, useMutation, useParams } = hooks;

    const { id } = useParams();

    const [createDeliveryTransaction] = useMutation(
      API.CREATE_DELIVERY_TRANSACTION
    );

    const openCreateDeliveryTransactionModal = useCallback(
      (e) => {
        Modal.open({
          className: "ConfirmOrderQuotationInfoModal",
          title: "ส่งสินค้าให้ผู้ซื้อ",
          children: (
            <CreateDeliveryTransactionModal
              buyerDeliveryAddress={deliveryOrder?.buyerDeliveryAddress ?? {}}
              orderItemsOfOrder={deliveryOrder?.orderItemsOfOrder ?? []}
              deliveryTransactions={deliveryOrder?.deliveryTransactions ?? []}
            />
          ),
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ยืนยัน",
          onOk: async ({ close, values }) => {
            try {
              const orderItemIds = filter(values.orderItems, [
                "confirmed",
                true,
              ]).map((item) => item.id);
              const validate = (values) => {
                if (!values.channel) {
                  return "โปรดเลือกช่องทางการส่งสินค้า";
                }
                if (!values.cost && values.cost !== 0) {
                  return "โปรดระบุค่าส่ง";
                }
                if (!values.trackingNo) {
                  return "โปรดระบุหมายเลขเพื่อติดตามสินค้า";
                }
                if (orderItemIds.length === 0) {
                  return "โปรดเลือกสินค้าที่ส่งอย่างน้อย 1 ชิ้น";
                }
              };

              const errorMessage = validate(values);
              if (errorMessage) {
                notifyError(errorMessage);
                return;
              }
              await createDeliveryTransaction({
                variables: { ...values, id, orderItemIds },
              });
              await reloadOrder();
              close();
              notifySuccess("ดำเนินการสำเร็จ");
            } catch (e) {
              notifyError(e);
            }
          },
        });
      },
      [deliveryOrder, createDeliveryTransaction, id, reloadOrder]
    );

    const deliveryTransactions = useMemo(() => {
      return deliveryOrder?.deliveryTransactions ?? [];
    }, [deliveryOrder]);

    const orderItemsOfOrder = useMemo(() => {
      return deliveryOrder?.orderItemsOfOrder ?? [];
    }, [deliveryOrder]);

    const isDeliverySuccess = useMemo(() => {
      return (
        props.deliveryOrder.status !==
          deliveryOrder.orderItemsOfOrder?.length ||
        deliveryOrder.orderItemsOfOrder?.length === 0
      );
    }, [props.deliveryOrder.status, deliveryOrder.orderItemsOfOrder]);

    return {
      openCreateDeliveryTransactionModal,
      deliveryOrder,
      deliveryTransactions,
      orderItemsOfOrder,
      isDeliverySuccess,
      reloadOrder,
    };
  })
);

export default enhancer(OrderEditCheckListDeliveryOrder);
