import { compose, withHooks } from "enhancers";
import { Box, Field, Typography, Button, Editor, Hidden } from "components";
import {} from "utils/helper";

const OrderNoteInfo = (props) => (
  <Box mt={props.mt}>
    <Typography variant="h4" color="Text/Black">
      บันทึกเพิ่มเติม
    </Typography>

    <Field
      component={Editor}
      name="note"
      mt={6}
      disabled={props.disabledForm}
    />

    <Hidden when={props.disabledForm}>
      <Button
        disabled={props.disabledSubmitButton}
        color="primary"
        onClick={props.handleSubmit}
        mt={6}
      >
        บันทึก
      </Button>
    </Hidden>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { mt, canceled, disabledSubmitButton, handleSubmit } = props;

    const disabledForm = canceled;

    return {
      mt,
      disabledSubmitButton,
      disabledForm,
      handleSubmit,
    };
  })
);

export default enhancer(OrderNoteInfo);
