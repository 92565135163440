import qs from "qs";
import { last } from "lodash";
import { history } from "components/common/BrowserRouter";
import appStore from "stores/appStore";

// prettier-ignore
export class Path extends String {
  newTab = () => {
    window.open(this.toString())
  };

  push = (extendedPath: String = "") => {
    if (history) {
      history.push(`${this.toString()}${extendedPath??''}`);
    }
  };

  reload = () => {
    window.location.href = this.toString()
  }

  makePath = (path: Function): Function => {
    return (...args: any[]) => {
      const isProfileDataInitialize = !!appStore.state.currentUser?.ownerData?.seller?.isProfileDataInitialize;
      const isSeller = appStore.state.currentUser?.ownerData?.type === "Seller"
      if (isSeller && !isProfileDataInitialize) {
        return new Path('/profile');
      }

      let pathMethod = typeof path === "string" ? () => path : path;
      let absolutePath = null;

      if (last(args) instanceof Object) {
        const params = args.slice(0, args.length - 1);
        const query = last(args);
        const queryString = qs.stringify(query);

        absolutePath = `${this}/${pathMethod(...params)}?${queryString}`;
      } else {
        absolutePath = `${this}/${pathMethod(...args)}`;
      }

      return new Path(absolutePath.replace(/\/+/, "/"));
    };
  };

  page404Path                 = this.makePath(() => `404`)
  page500Path                 = this.makePath(() => `500`)

  signInPath                  = this.makePath(() => `auth/sign-in`)
  signUpPath                  = this.makePath(() => `auth/sign-up`)
  setupPasswordPath           = this.makePath(() => `auth/setup-password`)
  resetPasswordPath           = this.makePath(() => `auth/reset-password`)

  dashboardPath               = this.makePath(() => `dashboard`)

  adminsPath                  = this.makePath(() => `admins`)
  adminNewPath                = this.makePath(() => `admins/new`)
  adminEditPath               = this.makePath((id: any) => `admins/${id}/edit`)

  buyersPath                  = this.makePath(() => `buyers`)
  buyerNewPath                = this.makePath(() => `buyers/new`)
  buyerEditPath               = this.makePath((id: any) => `buyers/${id}/edit`)

  sellersPath                 = this.makePath(() => `sellers`)
  sellerNewPath               = this.makePath(() => `sellers/new`)
  sellerEmailInvitationPath   = this.makePath(() => `sellers/email-invitation`)
  sellerEditPath              = this.makePath((id: any) => `sellers/${id}/edit`)

  productsPath                = this.makePath(() => `products`)
  productNewPath              = this.makePath(() => `products/new`)
  productEditPath = this.makePath((id: any) => `products/${id}/edit`)

  draftOrdersPath       = this.makePath(() => `draft-orders`)
  draftOrderDetailPath  = this.makePath((id: any) => `draft-orders/${id}/edit`)

  ordersPath                  = this.makePath(() => `orders`)
  orderNewPath                = this.makePath(() => `orders/new`)
  orderEditPath               = this.makePath((id: any) => `orders/${id}/edit`)

  purchaseOrdersPath          = this.makePath(() => `purchase-orders`)
  purchaseOrderNewPath        = this.makePath(() => `purchase-orders/new`)
  purchaseOrderEditPath       = this.makePath((id: any) => `purchase-orders/${id}/edit`)

  profilePath                 = this.makePath(() => `profile`)
  settingPath                 = this.makePath(() => `setting`)
  
  sidekiqPath                 = this.makePath(() => `sidekiq`)

  homePath                    = this.makePath(() => ``)
}

export default new Path();
