import { compose, withHooks } from "enhancers";
import { Box, Typography, Field, Checkbox, Hidden, Link } from "components";
import { gql, toCurrency, paths } from "utils/helper";
import { every, map, sum, filter } from "lodash";

import OrderItem from "./OrderItem";

const OrderItemList = (props) => (
  <Box mt={props.mt}>
    <Box
      display="flex"
      alignItems="center"
      height={32}
      mb={4}
      style={{ borderBottom: "1px solid #E0E0E0" }}
    >
      <Hidden when={props.readOnly}>
        <Field
          component={Checkbox}
          name="confirmedAll"
          type="checkbox"
          className="OrderItemChecked"
          ml={0}
          mr={2}
          width={40}
          disabled={props.disabled}
          onChange={props.handleCheckAll}
        />
      </Hidden>

      <Typography variant="h6" flex={1} mr={2}>
        รายการ
      </Typography>
      <Typography variant="h6" width={90} mr={2}>
        จำนวน
      </Typography>
      <Typography variant="h6" width={80} mr={2}>
        หน่วย
      </Typography>
      <Typography variant="h6" width={90} mr={2}>
        บาท/หน่วย
      </Typography>
      <Typography variant="h6" width={120} mr={2}>
        ค่าตัด (บาท)
      </Typography>
      <Typography variant="h6" width={120}>
        ราคารวม (บาท)
      </Typography>
    </Box>

    {props.orderItems.map((item, index) => (
      <Box key={index}>
        <Box display="flex" alignItems="center" mt={2}>
          <Hidden when={props.readOnly}>
            <Field
              component={Checkbox}
              name={`${props.name}[${index}].confirmed`}
              type="checkbox"
              className="OrderItemChecked"
              ml={0}
              mr={2}
              width={40}
              disabled={props.disabled}
            />
          </Hidden>
          <Field component={OrderItem} name={`${props.name}[${index}]`} />
        </Box>
        <Box display="flex" mt={2} mb={4} />
      </Box>
    ))}

    <Box mt={6.5}>
      <Hidden when={!props.hasVat}>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography color="Text/Black" textAlign="right">
            ราคารวม
          </Typography>
          <Typography
            color="Text/Dark Grey"
            textAlign="right"
            width={120}
            ml={3}
            pr={3}
          >
            {toCurrency(props.totalPrice)}
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography color="Text/Black" textAlign="right">
            ภาษีมูลค่าเพิ่ม 7%
          </Typography>
          <Typography
            color="Text/Dark Grey"
            textAlign="right"
            width={120}
            ml={3}
            pr={3}
          >
            {toCurrency(props.vat)}
          </Typography>
        </Box>
      </Hidden>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height={32}
      >
        <Typography variant="h6" color="Text/Black" textAlign="right">
          ยอดรวมทั้งหมด
        </Typography>
        <Typography
          variant="h6"
          color="Text/Dark Grey"
          textAlign="right"
          width={120}
          ml={3}
          pr={3}
        >
          {toCurrency(props.finalPrice)}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export const API = {
  FETCH_ORDER_ITEMS: gql`
    query FETCH_ORDER_ITEMS($sellerId: ID!) {
      orderItems(sellerId: $sellerId) {
        id
        productName
        sellingUnit
        costPricePerUnit
        sellingPricePerUnit
        cuttingPrice
        totalPrice
        amount
        productCode
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { form, name, mt, readOnly, disabled } = props;
    const { useMemo, useEffect, useCallback } = hooks;

    const { values, setFieldValue } = form;
    const { [name]: orderItems = [], confirmedAll, hasVat } = values;

    const itemCheckedList = map(orderItems, "confirmed");
    useEffect(() => {
      const allItemsChecked = every(orderItems, { confirmed: true });

      if (confirmedAll !== allItemsChecked) {
        setFieldValue("confirmedAll", allItemsChecked);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(itemCheckedList)]);

    const handleCheckAll = useCallback(
      (e, checked) => {
        setFieldValue("confirmedAll", checked);
        setFieldValue(
          name,
          map(orderItems, (item) => ({
            ...item,
            confirmed: checked,
          }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },
      [orderItems]
    );

    const { totalPrice, vat, finalPrice } = useMemo(() => {
      const totalPrice = sum(
        map(
          filter(orderItems, { confirmed: true }),
          (item) =>
            (item.amount ?? 0) * (item.costPricePerUnit ?? 0) +
            (item.cuttingPrice ?? 0)
        )
      );
      const vat = hasVat ? totalPrice * 0.07 : 0;
      const finalPrice = totalPrice + vat;

      return {
        totalPrice,
        vat,
        finalPrice,
      };
    }, [orderItems, hasVat]);

    return {
      mt,
      name,
      readOnly,
      disabled,
      orderItems,
      hasVat,
      totalPrice,
      vat,
      finalPrice,
      handleCheckAll,
    };
  })
);

export default enhancer(OrderItemList);
