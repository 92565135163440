/* eslint-disable */
import React, { useEffect } from "react";
import { compose, withHooks } from "enhancers";
import { useQuery, gql } from "@apollo/client";
import paths from "routes/paths";

import appStore from "stores/appStore";

import { Switch, Route, Redirect } from "components";
import { BlankLayout, MenuLayout } from "layouts";

import SignInPage from "pages/auth/SignIn";
import SignUpPage from "pages/auth/SignUp";
import SetupPasswordPage from "pages/auth/SetupPassword";
import ResetPasswordPage from "pages/auth/ResetPassword";

import DashboardPage from "pages/main/dashboard";

import AdminIndexPage from "pages/main/admins/index";
import AdminNewPage from "pages/main/admins/new";
import AdminEditPage from "pages/main/admins/edit";

import BuyerIndexPage from "pages/main/buyers";
import BuyerNewPage from "pages/main/buyers/new";
import BuyerEditPage from "pages/main/buyers/edit";

import SellerIndexPage from "pages/main/sellers";
import SellerNewPage from "pages/main/sellers/new";
import SellerEditPage from "pages/main/sellers/edit";
import SellerEmailInvitationPage from "pages/sellers/new";

import ProductIndexPage from "pages/main/products";
import ProductNewPage from "pages/main/products/new";
import ProductEditPage from "pages/main/products/edit";

import OrderIndexPage from "pages/main/orders";
import OrderNewPage from "pages/main/orders/new";
import OrderShowPage from "pages/main/orders/show";

import PurchaseOrderIndexPage from "pages/main/purchase_orders";
import PurchaseOrderNewPage from "pages/main/purchase_orders/new";
import PurchaseOrderEditPage from "pages/main/purchase_orders/show";

import SellerPurchaseOrderIndexPage from "pages/sellers/purchase_orders";
import SellerPurchaseOrderEditPage from "pages/sellers/purchase_orders/show";

import SellerProductIndexPage from "pages/sellers/products";
import SellerProductNewPage from "pages/sellers/products/new";
import SellerProductEditPage from "pages/sellers/products/edit";

import AdminProfilePage from "pages/main/profile/index";
import SellerProfilePage from "pages/sellers/profile/index";
import SettingPage from "pages/main/setting";

import SidekiqPage from "pages/main/sidekiq";

import DraftOrderIndexPage from "pages/main/draft_orders";
import DraftOrderDetailPage from "pages/main/draft_orders/edit";

// prettier-ignore
const InitialPages = () => (
  <BlankLayout>
    <Switch>
      <Route path={paths.signUpPath()} exact component={SignUpPage} />
      <Route path={paths.setupPasswordPath()} exact component={SetupPasswordPage} />

      <Redirect to={paths.signUpPath()} />
    </Switch>
  </BlankLayout>
)

// prettier-ignore
const GuestPages = () => (
  <BlankLayout>
    <Switch>
      <Route path={paths.signInPath()} exact component={SignInPage} />
      <Route path={paths.setupPasswordPath()} exact component={SetupPasswordPage} />
      <Route path={paths.resetPasswordPath()} exact component={ResetPasswordPage} />

      <Redirect to={paths.signInPath()} />
    </Switch>
  </BlankLayout>
)

// prettier-ignore
const MainPages = (props: any) => (
  <Switch>
    <Route path={paths.dashboardPath()} exact layout={MenuLayout} component={DashboardPage} />

    <Route path={paths.adminsPath()} exact layout={MenuLayout} component={AdminIndexPage} />
    <Route path={paths.adminNewPath()} exact layout={MenuLayout} component={AdminNewPage} />
    <Route path={paths.adminEditPath(':id')} exact layout={MenuLayout} component={AdminEditPage} />

    <Route path={paths.buyersPath()} exact layout={MenuLayout} component={BuyerIndexPage} />
    <Route path={paths.buyerNewPath()} exact layout={MenuLayout} component={BuyerNewPage} />
    <Route path={paths.buyerEditPath(':id')} exact layout={MenuLayout} component={BuyerEditPage} />

    <Route path={paths.sellersPath()} exact layout={MenuLayout} component={SellerIndexPage} />
    <Route path={paths.sellerEmailInvitationPath()} exact layout={MenuLayout} component={SellerEmailInvitationPage} />
    <Route path={paths.sellerNewPath()} exact layout={MenuLayout} component={SellerNewPage} />
    <Route path={paths.sellerEditPath(':id')} exact layout={MenuLayout} component={SellerEditPage} />

    <Route path={paths.productsPath()} exact layout={MenuLayout} component={ProductIndexPage} />
    <Route path={paths.productNewPath()} exact layout={MenuLayout} component={ProductNewPage} />
    <Route path={paths.productEditPath(':id')} exact layout={MenuLayout} component={ProductEditPage} />

    <Route path={paths.ordersPath()} exact layout={MenuLayout} component={OrderIndexPage} />
    <Route path={paths.orderNewPath()} exact layout={MenuLayout} component={OrderNewPage} />
    <Route path={paths.orderEditPath(':id')} exact layout={MenuLayout} component={OrderShowPage} />

    <Route path={paths.draftOrdersPath()} exact layout={MenuLayout} component={DraftOrderIndexPage} />
    <Route path={paths.draftOrderDetailPath(':id')} exact layout={MenuLayout} component={DraftOrderDetailPage} />

    <Route path={paths.purchaseOrdersPath()} exact layout={MenuLayout} component={PurchaseOrderIndexPage} />
    <Route path={paths.purchaseOrderNewPath()} exact layout={MenuLayout} component={PurchaseOrderNewPage} />
    <Route path={paths.purchaseOrderEditPath(':id')} exact layout={MenuLayout} component={PurchaseOrderEditPage} />

    <Route path={paths.profilePath()} exact layout={MenuLayout} component={AdminProfilePage} />

    <Route path={paths.settingPath()} exact layout={MenuLayout} component={SettingPage} permittedRoles={['SuperAdmin']} />
    <Route path={paths.sidekiqPath()} exact layout={MenuLayout} component={SidekiqPage} permittedRoles={['SuperAdmin']} />

    <Redirect to={paths.dashboardPath()} />
  </Switch>
)

// prettier-ignore
const SellerPages = (props: any) => props.isProfileDataInitialize ? (
  <Switch>
    <Route path={paths.productsPath()} exact layout={MenuLayout} component={SellerProductIndexPage} />
    <Route path={paths.productNewPath()} exact layout={MenuLayout} component={SellerProductNewPage} />
    <Route path={paths.productEditPath(':id')} exact layout={MenuLayout} component={SellerProductEditPage} />

    <Route path={paths.purchaseOrdersPath()} exact layout={MenuLayout} component={SellerPurchaseOrderIndexPage} />
    <Route path={paths.purchaseOrderEditPath(':id')} exact layout={MenuLayout} component={SellerPurchaseOrderEditPage} />

    <Route path={paths.profilePath()} exact layout={MenuLayout} component={SellerProfilePage} />

    <Redirect to={paths.productsPath()} />
  </Switch>
) : (
  <Switch>
    <Route path={paths.profilePath()} exact layout={MenuLayout} component={SellerProfilePage} />
    <Redirect to={paths.profilePath()} />
  </Switch>
);

interface RoutesProps {
  initialized: boolean;
  hasFirstAdmin: boolean;
  isAuthorized: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isProfileDataInitialize: boolean;
}

// prettier-ignore
const Routes = (props: RoutesProps) => {
  if (!props.initialized) { return null }
  else if (!props.hasFirstAdmin) { return <InitialPages /> }
  else if (!props.isAuthorized) { return <GuestPages /> }
  else if (props.initialized && !props.isAdmin) { return <SellerPages isProfileDataInitialize={props.isProfileDataInitialize} /> }
  else { return <MainPages isSuperAdmin={props.isSuperAdmin} /> }
}

export const API = {
  GET_APP_INFO: gql`
    query GET_APP_INFO {
      info {
        hasFirstAdmin
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      currentUser {
        email
        firstName
        lastName
        code
        avatarUrl
        ownerData {
          type
          isSuperAdmin
          seller {
            id
            isProfileDataInitialize
            thName
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks(() => {
    const appInfo = useQuery(API.GET_APP_INFO);
    const currentUser = useQuery(API.GET_CURRENT_USER, {
      onCompleted: (data) => {
        appStore.setCurrentUser(data.currentUser);
        appStore.setCurrentRole(
          currentUser?.data?.currentUser?.ownerData?.isSuperAdmin === true
            ? "SuperAdmin"
            : currentUser?.data?.currentUser?.ownerData?.type
        );
      },
    });

    const initialized = !appInfo.loading && !currentUser.loading;
    const isAuthorized = !currentUser.error;
    const hasFirstAdmin = !!appInfo.data?.info?.hasFirstAdmin;
    const isAdmin = currentUser?.data?.currentUser?.ownerData?.type === "Admin";
    const isSuperAdmin =
      currentUser?.data?.currentUser?.ownerData?.isSuperAdmin === true;
    const isProfileDataInitialize = !!currentUser?.data?.currentUser?.ownerData
      ?.seller?.isProfileDataInitialize;

    useEffect(() => {
      const $splashScreen = document.getElementById("splash-screen");
      if ($splashScreen) {
        const display = initialized ? "none" : "";
        $splashScreen.style.display = display;
      }
    }, [initialized]);

    return {
      isAdmin,
      initialized,
      isAuthorized,
      hasFirstAdmin,
      isProfileDataInitialize,
      isSuperAdmin,
    };
  })
);

export default enhancer(Routes);
