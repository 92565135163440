import React from "react";
import { compose, defaultProps, withHooks } from "enhancers";
import {
  Helmet,
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Divider,
  Paper,
  Button,
  BrowseButton,
} from "components";
import { ReactComponent as ShopifyLogo } from "../assets/image/shopify_logo.svg";

const PageContent = (props: any) => (
  <Box minWidth={786} maxWidth={1080} mx="auto">
    <Helmet title={props.title} />
    <Box display="flex">
      <Typography flex={1} variant="h2">
        {props.title}
      </Typography>
      {props.pageActions.map((actionProps: any, index: any) => (
        <>
          {actionProps.type !== "file" && (
            <Button key={index} ml={4} {...actionProps} />
          )}
          {actionProps.type === "file" && (
            <BrowseButton key={index} ml={4} {...actionProps} />
          )}
        </>
      ))}
    </Box>
    {props.breadcrumbs && (
      <>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Breadcrumbs aria-label="breadcrumb">
            {props.breadcrumbs.map(({ label, path }: any, index: any) => (
              <Link key={index} color={path ? undefined : "inherit"} to={path}>
                {label}
              </Link>
            ))}
          </Breadcrumbs>
          {props.sync && (
            <Box display="flex" alignItems="center">
              <ShopifyLogo style={{ marginRight: "8px" }} />
              <Typography variant="body1" color="#81BF37">
                {props.sync}
              </Typography>
            </Box>
          )}
        </Box>
        <Divider mt={6} />
      </>
    )}
    {props.paper ? (
      <Paper mt={6} px={4} py={6}>
        {props.children}
      </Paper>
    ) : (
      props.children
    )}
  </Box>
);

const enhancer = compose(
  defaultProps({
    paper: true,
  }),
  withHooks((props: any, hooks: any) => {
    const {
      title,
      breadcrumbs,
      pageActions = [],
      children,
      paper,
      sync,
    } = props;
    return {
      title,
      breadcrumbs,
      pageActions,
      children,
      paper,
      sync,
    };
  })
);

export default enhancer(PageContent);
