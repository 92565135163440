import { compose, defaultProps, withHooks, withStores } from "enhancers";
import { IconButton, Box, Chip, Typography } from "components";
import { formatPhoneNumber, toCurrency } from "utils/helper";
import { map, find, isEmpty } from "lodash";
import { format, parseISO } from "date-fns";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";

import { Popper, TextField, withStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { isEqual } from "lodash";

import { ReactComponent as FacebookIcon } from "assets/icon/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/icon/instagram.svg";
import { ReactComponent as LineIcon } from "assets/icon/line.svg";
import { ReactComponent as EmailIcon } from "assets/icon/email.svg";
import { ReactComponent as ShopifyLogo } from "../../assets/image/shopify_logo_black.svg";

import { ReactComponent as BlueDot } from "assets/icon/blue_dot.svg";
import { ReactComponent as YellowDot } from "assets/icon/yellow_dot.svg";

import { ReactComponent as WarningAmber } from "assets/icon/warning_amber.svg";
import appStore from "stores/appStore";

const StyledDataGrid = withStyles({
  root: {
    // "& .MuiDataGrid-viewport": {
    //   maxHeight: "none !important",
    // },
    // "& .MuiDataGrid-renderingZone": {
    //   maxHeight: "none !important",
    // },
    // "& .MuiDataGrid-cell": {
    //   lineHeight: "unset !important",
    //   maxHeight: "none !important",
    //   whiteSpace: "normal",
    // },
    // "& .MuiDataGrid-row": {
    //   maxHeight: "none !important",
    // },
    // "& .MuiTypography-root": {
    //   fontWeight: 700,
    // },
  },
})(DataGrid);

export const RENDER_CELLS = {
  text(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return (
      <Box
        fullWidth
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {value}
      </Box>
    );
  },
  caption(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return (
      <Typography
        fullWidth
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {value}
      </Typography>
    );
  },
  phoneNumber(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return formatPhoneNumber(value);
  },
  date(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return value;
  },
  dateTime(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return format(parseISO(value), "dd/MM/yyyy, HH:mm");
  },
  currency(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return (
      <Box marginLeft="auto">
        {toCurrency(value, { minimumFractionDigits: 0 })}
      </Box>
    );
  },
  social(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }

    const [info, channel] = value.split("{{MORELOOP_SPLIT_STRING}}");

    if (!info && !channel) {
      return <></>;
    }

    const Icon = {
      facebook: FacebookIcon,
      ig: InstagramIcon,
      line: LineIcon,
      email: EmailIcon,
      shopify_buyer_id: ShopifyLogo,
    }[channel];

    return (
      <Box display="flex" alignItems="center">
        {Icon && <Icon style={{ marginRight: 8 }} />}
        {info}
      </Box>
    );
  },
  selector(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return find(row.colDef.options, { value })?.label;
  },
  tags(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    const values = value.split(",");
    return (
      <Box whiteSpace="pre-wrap" p={1} pt={0}>
        {map(values, (v) => {
          return (
            <Chip
              size="small"
              label={v}
              mr={1}
              mt={1}
              style={{ fontSize: 12, lineHeight: 14 }}
            />
          );
        })}{" "}
      </Box>
    );
  },
  notiStatus(row) {
    const { value } = row;
    if (!value && value !== false) {
      return <></>;
    }

    return (
      <Box display="flex" alignItems="center" mx="auto">
        {value ? <BlueDot /> : <YellowDot />}
      </Box>
    );
  },
  boolean(row) {
    const { value } = row;
    if (!value && value !== false) {
      return <></>;
    }

    return (
      <Box display="flex" alignItems="center" mx="auto">
        {value ? <BlueDot /> : <YellowDot />}
      </Box>
    );
  },
  warningStatus(row) {
    const { value } = row;
    if (!value && value !== false) {
      return <></>;
    }

    return (
      <Box display="flex" alignItems="center" mx="auto">
        {value ? <WarningAmber /> : null}
      </Box>
    );
  },
  actions(row) {
    const { value } = row;
    if (!value) {
      return <></>;
    }

    return value.map((action, index) => {
      const { Icon, onClick, can } = action;
      if (can === false) {
        return <></>;
      }

      return (
        <IconButton
          key={index}
          onClick={(e) => {
            e.stopPropagation();
            onClick(row);
          }}
        >
          <Icon />
        </IconButton>
      );
    });
  },
};

const customValue = {
  date(row) {
    const { value } = row;
    if (!value) {
      return null;
    }
    return format(parseISO(value), "dd/MM/yyyy, HH:mm");
  },
  social(row) {
    const { value } = row;

    if (!value.channel || !value.info) {
      return null;
    }

    const { channel, info } = value;

    return `${info}{{MORELOOP_SPLIT_STRING}}${channel}`;
  },
  tags(row) {
    const { value } = row;
    if (!value) {
      return null;
    }

    const labels = map(
      value,
      (v) => find(row.colDef.options, { value: v })?.label ?? ""
    ).join(",");

    return labels;
  },
};

//https://github.com/mui-org/material-ui-x/blob/HEAD/packages/grid/_modules_/grid/constants/localeTextConstants.ts
//https://material-ui.com/components/data-grid/localization/
// components={{
//   Toolbar: GridToolbar,
// }}

const enhancer = compose(
  defaultProps({
    style: {
      minHeight: 606,
    },
    density: "compact",
    autoHeight: true,
    autoPageSize: true,
    disableSelectionOnClick: true,
    rowsPerPageOptions: [25, 50, 100],
  }),
  withStores((stores) => ({
    paginate: stores.appStore.paginate,
  })),
  withHooks((props, hooks) => {
    const {
      columns,
      onRowClickTo,
      onRowClick,
      autoDetectInitialFilterOnUrlQueryParams,
      defaultPageSize,
      initialFilter,
      paginationMode,
      refetch,
      filterMode,
      sortingMode,
      loading,
      paginate,
      ...rest
    } = props;
    const {
      useMemo,
      useCallback,
      useState,
      useUrlParam,
      useEffect,
      useLocation,
    } = hooks;
    const { pathname } = useLocation();
    const [pageSize, setPageSize] = useState(
      paginate.pathname === pathname
        ? paginate.pageSize
        : defaultPageSize || 100
    );
    const [page, onPageChange] = useState(
      paginate.pathname === pathname ? paginate.page : 0
    );
    const [sortModel, setSortModel] = useState([]);
    const [refetchLoading, setRefetchLoading] = useState(false);

    useEffect(() => {
      async function fetchNextPage() {
        if (paginationMode === "server") {
          setRefetchLoading(true);
          await refetch({ page: page, pageSize: pageSize });
          appStore.setPaginate(pathname, page, pageSize);
          setRefetchLoading(false);
        }
      }
      fetchNextPage();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize, paginationMode]);

    const customColumns = useMemo(() => {
      return map(columns, ({ type = "text", customFilterType, ...rest }) => {
        const filterOptions = [];

        if (customFilterType === "selector") {
          filterOptions.push({
            label: "contains",
            value: "contains",
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField ||
                !filterItem.value ||
                !filterItem.operatorValue
              ) {
                return null;
              }

              return (params) => {
                return params.value.includes(filterItem?.value?.value);
              };
            },
            InputComponent: ({ item, applyValue }) => (
              <Autocomplete
                id="value filter"
                options={rest.valueOptions}
                getOptionLabel={(option) => option.label ?? ""}
                value={item.value ?? ""}
                onChange={(e, value) => {
                  applyValue({ ...item, value: value });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Value" variant="standard" />
                )}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{ width: "500px" }}
                    placement="bottom-start"
                  />
                )}
              />
            ),
          });
        }

        const result = {
          renderCell:
            type === "text" ? undefined : RENDER_CELLS[type] || undefined,
          valueGetter: customValue[type] || undefined,
          type: ["singleSelect", "dateTime", "boolean"].includes(type)
            ? type
            : undefined,
          ...rest,
        };

        return filterOptions.length === 0
          ? result
          : { ...result, filterOperators: filterOptions };
      });
    }, [columns]);

    const customOnRowClick = useCallback(
      (row) => {
        if (onRowClickTo) {
          return onRowClickTo(row.id).push();
        }
        if (onRowClick) {
          return onRowClick(row);
        }
      },
      [onRowClickTo, onRowClick]
    );

    const onPageSizeChange = useCallback((newPage) => setPageSize(newPage), [
      setPageSize,
    ]);

    const urlParams = useUrlParam();
    const initialFilterModel = useMemo(() => {
      const items = map(urlParams, (value, key) => {
        return {
          columnField: key,
          operatorValue: "contains",
          value: value,
        };
      });

      return isEmpty(items) ? undefined : { items };
    }, [urlParams]);

    const [filterModel, onFilterModelChange] = useState(
      initialFilter ?? initialFilterModel
    );

    const customOnFilterModelChange = useCallback(
      async (items) => {
        if (items.items.length === 0 && initialFilter) {
          onFilterModelChange(initialFilter);
          refetch({ filters: [] });
          return;
        }

        if (filterMode === "server" && items.items[0]?.value) {
          setRefetchLoading(true);
          await refetch({
            filters: items.items,
            sorts: sortModel,
          });
          setRefetchLoading(false);
        }
        onFilterModelChange(items);
      },
      [initialFilter, filterMode, refetch, sortModel]
    );

    const handleSortModelChange = useCallback(
      async (newModel) => {
        if (!isEqual(newModel, sortModel) && sortingMode === "server") {
          setRefetchLoading(true);
          await refetch({ filters: filterModel?.items, sorts: newModel });
          setRefetchLoading(false);
        }
        setSortModel(newModel);
      },
      [filterModel, refetch, sortModel, sortingMode]
    );

    return {
      filterMode,
      pageSize,
      onPageSizeChange,
      page,
      onPageChange,
      paginationMode,
      ...rest,
      columns: customColumns,
      onRowClick: customOnRowClick,
      filterModel,
      onFilterModelChange: customOnFilterModelChange,
      onSortModelChange: handleSortModelChange,
      sortModel,
      components: {
        Toolbar: props.includeToolbar ? GridToolbar : undefined,
      },
      loading: loading || refetchLoading,
    };
  })
);

const CustomTable = enhancer(StyledDataGrid);

CustomTable.RENDER_CELLS = RENDER_CELLS;

export default CustomTable;
