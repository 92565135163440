import { compose, withHooks } from "enhancers";
import { Box, Typography } from "components";
import { QRCodeCanvas } from "qrcode.react";
import styled from "styled-components/macro";

const CardContainer = styled.div`
  width: 400px;
  height: 500px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.8);
`;

const PDFContentCard = (props) => (
  <CardContainer>
    <QRCodeCanvas value="https://reactjs.org/" />

    <Box display="flex" flexDirection="column" mt={2}>
      <Typography>{`Product code: ${props.code}`}</Typography>
      <Typography>{`Product name: ${props.productName}`}</Typography>
    </Box>
  </CardContainer>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { data } = props;
    const { code, productName } = data;

    return {
      code,
      productName,
    };
  })
);

export default enhancer(PDFContentCard);
