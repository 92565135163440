import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Field,
  Address,
  Select,
  TextField,
  Grid,
  FieldArray,
} from "components";
import OrderItemList from "./OrderItemList";
import { concat, filter, find, isEmpty, sortBy } from "lodash";

export const CreateOrderCheckListDeliveryOrder = (props) => (
  <Box mt={8}>
    <Grid container spacing={6} style={{ width: "100%", margin: "unset" }}>
      <Field component={Address.DeliveryOnModal} name="deliveryAddress" />
      <Grid item xs={6} pr="8px !important">
        <Field
          component={Select}
          name="channel"
          label="ช่องทางการส่งสินค้า"
          fullWidth
          required
          options={props.channelOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          type="number"
          name="cost"
          label="ค่าส่ง"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6} pr="8px !important">
        <Field
          component={TextField}
          name="trackingNo"
          label="หมายเลขเพื่อติดตามสินค้า"
          fullWidth
          required
        />
      </Grid>
    </Grid>
    <Box mt={6}>
      <Box display="flex" mb={4}>
        <Typography>สินค้าที่ส่ง</Typography>
        <Typography color="Other/Danger">*</Typography>
      </Box>
      <FieldArray component={OrderItemList} name="orderItems" />
    </Box>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      orderItemsOfOrder,
      deliveryTransactions,
      buyerDeliveryAddress,
    } = props;
    const { useEffect, useFormikContext, useMemo } = hooks;
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
      let orderItemsOfdelivery = [];

      deliveryTransactions.forEach((deliveryTransaction) => {
        orderItemsOfdelivery = concat(
          orderItemsOfdelivery,
          deliveryTransaction.orderItems
        );
      });

      const orderItems = filter(orderItemsOfOrder, (itemOfOrder) =>
        isEmpty(find(orderItemsOfdelivery, ["id", itemOfOrder.id]))
      ).map((item) => {
        return { ...item, confirmed: true };
      });

      setFieldValue("deliveryAddress", buyerDeliveryAddress);
      setFieldValue("orderItems", sortBy(orderItems, "productCode"));
    }, [
      buyerDeliveryAddress,
      setFieldValue,
      deliveryTransactions,
      orderItemsOfOrder,
    ]);

    const channelOptions = useMemo(() => {
      const costOptions = [
        {
          label: "Best Express",
          value: "Best Express",
          cost: null,
          trackingNo: "",
        },
        {
          label: "Lalamove",
          value: "Lalamove",
          cost: null,
          trackingNo: "",
        },
        {
          label: "Grab",
          value: "Grab",
          cost: null,
          trackingNo: "",
        },
        {
          label: "ThaiPost",
          value: "ThaiPost",
          cost: null,
          trackingNo: "",
        },
        {
          label: "Kerry",
          value: "Kerry",
          cost: null,
          trackingNo: "",
        },
        {
          label: "Flash Express",
          value: "Flash Express",
          cost: null,
          trackingNo: "",
        },
        {
          label: "J&T",
          value: "J&T",
          cost: null,
          trackingNo: "",
        },
        {
          label: "รับสินค้าเอง",
          value: "รับสินค้าเอง",
          cost: 0,
          trackingNo: "-",
        },
      ];
      return costOptions;
    }, []);

    useEffect(() => {
      const channel = find(channelOptions, ["value", values.channel]);
      setFieldValue("cost", channel?.cost);
      setFieldValue("trackingNo", channel?.trackingNo);
    }, [values.channel, setFieldValue, channelOptions]);

    return { channelOptions };
  })
);

export default enhancer(CreateOrderCheckListDeliveryOrder);
