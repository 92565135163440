import { compose, withFormik, withHooks, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  FieldArray,
  TextField,
  Divider,
  Button,
  Select,
  Modal,
  Hidden,
} from "components";
import {
  gql,
  notifyError,
  paths,
  getFullAddress,
  notifySuccess,
  cleanTypename,
} from "utils/helper";
import { map, find, filter, sumBy } from "lodash";

import OrderItemList from "./OrderItemList";
import BillingAddressInfo from "./BillingAddressInfo";
import DeliverAddressInfo from "../../orders/new/AddressInfo";
import appStore from "stores/appStore";

export const OrderForm = (props) => (
  <Form>
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h4">ผู้ขาย</Typography>
          <Typography color="Text/Dark Grey" mt={2}>
            เลือกผู้ขายที่ต้องการสร้างใบสั่งสินค้า
          </Typography>
        </Box>
        <Box display="flex">
          <Hidden when={props.hasSeller}>
            <Button
              startIcon="add"
              onClick={props.selectSeller}
              ml={6}
              color="primary"
            >
              เลือกผู้ขาย
            </Button>
          </Hidden>
          <Hidden when={!props.hasSeller}>
            <Button startIcon="history" onClick={props.changeSeller}>
              เปลี่ยนผู้ขาย
            </Button>
          </Hidden>
        </Box>
      </Box>

      <Hidden when={!props.hasSeller}>
        <Grid container spacing={6} mt={3}>
          <Hidden when={!props.billingAddressInfo}>
            <Grid item xs={6}>
              <BillingAddressInfo info={props.billingAddressInfo} />
            </Grid>
          </Hidden>
          <Grid item xs={6} />
        </Grid>
        <Divider mt={6} mb={10} />
        <Typography variant="h4">สถานที่จัดส่ง</Typography>
        <Grid container spacing={6} mt={3}>
          <Grid item xs={6}>
            <Field
              label="ที่อยู่ที่ใช้ในการส่งของ"
              name="deliveryAddressId"
              component={Select}
              options={props.deliveryAddressIdOptions}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Hidden when={!props.deliveryAddressInfo}>
            <Grid item xs={6}>
              <DeliverAddressInfo info={props.deliveryAddressInfo} />
            </Grid>
          </Hidden>
        </Grid>
        <Divider mt={6} mb={10} />
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">รายการสินค้า</Typography>
          <Typography color="Text/Dark Grey">
            ใช้เวลาส่งประมาณ {props.averageDeliveryDate} วัน
          </Typography>
        </Box>
        <Field
          component={TextField}
          name="oldSystemPurchaseOrderCode"
          label="หมายเลขใบสั่งสินค้าจากระบบบัญชี"
          width={512}
          mt={6}
        />
        <FieldArray
          component={OrderItemList}
          name="orderItems"
          products={props.products || [1, 2, 3]}
          mt={6}
        />

        <Divider mt={6} mb={10} />
        <Button
          type="submit"
          color="primary"
          width={132}
          style={{ border: "unset" }}
          disabled={props.loading}
        >
          {props.submitButtonLabel}
        </Button>
      </Hidden>
    </Box>
  </Form>
);

const OrderNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <OrderForm {...props} />
  </PageContent>
);

export const API = {
  CREATE_PURCHASE_ORDER: gql`
    mutation CREATE_PURCHASE_ORDER(
      $sellerId: ID!
      $deliveryAddress: AddressArguments
      $deliveryAddressId: ID
      $oldSystemPurchaseOrderCode: String
      $note: String
      $orderItemIds: [ID!]
    ) {
      createPurchaseOrder(
        input: {
          sellerId: $sellerId
          deliveryAddress: $deliveryAddress
          deliveryAddressId: $deliveryAddressId
          oldSystemPurchaseOrderCode: $oldSystemPurchaseOrderCode
          note: $note
          orderItemIds: $orderItemIds
        }
      ) {
        purchaseOrder {
          id
        }
      }
    }
  `,
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      products {
        products {
          id
          code
          productName
          sellingUnit
          pricePerCapital
        }
      }
      setting {
        deliveryAddresses {
          address
          addressType
          branchName
          companyName
          createdAt
          district
          firstName
          gmapUrl
          id
          isMain
          lastName
          ownerType
          phoneNumber
          province
          remark
          subDistrict
          taxId
          taxPayerType
          updatedAt
          zipCode
        }
      }
    }
  `,
  FETCH_OPTIONS: gql`
    query FETCH_OPTIONS {
      options {
        sellerWithOutPoOptions {
          value
          label
        }
      }
    }
  `,
  FETCH_SELLER: gql`
    query FETCH_SELLER($id: ID!) {
      seller(id: $id) {
        id
        code
        enName
        thName
        note
        averageDeliveryDate
        billingAddress {
          address
          addressType
          branchName
          companyName
          createdAt
          district
          firstName
          gmapUrl
          id
          isMain
          lastName
          ownerType
          phoneNumber
          province
          remark
          subDistrict
          taxId
          taxPayerType
          updatedAt
          zipCode
        }
        orderItemsWithoutPo {
          id
          productName
          sellingUnit
          costPricePerUnit
          sellingPricePerUnit
          cuttingPrice
          totalPrice
          amount
          productCode
          orderSlug
          orderCode
        }
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({}),
  withFormik({
    mapPropsToValues: () => ({
      orderItems: [],
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useHandleSubmit,
      useMutation,
      useQuery,
      useLazyQuery,
      useCallback,
      useEffect,
    } = hooks;
    const { setFieldValue, values } = props;

    const configs = useQuery(API.FETCH_CONFIG, {
      fetchPolicy: "network-only",
    });

    const options = useQuery(API.FETCH_OPTIONS, {
      fetchPolicy: "network-only",
    });

    const [fetchSeller, { data: sellerData, loading, error }] = useLazyQuery(
      API.FETCH_SELLER,
      {
        fetchPolicy: "network-only",
      }
    );

    const [createPurchaseOrder] = useMutation(API.CREATE_PURCHASE_ORDER);

    const handleOnSelect = useCallback(
      async (id) => {
        if (id) {
          try {
            await fetchSeller({
              variables: { id },
            });
          } catch (err) {
            console.error("Error fetching seller data:", err);
          }
        }
      },
      [fetchSeller]
    );

    useEffect(() => {
      if (sellerData) {
        setFieldValue("orderItems", sellerData.seller.orderItemsWithoutPo);
      }
    }, [sellerData, loading, error]);

    const sellerIdOptions = useMemo(() => {
      const deliveryAddressId = find(configs.data?.setting?.deliveryAddresses, {
        isMain: "true",
      })?.id;
      setFieldValue("deliveryAddressId", deliveryAddressId);

      return cleanTypename(options?.data?.options?.sellerWithOutPoOptions);
    }, [configs.data, setFieldValue, options?.data]);

    const moreOptions = useMemo(() => {
      const temp = {};
      return temp;
    }, []);

    const title = `สร้างใบสั่งสินค้า`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.purchaseOrdersPath(), label: "ใบสั่งสินค้า" },
        { path: null, label: title },
      ];
    }, [title]);

    useHandleSubmit(
      async (values) => {
        const filteredOrderItems = values?.orderItems
          .filter((item) => item.confirmed)
          .map((item) => item.id);

        const validate = (values) => {
          if (!values.sellerId) {
            return "โปรดเลือกผู้ขาย";
          }
          if (!values.deliveryAddressId) {
            return "โปรดเลือกที่อยู่จัดส่ง";
          }
          if (filteredOrderItems.length < 1) {
            return "กรุณาเลือกรายการสินค้าอย่างน้อย 1 รายการ";
          }
        };

        const errorMessage = validate(values);
        if (errorMessage) {
          notifyError(errorMessage);
          return;
        }

        const handleCreateOrder = async (values) => {
          try {
            const { orderItems, deliveryAddressId, ...rest } = values;

            await createPurchaseOrder({
              variables: {
                ...rest,
                deliveryAddressId,
                orderItemIds: filteredOrderItems,
              },
            });
            appStore.state.configSidebarRefetch();
            paths.purchaseOrdersPath().push();
            notifySuccess("เพิ่มข้อมูลสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        };

        await handleCreateOrder(values);
      },
      [createPurchaseOrder, configs]
    );

    const selectSeller = useCallback(async () => {
      Modal.open({
        title: "เลือกผู้ขาย",
        children: (
          <Field
            component={Select}
            name="sellerId"
            title="รหัส/ชื่อ/ชื่อเล่น"
            label="รหัส/ชื่อ"
            options={sellerIdOptions}
            helperText="เลือกผู้ขายที่ต้องการสร้างใบสั่งสินค้า"
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onOk: async ({ close, values }) => {
          try {
            const validate = (values) => {
              if (!values.sellerId) {
                return "กรุณาระบุผู้ขาย";
              }
            };

            const errorMessage = validate(values);
            if (errorMessage) {
              notifyError(errorMessage);
              return;
            }
            handleOnSelect(values.sellerId);
            setFieldValue("sellerId", values.sellerId);
            close();
          } catch (e) {
            console.log(e);
            notifyError(e);
          }
        },
      });
    }, [sellerIdOptions, setFieldValue, fetchSeller]);

    useEffect(() => {
      selectSeller();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options?.data]);

    const changeSeller = useCallback(async () => {
      Modal.open({
        title: "เปลี่ยนผู้ขาย",
        children: (
          <Field
            component={Select}
            name="sellerId"
            title="รหัส/ชื่อ/ชื่อเล่น"
            label="รหัส/ชื่อ"
            options={sellerIdOptions}
            mt={6}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onOk: async ({ close, values }) => {
          try {
            const validate = (values) => {
              if (!values.sellerId) {
                return "กรุณาระบุผู้ขาย";
              }
            };

            const errorMessage = validate(values);
            if (errorMessage) {
              notifyError(errorMessage);
              return;
            }
            // const sellers = configs.data?.sellers;
            // const orderItems =
            //   find(sellers, { id: values.sellerId }).orderItemsWithoutPo ?? [];
            handleOnSelect(values.sellerId);
            setFieldValue("sellerId", values.sellerId);
            // setFieldValue("orderItems", orderItems);
            close();
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [sellerIdOptions, setFieldValue, fetchSeller]);

    const hasSeller = values.sellerId;

    const sellerInfo = find(configs.data?.sellers, { id: values.sellerId });

    const billingAddressInfo = useMemo(() => {
      return sellerData?.seller?.billingAddress ?? {};
    }, [sellerInfo, sellerData, loading, error]);

    const deliveryAddressIdOptions = useMemo(() => {
      return [
        ...map(configs.data?.setting?.deliveryAddresses, (address) => {
          return {
            label: address.isMain ? "ใช้ที่อยู่หลัก" : getFullAddress(address),
            value: address.id,
          };
        }),
      ].filter((option) => option.label);
    }, [configs]);

    const deliveryAddressInfo = useMemo(
      () =>
        find(configs.data?.setting?.deliveryAddresses, {
          id: values.deliveryAddressId,
        }),
      [values.deliveryAddressId, configs]
    );

    const orderItems = props.values?.orderItems;
    const discount = props.values?.discount;
    const products = configs.data?.products.products;
    const {
      totalPrice,
      totalPriceWithDiscount,
      vat,
      finalPrice,
    } = useMemo(() => {
      const items = filter(orderItems, (item) => item.confirmed).map((item) => {
        const sellingUnit =
          { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[item.sellingUnit] ??
          null;
        const pricePerUnit = item.pricePerCapital ?? 0;
        const price = item.totalPrice ?? 0;

        return {
          ...item,
          sellingUnit,
          pricePerUnit,
          price,
        };
      });
      const totalPrice = sumBy(items, "price");
      const totalPriceWithDiscount = totalPrice - (discount ?? 0);
      const vat = totalPriceWithDiscount * 0.07;
      const finalPrice = totalPriceWithDiscount + vat;
      return {
        totalPrice,
        totalPriceWithDiscount,
        vat,
        finalPrice,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderItems, products, discount]);

    const averageDeliveryDate = useMemo(
      () => sellerInfo?.averageDeliveryDate ?? 0,
      [sellerInfo]
    );

    return {
      title,
      breadcrumbs,
      submitButtonLabel: "สร้างใบสั่งสินค้า",
      totalPrice,
      totalPriceWithDiscount,
      vat,
      finalPrice,
      selectSeller,
      changeSeller,
      hasSeller,
      deliveryAddressIdOptions,
      sellerInfo,
      billingAddressInfo,
      deliveryAddressInfo,
      averageDeliveryDate,
      ...moreOptions,
      loading,
    };
  })
);

export default enhancer(OrderNewPage);
