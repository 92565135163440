import { compose, withHooks } from "enhancers";
import PDFContentCard from "./ContentCard";
import styled from "styled-components/macro";

const ContentContainer = styled.div`
  width: 960px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
`;

const PDFContent = (props) => (
  <ContentContainer id="pdf-content">
    {props.data.map((item) => (
      <PDFContentCard data={item} />
    ))}
  </ContentContainer>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    return {
      data: props.data,
    };
  })
);

export default enhancer(PDFContent);
