import { compose, withHooks } from "enhancers";
import { Box, Field, Grid, Paper, Select, Table, Typography } from "components";
import Header from "../Header";
import styled from "styled-components";
import { map, sortBy } from "lodash";
import { OPTIONS } from "pages/main/products/new";

const CustomSelect = styled(Select)`
  background-color: #f6f9fc;

  height: 34px;
  > div {
    > div {
      height: 34px;
      display: flex;
      align-content: center;
    }
  }
  fieldset {
    border: unset;
  }
`;

export const CustomTable = styled(Table)`
  div.MuiDataGrid-columnsContainer {
    display: none;
  }
  .MuiDataGrid-cell {
    border-bottom: 1px solid #f6f9fc !important;
  }
`;
export const sortOptions = [
  { label: "ยอดขาย", value: "sales" },
  { label: "จำนวนคำสั่งซื้อ", value: "amount" },
  { label: "ปริมาณผ้าที่ขาย (Kg)", value: "totalKg" },
  { label: "Carbon", value: "totalCarbonFootprintPerKg" },
  { label: "Water", value: "waterFootprintPerKg" },
];

const Seller = (props) => (
  <Paper width={528} height={400} p={4}>
    <Header title="ผู้ขาย" date={props.date} subTitle={props.subTitle} />
    <Grid container spacing={2} mt={2} mb={8}>
      <Grid item xs={6}>
        <Field
          component={CustomSelect}
          name="sellerCategoryFilter"
          fullWidth
          disableClearable
          options={props.categoryOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={CustomSelect}
          name="sellerSort"
          fullWidth
          disableClearable
          options={sortOptions}
        />
      </Grid>
    </Grid>
    <Box height={10}>
      <CustomTable
        headerHeight={0}
        style={{ minHeight: "0px" }}
        autoHeight
        columns={props.tableColumns}
        pageSize={5}
        rows={props.data ?? []}
      />
    </Box>
  </Paper>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info } = props;
    const { values } = info;
    const { useMemo } = hooks;

    const categoryOptions = useMemo(() => {
      return [
        { label: "ประเภทผ้าทั้งหมด", value: "all" },
        ...OPTIONS.categoryOptions,
      ];
    }, []);

    const data = useMemo(() => {
      const infoData = info[values.sellerCategoryFilter];

      let newData = sortBy(infoData, values.sellerSort).reverse();

      newData = map(newData, (item, index) => {
        return { id: index + 1, ...item };
      });

      return newData;
    }, [info, values.sellerCategoryFilter, values.sellerSort]);

    const tableColumns = useMemo(() => {
      return [
        {
          width: 25,
          field: "id",
          renderCell: (row) => (
            <Box
              borderRadius={80}
              style={{ backgroundColor: "#2296F3" }}
              width={24}
              height={24}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography color="Text/White">{row.value}</Typography>
            </Box>
          ),
        },
        {
          width: 345,
          field: "sellerName",
        },
        { width: 100, type: "currency", field: values.sellerSort ?? "sales" },
      ];
    }, [values.sellerSort]);

    return { ...props, ...info, data, categoryOptions, tableColumns };
  })
);

export default enhancer(Seller);
