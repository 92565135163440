import React from "react";
import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, Modal } from "components";
import { gql, notifyError, notifySuccess, paths } from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import { ReactComponent as MailIcon } from "assets/icon/mail.svg";

const SellerIndexPage = (props) => (
  <PageContent
    title="ผู้ขาย"
    breadcrumbs={[
      { path: paths.homePath(), label: "หน้าแรก" },
      { path: null, label: "ผู้ขาย" },
    ]}
    pageActions={[
      {
        children: "ส่งเมลเชิญผู้ขาย",
        startIcon: <MailIcon />,
        onClick: () => paths.sellerEmailInvitationPath().push(),
        variant: "outlined",
        color: "primary",
      },
      {
        children: "เพิ่มผู้ขาย",
        startIcon: <AddIcon />,
        onClick: () => paths.sellerNewPath().push(),
        color: "primary",
      },
    ]}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายชื่อผู้ขาย
      </Typography>
      <Table
        columns={[
          { width: 125, field: "code", headerName: "รหัส" },
          { width: 200, field: "enName", headerName: "ชื่อ" },
          {
            width: 200,
            field: "industryTypes",
            headerName: "ประเภทบริษัท",
            type: "tags",
            options: [
              { label: "Textile", value: "textile" },
              { label: "Garment", value: "garment" },
              { label: "Trader", value: "trader" },
              { label: "Brand Owner", value: "brand_owner" },
            ],
          },
          {
            width: 170,
            field: "phoneNumber1",
            headerName: "เบอร์โทรศัพท์",
            type: "phoneNumber",
          },
          {
            width: 150,
            field: "secondaryContact",
            headerName: "ช่องทางสำรอง",
            type: "social",
          },
          {
            width: 160,
            field: "lastActiveAt",
            headerName: "แก้ไขล่าสุด",
            type: "dateTime",
          },
          {
            width: 55,
            field: "actions",
            headerName: " ",
            filterable: false,
            sortable: false,
            type: "actions",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.sellerEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
        includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_SELLERS: gql`
    query FETCH_SELLERS {
      sellers {
        id
        code
        email
        facebook
        ig
        note
        phoneNumber1
        phoneNumber2
        secondaryChannel
        line
        analysisInfo
        contactName
        deliveryCondition
        financialInfo
        thName
        enName
        tags
        industryTypes
        productTypes
        paymentCondition
        sellerTier
        sellingCondition
        adminId
        lastActiveAt
        bankAccount {
          bankAccountName
          bankAccountNumber
          bankName
          branchName
        }
        billingAddress {
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          isMain
          lastName
          ownerType
          phoneNumber
          province
          subDistrict
          taxPayerType
          zipCode
          taxId
          remark
        }
        deliveryAddresses {
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          isMain
          lastName
          ownerType
          phoneNumber
          province
          subDistrict
          taxPayerType
          zipCode
          taxId
          remark
          isMain
        }
        relatedDocuments
      }
    }
  `,
  DELETE_SELLERS: gql`
    mutation DELETE_SELLERS($ids: [String!]!) {
      deleteSellers(input: { ids: $ids }) {
        sellers {
          id
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useQuery, useMutation, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_SELLERS);
    const [deleteSellers] = useMutation(API.DELETE_SELLERS);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const deleteSeller = useCallback(
      async (params) => {
        const { id, code } = params.row;

        Modal.open({
          title: "ลบผู้ขาย",
          children: `การดำเนินการนี้จะทำให้ผู้ขาย ${code} ไม่สามารถใช้งานในระบบได้อีก`,
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ลบ",
          onOk: async ({ close }) => {
            try {
              await deleteSellers({ variables: { ids: [id] } });
              await refetch();
              close();
              notifySuccess("ดำเนินการลบผู้ขาย 1 คน");
            } catch (e) {
              notifyError(e);
            }
          },
        });
      },
      [deleteSellers, refetch]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.sellers.map((seller) => {
        const { secondaryChannel, ...rest } = seller;

        return {
          ...rest,
          secondaryContact: {
            channel: secondaryChannel,
            info: seller[secondaryChannel],
          },
          actions: [{ Icon: TrashIcon, onClick: deleteSeller }],
        };
      });
    }, [loading, data, error, deleteSeller]);

    return {
      tableData,
      loading,
    };
  })
);

export default enhancer(SellerIndexPage);
