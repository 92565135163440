import { compose, withHooks } from "enhancers";
import { Box, Typography, Divider } from "components";

import SellerInfoCard from "./SellerInfoCard";

const OrderBuyerInfo = (props) => (
  <Box mt={props.mt}>
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="h4" color="Text/Black">
          ผู้ขาย
        </Typography>
      </Box>
    </Box>
    <SellerInfoCard info={props.seller} />
    <Divider mt={6} />
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { mt, seller } = props;

    return {
      mt,
      seller,
    };
  })
);

export default enhancer(OrderBuyerInfo);
