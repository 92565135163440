import { compose, withHooks } from "enhancers";
import { Box, Paper, ProgressBar } from "components";
import Header from "../Header";

const SalesAndCost = (props) => (
  <Paper width={344} height={192} p={4} mt={props.mt}>
    <Header title={props.title} date={props.date} subTitle={props.subTitle} />
    <Box mt={7}>
      <ProgressBar
        variant="determinate"
        successValue={props.product}
        remainingValue={props.delivery}
        firstTextCaption="ค่าผ้า"
        secondTextCaption="ค่าส่ง"
        value={props.productPrecent}
        successPercentage={props.productPrecent ?? 0}
        remainingPercentage={props.deliveryPrecent ?? 0}
      />
    </Box>
  </Paper>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info } = props;

    return { ...info, ...props };
  })
);

export default enhancer(SalesAndCost);
