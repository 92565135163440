import { compose, withHooks } from "enhancers";
import { Box, Paper, Typography } from "components";
import { Bar } from "react-chartjs-2";
import Header from "./Header";

const BusinessProfit = (props) => (
  <Paper width={712} height={408} p={4}>
    <Header
      title="ผลประกอบการ"
      date={props.date}
      totalSales={props.totalSales}
      totalSalesPercent={props.totalSalesPercent}
      totalCost={props.totalCost}
      totalCostPercent={props.totalCostPercent}
      totalProfit={props.totalProfit}
      totalProfitPrecent={props.totalProfitPercent}
    />
    <Box mt={6} height={260}>
      <Bar data={props.data} options={props.options} />
    </Box>
    <Box display="flex" justifyContent="flex-end" mt={3}>
      <Typography variant="caption" color="Text/Light Grey">
        *อัตราการเปลี่ยนแปลงเปรียบเทียบกับช่วงเวลาเดียวกันในปี {props.startYear}
      </Typography>
    </Box>
  </Paper>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { overviewInfo } = props;

    const data = {
      labels: overviewInfo.labels ?? [],
      datasets: [
        {
          type: "line",
          label: "กำไรสุทธิ",
          data: overviewInfo.profits ?? [],
          borderColor: "#4CAF4F",
          backgroundColor: "#4CAF4F",
        },
        {
          type: "bar",
          label: "ยอดขาย",
          data: overviewInfo.sales ?? [],
          backgroundColor: "#5483D4",
          borderRadius: 4,
        },
        {
          type: "bar",
          label: "ต้นทุน",
          data: overviewInfo.costs ?? [],
          backgroundColor: "#E0E0E0",
          borderRadius: 4,
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      plugins: { legend: { display: false } },
      legend: {
        labels: {
          font: {
            family:
              'Noto Sans Thai,Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            size: 20,
          },
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: "บาท",
            color: "#767676",
            font:
              'Noto Sans Thai,Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
          },
        },
        yAxes: [
          {
            gridLines: {},
            stacked: false,
            ticks: {
              fontSize: 10,
              fontFamily:
                'Noto Sans Thai,Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            },
          },
        ],
        xAxes: [
          {
            color: "#5483D4",
            ticks: {
              fontSize: 10,
              fontFamily: "Noto Sans Thai",
            },
          },
        ],
      },
    };
    return { data, options, ...overviewInfo };
  })
);

export default enhancer(BusinessProfit);
