import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography } from "components";
import { gql, paths } from "utils/helper";

const OrderIndexPage = (props) => (
  <PageContent
    title="คำสั่งซื้อ"
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายชื่อคำสั่งซื้อ
      </Typography>
      <Table
        columns={props.tableColumns}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.orderEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
        autoDetectInitialFilterOnUrlQueryParams
        includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_ORDERS: gql`
    query FETCH_ORDERS {
      orders {
        id
        code
        oldSystemInvoiceCode
        buyerName
        state
        lastActiveAt
        deliveryDate
        finalPrice
        stateName
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useQuery, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_ORDERS, {
      fetchPolicy: "network-only",
    });

    useEffect(() => {
      refetch();
    }, [refetch]);

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: "คำสั่งซื้อ" },
      ];
    }, []);

    const pageActions = useMemo(() => {
      return [
        {
          children: "เพิ่มคำสั่งซื้อ",
          startIcon: "add",
          onClick: () => paths.orderNewPath().push(),
          color: "primary",
        },
      ];
    }, []);

    const tableColumns = useMemo(() => {
      return [
        {
          width: 10,
          field: "notify",
          type: "warningStatus",
          headerName: " ",
        },
        { width: 150, field: "code", headerName: "รหัส" },
        {
          width: 185,
          field: "oldSystemInvoiceCode",
          type: "caption",
          headerName: "รหัสใบแจ้งหนี้",
        },
        { width: 190, field: "buyerName", headerName: "ชื่อผู้ซื้อ" },
        {
          width: 155,
          field: "finalPrice",
          headerName: "มูลค่า (บาท)",
          type: "currency",
        },
        { width: 170, field: "stateName", headerName: "สถานะ" },
        {
          width: 160,
          field: "lastActiveAt",
          headerName: "อัพเดทล่าสุด",
          type: "dateTime",
        },
      ];
    }, []);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.orders.map((order) => {
        const { deliveryDate, ...rest } = order;
        return {
          ...rest,
          notify: !!deliveryDate,
        };
      });
    }, [loading, data, error]);

    return {
      tableData,
      loading,
      tableColumns,
      breadcrumbs,
      pageActions,
    };
  })
);

export default enhancer(OrderIndexPage);
