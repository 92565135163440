import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Button,
  Divider,
  FieldArray,
  Hidden,
} from "components";
import { toCurrency, notifyError, paths, notifySuccess } from "utils/helper";
import { filter, find, sumBy } from "lodash";

import OrderItemList from "./OrderItemList";
import { useParams } from "react-router";

const OrderItemsInfo = (props) => (
  <Box mt={props.mt}>
    <Box>
      <Typography id="product_section" variant="h4" color="Text/Black">
        รายการสินค้า
      </Typography>
      <Typography color="Text/Dark Grey" mt={2}>
        รายการสินค้าที่ซื้อและค่าใช้จ่ายเพิ่มเติม
      </Typography>
    </Box>

    <FieldArray
      component={OrderItemList}
      name="orderItems"
      products={props.products || [1, 2, 3]}
      mt={6}
      readOnly={true}
      disabled={props.disabledForm}
      deliveryTransactions={props.deliveryTransactions}
    />

    <Box mt={6} mr={props.orderItemListReadOnly ? 8 : 14}>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height={32}
      >
        <Typography textAlign="right">ราคาขายรวม</Typography>
        <Typography textAlign="right" width={120} ml={3} pr={3}>
          {toCurrency(props.totalPrice)}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height={32}
      >
        <Typography textAlign="right">ภาษีมูลค่าเพิ่ม 7%</Typography>
        <Typography textAlign="right" width={120} ml={3} pr={3}>
          {toCurrency(props.vat)}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height={32}
      >
        <Typography textAlign="right">
          <b>ยอดรวมทั้งหมด</b>
        </Typography>
        <Typography textAlign="right" width={120} ml={3} pr={3}>
          {toCurrency(props.finalPrice)}
        </Typography>
      </Box>
    </Box>
    <Hidden when={props.created}>
      <Divider mt={6} />
      <Box display="flex" mt={6}>
        <Button
          color="primary"
          mr={6}
          disabled={props.disabledForm}
          onClick={props.handleCreateOrder}
        >
          สร้างคำสั่งซื้อ
        </Button>
      </Box>
    </Hidden>
  </Box>
);

const API = {};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useMutation, useCallback } = hooks;
    const {
      mt,
      orderItems,
      discount,
      products,
      handleSubmit,
      disabledSubmitButton,
      canEditOrderItemsInfo,
      created,
      deliveryTransactions,
      handleCreateOrder,
      disableCreate,
    } = props;

    const deliveryDateHelperText = "";
    const deliveryTimeOptions = [];
    const deliveryAddressIdOptions = [];
    const isCustomDeliveryAddress = null;
    const deliveryAddressInfo = {};
    const buyerInfo = {};

    const { id } = useParams();

    const {
      totalPrice,
      totalPriceWithDiscount,
      vat,
      finalPrice,
    } = useMemo(() => {
      const items = filter(orderItems, (item) => item.productId).map((item) => {
        const product = item.product;

        const sellingUnit = product
          ? { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[product.sellingUnit]
          : null;

        const { amount, cuttingPrice, sellingPricePerUnit } = item;
        const price =
          (amount ?? 0) * (sellingPricePerUnit ?? 0) + (cuttingPrice ?? 0);

        return {
          ...item,
          sellingUnit,
          price,
        };
      });
      const totalShippingCost = sumBy(deliveryTransactions, "cost");
      const totalPrice = sumBy(items, "price") + totalShippingCost;
      const totalPriceWithDiscount = totalPrice - (discount ?? 0);
      const vat = totalPriceWithDiscount * 0.07;
      const finalPrice = totalPriceWithDiscount + vat;

      return {
        totalPrice,
        totalPriceWithDiscount,
        vat,
        finalPrice,
      };
    }, [orderItems, discount, deliveryTransactions]);

    const orderItemListReadOnly = !canEditOrderItemsInfo;

    const disabledForm = disableCreate;

    return {
      mt,
      deliveryDateHelperText,
      deliveryTimeOptions,
      deliveryAddressIdOptions,
      isCustomDeliveryAddress,
      deliveryAddressInfo,
      buyerInfo,
      discount,
      totalPrice,
      totalPriceWithDiscount,
      vat,
      finalPrice,
      handleSubmit,
      disabledSubmitButton,
      orderItemListReadOnly,
      disabledForm,
      deliveryTransactions,
      handleCreateOrder,
      created,
    };
  })
);

export default enhancer(OrderItemsInfo);
