import { compose, withHooks } from "enhancers";
import { concat, filter, find, isEmpty, sortBy } from "lodash";
import { CreateOrderCheckListDeliveryOrder } from "../new";

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      orderItemsOfOrder,
      deliveryTransactions,
      deliveryTransaction,
    } = props;
    const { useEffect, useFormikContext, useMemo } = hooks;
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
      let orderItemsOfdeliveries = [];

      deliveryTransactions.forEach((deliveryTransaction) => {
        orderItemsOfdeliveries = concat(
          orderItemsOfdeliveries,
          deliveryTransaction.orderItems
        );
      });

      const orderItemsOfdelivery = deliveryTransaction.orderItems.map(
        (item) => {
          return { ...item, confirmed: true };
        }
      );

      const orderItems = filter(orderItemsOfOrder, (itemOfOrder) =>
        isEmpty(find(orderItemsOfdeliveries, ["id", itemOfOrder.id]))
      ).map((item) => {
        return { ...item, confirmed: false };
      });

      setFieldValue(
        "deliveryAddress",
        deliveryTransaction.deliveryAddress ?? {}
      );
      setFieldValue("channel", deliveryTransaction.channel ?? "");
      setFieldValue("trackingNo", deliveryTransaction.trackingNo ?? "");
      setFieldValue("cost", deliveryTransaction.cost ?? "");
      setFieldValue(
        "orderItems",
        sortBy(concat(orderItems, orderItemsOfdelivery), "productCode") ?? []
      );
    }, [
      setFieldValue,
      deliveryTransactions,
      orderItemsOfOrder,
      deliveryTransaction,
    ]);

    const channelOptions = useMemo(() => {
      const costOptions = [
        {
          label: "Best Express",
          value: "Best Express",
          cost: null,
          trackingNo: "",
        },
        {
          label: "Lalamove",
          value: "Lalamove",
          cost: null,
          trackingNo: "",
        },
        {
          label: "Grab",
          value: "Grab",
          cost: null,
          trackingNo: "",
        },
        {
          label: "ThaiPost",
          value: "ThaiPost",
          cost: null,
          trackingNo: "",
        },
        {
          label: "Kerry",
          value: "Kerry",
          cost: null,
          trackingNo: "",
        },
        {
          label: "Flash Express",
          value: "Flash Express",
          cost: null,
          trackingNo: "",
        },
        {
          label: "J&T",
          value: "J&T",
          cost: null,
          trackingNo: "",
        },
        {
          label: "รับสินค้าเอง",
          value: "รับสินค้าเอง",
          cost: 0,
          trackingNo: "-",
        },
      ];

      const prevOption = find(costOptions, {
        label: deliveryTransaction.channel,
      });
      if (prevOption) {
        prevOption.cost = deliveryTransaction.cost;
        prevOption.trackingNo = deliveryTransaction.trackingNo;
      }

      return costOptions;
    }, [deliveryTransaction]);

    useEffect(() => {
      const channel = find(channelOptions, ["value", values.channel]);
      setFieldValue("cost", channel?.cost);
      setFieldValue("trackingNo", channel?.trackingNo);
    }, [values.channel, setFieldValue, channelOptions]);

    return { channelOptions };
  })
);

export default enhancer(CreateOrderCheckListDeliveryOrder);
