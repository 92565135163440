import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography } from "components";
import { gql, paths } from "utils/helper";

const DraftOrderIndexPage = (props) => (
  <PageContent
    title="ร่างคำสั่งซื้อ"
    breadcrumbs={[
      { path: paths.homePath(), label: "หน้าแรก" },
      { path: null, label: "ร่างคำสั่งซื้อ" },
    ]}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการร่างคำสั่งซื้อ
      </Typography>
      <Table
        columns={[
          { width: 135, field: "shopifyOrderId", headerName: "รหัส" },
          {
            width: 185,
            field: "orderCode",
            headerName: "รหัสคำสั่งซื้อ",
          },
          { width: 190, field: "buyerName", headerName: "ชื่อผู้ซื้อ" },
          {
            width: 155,
            field: "cost",
            headerName: "มูลค่า (บาท)",
            type: "currency",
          },
          { width: 170, field: "stateName", headerName: "สถานะ" },
          {
            width: 160,
            field: "createdAt",
            headerName: "อัพเดทล่าสุด",
            type: "date",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.draftOrderDetailPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
        autoDetectInitialFilterOnUrlQueryParams
        includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_ORDERS: gql`
    query FETCH_ORDERS {
      draftOrders {
        id
        shopifyOrderId
        buyerName
        cost
        stateName
        createdAt
        order {
          code
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useQuery, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_ORDERS);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.draftOrders.map((draftOrder) => {
        return {
          ...draftOrder,
          orderCode: draftOrder?.order?.code,
        };
      });
    }, [loading, data, error]);

    return {
      tableData,
      loading,
    };
  })
);

export default enhancer(DraftOrderIndexPage);
