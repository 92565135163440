import { compose, withHooks } from "enhancers";
import { Box, Typography, Button, Modal } from "components";
import {
  formatDate,
  notifyError,
  notifySuccess,
  gql,
  forceDownload,
} from "utils/helper";
import { ReactComponent as CheckCircleOutlineIcon } from "assets/icon/check_circle_outline.svg";
import ConfirmSummaryOrder from "./ConfirmInvoicingOrder";
import { ReactComponent as DownloadIcon } from "assets/icon/download-blue-icon.svg";

const OrderEditCheckListInvoicingOrder = (props) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
    <Box>
      <Typography color="Text/Black">สรุปราคาให้ผู้ซื้อ</Typography>
      <Box display="flex" mt={2}>
        {!props.invoicingOrder?.disabled && (
          <>
            <Typography
              variant="caption"
              color="Text/Dark Grey"
              style={{ width: "68px" }}
            >
              ใบแจ้งหนี้
            </Typography>
            <Box
              display="flex"
              onClick={props.downloadInvoicingPDF}
              mr={2}
              style={{ cursor: "pointer" }}
            >
              <Typography
                variant="caption"
                color="Other/Info"
                mr={2}
                style={{
                  textDecoration: "underline",
                }}
              >
                {`INV${props.invoicingOrder.code ?? ""}.pdf`}
              </Typography>
              <DownloadIcon />
            </Box>
          </>
        )}
      </Box>
    </Box>

    {props.invoicingOrder?.completedAt ? (
      <Box
        display="flex"
        height="fit-content"
        minWidth={200}
        justifyContent="flex-end"
        alignItems="center"
        mr={9}
      >
        <CheckCircleOutlineIcon />
        <Typography variant="caption" color="Text/Dark Grey" ml={2} width={154}>
          {formatDate(
            props.invoicingOrder?.completedAt,
            "สำเร็จเมื่อ dd/MM/yyyy, HH:mm"
          )}
        </Typography>
      </Box>
    ) : (
      <Button
        onClick={props.openConfirmQuotationModal}
        variant="outlined"
        color="primary"
        disabled={props.invoicingOrder?.disabled}
        mr={9}
      >
        ดำเนินการ
      </Button>
    )}
  </Box>
);

const API = {
  CONFIRM_INVOICING_ORDER: gql`
    mutation CONFIRM_INVOICING_ORDER($id: ID!) {
      confirmInvoicingOrder(input: { id: $id }) {
        order {
          id
        }
      }
    }
  `,
  DOWNLOAD_INVOICING_ORDER: gql`
    mutation DOWNLOAD_INVOICING_ORDER($id: ID!) {
      downloadInvoicingOrder(input: { id: $id }) {
        url
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { invoicingOrder, reloadOrder } = props;
    const { useParams, useCallback, useMutation } = hooks;
    const { id } = useParams();

    const [confirmInvoicingOrder] = useMutation(API.CONFIRM_INVOICING_ORDER);
    const [downloadInvoicingOrder] = useMutation(API.DOWNLOAD_INVOICING_ORDER);

    const downloadInvoicingPDF = useCallback(async () => {
      const { data } = await downloadInvoicingOrder({
        variables: {
          id,
        },
      });

      const url = data.downloadInvoicingOrder.url;
      window.open(url);

      if (url) {
        const loadFile = async () => {
          try {
            const blob = await fetch(url).then((r) => r.blob());
            const file = new File([blob], `INV${invoicingOrder.code}.pdf`, {
              type: blob.type,
            });

            if (file) {
              const url = URL.createObjectURL(file);
              forceDownload(url, `INV${invoicingOrder.code}.pdf`);
            }
          } catch (e) {
            notifyError(e);
          }
        };

        loadFile();
      } else {
        alert("Coming Soon!");
      }
    }, [id, downloadInvoicingOrder, invoicingOrder.code]);

    const openConfirmQuotationModal = useCallback(() => {
      Modal.open({
        className: "ConfirmOrderQuotationInfoModal",
        title: "สรุปราคาให้ผู้ซื้อ",
        children: (
          <ConfirmSummaryOrder
            orderDeliveryDate={invoicingOrder.deliveryDate ?? ""}
            orderDeliveryTime={invoicingOrder.deliveryTime ?? ""}
            orderItems={invoicingOrder.orderItemsOfOrder ?? []}
            deliveryTransactions={invoicingOrder.deliveryTransactions ?? []}
            discount={invoicingOrder.discount ?? 0}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onOk: async ({ close, values }) => {
          try {
            await confirmInvoicingOrder({
              variables: {
                id,
              },
            });
            await reloadOrder();
            close();
            notifySuccess("ดำเนินการสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [id, invoicingOrder, confirmInvoicingOrder, reloadOrder]);
    return { openConfirmQuotationModal, invoicingOrder, downloadInvoicingPDF };
  })
);

export default enhancer(OrderEditCheckListInvoicingOrder);
