import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography } from "components";
import { gql, paths } from "utils/helper";
import { sumBy } from "lodash";

const PurchaseOrderIndexPage = (props) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการใบสั่งสินค้า
      </Typography>
      <Table
        columns={[
          { width: 150, field: "code", headerName: "รหัส" },
          {
            width: 180,
            field: "oldSystemPurchaseOrderCode",
            headerName: "รหัสใบสั่งสินค้า",
            type: "caption",
          },
          { width: 263, field: "sellerName", headerName: "ชื่อผู้ขาย" },
          {
            width: 155,
            field: "finalPrice",
            headerName: "มูลค่า (บาท)",
            type: "currency",
            headerAlign: "right",
          },
          { width: 140, field: "status", headerName: "สถานะ" },
          {
            width: 160,
            field: "updatedAt",
            headerName: "อัพเดทล่าสุด",
            type: "dateTime",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.purchaseOrderEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
        autoDetectInitialFilterOnUrlQueryParams
        includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_PURCHASE_ORDERS: gql`
    query FETCH_PURCHASE_ORDERS {
      purchaseOrders {
        id
        code
        oldSystemPurchaseOrderCode
        finalPrice
        sellerName
        status
        updatedAt
      }
    }
  `,
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      orderItemsWithoutPo {
        id
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useQuery, useMemo, useEffect } = hooks;
    const {
      loading,
      data,
      error,
      refetch,
    } = useQuery(API.FETCH_PURCHASE_ORDERS, { fetchPolicy: "network-only" });

    const configs = useQuery(API.FETCH_CONFIG);

    useEffect(() => {
      refetch();
      configs.refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch, configs.refetch]);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.purchaseOrders.map((order) => {
        const { ...rest } = order;
        return {
          ...rest,
        };
      });
    }, [loading, data, error]);

    const title = `ใบสั่งสินค้า`;

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: title },
      ];
    }, [title]);
    const orderItemWithoutPoCount = useMemo(() => {
      return configs.data?.orderItemsWithoutPo.length ?? 0;
    }, [configs.data]);

    const pageActions = useMemo(() => {
      return [
        {
          children: `สร้างใบสั่งสินค้า (${orderItemWithoutPoCount})`,
          startIcon: "add",
          onClick: () => paths.purchaseOrderNewPath().push(),
          disabled: orderItemWithoutPoCount === 0,
          color: "primary",
        },
      ];
    }, [orderItemWithoutPoCount]);

    return {
      title,
      breadcrumbs,
      tableData,
      pageActions,
      orderItemWithoutPoCount,
      loading: loading || configs.loading,
    };
  })
);

export default enhancer(PurchaseOrderIndexPage);
