import { compose, withHooks } from "enhancers";
import { Box, Typography, Button, DownloadFileLink, Modal } from "components";
import {
  formatDate,
  notifyError,
  notifySuccess,
  gql,
  forceDownload,
} from "utils/helper";
import { ReactComponent as CheckCircleOutlineIcon } from "assets/icon/check_circle_outline.svg";
import ConfirmSummaryOrder from "./ConfirmPaymentOrder";
import { map } from "lodash";
import { ReactComponent as DownloadIcon } from "assets/icon/download-blue-icon.svg";

const OrderCheckListPaymentOrder = (props) => (
  <Box display="flex" justifyContent="space-between" py={2}>
    <Box>
      <Typography color="Text/Black">รับเงินค่าสินค้าจากผู้ซื้อ</Typography>
      <Box display="flex" mt={2}>
        {props.paymentOrder?.completedAt && (
          <>
            <Typography
              variant="caption"
              color="Text/Dark Grey"
              style={{ width: "68px" }}
            >
              หลักฐาน
            </Typography>
            {map(props.paymentOrder?.paymentDocuments, (paymentDocument) => (
              <DownloadFileLink
                filename={`${paymentDocument.filename ?? ""}`}
                url={paymentDocument.url}
              />
            ))}
          </>
        )}
      </Box>
      <Box display="flex" mt={2}>
        {props.paymentOrder?.completedAt && (
          <>
            <Typography
              variant="caption"
              color="Text/Dark Grey"
              style={{ width: "68px" }}
            >
              ใบเสร็จ
            </Typography>
            <Box
              display="flex"
              onClick={props.downloadPaySlipPDF}
              mr={2}
              style={{ cursor: "pointer" }}
            >
              <Typography
                variant="caption"
                color="Other/Info"
                mr={2}
                style={{
                  textDecoration: "underline",
                }}
              >
                {`REC${props.paymentOrder.code ?? ""}.pdf`}
              </Typography>
              <DownloadIcon />
            </Box>
          </>
        )}
      </Box>
    </Box>

    {props.paymentOrder?.completedAt ? (
      <Box
        display="flex"
        height="fit-content"
        minWidth={200}
        justifyContent="flex-end"
        alignItems="center"
        mr={9}
      >
        <CheckCircleOutlineIcon />
        <Typography variant="caption" color="Text/Dark Grey" ml={2} width={154}>
          {formatDate(
            props.paymentOrder?.completedAt,
            "สำเร็จเมื่อ dd/MM/yyyy, HH:mm"
          )}
        </Typography>
      </Box>
    ) : (
      <Button
        onClick={props.openConfirmQuotationModal}
        variant="outlined"
        color="primary"
        disabled={props.paymentOrder?.disabled}
        mr={9}
      >
        ดำเนินการ
      </Button>
    )}
  </Box>
);

const API = {
  CONFIRM_PAYMENT_ORDER: gql`
    mutation CONFIRM_PAYMENT_ORDER($id: ID!, $paymentDocuments: [Upload!]) {
      confirmPaymentOrder(
        input: { id: $id, paymentDocuments: $paymentDocuments }
      ) {
        order {
          id
        }
      }
    }
  `,
  DOWNLOAD_PAY_SLIP_ORDER: gql`
    mutation DOWNLOAD_PAY_SLIP_ORDER($id: ID!) {
      downloadPaySlipOrder(input: { id: $id }) {
        url
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { paymentOrder, reloadOrder } = props;
    const { useParams, useCallback, useMutation } = hooks;
    const { id } = useParams();

    const [confirmPaymentOrder] = useMutation(API.CONFIRM_PAYMENT_ORDER);
    const [downloadPaySlipOrder] = useMutation(API.DOWNLOAD_PAY_SLIP_ORDER);

    const downloadPaySlipPDF = useCallback(async () => {
      const { data } = await downloadPaySlipOrder({
        variables: {
          id,
        },
      });

      const url = data.downloadPaySlipOrder.url;
      window.open(url);

      if (url) {
        const loadFile = async () => {
          try {
            const blob = await fetch(url).then((r) => r.blob());
            const file = new File([blob], `REC${paymentOrder.code}.pdf`, {
              type: blob.type,
            });

            if (file) {
              const url = URL.createObjectURL(file);
              forceDownload(url, `REC${paymentOrder.code}.pdf`);
            }
          } catch (e) {
            notifyError(e);
          }
        };

        loadFile();
      } else {
        alert("Coming Soon!");
      }
    }, [id, downloadPaySlipOrder, paymentOrder.code]);

    const openConfirmQuotationModal = useCallback(() => {
      Modal.open({
        className: "ConfirmOrderQuotationInfoModal",
        title: "รับเงินค่าสินค้าจากผู้ซื้อ",
        children: (
          <ConfirmSummaryOrder
            orderDeliveryDate={paymentOrder.deliveryDate ?? ""}
            orderDeliveryTime={paymentOrder.deliveryTime ?? ""}
            orderItems={paymentOrder.orderItemsOfOrder ?? []}
            deliveryTransactions={paymentOrder.deliveryTransactions ?? []}
            discount={paymentOrder.discount ?? 0}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onOk: async ({ close, values }) => {
          try {
            await confirmPaymentOrder({
              variables: {
                ...values,
                id,
              },
            });
            await reloadOrder();
            close();
            notifySuccess("ดำเนินการสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [id, paymentOrder, reloadOrder, confirmPaymentOrder]);
    return { openConfirmQuotationModal, paymentOrder, downloadPaySlipPDF };
  })
);

export default enhancer(OrderCheckListPaymentOrder);
