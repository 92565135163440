import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Button,
  Divider,
  Field,
  FieldArray,
  TextField,
  Hidden,
} from "components";
import { toCurrency, gql, forceDownload, notifyError } from "utils/helper";
import { filter, find, sumBy } from "lodash";

import OrderItemList from "./OrderItemList";
import { useParams } from "react-router";

const OrderItemsInfo = (props) => (
  <Box mt={props.mt}>
    <Box>
      <Typography id="product_section" variant="h4" color="Text/Black">
        รายการสินค้า
      </Typography>
      <Typography color="Text/Dark Grey" mt={2}>
        รายการสินค้าที่ซื้อและค่าใช้จ่ายเพิ่มเติม
      </Typography>
    </Box>

    <Field
      component={TextField}
      name="oldSystemInvoiceCode"
      label="รหัสใบแจ้งหนี้จากระบบบัญชี"
      width={512}
      mt={6}
      disabled={props.disabledForm}
    />

    <FieldArray
      component={OrderItemList}
      name="orderItems"
      products={props.products || [1, 2, 3]}
      mt={6}
      readOnly={props.orderItemListReadOnly}
      disabled={props.disabledForm}
      deliveryTransactions={props.deliveryTransactions}
    />

    <Box mt={6} mr={props.orderItemListReadOnly ? 8 : 14}>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height={32}
      >
        <Typography textAlign="right">ราคาขายรวม</Typography>
        <Typography textAlign="right" width={120} ml={3} pr={3}>
          {toCurrency(props.totalPrice)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Typography textAlign="right">ส่วนลด</Typography>
        {props.orderItemListReadOnly ? (
          <Typography textAlign="right" width={120} ml={3} pr={3}>
            {toCurrency(props.discount)}
          </Typography>
        ) : (
          <Field
            component={TextField}
            name="discount"
            type="number"
            textAlign="right"
            placeholder="0.00"
            width={120}
            ml={3}
            disabled={props.disabledForm}
          />
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height={32}
      >
        <Typography textAlign="right">ราคาขายหลังหักส่วนลด</Typography>
        <Typography textAlign="right" width={120} ml={3} pr={3}>
          {toCurrency(props.totalPriceWithDiscount)}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height={32}
      >
        <Typography textAlign="right">ภาษีมูลค่าเพิ่ม 7%</Typography>
        <Typography textAlign="right" width={120} ml={3} pr={3}>
          {toCurrency(props.vat)}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height={32}
      >
        <Typography textAlign="right">
          <b>ยอดรวมทั้งหมด</b>
        </Typography>
        <Typography textAlign="right" width={120} ml={3} pr={3}>
          {toCurrency(props.finalPrice)}
        </Typography>
      </Box>
    </Box>

    <Field
      component={TextField}
      name="remark"
      label="หมายเหตุเพิ่มเติม (ข้อความส่วนนี้จะแสดงที่ส่วนท้ายใบแจ้งหนี้และใบเสร็จ)"
      fullWidth
      mt={6}
      disabled={props.disabledForm}
    />

    <Box display="flex" mt={6}>
      <Hidden when={props.disabledForm}>
        <Button
          onClick={props.handleSubmit}
          disabled={props.disabledSubmitButton}
          color="primary"
          mr={6}
        >
          บันทึก
        </Button>
      </Hidden>
      <Button
        variant="outlined"
        color="primary"
        mr={6}
        disabled={!props.canDownloadInvoicePDF}
        onClick={props.downloadInvoicingPDF}
      >
        สร้างใบแจ้งหนี้
      </Button>
      <Button
        variant="outlined"
        color="primary"
        disabled={!props.canDownloadPaySlipPDF}
        onClick={props.downloadPaySlipPDF}
      >
        สร้างใบเสร็จ
      </Button>
    </Box>
    <Divider mt={6} />
  </Box>
);

const API = {
  DOWNLOAD_INVOICING_ORDER: gql`
    mutation DOWNLOAD_INVOICING_ORDER($id: ID!) {
      downloadInvoicingOrder(input: { id: $id }) {
        url
      }
    }
  `,
  DOWNLOAD_PAY_SLIP_ORDER: gql`
    mutation DOWNLOAD_PAY_SLIP_ORDER($id: ID!) {
      downloadPaySlipOrder(input: { id: $id }) {
        url
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useMutation, useCallback } = hooks;
    const {
      mt,
      orderItems,
      discount,
      products,
      handleSubmit,
      disabledSubmitButton,
      canEditOrderItemsInfo,
      canceled,
      canDownloadInvoicePDF,
      canDownloadPaySlipPDF,
      code,
      deliveryTransactions,
    } = props;

    const deliveryDateHelperText = "";
    const deliveryTimeOptions = [];
    const deliveryAddressIdOptions = [];
    const isCustomDeliveryAddress = null;
    const deliveryAddressInfo = {};
    const buyerInfo = {};

    const { id } = useParams();
    const [downloadInvoicingOrder] = useMutation(API.DOWNLOAD_INVOICING_ORDER);
    const [downloadPaySlipOrder] = useMutation(API.DOWNLOAD_PAY_SLIP_ORDER);

    const downloadInvoicingPDF = useCallback(async () => {
      const { data } = await downloadInvoicingOrder({
        variables: {
          id,
        },
      });

      const url = data.downloadInvoicingOrder.url;
      window.open(url);

      if (url) {
        const loadFile = async () => {
          try {
            const blob = await fetch(url).then((r) => r.blob());
            const file = new File([blob], `INV${code}.pdf`, {
              type: blob.type,
            });

            if (file) {
              const url = URL.createObjectURL(file);
              forceDownload(url, `INV${code}.pdf`);
            }
          } catch (e) {
            notifyError(e);
          }
        };

        loadFile();
      } else {
        alert("Coming Soon!");
      }
    }, [id, downloadInvoicingOrder, code]);

    const downloadPaySlipPDF = useCallback(async () => {
      const { data } = await downloadPaySlipOrder({
        variables: {
          id,
        },
      });

      const url = data.downloadPaySlipOrder.url;
      window.open(url);

      if (url) {
        const loadFile = async () => {
          try {
            const blob = await fetch(url).then((r) => r.blob());
            const file = new File([blob], `REC${code}.pdf`, {
              type: blob.type,
            });

            if (file) {
              const url = URL.createObjectURL(file);
              forceDownload(url, `REC${code}.pdf`);
            }
          } catch (e) {
            notifyError(e);
          }
        };

        loadFile();
      } else {
        alert("Coming Soon!");
      }
    }, [id, downloadPaySlipOrder, code]);

    const {
      totalPrice,
      totalPriceWithDiscount,
      vat,
      finalPrice,
    } = useMemo(() => {
      const items = filter(orderItems, (item) => item.productId).map((item) => {
        // const product = find(products, { id: item.productId });
        const product = item.prduct;

        const sellingUnit = product
          ? { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[product.sellingUnit]
          : null;

        const { amount, cuttingPrice, sellingPricePerUnit } = item;
        const price =
          (amount ?? 0) * (sellingPricePerUnit ?? 0) + (cuttingPrice ?? 0);

        return {
          ...item,
          sellingUnit,
          price,
        };
      });
      const totalShippingCost = sumBy(deliveryTransactions, "cost");
      const totalPrice = sumBy(items, "price") + totalShippingCost;
      const totalPriceWithDiscount = totalPrice - (discount ?? 0);
      const vat = totalPriceWithDiscount * 0.07;
      const finalPrice = totalPriceWithDiscount + vat;

      return {
        totalPrice,
        totalPriceWithDiscount,
        vat,
        finalPrice,
      };
    }, [orderItems, products, discount, deliveryTransactions]);

    const orderItemListReadOnly = !canEditOrderItemsInfo;

    const disabledForm = canceled;

    return {
      mt,
      deliveryDateHelperText,
      deliveryTimeOptions,
      deliveryAddressIdOptions,
      isCustomDeliveryAddress,
      deliveryAddressInfo,
      buyerInfo,
      discount,
      totalPrice,
      totalPriceWithDiscount,
      vat,
      finalPrice,
      handleSubmit,
      disabledSubmitButton,
      orderItemListReadOnly,
      disabledForm,
      canDownloadInvoicePDF,
      canDownloadPaySlipPDF,
      downloadInvoicingPDF,
      downloadPaySlipPDF,
      deliveryTransactions,
    };
  })
);

export default enhancer(OrderItemsInfo);
