import { Field, TextField } from "components";
import { compose, withHooks } from "enhancers";

const UpdateStockModal = (props) => (
  <Field
    component={TextField}
    name="totalQuantity"
    type="number"
    label="ปริมาณที่มีทั้งหมด"
    fullWidth
    unit={props.totalQuantityUnit}
  />
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { totalQuantity, totalQuantityUnit } = props;
    const { useFormikContext, useEffect } = hooks;
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      setFieldValue("totalQuantity", totalQuantity);
    }, []);

    return { totalQuantityUnit };
  })
);

export default enhancer(UpdateStockModal);
