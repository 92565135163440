import { compose, withHooks } from "enhancers";
import {
  Box,
  Grid,
  Typography,
  Select,
  BuyerShopifyAddressCard,
  Address,
  Field,
  Hidden,
} from "components";
import { isEqual } from "lodash";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const ConfirmAddress = (props) => (
  <Grid container spacing={4}>
    <Grid item xs={12}>
      <BuyerShopifyAddressCard info={props.buyerDefaultShopifyAddress} />
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h6" bold>
        ที่อยู่ส่งของ
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Field
        component={Select}
        name="deliveryAddressId"
        options={props.modalOptions}
        label="ที่อยู่ที่ใช้ในการส่งของ"
        placeholder="ที่อยู่ที่ใช้ในการส่งของ"
      />
    </Grid>
    <Hidden when={props.notError}>
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          p={4}
          style={{
            background: "#FCECE9",
            borderRadius: 8,
          }}
        >
          <InfoOutlinedIcon
            style={{
              color: "#F34336",
              width: "24px",
              height: "24px",
              marginRight: "8px",
            }}
          />
          <Typography variant="body1" color="#601A15">
            มีข้อมูลที่อยู่นี้ในระบบแล้ว
          </Typography>
        </Box>
      </Grid>
    </Hidden>
    <Grid item xs={12}>
      <Field
        component={Address.Delivery}
        name="deliveryAddress"
        isModal={true}
      />
    </Grid>
  </Grid>
);

const ADD_NEW_ADDRESS = "ADD_NEW_ADDRESS";

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useFormikContext, useEffect, useCallback, useState } = hooks;
    const formikBag = useFormikContext();
    const { deliveryAddress, buyerAllAddresses } = props;

    const [notError, setNotError] = useState(true);

    useEffect(() => {
      formikBag.setValues({
        deliveryAddressId: deliveryAddress?.id,
        deliveryAddress,
      });
    }, [deliveryAddress]);

    useEffect(() => {
      const deliveryAddressId = formikBag?.values?.deliveryAddressId;
      if (
        deliveryAddressId === ADD_NEW_ADDRESS &&
        isAddressAlreadyExist(formikBag?.values?.deliveryAddress)
      ) {
        setNotError(false);
      } else {
        setNotError(true);
      }
    }, [formikBag?.values]);

    useEffect(() => {
      const deliveryAddressId = formikBag?.values?.deliveryAddressId;
      if (deliveryAddressId === ADD_NEW_ADDRESS) {
        formikBag.setFieldValue("deliveryAddress", null);
        formikBag.setFieldValue("deliveryAddress.firstName", "");
        formikBag.setFieldValue("deliveryAddress.lastName", "");
        formikBag.setFieldValue("deliveryAddress.address", "");
        formikBag.setFieldValue("deliveryAddress.remark", "");
      } else {
        const selectedAddress = buyerAllAddresses.find(
          (addr) => addr.id === deliveryAddressId
        );
        formikBag.setFieldValue("deliveryAddress", selectedAddress);
      }
    }, [formikBag?.values.deliveryAddressId]);

    const getCheckFieldAddr = useCallback(
      (values) => {
        const valueAddr = {
          firstName: values?.firstName,
          lastName: values?.lastName,
          district: values?.district,
          province: values?.province,
          address: values?.address,
          phoneNumber: values?.phoneNumber,
          zipCode: values?.zipCode,
        };
        return valueAddr;
      },
      [formikBag?.values, buyerAllAddresses]
    );

    const isAddressAlreadyExist = useCallback(
      (submitValue) => {
        const submitValueAddr = getCheckFieldAddr(submitValue);
        const findExistAddr = buyerAllAddresses.filter((addr) =>
          isEqual(getCheckFieldAddr(addr), submitValueAddr)
        );
        return findExistAddr.length > 0;
      },
      [formikBag?.values, buyerAllAddresses]
    );

    return { ...props, notError };
  })
);

export default enhancer(ConfirmAddress);
