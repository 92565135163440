import { compose, withHooks } from "enhancers";
import { Box, Typography } from "components";
import { isEmpty, map } from "lodash";
import { formatPhoneNumber, formatFullName } from "utils/helper";

const AddressInfo = (props) => (
  <Box style={{ background: "#E7F4FD", borderRadius: 8, padding: 8 }}>
    {map(props.details, (detail, index) => (
      <Box key={index} display="flex" mt={index === 0 ? 0 : 2}>
        <Typography width={140}>{detail.label}</Typography>
        <Typography width={24} mx={1}>
          :
        </Typography>
        <Typography flex={1}>{detail.value}</Typography>
      </Box>
    ))}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info } = props;
    const { useMemo } = hooks;

    const details = useMemo(() => {
      const isBangkok = info.province === "กรุงเทพมหานคร";
      const subDistrictPrefix = isBangkok ? "แขวง" : "ตำบล";
      const districtPrefix = isBangkok ? "เขต" : "อำเภอ";
      const addressDetails = [
        info.address,
        `${
          info.subDistrict ? `${subDistrictPrefix} ${info.subDistrict}` : ""
        } ${info.district ? `${districtPrefix} ${info.district}` : ""}`,
        `${info.province ?? ""} ${info.zipCode ?? ""}`,
      ].filter((detail) => !/^ *$/.test(detail || ""));

      return [
        {
          label: "ชื่อผู้ขาย",
          value: isEmpty(info.firstName, info.lastName)
            ? "-"
            : formatFullName(info.firstName, info.lastName),
        },
        {
          label: "ที่อยู่ออกบิล",
          value: isEmpty(addressDetails)
            ? "-"
            : map(addressDetails, (detail, index) => (
                <Box mt={index === 0 ? 0 : 2}>{detail}</Box>
              )),
        },
        {
          label: "โทรศัพท์",
          value: info.phoneNumber ? formatPhoneNumber(info.phoneNumber) : "-",
        },
        { label: "หมายเหตุ", value: info.remark ?? "-" },
        { label: "เลขประจำตัวผู้เสียภาษี", value: info.taxId ?? "-" },
      ];
    }, [info]);
    return { details };
  })
);

export default enhancer(AddressInfo);
