import { ExpandMore } from "@material-ui/icons";
import { Box, Field, Select, Typography } from "components";
import { compose, withHooks } from "enhancers";
import styled from "styled-components";

const CustomSelect = styled(Select)`
  background-color: #f6f9fc;
  width: 122px;
  height: 34px;
  > div {
    > div {
      height: 34px;
      display: flex;
      align-content: center;
    }
  }
  fieldset {
    border: unset;
  }
`;

const Header = (props) => (
  <Box display="flex" justifyContent="space-between">
    <Box>
      <Typography variant="h4">{props.title}</Typography>
      <Typography variant="caption" color="Text/Dark Grey">
        {props.date}
      </Typography>
    </Box>
    <Box>
      <Field
        name={props.filterName}
        component={CustomSelect}
        options={[
          { label: "ประเภทผ้า", value: "category" },
          { label: "โทนสี", value: "tone" },
        ]}
        disableClearable
        popupIcon={<ExpandMore />}
      />
    </Box>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    return { ...props };
  })
);

export default enhancer(Header);
