import { compose, withHooks, withFormik } from "enhancers";
import {
  Box,
  Field,
  TextField,
  Select,
  Typography,
  Divider,
  Button,
  Modal,
  ConfirmAddress,
  Hidden,
} from "components";
import {
  differenceInDays,
  isValid,
  parseISO,
  startOfToday,
  format,
} from "date-fns";
import { isString, map, isEmpty, isEqual, find } from "lodash";
import {
  gql,
  notifySuccess,
  getErrorMessage,
  notifyError,
  getFullAddress,
  cleanTypename,
  paths,
  formatPhoneNumber,
  formatFullName,
  formatDate,
  toDate,
} from "utils/helper";

import BuyerInfoCard from "./BuyerInfoCard";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const OrderBuyerInfo = (props) => (
  <Box mt={props.mt}>
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="h4" color="Text/Black">
          ผู้ซื้อ
        </Typography>
        <Typography color="Text/Dark Grey" mt={2}>
          ข้อมูลผู้ซื้อและรายละเอียดการจัดส่งสินค้า
        </Typography>
      </Box>
    </Box>
    <Box style={{ background: "#E7F4FD", borderRadius: 8 }} p={2} mt={6} mb={3}>
      <Typography variant="h6" color="Text/Black">
        ข้อมูลเพิ่มเติมจาก Shopify
      </Typography>
      <Box display="flex" mt={2}>
        <Typography width={140}>รหัสคำสั่งซื้อ</Typography>
        <Typography width={24} mx={1}>
          :
        </Typography>
        <Typography flex={1}>{props.shopifyOrderId || "-"}</Typography>
      </Box>
    </Box>
    <Field
      component={TextField}
      name="buyerName"
      type="text"
      label="ชื่อผู้ซื้อ"
      width={512}
      mt={4}
      disabled={props.disabled}
    />
    <Hidden when={props.isAddressNotChanged}>
      <Box
        display="flex"
        alignItems="center"
        style={{
          background: "#FCECE9",
          borderRadius: 8,
        }}
        justifyContent="space-between"
        p={4}
        my={6}
      >
        <Box display="flex">
          <InfoOutlinedIcon
            style={{
              color: "#F34336",
              width: "24px",
              height: "24px",
              marginRight: "8px",
            }}
          />
          <Typography variant="body1" color="#601A15">
            ข้อมูลที่อยู่ส่งของไม่ตรงกับ Shopify กรุณาตรวจสอบ
          </Typography>
        </Box>

        <Button
          mr={6}
          onClick={props.handleManageAddress}
          disabled={props.disabledForm}
        >
          จัดการที่อยู่
        </Button>
      </Box>
    </Hidden>

    <Field
      component={Select}
      name="deliveryAddressId"
      options={props.deliveryAddressIdOptions}
      label="ที่อยู่ที่ใช้ในการส่งของ"
      placeholder="ที่อยู่ที่ใช้ในการส่งของ"
      width={512}
      mt={4}
      disabled={props.disabled}
    />
    <BuyerInfoCard info={props.buyerInfo} />
    <Divider mt={6} />
  </Box>
);

const API = {
  UPDATE_BUYER: gql`
    mutation UPDATE_BUYER(
      $id: String!
      $deliveryAddresses: [AddressArguments!]
      $isFromDraftOrder: Boolean
    ) {
      updateBuyerInfo(
        input: {
          id: $id
          deliveryAddresses: $deliveryAddresses
          isFromDraftOrder: $isFromDraftOrder
        }
      ) {
        buyer {
          id
          birthdate
          brand
          code
          email
          facebook
          firstName
          ig
          lastName
          nickName
          note
          phoneNumber1
          phoneNumber2
          secondaryChannel
          slug
          createdAt
          updatedAt
          line
          configs
          lastActiveAt
          canDelete
          billingAddress {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
          }
          deliveryAddresses {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
            isMain
          }
          documents
        }
      }
    }
  `,
};

const ADD_NEW_ADDRESS = "ADD_NEW_ADDRESS";

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useMutation } = hooks;
    const {
      mt,
      buyerInfo,
      deliveryAddressIdOptions,
      deliveryDate,
      handleSubmit,
      disabledSubmitButton,
      canEditBuyerInfo,
      setValues,
      values,
      refetchOrder,
      disableCreate,
    } = props;

    const [updateBuyer] = useMutation(API.UPDATE_BUYER);

    const disabledForm = disableCreate;

    const isAddressNotChanged = useMemo(() => {
      const bfAddr = {
        firstName: buyerInfo?.deliveryAddress?.firstName,
        lastName: buyerInfo?.deliveryAddress?.lastName,
        district: buyerInfo?.deliveryAddress?.district, //city
        province: buyerInfo?.deliveryAddress?.province,
        address: buyerInfo?.deliveryAddress?.address,
        phoneNumber: buyerInfo?.deliveryAddress?.phoneNumber,
        zipCode: buyerInfo?.deliveryAddress?.zipCode,
      };

      const shopifyAddr = {
        firstName: buyerInfo?.defaultShopifyAddress?.firstName,
        lastName: buyerInfo?.defaultShopifyAddress?.lastName,
        district: buyerInfo?.defaultShopifyAddress?.city, //city
        province: buyerInfo?.defaultShopifyAddress?.province,
        address: buyerInfo?.defaultShopifyAddress?.address1,
        phoneNumber: buyerInfo?.defaultShopifyAddress?.phone,
        zipCode: buyerInfo?.defaultShopifyAddress?.zip,
      };
      const isAddressNotChanged = isEqual(bfAddr, shopifyAddr);

      return isAddressNotChanged;
    }, [buyerInfo]);

    const deliveryDateHelperText = useMemo(() => {
      const date = isString(deliveryDate)
        ? parseISO(deliveryDate)
        : deliveryDate;

      if (isValid(date)) {
        return (
          <Typography
            variant="body1"
            color="Other/Info"
            ml={-3.5}
          >{`*อีก ${differenceInDays(date, startOfToday())} วัน`}</Typography>
        );
      }
    }, [deliveryDate]);

    const readOnly = !canEditBuyerInfo;

    const buyerDefaultShopifyAddress = useMemo(() => {
      if (!isEmpty(buyerInfo?.defaultShopifyAddress)) {
        const defaultShopifyAddress = buyerInfo?.defaultShopifyAddress;
        return {
          addresses: [
            `${defaultShopifyAddress.firstName || ""} ${
              defaultShopifyAddress.lastName || ""
            }`,
            defaultShopifyAddress.address1,
            defaultShopifyAddress.city,
            defaultShopifyAddress.province || defaultShopifyAddress.zip
              ? `${defaultShopifyAddress.province} ${defaultShopifyAddress.zip}`
              : "",
          ],
          phone: defaultShopifyAddress.phone
            ? formatPhoneNumber(defaultShopifyAddress.phone)
            : "-",
          updatedAt: format(
            parseISO(defaultShopifyAddress.updatedAt),
            "dd/MM/yyyy, HH:mm"
          ),
        };
      }
      return {};
    }, [buyerInfo]);

    const getCheckFieldAddr = useCallback(
      (values) => {
        const valueAddr = {
          firstName: values?.firstName,
          lastName: values?.lastName,
          district: values?.district,
          province: values?.province,
          address: values?.address,
          phoneNumber: values?.phoneNumber,
          zipCode: values?.zipCode,
        };
        return valueAddr;
      },
      [values, buyerInfo]
    );

    const isAddressAlreadyExist = useCallback(
      (submitValue) => {
        const submitValueAddr = getCheckFieldAddr(submitValue);
        const findExistAddr = buyerInfo?.deliveryAddresses.filter((addr) =>
          isEqual(getCheckFieldAddr(addr), submitValueAddr)
        );
        return findExistAddr.length > 0;
      },
      [values, buyerInfo]
    );

    const handleManageAddress = useCallback(async () => {
      Modal.open({
        className: "sync-address-modal",
        title: "จัดการที่อยู่",
        children: (
          <ConfirmAddress
            deliveryAddress={
              !isEmpty(values)
                ? values.deliveryAddress
                : buyerInfo?.deliveryAddress
            }
            buyerDefaultShopifyAddress={buyerDefaultShopifyAddress}
            modalOptions={deliveryAddressIdOptions}
            buyerAllAddresses={buyerInfo?.deliveryAddresses}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "บันทึก",
        onOk: async ({ close, values }) => {
          const validate = (values) => {
            if (!values.deliveryAddressId) {
              return "กรุณาเลือกที่อยู่ที่ใช้ในการส่งของ";
            }
          };

          const errorMessage = validate(values);
          if (errorMessage) {
            notifyError(errorMessage);
            return;
          }

          try {
            const { deliveryAddressId, deliveryAddress } = values;
            if (deliveryAddressId === ADD_NEW_ADDRESS) {
              if (!isAddressAlreadyExist(deliveryAddress)) {
                const oldAddr = buyerInfo?.deliveryAddresses;

                const updatedOldAddr = oldAddr.map((obj) => ({
                  ...obj,
                  isMain: null,
                }));

                const newDeliveryAddresses = {
                  ...deliveryAddress,
                  id: null,
                  isMain: "true",
                };

                const addressAgruments = updatedOldAddr.concat([
                  newDeliveryAddresses,
                ]);

                await updateBuyer({
                  variables: {
                    id: buyerInfo.id,
                    deliveryAddresses: addressAgruments,
                    isFromDraftOrder: true,
                  },
                });

                await refetchOrder();
                close();
                notifySuccess("เพิ่มข้อมูลสำเร็จ");
              } else {
                notifyError("มีข้อมูลนี้อยู่แล้ว");
              }
            } else {
              const oldAddr = buyerInfo?.deliveryAddresses.filter(
                (addr) => addr.id != deliveryAddressId
              );

              const updatedOldAddr = oldAddr.map((obj) => ({
                ...obj,
                isMain: null,
              }));

              const selectDeliveryAddresses = {
                ...deliveryAddress,
                id: deliveryAddressId,
                isMain: "true",
              };

              const addressAgruments = updatedOldAddr.concat([
                selectDeliveryAddresses,
              ]);

              await updateBuyer({
                variables: {
                  id: buyerInfo.id,
                  deliveryAddresses: addressAgruments,
                  isFromDraftOrder: true,
                },
              });
              await refetchOrder();
              close();
              notifySuccess("แก้ไขข้อมูลสำเร็จ");
            }
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [buyerInfo, values, setValues, buyerDefaultShopifyAddress]);

    return {
      mt,
      deliveryDateHelperText,
      deliveryAddressIdOptions,
      buyerInfo,
      handleSubmit,
      disabledSubmitButton,
      disabled: true,
      readOnly,
      shopifyOrderId: buyerInfo.shopifyOrderId,
      buyerNameCode: buyerInfo.buyerNameCode,
      handleManageAddress,
      buyerDefaultShopifyAddress,
      isAddressNotChanged,
      disabledForm,
    };
  })
);

export default enhancer(OrderBuyerInfo);
