import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Divider,
  Field,
  FieldArray,
  TextField,
  Button,
} from "components";
import { API } from "../OrderCheckList/PurchaseOrderCheckListOrder/ConfirmPurchaseOrder";
import OrderItemList from "../../../new/OrderItemList";
import { forceDownload, notifyError } from "utils/helper";

const OrderItemsInfo = (props) => (
  <Box mt={props.mt}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h4" color="Text/Black">
        รายการสินค้า
      </Typography>
      <Button onClick={props.downloadPoPDF} disabled={props.disabledForm}>
        ดาวน์โหลดใบสั่งสินค้า
      </Button>
    </Box>
    <Field
      component={TextField}
      name="oldSystemPurchaseOrderCode"
      label="รหัสใบแจ้งหนี้จากระบบบัญชี"
      width={512}
      mt={6}
      disabled={props.disabledForm}
    />

    <FieldArray
      component={OrderItemList}
      name="orderItems"
      products={props.products || [1, 2, 3]}
      mt={6}
      readOnly={props.orderItemListReadOnly}
      disabled={props.disabledForm}
    />

    <Divider mt={6} />
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { mt, products, canEditOrderItemsInfo, canceled, code } = props;
    const { useCallback, useMutation, useParams } = hooks;
    const orderItemListReadOnly = !canEditOrderItemsInfo;
    const disabledForm = canceled;

    const { id } = useParams();
    const [downloadPdf] = useMutation(API.DOWNLOAD_PDF);

    const downloadPoPDF = useCallback(async () => {
      const { data } = await downloadPdf({
        variables: {
          id,
        },
      });

      const url = data.downloadPdf.url;
      window.open(url);

      if (url) {
        const loadFile = async () => {
          try {
            const blob = await fetch(url).then((r) => r.blob());
            const file = new File([blob], `${code ?? ""}.pdf`, {
              type: blob.type,
            });

            if (file) {
              const url = URL.createObjectURL(file);
              forceDownload(url, `${code ?? ""}.pdf`);
            }
          } catch (e) {
            notifyError(e);
          }
        };

        loadFile();
      } else {
        alert("Coming Soon!");
      }
    }, [id, downloadPdf, code]);

    return {
      mt,
      disabledForm,
      products,
      orderItemListReadOnly,
      downloadPoPDF,
    };
  })
);

export default enhancer(OrderItemsInfo);
