import { compose, withHooks } from "enhancers";
import { Box, Typography, Modal } from "components";
import { notifyError, notifySuccess, toCurrency, gql } from "utils/helper";
import EditDeliveryTransactionModal from "./edit";
import { filter } from "lodash";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import { ReactComponent as EditIcon } from "assets/icon/edit.svg";

const DeliveryItem = (props) => (
  <Box display="flex" justifyContent="space-between" mb={2}>
    <Box width={396}>
      {props.orderItemsOfdelivery.map((orderItem, index) => (
        <Box
          key={index}
          display="flex"
          justifyContent="space-between"
          align="center"
          mt={2}
        >
          <Box display="flex" align="center">
            <Typography
              variant="caption"
              color="Text/Dark Grey"
              mx={2}
              mt={-0.25}
            >
              •
            </Typography>
            <Typography variant="caption" color="Text/Dark Grey">
              {orderItem.productName}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mx={2}>
            <Typography variant="caption" color="Text/Dark Grey">
              {toCurrency(orderItem.amount)}
            </Typography>
            <Typography
              variant="caption"
              color="Text/Dark Grey"
              width={44}
              textAlign="left"
              ml={2}
            >
              {
                { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[
                  orderItem.sellingUnit
                ]
              }
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
    <Box mr={9} mt={2}>
      {!props.invoicingOrderCompletedAt && (
        <>
          <EditIcon onClick={props.openEditDeliveryTransactionModal} />
          <TrashIcon
            style={{ marginLeft: "29px" }}
            onClick={props.openDeleteDeliveryTransactionModal}
          />
        </>
      )}
    </Box>
  </Box>
);

const API = {
  UPDATE_DELIVERY_TRANSACTION: gql`
    mutation UPDATE_DELIVERY_TRANSACTION(
      $id: ID!
      $deliveryAddress: AddressArguments!
      $cost: Float
      $channel: String
      $trackingNo: String
      $orderItemIds: [ID!]!
    ) {
      updateDeliveryTransaction(
        input: {
          id: $id
          deliveryAddress: $deliveryAddress
          cost: $cost
          channel: $channel
          trackingNo: $trackingNo
          orderItemIds: $orderItemIds
        }
      ) {
        order {
          id
        }
      }
    }
  `,
  DELETE_DELIVERY_TRANSACTION: gql`
    mutation DELETE_DELIVERY_TRANSACTION($id: ID!) {
      deleteDeliveryTransaction(input: { id: $id }) {
        order {
          id
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      deliveryTransactions,
      deliveryTransaction,
      orderItemsOfOrder,
      reloadOrder,
      invoicingOrderCompletedAt,
    } = props;
    const { useCallback, useMemo, useMutation } = hooks;

    const [updateDeliveryTransaction] = useMutation(
      API.UPDATE_DELIVERY_TRANSACTION
    );

    const [deleteDeliveryTransaction] = useMutation(
      API.DELETE_DELIVERY_TRANSACTION
    );

    const openEditDeliveryTransactionModal = useCallback(() => {
      Modal.open({
        className: "ConfirmOrderQuotationInfoModal",
        title: "ส่งสินค้าให้ผู้ซื้อ",
        children: (
          <EditDeliveryTransactionModal
            orderItemsOfOrder={orderItemsOfOrder ?? []}
            deliveryTransactions={deliveryTransactions ?? []}
            deliveryTransaction={deliveryTransaction ?? {}}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onOk: async ({ close, values }) => {
          try {
            const orderItemIds = filter(values.orderItems, [
              "confirmed",
              true,
            ]).map((item) => item.id);
            const validate = (values) => {
              if (!values.channel) {
                return "โปรดเลือกช่องทางการส่งสินค้า";
              }
              if (!values.cost && values.cost !== 0) {
                return "โปรดระบุค่าส่ง";
              }
              if (!values.trackingNo) {
                return "โปรดระบุหมายเลขเพื่อติดตามสินค้า";
              }
              if (orderItemIds.length === 0) {
                return "โปรดเลือกสินค้าที่ส่งอย่างน้อย 1 ชิ้น";
              }
            };

            const errorMessage = validate(values);
            if (errorMessage) {
              notifyError(errorMessage);
              return;
            }
            await updateDeliveryTransaction({
              variables: {
                ...values,
                orderItemIds,
                id: deliveryTransaction.id,
              },
            });
            await reloadOrder();
            close();
            notifySuccess("ดำเนินการสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [
      deliveryTransactions,
      deliveryTransaction,
      orderItemsOfOrder,
      updateDeliveryTransaction,
      reloadOrder,
    ]);

    const openDeleteDeliveryTransactionModal = useCallback(() => {
      Modal.open({
        title: "ลบรายการส่งสินค้า",
        children: `ยืนยันการลบ ${deliveryTransaction.trackingNo} - ${deliveryTransaction.channel}`,
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onOk: async ({ close, values }) => {
          try {
            await deleteDeliveryTransaction({
              variables: { id: deliveryTransaction.id },
            });
            await reloadOrder();
            close();
            notifySuccess("ดำเนินการสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [deliveryTransaction, deleteDeliveryTransaction, reloadOrder]);

    const orderItemsOfdelivery = useMemo(
      () => deliveryTransaction.orderItems ?? [],
      [deliveryTransaction]
    );
    return {
      openEditDeliveryTransactionModal,
      openDeleteDeliveryTransactionModal,
      orderItemsOfdelivery,
      invoicingOrderCompletedAt,
    };
  })
);

export default enhancer(DeliveryItem);
