import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Button,
  Modal,
  Hidden,
  DownloadFileLink,
} from "components";
import {
  notifySuccess,
  notifyError,
  formatDate,
  forceDownload,
} from "utils/helper";
import ConfirmPurchaseOrder from "./ConfirmPurchaseOrder";
import { API } from "./ConfirmPurchaseOrder";

import { ReactComponent as CheckCircleOutlineIcon } from "assets/icon/check_circle_outline.svg";
import { Field } from "formik";
import SwitchInput from "components/common/SwitchInput";
import PurchaseOrderSummaryCard from "./PurchaseOrderSummaryCard";

const OrderEditCheckListConfirmOrder = (props) => (
  <Box
    display="flex"
    justifyContent="space-between"
    py={2}
    alignItems={props.info?.orderedAt ? undefined : "center"}
  >
    <Box>
      <Typography color="Text/Black">ส่งใบสั่งสินค้าให้ผู้ขาย</Typography>

      {!props.info?.canceled && props.info?.orderedAt && (
        <Box display="flex" mt={2}>
          <Typography
            variant="caption"
            color="Text/Dark Grey"
            style={{ width: "68px" }}
          >
            ใบสั่งสินค้า
          </Typography>
          <DownloadFileLink
            filename={`${props.info?.code ?? ""}.pdf`}
            download={props.downloadPoPDF}
          />
        </Box>
      )}

      <Hidden when={!props.info?.orderedAt}>
        <Box display="flex" mt={2}>
          <PurchaseOrderSummaryCard info={props.info} />
        </Box>
      </Hidden>
    </Box>

    {props.info?.orderedAt ? (
      <Box
        display="flex"
        height="fit-content"
        minWidth={200}
        justifyContent="flex-end"
        alignItems="center"
      >
        <CheckCircleOutlineIcon />
        <Typography variant="caption" color="Text/Dark Grey" ml={2} width={154}>
          {formatDate(props.info?.orderedAt, "สำเร็จเมื่อ dd/MM/yyyy, HH:mm")}
        </Typography>
      </Box>
    ) : (
      <Box>
        <Field
          component={SwitchInput}
          name="hasVat"
          label="รวม VAT"
          disabled={props.info?.canceled}
        />
        <Button
          onClick={props.openConfirmQuotationModal}
          variant="outlined"
          color="primary"
          disabled={props.info?.orderedAt || props.info?.canceled}
        >
          ดำเนินการ
        </Button>
      </Box>
    )}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { poCode, info, reloadPurchaseOrder } = props;
    const { useParams, useCallback, useMutation } = hooks;

    const { id } = useParams();
    const [confirmPurchaseOrder] = useMutation(API.CONFIRM_PURCHASE_ORDER);
    const [downloadPdf] = useMutation(API.DOWNLOAD_PDF);

    const openConfirmQuotationModal = useCallback(() => {
      Modal.open({
        className: "ConfirmOrderQuotationInfoModal",
        title: "ส่งใบสั่งสินค้าให้ผู้ขาย",
        children: (
          <ConfirmPurchaseOrder
            orderDeliveryDate={info.orderDeliveryDate ?? ""}
            orderDeliveryTime={info.orderDeliveryTime ?? ""}
            orderItems={info.orderItems ?? []}
            hasVat={info.hasVat}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onOk: async ({ close, values }) => {
          try {
            await confirmPurchaseOrder({
              variables: {
                ...values,
                id,
                hasVat: info.hasVat,
              },
            });
            await reloadPurchaseOrder();
            close();
            notifySuccess("ดำเนินการสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [info, confirmPurchaseOrder, reloadPurchaseOrder, id]);

    const downloadPoPDF = useCallback(async () => {
      const { data } = await downloadPdf({
        variables: {
          id,
        },
      });

      const url = data.downloadPdf.url;
      window.open(url);

      if (url) {
        const loadFile = async () => {
          try {
            const blob = await fetch(url).then((r) => r.blob());
            const file = new File([blob], `${props.info?.code ?? ""}.pdf`, {
              type: blob.type,
            });

            if (file) {
              const url = URL.createObjectURL(file);
              forceDownload(url, `${props.info?.code ?? ""}.pdf`);
            }
          } catch (e) {
            notifyError(e);
          }
        };

        loadFile();
      } else {
        alert("Coming Soon!");
      }
    }, [id, downloadPdf, props.info]);

    return {
      openConfirmQuotationModal,
      info,
      poCode,
      downloadPoPDF,
    };
  })
);

export default enhancer(OrderEditCheckListConfirmOrder);
