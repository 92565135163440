import "./wdyr";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import "./mocks";

import { Provider } from "react-redux";

import { ApiProvider } from "api";

import { HelmetProvider, Helmet } from "react-helmet-async";
import DateFnsUtils from "@date-io/date-fns";

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createTheme from "theme";

import { THEMES } from "./constants";

import {
  BrowserRouter,
  Modal,
  Notification,
  ExcelGenerator,
  ImageLightbox,
  Backdrop,
} from "components";
import store from "stores";

import Routes from "routes/Routes";

import { SnackbarProvider } from "notistack";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

const theme = createTheme(THEMES.DEFAULT);

ReactDOM.render(
  <Provider store={store}>
    <ApiProvider>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Moreloop Admin" defaultTitle="Welcome" />
        <StylesProvider jss={jss}>
          <SnackbarProvider maxSnack={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                  <BrowserRouter>
                    <Routes />
                    <Backdrop storeName="appStore.loadingBackdrop" />
                    <Modal storeName="appStore.mainModal" />
                    <Modal storeName="appStore.alertModal" />
                    <Modal storeName="appStore.confirmModal" />
                    <ImageLightbox storeName="appStore.imageLightbox" />
                    <Notification />
                    <ExcelGenerator storeName="appStore.excelGenerator" />
                  </BrowserRouter>
                </ThemeProvider>
              </MuiThemeProvider>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </StylesProvider>
      </HelmetProvider>
    </ApiProvider>
  </Provider>,
  document.getElementById("root")
);
