import React from "react";
import { compose, withHooks } from "enhancers";
import { Box, Button, BrowseButton, Field, ImageLightbox } from "components";
import ImageUpload from "./ImageUpload";
import { get, range, map } from "lodash";

const ImageUploadList = (props) => (
  <Box {...props}>
    <Box display="flex">
      {range(props.amount).map((index) => {
        return (
          <Box key={index} mt={6} mr={6}>
            <Box onClick={() => props.openImageLightbox(index)}>
              <Field component={ImageUpload} name={`${props.name}[${index}]`} />
            </Box>
            {!props.readonly && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={2.5}
              >
                {props.label ? props.label(index) : null}
                <Button
                  startIcon="bigTrash"
                  style={{ minWidth: 32, paddingLeft: 0, paddingRight: 0 }}
                  onClick={() => props.removeImage(index)}
                />
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
    {!props.readonly && (
      <BrowseButton
        startIcon="add"
        onBrowse={props.addImage}
        mt={6}
        width={171}
        accept="image/*"
        disabled={!props.canAddMore}
      >
        {props.addButtonLabel}
      </BrowseButton>
    )}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      form,
      name,
      insert,
      remove,
      title,
      addButtonLabel,
      mt,
      label,
      onRemoveImage,
      max,
      readonly,
    } = props;
    const { useCallback } = hooks;
    const values = get(form.values, name);
    const amount = values?.length ?? 0;

    const canAddMore = amount < max;
    const addImage = useCallback(
      (file) => {
        if (canAddMore) {
          insert(amount, file);
        }
      },
      [insert, amount, canAddMore]
    );

    const removeImage = useCallback(
      (index) => {
        remove(index);
        if (onRemoveImage) {
          onRemoveImage(index, props);
        }
      },
      [remove, onRemoveImage, props]
    );

    const openImageLightbox = useCallback(
      (index) => {
        const images = map(values, (file, index) => {
          return {
            url: file instanceof File ? URL.createObjectURL(file) : null,
            title: `รูปที่ ${index + 1}`,
          };
        });
        ImageLightbox.open({
          images,
          startIndex: index,
        });
      },
      [values]
    );

    return {
      name,
      amount,
      addImage,
      removeImage,
      title,
      addButtonLabel,
      mt,
      label,
      canAddMore,
      openImageLightbox,
      readonly,
    };
  })
);

export default enhancer(ImageUploadList);
