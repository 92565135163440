import { compose, withHooks, withFormik, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import {} from "components";
import {
  gql,
  paths,
  cleanTypename,
  notifySuccess,
  notifyError,
  getErrorMessage,
} from "utils/helper";
import { SellerForm } from "./new";
import { compact, uniq, flatten, map } from "lodash";

const SellerEditPage = (props) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <SellerForm {...props} />
  </PageContent>
);

export const API = {
  FETCH_SELLER: gql`
    query FETCH_SELLER($id: ID!) {
      seller(id: $id) {
        id
        code
        email
        facebook
        ig
        note
        phoneNumber1
        phoneNumber2
        secondaryChannel
        line
        analysisInfo
        contactName
        deliveryCondition
        financialInfo
        thName
        enName
        tags
        industryTypes
        productTypes
        paymentCondition
        sellerTier
        sellingCondition
        adminId
        bankAccount {
          bankAccountName
          bankAccountNumber
          bankName
          branchName
        }
        billingAddress {
          id
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          isMain
          lastName
          ownerType
          phoneNumber
          province
          subDistrict
          taxPayerType
          zipCode
          taxId
          remark
        }
        deliveryAddresses {
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          isMain
          lastName
          ownerType
          phoneNumber
          province
          subDistrict
          taxPayerType
          zipCode
          taxId
          remark
          isMain
        }
        relatedDocuments
      }
    }
  `,
  UPDATE_SELLER: gql`
    mutation UPDATE_SELLER(
      $id: ID!
      $email: String
      $facebook: String
      $ig: String
      $note: String
      $phoneNumber1: String
      $phoneNumber2: String
      $secondaryChannel: String
      $line: String
      $billingAddress: AddressArguments
      $deliveryAddresses: [AddressArguments!]
      $analysisInfo: String
      $contactName: String
      $deliveryCondition: String
      $financialInfo: String
      $thName: String
      $enName: String
      $tags: [String!]
      $industryTypes: [String!]
      $productTypes: [String!]
      $paymentCondition: String
      $sellerTier: String
      $sellingCondition: String
      $bankAccount: BankAccountArguments
      $relatedDocuments: [Upload!]
      $adminId: String
    ) {
      updateSellerInfo(
        input: {
          id: $id
          email: $email
          facebook: $facebook
          ig: $ig
          note: $note
          phoneNumber1: $phoneNumber1
          phoneNumber2: $phoneNumber2
          secondaryChannel: $secondaryChannel
          line: $line
          billingAddress: $billingAddress
          deliveryAddresses: $deliveryAddresses
          analysisInfo: $analysisInfo
          contactName: $contactName
          deliveryCondition: $deliveryCondition
          financialInfo: $financialInfo
          thName: $thName
          enName: $enName
          tags: $tags
          industryTypes: $industryTypes
          productTypes: $productTypes
          paymentCondition: $paymentCondition
          sellerTier: $sellerTier
          sellingCondition: $sellingCondition
          bankAccount: $bankAccount
          relatedDocuments: $relatedDocuments
          adminId: $adminId
        }
      ) {
        seller {
          id
          email
          facebook
          ig
          note
          phoneNumber1
          phoneNumber2
          secondaryChannel
          line
          analysisInfo
          contactName
          deliveryCondition
          financialInfo
          thName
          enName
          tags
          industryTypes
          productTypes
          paymentCondition
          sellerTier
          sellingCondition
          adminId
          bankAccount {
            bankAccountName
            bankAccountNumber
            bankName
            branchName
          }
          billingAddress {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
          }
          deliveryAddresses {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
            isMain
          }
          relatedDocuments
        }
      }
    }
  `,
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      admins {
        id
        code
        firstName
        lastName
      }
      sellers {
        tags
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({
    industryTypeOptions: [
      { label: "Textile", value: "textile" },
      { label: "Garment", value: "garment" },
      { label: "Trader", value: "trader" },
      { label: "Brand Owner", value: "brand_owner" },
    ],
    productTypeOptions: [
      { label: "Knit", value: "knit" },
      { label: "Woven", value: "woven" },
      { label: "Natural", value: "natural" },
      { label: "Synthetic", value: "synthetic" },
      { label: "Mixed", value: "mixed" },
      { label: "Cotton", value: "cotton" },
      { label: "Linen", value: "linen" },
      { label: "Ramie", value: "ramie" },
      { label: "Bamboo", value: "bamboo" },
      { label: "Wool", value: "wool" },
      { label: "Silk", value: "silk" },
      { label: "Mohair", value: "mohair" },
      { label: "Polyester", value: "polyester" },
      { label: "Nylon", value: "nylon" },
      { label: "Rayon", value: "rayon" },
      { label: "Spandex", value: "spandex" },
      { label: "Jersey", value: "jersey" },
      { label: "Pique", value: "pique" },
      { label: "Interlock", value: "interlock" },
      { label: "Double Knit", value: "bouble_knit" },
      { label: "French Terry", value: "french_terry" },
      { label: "Mesh", value: "mesh" },
      { label: "Twill", value: "twill" },
      { label: "Canvas", value: "canvas" },
      { label: "Denim", value: "denim" },
      { label: "Corduroy", value: "corduroy" },
      { label: "Velvet", value: "velvet" },
      { label: "Satin", value: "satin" },
      { label: "Oxford", value: "oxford" },
      { label: "Plain ", value: "plain" },
      { label: "Pattern", value: "pattern" },
      { label: "Print", value: "print" },
      { label: "Solid", value: "solid" },
      { label: "Yarn Dyed", value: "yarn_dyed" },
      { label: "Home Deco", value: "home_deco" },
      { label: "Fashion", value: "fashion" },
      { label: "Chiffone", value: "chiffone" },
      { label: "Sateen", value: "sateen" },
    ],
    sellerTierOptions: [
      { label: "Tier 1 - Green", value: "tier1" },
      { label: "Tier 2 - Amber", value: "tier2" },
      { label: "Tier 3 - Red", value: "tier3" },
    ],
  }),
  withFormik({
    mapPropsToValues: () => ({
      deliveryAddresses: [{}],
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useHandleSubmit,
      useQuery,
      useMutation,
      useEffect,
      useParams,
    } = hooks;
    const {
      industryTypeOptions,
      productTypeOptions,
      sellerTierOptions,
      setValues,
      setFieldValue,
    } = props;

    const { id } = useParams();

    const configs = useQuery(API.FETCH_CONFIG);
    const { loading, data, error, refetch } = useQuery(API.FETCH_SELLER, {
      variables: { id },
    });
    const [updateSeller] = useMutation(API.UPDATE_SELLER);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const seller = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.sellersPath().push();
        return null;
      }
      return data.seller;
    }, [loading, data, error]);

    useEffect(() => {
      if (seller) {
        const values = cleanTypename(seller);
        setValues(values);
        setFieldValue("sellerTier", values.sellerTier);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValues, seller]);

    const title = seller?.code;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.sellersPath(), label: "ผู้ขาย" },
        { path: null, label: title },
      ];
    }, [title]);

    const admins = configs.data?.admins;
    const adminIdOptions = useMemo(() => {
      const options = admins
        ? admins.map((a) => ({
            label: `${a.code} - ${a.firstName || ""} ${a.lastName || ""}`,
            value: a.id,
          }))
        : [];
      return options;
    }, [admins]);
    const sellers = configs.data?.sellers;
    const tagOptions = useMemo(() => {
      const options = sellers
        ? compact(uniq(flatten(map(sellers, "tags")))).map((tag) => ({
            label: tag,
            value: tag,
          }))
        : [];
      return options;
    }, [sellers]);

    useHandleSubmit(
      async (values) => {
        try {
          await updateSeller({ variables: values });
          paths.sellersPath().push();
          notifySuccess("เพิ่มข้อมูลสำเร็จ");
        } catch (e) {
          notifyError(e);
        }
      },
      [updateSeller]
    );

    const pageActions = useMemo(() => {
      return [
        {
          children: "ประวัติการขาย",
          startIcon: "history",
          onClick: () =>
            paths
              .purchaseOrdersPath({
                sellerName: `${seller.enName}`,
              })
              .push(),
          color: "black",
          variant: "outlined",
        },
      ];
    }, [seller]);

    return {
      title,
      breadcrumbs,
      industryTypeOptions,
      productTypeOptions,
      sellerTierOptions,
      adminIdOptions,
      tagOptions,
      submitButtonLabel: "บันทึก",
      pageActions,
    };
  })
);

export default enhancer(SellerEditPage);
