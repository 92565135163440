import React from "react";
import {
  compose,
  withFormik,
  withHooks,
  defaultProps,
  withStores,
} from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  FieldArray,
  TextField,
  Editor,
  Divider,
  Button,
  Select,
  SwitchInput,
  ImageUploadList,
  RadioGroup,
  PhysicalCode,
} from "components";
import {
  gql,
  notifyError,
  notifySuccess,
  paths,
  toCurrency,
  calculateKgToYard,
  getCarbonFootprintPerKg,
  getWaterFootprintPerKg,
} from "utils/helper";
import {
  find,
  uniq,
  flatten,
  map,
  parseInt,
  isNaN,
  isNumber,
  compact,
} from "lodash";
import { ReactComponent as StoreIcon } from "assets/icon/store.svg";
import { Field as FormikField } from "formik";

export const ProductForm = (props) => (
  <Form>
    <Box display="flex" flexDirection="column">
      {props.isEditPage ? (
        <>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">รูปผลิตภัณฑ์</Typography>
            <Box display="flex" alignItems="center">
              <Typography
                variant="body"
                mr={2.5}
              >{`แก้ไขล่าสุดโดย:`}</Typography>
              <StoreIcon />
              <Typography variant="body" ml={1.5}>
                {props.lastEditedBy}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="caption" mt={2}>
              แนะนำให้ใช้รูป .jpg ขนาดอย่างน้อย 480 x 480px (*shopify)
            </Typography>
            <Typography variant="body">{`เมื่อ: ${props.lastActiveAt}`}</Typography>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="h4">รูปผลิตภัณฑ์</Typography>
          <Typography variant="caption" mt={2}>
            แนะนำให้ใช้รูป .jpg ขนาดอย่างน้อย 480 x 480px (*shopify)
          </Typography>
        </>
      )}

      <FieldArray
        component={ImageUploadList}
        name="images"
        addButtonLabel="เพิ่มรูปผลิตภัณฑ์"
        max={7}
        label={(index) => (
          <Field
            component={RadioGroup}
            name={`highlightImagesIndex`}
            label="ภาพปก"
            options={[{ label: "ภาพปก", value: `${index}` }]}
          />
        )}
        onRemoveImage={(removedIndex, props) => {
          const currentIndex = parseInt(props.form.values.highlightImagesIndex);
          if (!isNaN(currentIndex)) {
            if (removedIndex < currentIndex) {
              props.form.setFieldValue(
                "highlightImagesIndex",
                `${currentIndex - 1}`
              );
            } else if (removedIndex === currentIndex) {
              props.form.setFieldValue("highlightImagesIndex", null);
            }
          }
        }}
      />
    </Box>

    <Typography variant="h4" mt={10}>
      ข้อมูลทั่วไป
    </Typography>
    <Grid container mt={3} spacing={6}>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="legacyCode"
          label="รหัสผลิตภัณฑ์จากระบบเก่า"
          fullWidth
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="productName"
          label="ชื่อผลิตภัณฑ์ (*shopify)"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="category"
          label="ชนิดผ้า (*shopify)"
          fullWidth
          options={props.categoryOptions}
          forceFix
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="composition"
          label="ส่วนประกอบผ้า (*shopify)"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="constructions"
          label="ลักษณะวัสดุ"
          fullWidth
          options={props.constructionOptions}
          multiple
          freeSolo
          forceFix
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="fibers"
          label="เส้นใยที่ใช้"
          fullWidth
          options={props.fiberOptions}
          multiple
          freeSolo
          forceFix
        />
      </Grid>
      <Grid item xs={12}>
        <Divider my={3} />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="yarnCount"
          label="เบอร์เส้นด้าย"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="gsm"
          type="number"
          label="น้ำหนักต่อตารางเมตร"
          fullWidth
          unit="กรัม"
          onBlur={props.recalculateUnitHelperText}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="thickness"
          label="ความหนา (*shopify)"
          fullWidth
          options={props.thicknessOptions}
          forceFix
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="fwWidth"
          type="number"
          label="ความกว้างหน้าผ้า FW (*shopify)"
          fullWidth
          unit="นิ้ว"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="cwWidth"
          type="number"
          label="ความกว้างหน้าผ้า CW (*shopify)"
          fullWidth
          unit="นิ้ว"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="tWidth"
          type="number"
          label="ความกว้างหน้าผ้า T (*shopify)"
          fullWidth
          unit="นิ้ว"
        />
      </Grid>
      <Grid item xs={12}>
        <Divider my={3} />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="physicalSeperations"
          label="Physical Seperation (*shopify)"
          fullWidth
          options={props.physicalSeperationOptions}
          multiple
          freeSolo
          forceFix
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="tone"
          label="โทนสี (*shopify)"
          fullWidth
          options={props.toneOptions}
          forceFix
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="colors"
          label="สี (*shopify)"
          fullWidth
          options={props.colorOptions}
          multiple
          freeSolo
          forceFix
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="sellerColor"
          label="สีที่ผู้ขายใช้เรียก"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="hexColorCode"
          label="รหัสสี (Hex Color Code)"
          fullWidth
          helperText="เช่น 329DAB, 060709"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={Select}
          name="specialFunctions"
          label="คุณสมบัติพิเศษ"
          fullWidth
          options={props.specialFunctionOptions}
          multiple
          freeSolo
          forceFix
        />
      </Grid>
      <Grid item xs={12}>
        <Divider my={3} />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={Select}
          name="suitableForThings"
          label="คำแนะนำในการนำไปใช้"
          fullWidth
          options={props.suitableForThingOptions}
          multiple
          freeSolo
          forceFix
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={Select}
          name="tags"
          label="แท็ก"
          fullWidth
          options={props.tagOptions}
          multiple
          freeSolo
          forceFix
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="video"
          label="วิดีโอ"
          fullWidth
          forceFix
        />
      </Grid>
    </Grid>

    <Typography variant="h4" mt={10} mb={3}>
      มลภาวะจากการผลิต
    </Typography>
    <Grid container mt={3} spacing={6}>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="carbonFootprintType"
          label="ประเภทผ้าเพื่อคำนวณ Carbon footprints (*shopify)"
          fullWidth
          options={props.carbonFootprintTypeOptions}
          forceFix
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="carbonFootprintPerKg"
          type="number"
          label="Carbon footprint ต่อผ้า 1 กิโลกรัม"
          fullWidth
          disabled
          unit="กิโลกรัม"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="waterFootprintPerKg"
          type="number"
          label="Water footprint ต่อผ้า 1 กิโลกรัม"
          fullWidth
          disabled
          unit="ลิตร"
        />
      </Grid>
    </Grid>

    <Typography variant="h4" mt={10} mb={3}>
      ข้อมูลเพื่อซื้อขาย
    </Typography>
    <Grid container mt={3} spacing={6}>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="sellerId"
          label="ผู้ขาย (*shopify)"
          fullWidth
          options={props.sellerIdOptions}
          forceFix
        />
      </Grid>
      <Grid item xs={6}>
        <FormikField component={PhysicalCode} name="physicalCode" />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="sellerProductCode"
          label="รหัสผ้าฝั่งผู้ขาย (*shopify)"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="sellingUnit"
          label="หน่วยในการจัดเก็บ/ขาย (*shopify)"
          fullWidth
          options={props.sellingUnitOptions}
          helperText={props.sellingUnitHelperText}
          forceFix
          onBlur={props.recalculateUnitHelperText}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="costPerCapital"
          type="number"
          label="ราคาทุนต่อหน่วย (*shopify)"
          fullWidth
          unit="บาท"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="pricePerCapital"
          type="number"
          label="ราคาขายต่อหน่วย (*shopify)"
          fullWidth
          helperText="คำนวณจากราคาทุน * 1.25"
          unit="บาท"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="sellingCondition"
          label="เงื่อนไขในการซื้อ"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="defect"
          label="ตำหนิผ้า"
          fullWidth
          forceFix
        />
      </Grid>
    </Grid>

    <Box display="flex" alignItems="center" mt={10}>
      <Typography variant="h4" flex={1}>
        คลังสินค้า
      </Typography>
      <Field component={SwitchInput} name="published" label="แสดงใน Shopify" />
    </Box>
    <Grid container mt={1} spacing={6}>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="totalQuantity"
          type="number"
          label="ปริมาณที่มีทั้งหมด (*shopify)"
          fullWidth
          unit={props.totalQuantityUnit}
        />
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="quantityPerFold"
          label="ปริมาณต่อพับ"
          fullWidth
        />
      </Grid>
    </Grid>

    <Typography variant="h4" mt={10} mb={4}>
      บันทึกเพิ่มเติม
    </Typography>
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Field component={Editor} name="note" fullWidth />
      </Grid>
    </Grid>

    <Divider mt={10} />
    <Button
      type="submit"
      color="primary"
      mt={6}
      disabled={props.disabledSubmitButton}
    >
      {props.submitButtonLabel}
    </Button>
  </Form>
);

const ProductNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <ProductForm {...props} />
  </PageContent>
);

const API = {
  CREATE_PRODUCT: gql`
    mutation CREATE_PRODUCT(
      $images: [Upload!]
      $highlightImagesIndex: String
      $legacyCode: String
      $productName: String
      $category: String
      $composition: String
      $constructions: [String!]
      $fibers: [String!]
      $yarnCount: String
      $gsm: Float
      $thickness: String
      $fwWidth: Float
      $cwWidth: Float
      $tWidth: Float
      $physicalSeperations: [String!]
      $tone: String
      $colors: [String!]
      $sellerColor: String
      $hexColorCode: String
      $specialFunctions: [String!]
      $suitableForThings: [String!]
      $tags: [String!]
      $video: String
      $carbonFootprintType: String
      $carbonFootprintPerKg: Float
      $waterFootprintPerKg: Float
      $sellerId: ID
      $physicalCode: String
      $sellerProductCode: String
      $sellingUnit: String
      $costPerCapital: Float
      $pricePerCapital: Float
      $sellingCondition: String
      $defect: String
      $totalQuantity: Float
      $quantityPerFold: String
      $note: String
      $published: Boolean
      $lastEditedBy: String
    ) {
      createProduct(
        input: {
          images: $images
          highlightImagesIndex: $highlightImagesIndex
          legacyCode: $legacyCode
          productName: $productName
          category: $category
          composition: $composition
          constructions: $constructions
          fibers: $fibers
          yarnCount: $yarnCount
          gsm: $gsm
          thickness: $thickness
          fwWidth: $fwWidth
          cwWidth: $cwWidth
          tWidth: $tWidth
          physicalSeperations: $physicalSeperations
          tone: $tone
          colors: $colors
          sellerColor: $sellerColor
          hexColorCode: $hexColorCode
          specialFunctions: $specialFunctions
          suitableForThings: $suitableForThings
          tags: $tags
          video: $video
          carbonFootprintType: $carbonFootprintType
          carbonFootprintPerKg: $carbonFootprintPerKg
          waterFootprintPerKg: $waterFootprintPerKg
          sellerId: $sellerId
          physicalCode: $physicalCode
          sellerProductCode: $sellerProductCode
          sellingUnit: $sellingUnit
          costPerCapital: $costPerCapital
          pricePerCapital: $pricePerCapital
          sellingCondition: $sellingCondition
          defect: $defect
          totalQuantity: $totalQuantity
          quantityPerFold: $quantityPerFold
          note: $note
          published: $published
          lastEditedBy: $lastEditedBy
        }
      ) {
        product {
          legacyCode
          carbonFootprintPerKg
          carbonFootprintType
          category
          colors
          composition
          constructions
          costPerCapital
          cwWidth
          defect
          fibers
          fwWidth
          gsm
          id
          images
          highlightImagesIndex
          note
          physicalCode
          physicalSeperations
          pricePerCapital
          productName
          quantityPerFold
          sellerColor
          hexColorCode
          sellerId
          sellerProductCode
          sellingCondition
          sellingUnit
          specialFunctions
          suitableForThings
          tags
          video
          tWidth
          thickness
          tone
          totalQuantity
          waterFootprintPerKg
          yarnCount
          published
          lastEditedBy
        }
      }
    }
  `,
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      configs {
        constructionOptions
        fiberOptions
        physicalSeperationOptions
        colorOptions
        specialFunctionOptions
        suitableForThingOptions
        tagOptions
        sellerIdOptions
      }
    }
  `,
};

export const OPTIONS = {
  categoryOptions: [
    { label: "Knit Natural", value: "knit_natural" },
    { label: "Knit Synthetic", value: "knit_synthetic" },
    { label: "Knit Mixed", value: "knit_mixed" },
    { label: "Woven Natural", value: "woven_natural" },
    { label: "Woven Synthetic", value: "woven_synthetic" },
    { label: "Woven Mixed", value: "woven_mixed" },
    { label: "Non-woven", value: "non_woven" },
  ],
  constructionOptions: [],
  fiberOptions: [],
  thicknessOptions: [
    { label: "Extra Light", value: "extra_light" },
    { label: "Light", value: "light" },
    { label: "Medium", value: "medium" },
    { label: "Heavy", value: "heavy" },
    { label: "Extra Heavy", value: "extra_heavy" },
  ],
  physicalSeperationOptions: [],
  toneOptions: [
    { label: "White", value: "white" },
    { label: "Light", value: "light" },
    { label: "Medium", value: "medium" },
    { label: "Dark", value: "dark" },
    { label: "Very Dark", value: "very_dark" },
  ],
  colorOptions: [],
  specialFunctionOptions: [],
  suitableForThingOptions: [],
  tagOptions: [],
  carbonFootprintTypeOptions: [
    { label: "Cotton 100%", value: "cotton_100" },
    { label: "Polyester 100%", value: "polyester_100" },
    { label: "TC (C35/P65)", value: "tc" },
    { label: "CVC (C70/P30)", value: "cvc" },
  ],
  sellerIdOptions: [],
  sellingUnitOptions: [
    { label: "กิโลกรัม", value: "kg" },
    { label: "หลา", value: "yard" },
    { label: "เมตร", value: "metre" },
  ],
};

const enhancer = compose(
  defaultProps({
    ...OPTIONS,
  }),
  withStores((stores) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withFormik({
    mapPropsToValues: () => ({
      published: false,
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useHandleSubmit,
      useMutation,
      useQuery,
      useEffect,
      useCallback,
      useState,
      useFormikContext,
    } = hooks;
    const {
      categoryOptions,
      constructionOptions,
      fiberOptions,
      thicknessOptions,
      physicalSeperationOptions,
      toneOptions,
      colorOptions,
      specialFunctionOptions,
      tagOptions,
      carbonFootprintTypeOptions,
      sellerIdOptions,
      sellingUnitOptions,
      setFieldValue,
      currentUser,
    } = props;

    const code = currentUser.code;
    const configs = useQuery(API.FETCH_CONFIG);
    const [createProduct] = useMutation(API.CREATE_PRODUCT);
    const form = useFormikContext();

    const moreOptions = useMemo(() => {
      return configs.data?.configs;
    }, [configs.data]);

    const title = `เพิ่มผลิตภัณฑ์`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.productsPath(), label: "ผลิตภัณฑ์" },
        { path: null, label: title },
      ];
    }, [title]);

    useHandleSubmit(
      async (values) => {
        let splitOption =
          values.physicalCode && values.physicalCode.split(/[()-]/);
        splitOption = compact(splitOption);

        if (splitOption.length !== 4 && splitOption.length > 0) {
          notifyError("Physical Code ไม่ถูกต้อง ตรวจสอบอีกครั้ง");
        } else {
          try {
            await createProduct({
              variables: { ...values, lastEditedBy: code },
            });
            paths.productsPath().push();
            notifySuccess("เพิ่มข้อมูลสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        }
      },
      [createProduct, code, form.errors]
    );

    const totalQuantityUnit = find(sellingUnitOptions, {
      value: props.values?.sellingUnit,
    })?.label;

    const [sellingUnitHelperText, setSellingUnitHelperText] = useState();
    const sellingUnit = props.values?.sellingUnit;
    const gsm = props.values?.gsm;
    const fwWidth = props.values?.fwWidth;
    const recalculateUnitHelperText = useCallback(() => {
      const yardPerKg = calculateKgToYard(gsm, fwWidth);
      const helperText =
        sellingUnit === "kg" && isNumber(yardPerKg)
          ? `${toCurrency(yardPerKg)} หลาต่อกิโลกรัม`
          : undefined;
      setSellingUnitHelperText(helperText);
    }, [setSellingUnitHelperText, sellingUnit, gsm, fwWidth]);

    useEffect(() => {
      recalculateUnitHelperText();
    }, [recalculateUnitHelperText]);

    const costPerCapital = props.values?.costPerCapital;
    useEffect(() => {
      const pricePerCapital = costPerCapital ? costPerCapital * 1.25 : 0;
      setFieldValue("pricePerCapital", pricePerCapital);
    }, [setFieldValue, costPerCapital]);

    const category = props.values?.category;
    const tone = props.values?.tone;
    const carbonFootprintType = props.values?.carbonFootprintType;
    useEffect(() => {
      const carbonFootprintPerKg = getCarbonFootprintPerKg(
        category,
        tone,
        carbonFootprintType
      );
      if (isNumber(carbonFootprintPerKg)) {
        setFieldValue("carbonFootprintPerKg", carbonFootprintPerKg);
      } else {
        setFieldValue("carbonFootprintPerKg", null);
      }
    }, [setFieldValue, category, tone, carbonFootprintType]);

    useEffect(() => {
      const waterFootprintPerKg = getWaterFootprintPerKg(
        category,
        tone,
        carbonFootprintType
      );
      if (isNumber(waterFootprintPerKg)) {
        setFieldValue("waterFootprintPerKg", waterFootprintPerKg);
      }
    }, [setFieldValue, category, tone, carbonFootprintType]);

    return {
      title,
      breadcrumbs,
      submitButtonLabel: "เพิ่มผลิตภัณฑ์",
      totalQuantityUnit,
      sellingUnitHelperText,
      categoryOptions,
      constructionOptions,
      fiberOptions,
      thicknessOptions,
      physicalSeperationOptions,
      toneOptions,
      colorOptions,
      specialFunctionOptions,
      tagOptions,
      carbonFootprintTypeOptions,
      sellerIdOptions,
      sellingUnitOptions,
      recalculateUnitHelperText,
      setFieldValue,
      ...moreOptions,
    };
  })
);

export default enhancer(ProductNewPage);
