import { compose, withHooks } from "enhancers";
import { Box, Typography, Link, Divider, Table } from "components";
import { toCurrency, paths } from "utils/helper";

const OrderSellerInfo = (props) =>
  props.hide ? null : (
    <Box mt={props.mt}>
      <Box>
        <Typography variant="h4" color="Text/Black">
          ข้อมูลสำหรับติดต่อผู้ขาย
        </Typography>
        <Box display="flex" alignItems="center" mt={2}>
          <Typography color="Text/Dark Grey">
            รายชื่อผู้ขายที่ต้องติดต่อและรายการสินค้าที่ต้องสั่งซื้อปรับแก้รายการและกดยืนยันได้ที่ส่วน
          </Typography>
          <Link
            to={paths.productsPath()}
            newTab
            ml={1}
            style={{ color: "#376FD0", textDecoration: "underline" }}
          >
            ผลิตภัณฑ์
          </Link>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" height={32} mt={6}>
        <Typography variant="h6" width={300} px={3}>
          ผู้ขาย
        </Typography>
        <Typography variant="h6" width={448} px={3}>
          ผลิตภัณฑ์
        </Typography>
        <Typography variant="h6" width={100} px={3} textAlign="right">
          จำนวนที่สั่ง
        </Typography>
        <Typography variant="h6" width={100} px={3} textAlign="right">
          ยังขายได้
        </Typography>
        <Typography variant="h6" width={100} px={3} textAlign="right">
          มีในคลัง
        </Typography>
      </Box>
      <Divider />
      {props.sellerInfoList.map((sellerInfo, index) => (
        <Box key={index}>
          <Box display="flex">
            <Box width={300} px={3}>
              <Box display="flex" alignItems="center" height={32}>
                <Link
                  to={paths.sellerEditPath(sellerInfo.id)}
                  newTab
                  style={{ textDecoration: "underline" }}
                >
                  {sellerInfo.enName}
                </Link>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                height={32}
                className="OrderSellerInfo-social-contact"
              >
                {sellerInfo[sellerInfo.secondaryChannel] &&
                  sellerInfo.secondaryChannel &&
                  Table.RENDER_CELLS.social({
                    value: `${
                      sellerInfo[sellerInfo.secondaryChannel]
                    }{{MORELOOP_SPLIT_STRING}}${sellerInfo.secondaryChannel}`,
                  })}
              </Box>
            </Box>
            <Box width={448} px={3}>
              {sellerInfo.orderItems.map((productInfo, index) => (
                <Box key={index} display="flex" alignItems="center" height={32}>
                  <Typography>[{productInfo.productCode}]</Typography>
                  <Link
                    to={paths.productEditPath(productInfo.productId)}
                    newTab
                    ml={1}
                    style={{ textDecoration: "underline" }}
                  >
                    {productInfo.productName}
                  </Link>
                </Box>
              ))}
            </Box>
            <Box width={100} px={3}>
              {sellerInfo.orderItems.map((productInfo, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  height={32}
                >
                  <Typography>{toCurrency(productInfo.amount)}</Typography>
                </Box>
              ))}
            </Box>
            <Box width={100} px={3}>
              {sellerInfo.orderItems.map((productInfo, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  height={32}
                >
                  <Typography>
                    {toCurrency(productInfo.remainingAmount)}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box width={100} px={3}>
              {sellerInfo.orderItems.map((productInfo, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  height={32}
                >
                  <Typography>
                    {toCurrency(productInfo.inStockAmount)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Divider />
        </Box>
      ))}
    </Box>
  );

export const API = {};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { mt, sellerItems, canViewSellerInfo } = props;
    const { useMemo } = hooks;

    const deliveryDateHelperText = "";
    const deliveryTimeOptions = [];
    const deliveryAddressIdOptions = [];
    const isCustomDeliveryAddress = null;
    const deliveryAddressInfo = {};
    const buyerInfo = {};

    const sellerInfoList = useMemo(() => sellerItems ?? [], [sellerItems]);

    const hide = !canViewSellerInfo;

    return {
      mt,
      deliveryDateHelperText,
      deliveryTimeOptions,
      deliveryAddressIdOptions,
      isCustomDeliveryAddress,
      deliveryAddressInfo,
      buyerInfo,
      sellerInfoList,
      hide,
    };
  })
);

export default enhancer(OrderSellerInfo);
