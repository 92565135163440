import { compose, withHooks, withFormik } from "enhancers";
import {
  Box,
  Field,
  Select,
  TextField,
  Button,
  MuiTextField,
  Modal,
  Typography,
  Divider,
} from "components";
import { toCurrency } from "utils/helper";
import { map, find, filter } from "lodash";
import { format, parseISO } from "date-fns";
import htmlReactParser from "html-react-parser";

const OrderItem = (props) => (
  <>
    <Field
      component={Select}
      name="productId"
      options={props.productIdOptions}
      placeholder="เพิ่มรายการสินค้า/บริการ"
      width={604}
      mr={2}
      disabled={props.disabled}
    />
    <Field
      component={TextField}
      name="amount"
      type="number"
      textAlign="right"
      placeholder="0.00"
      width={90}
      mr={2}
      disabled={props.disabled}
    />
    <MuiTextField
      placeholder="หน่วย"
      width={80}
      mr={2}
      value={props.sellingUnitLabel}
      disabled
    />

    <Field
      component={TextField}
      name="sellingPricePerUnit"
      type="number"
      textAlign="right"
      placeholder="0.00"
      width={90}
      mr={2}
      disabled={props.disabled}
    />

    <MuiTextField
      inputProps={{ style: { textAlign: "right" } }}
      placeholder="0.00"
      width={120}
      mr={2}
      value={toCurrency(props.price ?? 0, { minimumFractionDigits: 0 })}
      disabled
    />
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={24}
      height={32}
      style={{ cursor: "pointer" }}
      onClick={props.showProductInfo}
      disabled={!props.showProductInfo}
    >
      <Button.InfoIcon />
    </Box>
  </>
);

const enhancer = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => ({
      productId: null,
      amount: null,
      sellingPricePerUnit: null,
      ...props.field.value,
    }),
  }),
  withHooks((props, hooks) => {
    const { products, form, field, values, disabled, readOnly } = props;
    const { useCallback, useMemo, useEffect, usePrevious } = hooks;

    const productIdOptions = useMemo(() => {
      const optionsPublishedAreTrue = filter(
        products,
        (product) => product.id === values.productId || product.published
      );

      return map(optionsPublishedAreTrue, (product) => ({
        label: `[${product.code}] - ${product.productName ?? ""}`,
        value: product.id,
      }));
    }, [products, values]);

    const outerSetFieldValue = form.setFieldValue;
    const outerFieldName = field.name;
    const onChange = useCallback(
      (orderItem) => {
        outerSetFieldValue(outerFieldName, orderItem);
      },
      [outerSetFieldValue, outerFieldName]
    );

    const product = find(products, { id: values.productId });

    const prevProductId = usePrevious(values?.productId);
    useEffect(() => {
      const newValues = { ...values };
      if (
        prevProductId !== values.productId &&
        prevProductId !== undefined &&
        product &&
        !readOnly
      ) {
        newValues.costPricePerUnit = product.costPerCapital;
        newValues.sellingPricePerUnit = product.pricePerCapital;
      }

      onChange(newValues);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(values)]);

    const sellingUnitLabel = product
      ? { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[product.sellingUnit]
      : null;

    const costPricePerUnit = values.costPricePerUnit;
    const sellingPricePerUnit = values.sellingPricePerUnit;
    const { amount, cuttingPrice } = values;
    const price =
      (amount ?? 0) * (sellingPricePerUnit ?? 0) + (cuttingPrice ?? 0);

    const showProductInfo = useCallback(() => {
      if (product) {
        const seller = product.seller;
        const productInfoList = [
          { label: "รหัสผ้าฝั่งผู้ขาย", value: product.sellerProductCode },
          {
            label: "มีในคลัง",
            value: `${toCurrency(product.totalQuantity) ?? "-"} ${
              { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[
                product.sellingUnit
              ]
            }`,
          },
          { label: "ปริมาณต่อพับ", value: product.quantityPerFold },
          {
            label: "อัพเดทเมื่อ",
            value: product.lastActiveAt
              ? format(parseISO(product.lastActiveAt), "dd/MM/yyyy, HH:mm")
              : "-",
          },
          {
            label: "ราคาทุน",
            value: `${toCurrency(product.costPerCapital) ?? "-"} บาท`,
          },
          {
            label: "ราคาขาย",
            value: `${toCurrency(product.pricePerCapital) ?? "-"} บาท`,
          },
        ];

        const sellerInfoList = [
          { label: "เงื่อนไข", value: seller.sellingCondition ?? "-" },
          { label: "การส่ง", value: seller.deliveryCondition ?? "-" },
          { label: "การชำระเงิน", value: seller.paymentCondition ?? "-" },
          {
            label: "บันทึกเพิ่มเติม",
            value: (
              <div className="renderHtmlFromString">
                {seller.note ? htmlReactParser(seller.note) : "-"}
              </div>
            ),
          },
        ];

        Modal.alert({
          className: "productInfoModal",
          children: (
            <Box>
              <Box>
                <Typography variant="h6">
                  {product.code} - {product.productName}
                </Typography>
                <Divider style={{ backgroundColor: "#E0E0E0" }} mt={3} />
                {map(productInfoList, (info) => (
                  <>
                    <Box display="flex" minHeight={32} alignItems="center">
                      <Typography variant="body1" width={120} mb={2}>
                        {info.label}
                      </Typography>
                      <Typography variant="body1" flex={1} mb={2}>
                        {info.value}
                      </Typography>
                    </Box>
                    <Divider style={{ backgroundColor: "#E0E0E0" }} />
                  </>
                ))}
              </Box>
              <Box mt={8}>
                <Typography variant="h6">
                  {seller.code} - {seller.enName || seller.thName}
                </Typography>
                <Divider style={{ backgroundColor: "#E0E0E0" }} mt={3} />
                {map(sellerInfoList, (info) => (
                  <>
                    <Box display="flex" minHeight={32} alignItems="center">
                      <Typography variant="body1" width={120} mb={2}>
                        {info.label}
                      </Typography>
                      <Typography variant="body1" flex={1} mb={2}>
                        {info.value}
                      </Typography>
                    </Box>
                    <Divider style={{ backgroundColor: "#E0E0E0" }} />
                  </>
                ))}
              </Box>
            </Box>
          ),
          okButtonLabel: "ปิด",
        });
      }
    }, [product]);

    return {
      showProductInfo: product ? showProductInfo : null,
      productIdOptions,
      sellingUnitLabel,
      price,
      costPricePerUnit,
      sellingPricePerUnit,
      disabled,
      readOnly,
    };
  })
);

export default enhancer(OrderItem);
