import paths from "routes/paths";

import { RouteType } from "types/routes";

// Menu Icon
import { ReactComponent as AdminIcon } from "vendor/menu_icon/admin.svg";
import { ReactComponent as BuyerIcon } from "vendor/menu_icon/buyer.svg";
import { ReactComponent as SellerIcon } from "vendor/menu_icon/seller.svg";
import { ReactComponent as ProductIcon } from "vendor/menu_icon/product.svg";
import { ReactComponent as OrderIcon } from "vendor/menu_icon/order.svg";
import { ReactComponent as PurchaseOrderIcon } from "vendor/menu_icon/purchase_order.svg";
import { ReactComponent as DaftOrderIcon } from "vendor/menu_icon/draft_order.svg";

export const MAIN_MENU: RouteType[] = [
  {
    id: "ผู้ดูแลระบบ",
    path: paths.adminsPath().toString(),
    header: "เมนู",
    icon: <AdminIcon />,
  },
  {
    id: "ผู้ซื้อ",
    path: paths.buyersPath().toString(),
    icon: <BuyerIcon />,
  },
  {
    id: "ผู้ขาย",
    path: paths.sellersPath().toString(),
    icon: <SellerIcon />,
  },
  {
    id: "ผลิตภัณฑ์",
    path: paths.productsPath().toString(),
    icon: <ProductIcon />,
  },
  {
    id: "ร่างคำสั่งซื้อ",
    path: paths.draftOrdersPath().toString(),
    icon: <DaftOrderIcon />,
  },
  {
    id: "คำสั่งซื้อ",
    path: paths.ordersPath().toString(),
    icon: <OrderIcon />,
  },
  {
    id: "ใบสั่งสินค้า",
    path: paths.purchaseOrdersPath().toString(),
    icon: <PurchaseOrderIcon />,
  },
];

export const SELLER_MAIN_MENU: RouteType[] = [
  {
    id: "ผลิตภัณฑ์",
    path: paths.productsPath().toString(),
    icon: <ProductIcon />,
  },
  {
    id: "การสั่งสินค้า",
    path: paths.purchaseOrdersPath().toString(),
    icon: <PurchaseOrderIcon />,
  },
];

// export default MAIN_MENU;
