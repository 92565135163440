import { compose, withHooks } from "enhancers";
import { Box, Typography } from "components";

import { ReactComponent as ErrorOutlineIcon } from "assets/icon/error_outline.svg";

const CancelRemarkCard = (props) => (
  <Box
    style={{ background: "#FCECE9", borderRadius: 8 }}
    display="flex"
    alignItems="center"
    fullWidth
    p={3}
    mb={4}
  >
    <ErrorOutlineIcon />
    <Typography color="SecondaryText / Danger" ml={2}>
      สาเหตุที่ยกเลิก: {props.message}
    </Typography>
  </Box>
);

const enhancer = compose(
  withHooks((props) => {
    const { message } = props;
    return { message };
  })
);

export default enhancer(CancelRemarkCard);
