import { compose, withHooks } from "enhancers";
import { Box, Divider, Paper, Typography } from "components";
import Header from "./Header";
import { map } from "lodash";
import { useMemo } from "hooks";
import { ReactComponent as Rectangle } from "assets/icon/rectangle.svg";
import { ReactComponent as Circle1 } from "assets/icon/circle_1.svg";
import { ReactComponent as Circle2 } from "assets/icon/circle_2.svg";
import { ReactComponent as Circle3 } from "assets/icon/circle_3.svg";
import { ReactComponent as Circle4 } from "assets/icon/circle_4.svg";
import { ReactComponent as Circle5 } from "assets/icon/circle_5.svg";
import { ReactComponent as Circle6 } from "assets/icon/circle_6.svg";
import { ReactComponent as Circle7 } from "assets/icon/circle_7.svg";
import { toCurrency } from "utils/helper";

const Product = (props) => (
  <Paper width={344} height={412} p={4}>
    <Header title={props.title} date={props.date} filterName="productFilter" />
    <Box mt={4}>
      <Typography variant="h3">
        {toCurrency(props.total ?? 0, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}{" "}
        kg
      </Typography>
      <Box>
        <Typography variant="caption" color="Text/Dark Grey">
          {toCurrency(props.totalKg ?? 0, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })}{" "}
          kg /{" "}
          {toCurrency(props.totalYard ?? 0, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })}{" "}
          yd /{" "}
          {toCurrency(props.totalMetre ?? 0, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })}{" "}
          m
        </Typography>
      </Box>
    </Box>
    <Box display="flex" mt={8}>
      <Rectangle />
      <Box ml={4}>
        {map(props.data, (item, index) => (
          <Box>
            <Box display="flex" justifyContent="space-between" width={277}>
              <Box width={122} display="flex">
                {props.circleIcons[index]}
                <Typography variant="caption" color="Text/Black" ml={2}>
                  {item.name}
                </Typography>
              </Box>
              <Typography
                variant="caption"
                color="Text/Black"
                width={40}
              >{`${item.percent}%`}</Typography>
              <Typography
                variant="caption"
                color="Text/Black"
                style={{ fontWeight: "bold" }}
              >{`${toCurrency(item.totalKg ?? 0, {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })} kg`}</Typography>
            </Box>
            <Divider my={2.5} />
          </Box>
        ))}
      </Box>
    </Box>
  </Paper>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info } = props;
    const { values } = info;

    const data = useMemo(() => {
      if (values.productFilter === "tone") {
        const result = [
          ...info.tones
            .reduce((map, current) => {
              const { name } = current;
              const grouped = map.get(name);
              if (!grouped) {
                map.set(name, { ...current });
              } else {
                map.set(name, {
                  ...grouped,
                  totalKg: grouped.totalKg + current.totalKg,
                });
              }
              return map;
            }, new Map())
            .values(),
        ];

        return result;
      } else {
        return info.categories;
      }
    }, [values.productFilter, info]);

    const circleIcons = useMemo(() => {
      return [
        <Circle1 />,
        <Circle2 />,
        <Circle3 />,
        <Circle4 />,
        <Circle5 />,
        <Circle6 />,
        <Circle7 />,
      ];
    }, []);

    return { ...props, ...info, data, circleIcons };
  })
);

export default enhancer(Product);
