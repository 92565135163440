import { compose, withHooks } from "enhancers";
import { Box, Divider, Paper, Tab, Tabs, Typography } from "components";
import Header from "../Product/Header";
import GraphLabel from "./GraphLabel";
import { Bar } from "react-chartjs-2";
import { map } from "lodash";
import styled from "styled-components";

const CustomTabs = styled(Tabs)`
  > div > div > button {
    background-color: #f6f9fc !important;
    border-radius: 80px;
    margin-right: 4px;
    min-height: 40px;
    min-width: 0px !important;
    padding: 8px;
    > span {
      font-size: 12px;
    }
  }
  .Mui-selected {
    background-color: #2296f3 !important;
    color: #ffffff !important;
  }

  > div > span {
    display: none;
  }
`;

const SalesProduct = (props) => (
  <Paper width={712} height={412} ml={6} p={4}>
    <Header
      title={props.title}
      date={props.date}
      filterName="salesProductFilter"
    />
    <Box display="flex">
      <GraphLabel
        total={props.total}
        totalKg={props.totalKg}
        totalYard={props.totalYard}
        totalMetre={props.totalMetre}
        color="#2296F3"
        label="คงเหลือ"
      />
      <GraphLabel
        total={props.totalSales}
        totalKg={props.totalSalesKg}
        totalYard={props.totalSalesYard}
        totalMetre={props.totalSalesMetre}
        color="#4CAF4F"
        label="ยอดขาย"
        ml={7}
      />
      <Box ml={9} height={140} width={380}>
        <Bar data={props.dataGraph} options={props.options} />
      </Box>
    </Box>
    <CustomTabs value={props.tabState} onChange={props.changeTabState}>
      {map(props.labels, (label) => (
        <Tab label={label} />
      ))}
    </CustomTabs>
    <Box display="flex" justifyContent="space-between" mt={8} mb={2.5}>
      <Box width={140} />
      {map(props.header, (text) => (
        <Typography variant="caption" width={56} textAlign="right">
          {text}
        </Typography>
      ))}
      <Typography
        variant="caption"
        color="Text/Black"
        style={{ fontWeight: "bold" }}
        width={56}
        textAlign="right"
      >
        รวม
      </Typography>
    </Box>
    <Divider style={{ background: "#E0E0E0" }} />
    <Box display="flex" justifyContent="space-between" my={2.5}>
      <Typography width={140} variant="caption">
        มีในคลัง (kg)
      </Typography>
      {map(props.detailQuantities, (text) => (
        <Typography variant="caption" width={56} textAlign="right">
          {text}
        </Typography>
      ))}
      <Typography
        variant="caption"
        color="Text/Black"
        style={{ fontWeight: "bold" }}
        width={56}
        textAlign="right"
      >
        {props.detailTotalQuantity}
      </Typography>
    </Box>
    <Divider style={{ background: "#F6F9FC" }} />
    <Box display="flex" justifyContent="space-between" my={2.5}>
      <Typography width={140} variant="caption">
        ยอดขาย (kg)
      </Typography>
      {map(props.detailSales, (text) => (
        <Typography variant="caption" width={56} textAlign="right">
          {text}
        </Typography>
      ))}
      <Typography
        variant="caption"
        color="Text/Black"
        style={{ fontWeight: "bold" }}
        width={56}
        textAlign="right"
      >
        {props.detailTotalSales}
      </Typography>
    </Box>
    <Divider style={{ background: "#F6F9FC" }} />
  </Paper>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { info } = props;
    const { values } = info;
    const { useMemo, useState, useCallback } = hooks;
    const [tabState, setTabState] = useState(0);
    const data = useMemo(() => {
      setTabState(0);
      return values.salesProductFilter === "category"
        ? info.categories ?? []
        : info.tones ?? [];
    }, [info, values.salesProductFilter]);
    const labels = useMemo(
      () =>
        map(data, (item) => (item.label === "Non-woven" ? "-" : item.label)),
      [data]
    );
    const totalQuantities = useMemo(() => map(data, "totalQuantity"), [data]);
    const totalSales = useMemo(() => map(data, "totalSales"), [data]);

    const dataGraph = useMemo(() => {
      return {
        labels: labels ?? [],
        datasets: [
          {
            type: "bar",
            label: "คงเหลือ",
            data: totalQuantities ?? [],
            backgroundColor: "#2296F3",
            borderRadius: 4,
          },
          {
            type: "bar",
            label: "ยอดขาย",
            data: totalSales ?? [],
            backgroundColor: "#4CAF4F",
            borderRadius: 4,
          },
        ],
      };
    }, [labels, totalQuantities, totalSales]);

    const options = {
      maintainAspectRatio: false,
      plugins: { legend: { display: false } },
      legend: {
        labels: {
          font: {
            family:
              'Noto Sans Thai,Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            size: 20,
          },
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: "kg",
            color: "#767676",
            font:
              'Noto Sans Thai,Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
          },
        },
        yAxes: [
          {
            gridLines: {},
            stacked: false,
            ticks: {
              fontSize: 10,
              fontFamily:
                'Noto Sans Thai,Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            },
          },
        ],
        xAxes: [
          {
            color: "#5483D4",
            ticks: {
              fontSize: 10,
              fontFamily: "Noto Sans Thai",
            },
          },
        ],
      },
    };
    const changeTabState = useCallback((e, value) => {
      setTabState(value);
    }, []);

    const {
      header,
      detailQuantities,
      detailSales,
      detailTotalQuantity,
      detailTotalSales,
    } = useMemo(() => {
      const details = data[tabState]?.details ?? [];

      const header = map(details, "label");
      const detailQuantities = map(details, "quantity");
      const detailSales = map(details, "sales");
      const detailTotalQuantity = data[tabState]?.totalQuantity;
      const detailTotalSales = data[tabState]?.totalSales;

      return {
        header,
        detailQuantities,
        detailSales,
        detailTotalQuantity,
        detailTotalSales,
      };
    }, [tabState, data]);

    return {
      ...props,
      ...info,
      dataGraph,
      options,
      changeTabState,
      tabState,
      labels,
      header,
      detailQuantities,
      detailSales,
      detailTotalQuantity,
      detailTotalSales,
    };
  })
);

export default enhancer(SalesProduct);
