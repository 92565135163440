import { compose, withHooks } from "enhancers";
import { Box, Typography, Table } from "components";
import {} from "utils/helper";
import { cloneDeep } from "lodash";

const OrderHistoryPage = (props) => (
  <Box key={Math.random()}>
    <Typography variant="h4" color="Text/Black" mb={4}>
      การแก้ไขคำสั่งซื้อ
    </Typography>
    <Table
      columns={[
        {
          field: "description",
          headerName: "การแก้ไข",
          width: 688,
        },
        { field: "modifiedName", headerName: "ผู้แก้ไข", width: 200 },
        {
          field: "updatedAt",
          headerName: "สำเร็จเมื่อ",
          width: 160,
          type: "dateTime",
        },
      ]}
      rows={props.tableData}
      density="compact"
      autoHeight
      autoPageSize
      disableSelectionOnClick
    />
  </Box>
);

export const API = {};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { histories } = props;
    const { useMemo } = hooks;

    const tableData = useMemo(() => {
      return cloneDeep(histories) || [];
    }, [histories]);

    return { tableData };
  })
);

export default enhancer(OrderHistoryPage);
