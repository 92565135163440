import { compose, withHooks } from "enhancers";
import { Box, Typography, Divider } from "components";
import { toCurrency } from "utils/helper";

import OrderEditCheckListConfirmOrder from "./OrderCheckListConfirmOrder";
import OrderEditCheckListPurchaseOrder from "./OrderCheckListPurchaseOrder";
import OrderEditCheckListDeliveryOrder from "./OrderCheckListDeliveryOrder";
import OrderEditCheckListSummaryOrder from "./OrderCheckListInvoicingOrder";
import OrderEditCheckListPaymentOrder from "./OrderCheckListPaymentOrder";

const OrderCheckList = (props) => (
  <Box>
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h4">เช็คลิสต์</Typography>
      <Typography variant="h4">{props.status}</Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" mb={6}>
      <Typography color="Text/Dark Grey" mt={2}>
        สิ่งที่ต้องทำเพื่อให้คำสั่งซื้อเสร็จสมบูรณ์
      </Typography>
      <Typography color="Other/Info" mt={2}>
        ยอดรวมทั้งหมด {toCurrency(props.finalPrice)} บาท
      </Typography>
    </Box>

    <OrderEditCheckListConfirmOrder
      quotationInfo={props.quotationInfo}
      reloadOrder={props.reloadOrder}
    />
    <Divider />
    <OrderEditCheckListPurchaseOrder
      title="ส่งใบสั่งสินค้าให้ผู้ขาย"
      poQuotationInfoList={props.poOrderingInfo}
    />
    <Divider />
    <OrderEditCheckListPurchaseOrder
      title="จ่ายเงินให้ผู้ขาย"
      poQuotationInfoList={props.poPaymentInfo}
    />
    <Divider />
    <OrderEditCheckListPurchaseOrder
      title="รับสินค้าจากผู้ขาย"
      poQuotationInfoList={props.poRecievingInfo}
    />
    <Divider />
    <OrderEditCheckListDeliveryOrder
      deliveryOrder={props.deliveryOrder}
      reloadOrder={props.reloadOrder}
    />
    <Divider />
    <OrderEditCheckListSummaryOrder
      invoicingOrder={props.invoicingOrder}
      reloadOrder={props.reloadOrder}
    />
    <Divider />
    <OrderEditCheckListPaymentOrder
      paymentOrder={props.paymentOrder}
      reloadOrder={props.reloadOrder}
    />
    <Divider />
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo } = hooks;
    const { reloadOrder, status, finalPrice } = props;

    const quotationInfo = useMemo(() => {
      return props?.quotationInfo || {};
    }, [props.quotationInfo]);

    const poOrderingInfo = useMemo(() => {
      return props?.poOrderingInfo || {};
    }, [props.poOrderingInfo]);

    const poPaymentInfo = useMemo(() => {
      return props?.poPaymentInfo || {};
    }, [props.poPaymentInfo]);

    const poRecievingInfo = useMemo(() => {
      return props?.poRecievingInfo || {};
    }, [props.poRecievingInfo]);

    const deliveryOrder = useMemo(() => {
      return (
        {
          ...props.deliveryOrder,
          invoicingOrderCompletedAt: props.invoicingOrder?.completedAt,
          orderItemsOfOrder: props.deliveryOrder?.orderItems,
          buyerDeliveryAddress: props.buyerDeliveryAddress,
          disabled:
            props.poPaymentInfo?.status !==
              props.poPaymentInfo?.sellerItems.length ||
            props.poRecievingInfo?.status !==
              props.poRecievingInfo?.sellerItems.length ||
            props.canceled ||
            props.poRecievingInfo?.sellerItems.length === 0,
        } || {}
      );
    }, [
      props?.deliveryOrder,
      props.buyerDeliveryAddress,
      props.poRecievingInfo,
      props.invoicingOrder,
      props.canceled,
      props.poPaymentInfo,
    ]);

    const invoicingOrder = useMemo(() => {
      return {
        ...props.deliveryOrder,
        ...props.invoicingOrder,
        orderItemsOfOrder: props.invoicingOrder?.orderItems,
        deliveryDate: props.deliveryDate,
        deliveryTime: props.deliveryTime,
        discount: props.discount,
        code: props.code,
        disabled:
          props.deliveryOrder?.status !== props.orderItemsOfOrder?.length ||
          props.canceled ||
          props.orderItemsOfOrder?.length === 0,
      };
    }, [
      props?.deliveryOrder,
      props.orderItemsOfOrder,
      props.deliveryDate,
      props.deliveryTime,
      props.discount,
      props.invoicingOrder,
      props.code,
      props.canceled,
    ]);

    const paymentOrder = useMemo(() => {
      return {
        ...props.deliveryOrder,
        ...props.paymentOrder,
        orderItemsOfOrder: props.paymentOrder?.orderItems,
        deliveryDate: props.deliveryDate,
        deliveryTime: props.deliveryTime,
        discount: props.discount,
        code: props.code,
        disabled: !props.invoicingOrder?.completedAt || props.canceled,
      };
    }, [
      props?.deliveryOrder,
      props.deliveryDate,
      props.deliveryTime,
      props.discount,
      props.paymentOrder,
      props.invoicingOrder,
      props.code,
      props.canceled,
    ]);

    return {
      quotationInfo,
      poOrderingInfo,
      poPaymentInfo,
      poRecievingInfo,
      finalPrice,
      status,
      reloadOrder,
      deliveryOrder,
      invoicingOrder,
      paymentOrder,
    };
  })
);

export default enhancer(OrderCheckList);
