import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Divider,
  Hidden,
  FileUploadList,
  FieldArray,
} from "components";
import { formatDate, toCurrency } from "utils/helper";
import { gql } from "utils/helper";
import { filter, sum } from "lodash";

const ConfirmOrderQuotationInfo = (props) => (
  <Box mt={-1.5}>
    <Box
      display="flex"
      alignItems="center"
      height={32}
      mt={6}
      style={{ borderBottom: "1px solid #E0E0E0" }}
    >
      <Typography variant="h6" width={95} px={3}>
        รหัส
      </Typography>
      <Typography variant="h6" width={267} px={3}>
        รายการสินค้า
      </Typography>
      <Typography variant="h6" width={100} px={3} textAlign="right">
        จำนวน
      </Typography>
      <Typography variant="h6" width={80} px={3}>
        หน่วย
      </Typography>
      <Typography variant="h6" width={170} px={3} textAlign="right">
        ราคาต่อหน่วย (บาท)
      </Typography>
      <Typography variant="h6" width={170} px={3} textAlign="right">
        ค่าตัด (บาท)
      </Typography>
      <Typography variant="h6" width={150} px={3} textAlign="right">
        ราคาขายรวม (บาท)
      </Typography>
    </Box>

    {props.orderItems.map((orderItem, index) => (
      <Box key={index} display="flex" alignItems="center" height={32}>
        <Typography width={95} px={3}>
          {orderItem.productCode}
        </Typography>
        <Typography width={267} px={3}>
          {orderItem.productName}
        </Typography>
        <Typography width={100} px={3} textAlign="right">
          {toCurrency(orderItem.amount ?? 0)}
        </Typography>
        <Typography width={80} px={3}>
          {
            { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[
              orderItem.sellingUnit
            ]
          }
        </Typography>
        <Typography width={170} px={3} textAlign="right">
          {toCurrency(orderItem.costPricePerUnit ?? 0)}
        </Typography>
        <Typography width={170} px={3} textAlign="right">
          {toCurrency(orderItem.cuttingPrice ?? 0)}
        </Typography>
        <Typography width={150} px={3} textAlign="right">
          {toCurrency(orderItem.totalCost ?? 0)}
        </Typography>
      </Box>
    ))}
    <Divider mt={15} />

    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="caption" color="Text/Dark Grey" height={32} px={3}>
          {props.orderDeliveryDate &&
            props.orderDeliveryTime &&
            `*ต้องส่งถึงลูกค้าภายใน ${formatDate(
              props.orderDeliveryDate,
              "dd/MM/yyyy"
            )} เวลา
            ${props.orderDeliveryTime} น.`}
        </Typography>
      </Box>
      <Box>
        <Hidden when={!props.hasVat}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            height={32}
          >
            <Typography textAlign="right" px={3}>
              ราคารวม
            </Typography>
            <Typography textAlign="right" width={150} px={3}>
              {toCurrency(props.totalItemsPrice)}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            height={32}
          >
            <Typography textAlign="right" px={3}>
              ภาษีมูลค่าเพิ่ม 7%
            </Typography>
            <Typography textAlign="right" width={150} px={3}>
              {toCurrency(props.vat)}
            </Typography>
          </Box>
        </Hidden>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography variant="h6" textAlign="right" px={3}>
            <b>ยอดรวมทั้งหมด</b>
          </Typography>
          <Typography variant="h6" textAlign="right" width={150} px={3}>
            {toCurrency(props.totalPrice)}
          </Typography>
        </Box>
      </Box>
    </Box>
    <Divider mt={6} />
    <Box mb={4}>
      <FieldArray
        component={FileUploadList}
        name="paymentDocuments"
        addButtonLabel="อัปโหลดหลักฐาน"
        simple
      />
    </Box>
  </Box>
);

export const API = {
  PAID_PURCHASE_ORDER: gql`
    mutation PAID_PURCHASE_ORDER($id: ID!, $paymentDocuments: [Upload!]) {
      paidPurchaseOrder(
        input: { id: $id, paymentDocuments: $paymentDocuments }
      ) {
        purchaseOrder {
          id
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      mt,
      orderItems,
      hasVat,
      orderDeliveryDate,
      orderDeliveryTime,
    } = props;
    const { useMemo } = hooks;

    const { totalItemsPrice, vat, totalPrice } = useMemo(() => {
      const items = filter(orderItems, (item) => item.productId).map(
        (item) =>
          (item.amount ?? 0) * (item.costPricePerUnit ?? 0) +
          (item.cuttingPrice ?? 0)
      );
      const totalItemsPrice = sum(items, "price");
      const vat = totalItemsPrice * 0.07;
      const totalPrice = totalItemsPrice + (hasVat ? vat : 0);

      return {
        totalItemsPrice,
        vat,
        totalPrice,
      };
    }, [orderItems, hasVat]);

    return {
      mt,
      orderItems,
      hasVat,
      totalItemsPrice,
      totalPrice,
      vat,
      orderDeliveryDate,
      orderDeliveryTime,
    };
  })
);

export default enhancer(ConfirmOrderQuotationInfo);
