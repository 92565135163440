/* eslint-disable */
import React from "react";
import {
  compose,
  defaultProps,
  withFormik,
  withHooks,
  withStores,
} from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  FieldArray,
  TextField,
  Address,
  AddressList,
  FileUploadList,
  Divider,
  Button,
  Select,
  BankAccount,
} from "components";
import {
  gql,
  notifyError,
  notifySuccess,
  paths,
  cleanTypename,
} from "utils/helper";
import { flatten, map, compact, uniq, isEmpty } from "lodash";
import { ReactComponent as ExclamationIcon } from "assets/icon/exclamation.svg";

const SellerProfile = (props) => (
  <PageContent
    title={`โปรไฟล์ - ${props.code}`}
    breadcrumbs={[
      { path: paths.productsPath(), label: "หน้าแรก" },
      { path: null, label: `โปรไฟล์ - ${props.code}` },
    ]}
  >
    <Form>
      {props.statusBanner}
      <Typography variant="h4" mb={4}>
        ข้อมูลทั่วไป
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="enName"
            type="text"
            label="ชื่อภาษาอังกฤษ"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="thName"
            type="text"
            label="ชื่อภาษาไทย"
            fullWidth
            required
          />
        </Grid>
        {/* <Grid item xs={12}>
        <Field
          component={Select}
          name="industryTypes"
          type="text"
          label="ประเภทบริษัท"
          fullWidth
          options={props.industryTypeOptions}
          multiple
          // required
          // forceFix
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={Select}
          name="productTypes"
          type="text"
          label="ประเภทผลิตภัณฑ์"
          fullWidth
          options={props.productTypeOptions}
          multiple
          forceFix
        />
      </Grid> */}
        {/* <Grid item xs={12}>
          <Field
            component={TextField}
            name="sellingCondition"
            type="text"
            label="เงื่อนไขในการขายผ้า"
            fullWidth
            multiline
            className="height-136"
          />
        </Grid> */}
      </Grid>

      <Typography variant="h4" mt={10} mb={6}>
        การติดต่อ
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="contactName"
            type="text"
            label="ชื่อคนที่ต้องติดต่อ"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField.PhoneNumber}
            name="phoneNumber1"
            label="เบอร์โทรศัพท์"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Box mt={6}>
        <Grid mt={6} container spacing={6}>
          <Grid item xs={6}>
            <Box fullWidth display="flex" alignItems="center">
              <Field
                component={TextField.PhoneNumber}
                name="phoneNumber2"
                label="เบอร์โทรศัพท์ 2"
                flex={1}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box fullWidth display="flex" alignItems="center">
              <Field
                component={TextField}
                name="line"
                type="text"
                label="ไลน์"
                flex={1}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box fullWidth display="flex" alignItems="center">
              <Field
                component={TextField}
                name="facebook"
                type="text"
                label="เฟสบุ๊ค"
                flex={1}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box fullWidth display="flex" alignItems="center">
              <Field
                component={TextField}
                name="ig"
                type="text"
                label="อินสตาแกรม"
                flex={1}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box fullWidth display="flex" alignItems="center">
              <Field
                component={TextField.Email}
                name="email"
                label="อีเมล"
                flex={1}
                required
                disabled
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Field
        component={BankAccount}
        name="bankAccount"
        title="การชำระเงิน*"
        mt={10}
        noBranch
        isSeller
      />
      <Field
        component={Address.Billing}
        name="billingAddress"
        title="ที่อยู่ที่ใช้ในการออกบิล*"
        mt={10.5}
      />
      <FieldArray
        component={AddressList.Delivery}
        name="deliveryAddresses"
        title="ที่อยู่ที่ใช้ในการรับของ*"
        addButtonLabel="เพิ่มที่อยู่รับของ"
        hasGmap
        mt={10}
      />
      <FieldArray
        component={FileUploadList}
        name="relatedDocuments"
        title="เอกสาร*"
        addButtonLabel="เพิ่มเอกสาร"
        mt={10}
      />

      <Divider mt={10} />
      <Button
        type="submit"
        color="primary"
        mt={6}
        disabled={props.disabledSubmitButton}
      >
        {props.submitButtonLabel}
      </Button>
    </Form>
  </PageContent>
);

export const API = {
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      currentUser {
        code
        email
        seller {
          id
          code
          email
          facebook
          ig
          note
          phoneNumber1
          phoneNumber2
          secondaryChannel
          line
          analysisInfo
          contactName
          
          deliveryCondition
          financialInfo
          thName
          enName
          # industryTypes
          # productTypes
          paymentCondition
          sellerTier
          sellingCondition
          adminId
          bankAccount {
            bankAccountName
            bankAccountNumber
            bankName
            branchName
          }
          billingAddress {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
          }
          deliveryAddresses {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
            isMain
          }
          relatedDocuments
          isProfileDataInitialize
        }
      }
    }
  `,
  UPDATE_SELLER: gql`
    mutation UPDATE_SELLER(
      $id: ID!
      $email: String
      $facebook: String
      $ig: String
      $phoneNumber1: String
      $phoneNumber2: String
      $line: String
      $billingAddress: AddressArguments
      $deliveryAddresses: [AddressArguments!]
      $contactName: String
      $thName: String
      $enName: String
      # $industryTypes: [String!]
      # $productTypes: [String!]
      $sellingCondition: String
      $bankAccount: BankAccountArguments
      $relatedDocuments: [Upload!]
      $isProfileDataInitialize: Boolean
    ) {
      updateSellerInfo(
        input: {
          id: $id
          email: $email
          facebook: $facebook
          ig: $ig
          phoneNumber1: $phoneNumber1
          phoneNumber2: $phoneNumber2
          line: $line
          billingAddress: $billingAddress
          deliveryAddresses: $deliveryAddresses
          contactName: $contactName
          thName: $thName
          enName: $enName
          # industryTypes: $industryTypes
          # productTypes: $productTypes
          sellingCondition: $sellingCondition
          bankAccount: $bankAccount
          relatedDocuments: $relatedDocuments
          isProfileDataInitialize: $isProfileDataInitialize
        }
      ) {
        seller {
          id
          email
          facebook
          ig
          phoneNumber1
          phoneNumber2
          line
          contactName
          thName
          enName
          # industryTypes
          # productTypes
          sellingCondition
          bankAccount {
            bankAccountName
            bankAccountNumber
            bankName
            branchName
          }
          billingAddress {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
          }
          deliveryAddresses {
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
            isMain
          }
          relatedDocuments
          isProfileDataInitialize
        }
      }
    }
  `,
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      sellers {
        tags
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores) => ({
    isProfileDataInitialize: !!stores.appStore.currentUser?.ownerData?.seller
      ?.isProfileDataInitialize,
  })),
  defaultProps({
    industryTypeOptions: [
      { label: "Textile", value: "textile" },
      { label: "Garment", value: "garment" },
      { label: "Trader", value: "trader" },
      { label: "Brand Owner", value: "brand_owner" },
    ],
    productTypeOptions: [
      { label: "Knitted", value: "knitted" },
      { label: "Woven / Cotton", value: "woven_cotton" },
      { label: "Polyester", value: "polyester" },
    ],
    sellerTierOptions: [
      { label: "Tier 1 - Moreloop ", value: "tier1_moreloop" },
      { label: "Tier 2 - Garment ", value: "tier2_garment" },
      { label: "Tier 3 - Trader ", value: "tier3_trader" },
    ],
  }),
  withFormik({
    mapPropsToValues: () => ({
      deliveryAddresses: [{}],
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useHandleSubmit,
      useQuery,
      useMutation,
      useEffect,
    } = hooks;
    const {
      industryTypeOptions,
      productTypeOptions,
      sellerTierOptions,
      values,
      setValues,
      isProfileDataInitialize,
      isValid,
    } = props;

    const { loading, data, error, refetch } = useQuery(API.GET_CURRENT_USER);
    const configs = useQuery(API.FETCH_CONFIG);
    const [updateSeller] = useMutation(API.UPDATE_SELLER);

    const code = useMemo(() => {
      if (loading || error) {
        return null;
      }

      return data?.currentUser?.code;
    }, [data, loading, error]);

    const seller = useMemo(() => {
      if (loading || error) {
        return null;
      }

      return data?.currentUser?.seller;
    }, [data, loading, error]);

    useEffect(() => {
      const values = cleanTypename(seller);
      setValues({ ...values });
    }, [setValues, seller, industryTypeOptions, productTypeOptions]);

    const sellers = configs.data?.sellers;
    const tagOptions = useMemo(() => {
      const options = sellers
        ? compact(uniq(flatten(map(sellers, "tags")))).map((tag) => ({
            label: tag,
            value: tag,
          }))
        : [];
      return options;
    }, [sellers]);

    useHandleSubmit(
      async (values) => {
        try {
          const customValues = { ...values, isProfileDataInitialize: true };
          await updateSeller({ variables: customValues });
          notifySuccess("เพิ่มข้อมูลสำเร็จ");
          refetch();
          if (!isProfileDataInitialize) {
            window.location.href = "/";
          }
        } catch (e) {
          notifyError(e);
        }
      },
      [updateSeller, refetch, isProfileDataInitialize]
    );

    const statusBanner = useMemo(() => {
      if (isProfileDataInitialize === null) {
        return;
      }

      const bannerMessage =
        "กรุณากรอกรายละเอียดส่วนตัวให้ครบถ้วนก่อนเริ่มใช้งานระบบ";

      return !isProfileDataInitialize ? (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          bgcolor="#FCECE9"
          padding={2.5}
          mb={10}
          borderRadius={4}
        >
          <ExclamationIcon />
          <Typography variant="body1" color="#601A15" ml={2}>
            {bannerMessage}
          </Typography>
        </Box>
      ) : null;
    }, [isProfileDataInitialize]);

    const disabledSubmitButton = useMemo(() => {
      return (
        !isValid ||
        values.relatedDocuments?.length === 0 ||
        values.deliveryAddresses?.length === 0 ||
        isEmpty(values.billingAddress)
      );
    }, [isValid, values]);

    return {
      code,
      statusBanner,
      industryTypeOptions,
      productTypeOptions,
      sellerTierOptions,
      tagOptions,
      submitButtonLabel: "บันทึก",
      disabledSubmitButton,
    };
  })
);

export default enhancer(SellerProfile);
