import { compose, withFormik, withHooks } from "enhancers";
import styled from "styled-components/macro";
import { Box, Field, Select } from "components";
import Typography from "components/common/Typography";
import { map, uniq, uniqBy, countBy, orderBy, compact } from "lodash";
import { gql } from "utils/helper";

const ContentContainer = styled.div`
  display: flex;
`;

const PDFContent = (props) => (
  <ContentContainer>
    <Box sx={{ width: "100%", maxWidth: "100%", minWidth: "20%" }}>
      <Field
        component={Select}
        name="p1"
        label="Physical Code"
        fullWidth
        freeSolo
        helperText={props.categorySuggestionText}
        options={props.p1Option}
      />
    </Box>

    <Box alignSelf="center" mx={1} mb={props.categorySuggestionText ? 5 : 0}>
      <Typography>-</Typography>
    </Box>

    <Box sx={{ width: "100%", maxWidth: "100%", minWidth: "20%" }}>
      <Field
        component={Select}
        name="p2"
        fullWidth
        freeSolo
        options={props.p2Option}
      />
    </Box>

    <Box alignSelf="center" mx={1} mb={props.categorySuggestionText ? 5 : 0}>
      <Typography>-</Typography>
    </Box>

    <Box sx={{ width: "100%", maxWidth: "100%", minWidth: "20%" }}>
      <Field
        component={Select}
        name="p3"
        fullWidth
        freeSolo
        options={props.p3Option}
      />
    </Box>

    <Box alignSelf="center" mx={1} mb={props.categorySuggestionText ? 5 : 0}>
      <Typography>-</Typography>
    </Box>

    <Box sx={{ width: "100%", maxWidth: "100%", minWidth: "20%" }}>
      <Field
        component={Select}
        name="p4"
        fullWidth
        options={props.p4Option}
        renderOption={props.renderP4Option}
      ></Field>
    </Box>
  </ContentContainer>
);

const API = {
  FETCH_PRODUCTS: gql`
    query FETCH_PRODUCTS {
      products {
        products {
          physicalCode
        }
      }
    }
  `,
};

const CATEGORY_SUGGESTION_TYPE = {
  knit_natural: "K1",
  knit_synthetic: "K2",
  knit_mixed: "K3",
  woven_natural: "W1",
  woven_synthetic: "W2",
  woven_mixed: "W3",
  non_woven: "C0",
};

const enhancer = compose(
  withFormik(),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useQuery,
      useEffect,
      useState,
      usePrevious,
      useCallback,
    } = hooks;
    const { form, field, values, setFieldValue } = props;

    const outerSetFieldValue = form.setFieldValue;
    const outerValues = form.values;
    const outerSetErrors = form.setErrors;

    const products = useQuery(API.FETCH_PRODUCTS);

    const [p1Option, setP1Option] = useState([]);

    const [p2Config, setP2Config] = useState({});
    const [p3Config, setP3Config] = useState({});
    const [p4Config, setP4Config] = useState({});

    const prevP1 = usePrevious(values.p1);
    const prevP2 = usePrevious(values.p2);
    const prevP3 = usePrevious(values.p3);
    const prevP4 = usePrevious(values.p4);

    const physicalCodes = useMemo(() => {
      if (products.data) {
        const physicalCodes = products.data?.products.products.map(
          (product) => product.physicalCode
        );

        return physicalCodes.filter((physicalCode) => physicalCode !== null);
      }
    }, [products]);

    useEffect(() => {
      if (
        field.value &&
        prevP1 === undefined &&
        prevP2 === undefined &&
        prevP3 === undefined &&
        prevP4 === undefined
      ) {
        let splitValue = field.value.split(/[()-]/);
        splitValue = compact(splitValue);

        setFieldValue("p1", splitValue[0]);
        setFieldValue("p2", splitValue[1]);
        setFieldValue("p3", splitValue[2]);
        setFieldValue("p4", `(${splitValue[3]})`);
      }
    }, [field.value, setFieldValue]);

    useEffect(() => {
      if (physicalCodes) {
        const results = uniq(physicalCodes).filter((element) => {
          return element !== null;
        });

        // make option p1
        const level1Options = map(results, (physicalCode) => {
          const value = physicalCode && physicalCode.split("-")[0];
          return { label: value, value };
        });

        const config2 = {};
        const config3 = {};
        const config4 = {};

        results.forEach((physicalCode) => {
          let splitOption = physicalCode && physicalCode.split(/[()-]/);
          splitOption = compact(splitOption);

          // make option p2
          if (splitOption[1]) {
            const value2 = splitOption[0];
            const options2 = config2[value2] ?? [];
            options2.push({
              label: splitOption[1],
              value: splitOption[1],
            });
            config2[value2] = options2;
          }

          // make option p3
          if (splitOption[2]) {
            const value3 = `${splitOption[0]}❀${splitOption[1]}`;
            const options3 = config3[value3] ?? [];
            options3.push({
              label: splitOption[2],
              value: splitOption[2],
            });
            config3[value3] = options3;
          }

          // make option p4
          if (splitOption[3]) {
            const value4 = `${splitOption[0]}❀${splitOption[1]}❀${splitOption[2]}`;
            const options4 = config4[value4] ?? [];

            options4.push({
              label: `(${splitOption[3]})`,
              value: `(${splitOption[3]})`,
            });
            config4[value4] = options4;
          }
        });

        setP1Option(uniqBy(level1Options, "value"));
        setP2Config(config2);
        setP3Config(config3);
        setP4Config(config4);
      }
    }, [physicalCodes, setP1Option, setP2Config, setP3Config, setP4Config]);

    const p2Option = useMemo(() => uniqBy(p2Config[values.p1], "value"), [
      values.p1,
      p2Config,
    ]);

    const p3Option = useMemo(
      () => uniqBy(p3Config[`${values.p1}❀${values.p2}`], "value"),
      [values.p2, p3Config]
    );

    const p4Option = useMemo(() => {
      if (values.p3) {
        let p4 = uniqBy(
          p4Config[`${values.p1}❀${values.p2}❀${values.p3}`],
          "value"
        );
        const p4Undefined = p4.filter((p) => p.value === `(${undefined})`);
        p4 = p4.filter((p) => p.value !== `(${undefined})`);

        p4.sort((a, b) => {
          const numA = parseInt(a.value.match(/\d+/)[0]);
          const numB = parseInt(b.value.match(/\d+/)[0]);
          return numA - numB;
        });
        const lastValue = p4.slice(-1)[0] ?? {};
        const resultValue = (lastValue.value || "").replace(/[^\w\s]/gi, "");
        const nextIndexValue = +resultValue + 1;
        p4.push({
          label: `(${nextIndexValue})`,
          value: `(${nextIndexValue})`,
        });
        p4 = p4.concat(p4Undefined);

        const prevPhysicalCode = `${values.p1}-${values.p2}-${values.p3}`;

        let physicalCodesAmount = countBy(physicalCodes, (item) => {
          return item;
        });

        const result = p4.map((item, index) => {
          const physicalCode = prevPhysicalCode + item.label;
          const amount = physicalCodesAmount[physicalCode];

          return {
            label: `${item.label}`,
            value: item.value,
            amount,
          };
        });

        return result;
      }
    }, [values.p3, p4Config]);

    useEffect(() => {
      const physicalCode = `${values.p1}-${values.p2}-${values.p3}${values.p4}`;
      if (!values.p1 && !values.p2 && !values.p3 && !values.p4) {
        outerSetFieldValue("physicalCode", null);
      } else {
        outerSetFieldValue("physicalCode", physicalCode);
      }
      // if (values.p1 && values.p2 && values.p3 && values.p4) {
      //   const physicalCode = `${values.p1}-${values.p2}-${values.p3}${values.p4}`;
      //   outerSetFieldValue("physicalCode", physicalCode);
      // } else if (!values.p1 && !values.p2 && !values.p3 && !values.p4) {
      //   outerSetFieldValue("physicalCode", null);
      //   outerSetErrors({});
      // } else {
      //   outerSetErrors({
      //     __error__: "กรอก Physical Code ไม่ครบ",
      //     physicalCode: "กรอก Physical Code ไม่ครบ",
      //   });
      // }
    }, [values, outerSetFieldValue, outerSetErrors]);

    useEffect(() => {
      if (prevP1 !== undefined && values.p1 !== prevP1) {
        setFieldValue("p2", null);
        setFieldValue("p3", null);
        setFieldValue("p4", null);
      }
    }, [values.p1, prevP1, setFieldValue]);

    useEffect(() => {
      if (prevP2 !== undefined && values.p2 !== prevP2) {
        setFieldValue("p3", null);
        setFieldValue("p4", null);
      }
    }, [values.p2, prevP2, setFieldValue]);

    useEffect(() => {
      if (prevP3 !== undefined && values.p3 !== prevP3) {
        setFieldValue("p4", null);
      }
    }, [values.p3, prevP3, setFieldValue]);

    const categorySuggestionText = useMemo(
      () =>
        CATEGORY_SUGGESTION_TYPE[outerValues.category]
          ? `คาดว่าเป็น ${CATEGORY_SUGGESTION_TYPE[outerValues.category]}`
          : null,
      [outerValues.category]
    );

    const renderP4Option = useCallback(
      (value) => {
        const amount = p4Option.find((opt) => opt.value === value)?.amount ?? 0;
        return (
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body" color="Text/Black">
              {value}
            </Typography>
            <Typography variant="body" color="Text/Dark Grey">
              {`${amount} ชิ้น`}
            </Typography>
          </Box>
        );
      },
      [p4Option]
    );

    return {
      p1Option,
      p2Option,
      p3Option,
      p4Option,
      categorySuggestionText,
      renderP4Option,
    };
  })
);

export default enhancer(PDFContent);
