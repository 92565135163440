import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Divider,
  FieldArray,
  FileUploadList,
} from "components";
import { formatDate, toCurrency } from "utils/helper";
import { gql } from "utils/helper";
import { filter, sum, sumBy } from "lodash";

const ConfirmPaymentOrder = (props) => (
  <Box mt={-1.5}>
    <Box
      display="flex"
      alignItems="center"
      height={32}
      mt={6}
      style={{ borderBottom: "1px solid #E0E0E0" }}
    >
      <Typography variant="h6" width={95} px={3}>
        รหัส
      </Typography>
      <Typography variant="h6" width={267} px={3}>
        รายการสินค้า
      </Typography>
      <Typography variant="h6" width={100} px={3} textAlign="right">
        จำนวน
      </Typography>
      <Typography variant="h6" width={80} px={3}>
        หน่วย
      </Typography>
      <Typography variant="h6" width={170} px={3} textAlign="right">
        ราคาต่อหน่วย (บาท)
      </Typography>
      <Typography variant="h6" width={170} px={3} textAlign="right">
        ค่าตัด (บาท)
      </Typography>
      <Typography variant="h6" width={150} px={3} textAlign="right">
        ราคาขายรวม (บาท)
      </Typography>
    </Box>

    <Box mt={1.5}>
      {props.orderItems.map((orderItem, index) => (
        <Box key={index} display="flex" alignItems="center" mb={3}>
          <Typography width={95} px={3}>
            {orderItem.productCode}
          </Typography>
          <Typography width={267} px={3}>
            {orderItem.productName}
          </Typography>
          <Typography width={100} px={3} textAlign="right">
            {orderItem.amount ? toCurrency(orderItem.amount) : ""}
          </Typography>
          <Typography width={80} px={3}>
            {
              { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[
                orderItem.sellingUnit
              ]
            }
          </Typography>
          <Typography width={170} px={3} textAlign="right">
            {orderItem.sellingPricePerUnit
              ? toCurrency(orderItem.sellingPricePerUnit)
              : " "}
          </Typography>
          <Typography width={170} px={3} textAlign="right">
            {orderItem.cuttingPrice ? toCurrency(orderItem.cuttingPrice) : ""}
          </Typography>
          <Typography width={150} px={3} textAlign="right">
            {toCurrency(orderItem.totalPrice)}
          </Typography>
        </Box>
      ))}
      {props.deliveryTransactions.map((deliveryTransaction, index) => (
        <Box key={index} display="flex" alignItems="center" mb={3}>
          <Typography width={95} px={3}></Typography>
          <Typography
            width={787}
            px={3}
            color="Text/Black"
            style={{ wordBreak: "break-all" }}
          >
            {`ค่าขนส่ง ${deliveryTransaction.channel} (${deliveryTransaction.trackingNo})`}
          </Typography>
          <Typography width={150} px={3} textAlign="right">
            {toCurrency(deliveryTransaction.cost)}
          </Typography>
        </Box>
      ))}
    </Box>
    <Divider mt={15} />

    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="caption" color="Text/Dark Grey" height={32} px={3}>
          {props.orderDeliveryDate &&
            props.orderDeliveryTime &&
            `*ต้องส่งถึงลูกค้าภายใน ${formatDate(
              props.orderDeliveryDate,
              "dd/MM/yyyy"
            )} เวลา
            ${props.orderDeliveryTime} น.`}
        </Typography>
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography textAlign="right" px={3}>
            ราคารวม
          </Typography>
          <Typography textAlign="right" width={150} px={3}>
            {toCurrency(props.totalItemsPrice)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Typography textAlign="right" px={3}>
            ส่วนลด
          </Typography>
          <Typography textAlign="right" width={150} px={3}>
            {toCurrency(props.discount)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography textAlign="right" px={3}>
            ราคาขายหลังหักส่วนลด
          </Typography>
          <Typography textAlign="right" width={150} px={3}>
            {toCurrency(props.totalPriceWithDiscount)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography textAlign="right" px={3}>
            ภาษีมูลค่าเพิ่ม 7%
          </Typography>
          <Typography textAlign="right" width={150} px={3}>
            {toCurrency(props.vat)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height={32}
        >
          <Typography variant="h6" textAlign="right" px={3}>
            <b>ยอดรวมทั้งหมด</b>
          </Typography>
          <Typography variant="h6" textAlign="right" width={150} px={3}>
            {toCurrency(props.totalPrice)}
          </Typography>
        </Box>
      </Box>
    </Box>
    <Divider mt={6} />
    <Box mb={4}>
      <FieldArray
        component={FileUploadList}
        name="paymentDocuments"
        addButtonLabel="อัปโหลดหลักฐาน"
        simple
      />
    </Box>
  </Box>
);

export const API = {
  CONFIRM_PURCHASE_ORDER: gql`
    mutation CONFIRM_PURCHASE_ORDER(
      $id: ID!
      $orderedRemark: String
      $hasVat: Boolean
    ) {
      confirmPurchaseOrder(
        input: { id: $id, orderedRemark: $orderedRemark, hasVat: $hasVat }
      ) {
        purchaseOrder {
          id
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      mt,
      orderItems,
      orderDeliveryTime,
      orderDeliveryDate,
      deliveryTransactions,
      discount,
    } = props;
    const { useMemo } = hooks;

    const {
      totalItemsPrice,
      totalPriceWithDiscount,
      vat,
      totalPrice,
    } = useMemo(() => {
      const items = filter(orderItems, (item) => item.productId).map(
        (item) =>
          (item.amount ?? 0) * (item.sellingPricePerUnit ?? 0) +
          (item.cuttingPrice ?? 0)
      );

      const totalItemsPrice =
        sum(items, "price") + sumBy(deliveryTransactions, "cost");
      const totalPriceWithDiscount = totalItemsPrice - (discount ?? 0);
      const vat = totalPriceWithDiscount * 0.07;
      const totalPrice = totalPriceWithDiscount + vat;

      return {
        totalItemsPrice,
        totalPriceWithDiscount,
        vat,
        totalPrice,
      };
    }, [orderItems, discount, deliveryTransactions]);

    return {
      mt,
      orderItems,
      deliveryTransactions,
      totalItemsPrice,
      totalPrice,
      vat,
      orderDeliveryTime,
      orderDeliveryDate,
      totalPriceWithDiscount,
      discount,
    };
  })
);

export default enhancer(ConfirmPaymentOrder);
