import { compose, withHooks } from "enhancers";
import { Box, Field, Typography, Button, Editor, Hidden } from "components";
import {} from "utils/helper";

const OrderNoteInfo = (props) => (
  <Box mt={props.mt}>
    <Typography variant="h4" color="Text/Black">
      บันทึกเพิ่มเติม
    </Typography>

    <Field
      component={Editor}
      name="note"
      mt={6}
      disabled={props.disabledForm}
    />

    <Hidden when={props.disabledForm}>
      <Button
        onClick={props.handleSubmit}
        disabled={props.disabledSubmitButton}
        color="primary"
        mt={6}
      >
        บันทึก
      </Button>
    </Hidden>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useState, useCallback } = hooks;
    const { mt, handleSubmit, disabled, canceled } = props;
    const [submitting, setSubmitting] = useState(false);

    const customHandleSubmit = useCallback(async () => {
      setSubmitting(true);
      await handleSubmit();
      setSubmitting(false);
    }, [handleSubmit]);

    const disabledSubmitButton = submitting || disabled;

    const disabledForm = canceled;

    return {
      mt,
      handleSubmit: customHandleSubmit,
      disabledSubmitButton,
      disabledForm,
    };
  })
);

export default enhancer(OrderNoteInfo);
