import { compose, withHooks } from "enhancers";
import { MuiTextField } from "components";
import { toCurrency } from "utils/helper";

const OrderItem = (props) => (
  <>
    <MuiTextField
      placeholder="เพิ่มรายการสินค้า/บริการ"
      flex={1}
      mr={2}
      disabled
      value={props.productFullName}
    />
    <MuiTextField
      inputProps={{ style: { textAlign: "right" } }}
      placeholder="0.00"
      width={90}
      mr={2}
      disabled
      value={toCurrency(props.amount ?? 0, { minimumFractionDigits: 0 })}
    />
    <MuiTextField
      placeholder="หน่วย"
      width={80}
      mr={2}
      disabled
      value={props.sellingUnitLabel}
    />
    <MuiTextField
      inputProps={{ style: { textAlign: "right" } }}
      placeholder="0.00"
      width={90}
      mr={2}
      disabled
      value={toCurrency(props.costPricePerUnit ?? 0, {
        minimumFractionDigits: 0,
      })}
    />
    <MuiTextField
      inputProps={{ style: { textAlign: "right" } }}
      placeholder="0.00"
      width={120}
      mr={2}
      disabled
      value={toCurrency(props.cuttingPrice ?? 0, {
        minimumFractionDigits: 0,
      })}
    />
    <MuiTextField
      inputProps={{ style: { textAlign: "right" } }}
      placeholder="0.00"
      width={120}
      disabled
      value={toCurrency(props.totalPrice ?? 0, { minimumFractionDigits: 0 })}
    />
  </>
);

const enhancer = compose(
  withHooks((props) => {
    const { field } = props;

    const {
      amount,
      cuttingPrice,
      productCode,
      productName,
      costPricePerUnit,
      sellingUnit,
      sellerProductCode,
    } = field?.value ?? {};

    const productFullName = `[${sellerProductCode || productCode}] - ${
      productName ?? ""
    }`;
    const sellingUnitLabel = { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[
      sellingUnit
    ];
    const totalPrice =
      (amount ?? 0) * (costPricePerUnit ?? 0) + (cuttingPrice ?? 0);

    return {
      productFullName,
      amount,
      sellingUnitLabel,
      costPricePerUnit,
      cuttingPrice,
      totalPrice,
    };
  })
);

export default enhancer(OrderItem);
