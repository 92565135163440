import { compose, withHooks } from "enhancers";
import { Box, Field, Checkbox, Typography, Divider } from "components";
import { get, map } from "lodash";
import { ReactComponent as StoreIcon } from "assets/icon/store.svg";

const OrderItemList = (props) => (
  <Box mt={props.mt}>
    {props.values.map((item, index) => (
      <>
        <Box
          key={index}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          mb={2}
        >
          <Box display="flex">
            <Field
              component={Checkbox}
              name={`${props.name}[${index}].confirmed`}
              type="checkbox"
              className="OrderItemChecked"
              ml={0}
              mr={2}
              width={40}
              disabled={props.disabled}
            />
            <Box width={300} ml={3}>
              <Box display="flex" alignItems="center">
                <Typography>
                  {item.productCode} - {item.productName}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className="OrderSellerInfo-social-contact"
              >
                <StoreIcon />
                <Typography ml="4px !important" variant="caption">
                  {item.sellerEnName}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" mr={2}>
            <Typography>{item.amount}</Typography>
            <Typography width={68} textAlign="right">
              {props.convertUnitToTh(item.sellingUnit)}
            </Typography>
          </Box>
        </Box>
        <Divider mr={2} />
      </>
    ))}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { form, name, mt, readOnly, disabled } = props;
    const { useCallback, useMemo } = hooks;

    const values = get(form.values, name) ?? [];

    const checkedListTemp = map(values, "confirmed");
    const checkedList = useMemo(() => {
      return checkedListTemp;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(checkedListTemp)]);

    const convertUnitToTh = useCallback((unit) => {
      return { kg: "กิโลกรัม", yard: "หลา", metre: "เมตร" }[unit];
    }, []);

    return {
      mt,
      name,
      readOnly,
      checkedList,
      disabled,
      values,
      convertUnitToTh,
    };
  })
);

export default enhancer(OrderItemList);
