import { compose, withHooks, withFormik, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Tabs,
  Tab,
  Paper,
  Button,
  Field,
  TextField,
  Typography,
  Modal,
  Hidden,
} from "components";
import {
  gql,
  paths,
  cleanTypename,
  notifyError,
  notifySuccess,
  toCurrency,
  getErrorMessage,
} from "utils/helper";

import { cloneDeep, get } from "lodash";
import { format, parseISO } from "date-fns";

import OrderEditPage from "./edit";
import OrderHistoryPage from "./history";
import CancelRemarkCard from "./CancelRemarkCard";
import { ReactComponent as DaftOrderIcon } from "vendor/menu_icon/draft_order.svg";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const OrderShowPage = (props) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={[]}
    paper={false}
    sync={props.sync}
  >
    {props.initialized ? (
      <Box mt={4}>
        <Hidden when={!props.canCancel}>
          <CancelRemarkCard message={props.canceledRemark} />
        </Hidden>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Tabs value={props.tabState} onChange={props.changeTabState}>
            <Tab label="สถานะคำสั่งซื้อ" />
            <Tab label="ประวัติการแก้ไข" />
          </Tabs>
          <Box>
            <Hidden when={!props.draftOrderInfo}>
              <Button {...props.draftOrderInfo} />
            </Hidden>
            <Hidden when={props.canCancel}>
              <Button
                onClick={props.showCancelOrderModal}
                style={{ color: "#F34336", borderColor: "#F34336" }}
                ml={4}
              >
                ยกเลิกคำสั่งซื้อ
              </Button>
            </Hidden>
          </Box>
        </Box>
        <Paper px={4} pt={4} pb={6}>
          {props.creatOrderInfo && (
            <Box
              display="flex"
              alignItems="center"
              style={{
                background: "#E7F4FD",
                borderRadius: 8,
              }}
              p={4}
              mb={4}
            >
              <InfoOutlinedIcon
                style={{
                  color: "#376FD0",
                  width: "24px",
                  height: "24px",
                  marginRight: "8px",
                }}
              />
              <Typography variant="body1" color="#0D3C61">
                {props.creatOrderInfo}
              </Typography>
            </Box>
          )}
          <Box display={props.tabState === 0 ? "block" : "none"}>
            <OrderEditPage
              configs={props.configs}
              order={props.order}
              reloadOrder={props.reloadOrder}
            />
          </Box>
          <Box display={props.tabState === 1 ? "block" : "none"}>
            <OrderHistoryPage histories={props.orderHistories} />
          </Box>
        </Paper>
      </Box>
    ) : null}
  </PageContent>
);

export const API = {
  FETCH_ORDER: gql`
    query FETCH_ORDER($id: ID!) {
      order(id: $id) {
        id
        code
        note
        remark
        discount
        canEditBuyerInfo
        canViewSellerInfo
        canEditOrderItemsInfo
        canceled
        canceledRemark
        createdAt
        lastSyncUpAt
        draftOrder {
          id
          shopifyOrderId
          lastSyncDownAt
        }
        buyer {
          id
          code
          firstName
          lastName
          nickName
          brand
          note
          deliveryAddresses {
            id
            address
            addressType
            branchName
            companyName
            district
            firstName
            gmapUrl
            isMain
            lastName
            ownerType
            phoneNumber
            province
            subDistrict
            taxPayerType
            zipCode
            taxId
            remark
            isMain
          }
        }
        deliveryDate
        deliveryTime
        deliveryAddress {
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          id
          isMain
          lastName
          ownerType
          phoneNumber
          province
          remark
          subDistrict
          taxId
          taxPayerType
          zipCode
        }
        oldSystemInvoiceCode
        orderCheckList {
          finalPrice
          status
          quotationInfo {
            deliveryDate
            deliveryTime
            completedAt
            deliveryTime
            status
            canConfirm
            orderItems {
              productCode
              productName
              amount
              sellingUnit
              sellingPricePerUnit
              cuttingPrice
              totalPrice
            }
            totalItemsPrice
            discount
            totalItemPriceWithDiscount
            vat
            totalPrice
          }
          poOrderingInfo {
            completedAt
            status
            sellerItems {
              thName
              enName
              code
              poCodes
              completedAt: orderedAt
              orderItems {
                id
                productName
                productCode
                sellingUnit
                amount
              }
            }
          }
          poPaymentInfo {
            completedAt
            status
            sellerItems {
              thName
              enName
              code
              poCodes
              completedAt: paymentedAt
              orderItems {
                id
                productName
                productCode
                sellingUnit
                amount
              }
            }
          }
          poRecievingInfo {
            completedAt
            status
            sellerItems {
              thName
              enName
              code
              poCodes
              completedAt: receivedAt
              orderItems {
                id
                productName
                productCode
                sellingUnit
                amount
              }
            }
          }
          deliveryOrder {
            completedAt
            status
            totalShippingCost
            orderItems {
              id
              productId
              confirmed
              sellingUnit
              costPricePerUnit
              sellingPricePerUnit
              cuttingPrice
              amount
              productCode
              productName
              sellerEnName
              totalCost
            }
            deliveryTransactions {
              id
              channel
              cost
              trackingNo
              createdAt
              updatedAt
              completedAt
              deliveryAddress {
                address
                addressType
                branchName
                companyName
                district
                firstName
                gmapUrl
                id
                isMain
                lastName
                ownerType
                phoneNumber
                province
                remark
                subDistrict
                taxId
                taxPayerType
                zipCode
              }
              orderItems {
                id
                productId
                productName
                productCode
                amount
                sellingUnit
                sellerEnName
              }
            }
          }
          invoicingOrder {
            completedAt
            orderItems {
              id
              productId
              confirmed
              sellingUnit
              costPricePerUnit
              sellingPricePerUnit
              cuttingPrice
              amount
              productCode
              productName
              sellerEnName
              totalCost
              totalPrice
            }
          }
          paymentOrder {
            paymentDocuments
            completedAt
            orderItems {
              id
              productId
              confirmed
              sellingUnit
              costPricePerUnit
              sellingPricePerUnit
              cuttingPrice
              amount
              productCode
              productName
              sellerEnName
              totalCost
              totalPrice
            }
          }
        }
        orderSellerInfo {
          sellerItems {
            id
            secondaryChannel
            enName
            ig
            line
            facebook
            email
            orderItems {
              id
              productId
              productName
              productCode
              amount
              inStockAmount
              remainingAmount
            }
          }
        }
        orderItems {
          id
          productId
          confirmed
          sellingUnit
          costPricePerUnit
          sellingPricePerUnit
          cuttingPrice
          amount
          poCode
          poSlug
          product {
            id
            code
            productName
            sellingUnit
            costPerCapital
            pricePerCapital
            totalQuantity
            quantityPerFold
            lastActiveAt
            seller {
              code
              thName
              enName
              sellingCondition
              deliveryCondition
              paymentCondition
              note
            }
          }
        }
        actionHistories {
          id
          description
          modifiedName
          updatedAt
        }
      }
    }
  `,
  CANCEL_ORDER: gql`
    mutation CANCEL_ORDER($id: ID!, $canceledRemark: String!) {
      cancelOrder(input: { id: $id, canceledRemark: $canceledRemark }) {
        order {
          id
        }
      }
    }
  `,
  FETCH_CONFIG: gql`
    query FETCH_CONFIG {
      buyers {
        id
        code
        firstName
        lastName
        nickName
        brand
        note
        deliveryAddresses {
          address
          addressType
          branchName
          companyName
          district
          firstName
          gmapUrl
          id
          isMain
          lastName
          ownerType
          phoneNumber
          province
          remark
          subDistrict
          taxId
          taxPayerType
          zipCode
        }
      }
      products {
        products {
          id
          code
          productName
          sellingUnit
          costPerCapital
          pricePerCapital
          totalQuantity
          quantityPerFold
          lastActiveAt
          seller {
            code
            thName
            enName
            sellingCondition
            deliveryCondition
            paymentCondition
            note
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({}),
  withFormik({
    mapPropsToValues: () => ({
      published: false,
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useQuery,
      useMutation,
      useEffect,
      useParams,
      useLocation,
      useCallback,
      useState,
    } = hooks;

    const { id } = useParams();

    const { loading, data, error, refetch } = useQuery(API.FETCH_ORDER, {
      variables: { id },
    });
    // const configs = useQuery(API.FETCH_CONFIG);
    const [cancelOrder] = useMutation(API.CANCEL_ORDER);

    const initialized = !loading;

    useEffect(() => {
      refetch();
    }, [refetch]);

    const order = useMemo(() => {
      if (loading) {
        return null;
      }

      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.ordersPath().push();
        return null;
      }
      return cleanTypename(cloneDeep(data.order));
    }, [loading, data, error]);

    const [tabState, setTabState] = useState(0);
    const changeTabState = useCallback(
      (e, value) => {
        if (value === 0) {
          paths.orderEditPath(id).push("");
        } else if (value === 1) {
          paths.orderEditPath(id).push("#action_histories");
        }
      },
      [id]
    );

    const location = useLocation();
    const hashTag = location.hash;
    useEffect(() => {
      if (hashTag === "#action_histories") {
        setTabState(1);
      } else {
        setTabState(0);
      }
    }, [hashTag]);

    const title = order?.code;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.ordersPath(), label: "คำสั่งซื้อ" },
        { path: null, label: title },
      ];
    }, [title]);

    const showCancelOrderModal = useCallback(() => {
      const id = get(order, "id");
      const code = get(order, "code");
      const totalPrice = get(order, "orderCheckList.quotationInfo.totalPrice");

      Modal.open({
        title: "ยกเลิกคำสั่งซื้อ",
        className: "reject-modal",
        children: (
          <Box>
            <Box display="flex">
              <Typography color="Text/Dark Grey">
                ต้องการยกเลิกคำสั่งซื้อ&nbsp;
              </Typography>
              <Typography>
                {code} มูลค่า {toCurrency(totalPrice)} บาท
              </Typography>
            </Box>
            <Field
              component={TextField}
              name="canceledRemark"
              type="text"
              label="สาเหตุที่ยกเลิก"
              fullWidth
              multiline
              className="height-136"
              mt={4}
            />
          </Box>
        ),
        cancelButtonLabel: "ปิด",
        okButtonLabel: "ยกเลิกคำสั่งซื้อ",
        onOk: async ({ close, values }) => {
          try {
            const { canceledRemark } = values || {};

            if (!canceledRemark) {
              notifyError("โปรดใส่เหตุผล");
              return;
            }

            await cancelOrder({ variables: { id, canceledRemark } });
            await refetch();
            close();
            paths.ordersPath().push();
            notifySuccess("ยกเลิกรายการสั่งซื้อสำเร็จ");
          } catch (e) {
            notifyError(e);
          }
        },
      });
    }, [refetch, cancelOrder, order]);

    const canCancel = order?.canceled;
    const canceledRemark = order?.canceledRemark;

    const orderHistories = useMemo(() => {
      return order?.actionHistories || [];
    }, [order]);

    const draftOrderInfo = useMemo(() => {
      if (order?.draftOrder && orderHistories.length === 1) {
        return {
          children: "ดูร่างคำสั่งซื้อ",
          startIcon: <DaftOrderIcon />,
          onClick: () =>
            paths.draftOrderDetailPath(order?.draftOrder.id).push(),
          variant: "outlined",
          color: "primary",
        };
      }
      return null;
    }, [order, orderHistories]);

    const creatOrderInfo = useMemo(() => {
      if (order?.draftOrder && orderHistories.length === 1) {
        return `สร้างจากคำสั่งซื้อของ Shopify ${
          order?.draftOrder?.shopifyOrderId
        } เมื่อ ${format(parseISO(order.createdAt), "dd/MM/yyyy, HH:mm")}`;
      }
      return null;
    }, [order, orderHistories]);

    const sync = useMemo(() => {
      if (order?.lastSyncUpAt && order?.draftOrder?.lastSyncDownAt) {
        const lastSyncUpAt = new Date(order?.lastSyncUpAt);
        const lastSyncDownAt = new Date(order?.draftOrder?.lastSyncDownAt);
        if (lastSyncUpAt > lastSyncDownAt) {
          return `ส่งข้อมูลล่าสุด: ${format(
            parseISO(order?.lastSyncUpAt),
            "dd/MM/yyyy, HH:mm"
          )}`;
        } else {
          return `รับข้อมูลล่าสุด: ${format(
            parseISO(order?.draftOrder?.lastSyncDownAt),
            "dd/MM/yyyy, HH:mm"
          )}`;
        }
      }
      if (order?.lastSyncUpAt) {
        return `ส่งข้อมูลล่าสุด: ${format(
          parseISO(order?.lastSyncUpAt),
          "dd/MM/yyyy, HH:mm"
        )}`;
      }
      if (order?.draftOrder?.lastSyncDownAt) {
        return `รับข้อมูลล่าสุด: ${format(
          parseISO(order?.draftOrder?.lastSyncDownAt),
          "dd/MM/yyyy, HH:mm"
        )}`;
      }
      return null;
    }, [order]);

    return {
      title,
      breadcrumbs,
      initialized,
      tabState,
      changeTabState,
      showCancelOrderModal,
      order,
      reloadOrder: refetch,
      // configs: configs.data,
      canCancel,
      canceledRemark,
      orderHistories,
      draftOrderInfo,
      creatOrderInfo,
      sync,
    };
  })
);

export default enhancer(OrderShowPage);
