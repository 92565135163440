import { compose, withHooks } from "enhancers";
import { Box, Field, Select, MuiTextField } from "components";
import { toCurrency } from "utils/helper";

const DeliveryItem = (props) => (
  <>
    <Field
      component={Select}
      value={props.channel}
      options={props.optionsChannels}
      placeholder="เพิ่มรายการสินค้า/บริการ"
      width={326}
      mr={2}
      disabled
    />
    <MuiTextField width={90} mr={2} disabled />
    <MuiTextField width={80} mr={2} disabled />
    <MuiTextField width={90} mr={2} disabled />
    <MuiTextField width={90} mr={2} disabled />
    <MuiTextField width={100} mr={2} disabled />
    <MuiTextField
      inputProps={{ style: { textAlign: "right" } }}
      placeholder="0.00"
      width={120}
      mr={2}
      value={toCurrency(props.cost ?? 0, { minimumFractionDigits: 0 })}
      disabled
    />
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={24}
      height={32}
    />
  </>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { deliveryTransaction } = props;

    return {
      channel: deliveryTransaction?.channel ?? "",
      optionsChannels: [
        {
          label: `ค่าขนส่ง ${deliveryTransaction?.channel ?? ""} (${
            deliveryTransaction?.trackingNo ?? ""
          })`,
          value: deliveryTransaction?.channel ?? "",
        },
      ],
      cost: deliveryTransaction?.cost ?? 0,
    };
  })
);

export default enhancer(DeliveryItem);
